import { FileType } from '@/Models/page'

export enum FileMimeType {
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  png = 'image/png',
  jpeg = 'image/jpeg',
  pdf = 'application/pdf',
  text = 'text/plain',
}

export const fileTypeNumToMime = (fileType: FileType) => {
  switch (fileType) {
    case FileType.xls: return FileMimeType.xls
    case FileType.xlsx: return FileMimeType.xlsx
    case FileType.doc: return FileMimeType.doc
    case FileType.docx: return FileMimeType.docx
    case FileType.ppt: return FileMimeType.ppt
    case FileType.pptx: return FileMimeType.pptx
    case FileType.png: return FileMimeType.png
    case FileType.jpeg: return FileMimeType.jpeg
    case FileType.pdf: return FileMimeType.pdf
    case FileType.text: return FileMimeType.text
  }
}
