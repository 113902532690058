import BackendAdapter from '@/Api/_adapter'
import { IConfiguration } from '@/Models/configuration'

export const apiGetDynamicConfiguration = async (): Promise<IConfiguration[]> => {
  try {
    return await BackendAdapter.get('/api/settings/dynamic')
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiUpdateDynamicConfiguration = async (configurations: IConfiguration[]): Promise<void> => {
  try {
    return await BackendAdapter.post('/api/settings/dynamic', configurations)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
