import { ReactNode, CSSProperties } from 'react'
import { useMount } from 'react-use'
import stickybits from 'stickybits'

import { Container } from './styled'

interface ISticky {
  style?: CSSProperties
  verticalPosition: 'top' | 'bottom'
  verticalOffset: number
  children: ReactNode
}

const Sticky = ({ children, verticalOffset, verticalPosition, style }: ISticky) => {
  const componentName = `Sticky-${Math.random().toString(36).substring(7)}`

  useMount(() => {
    const pinned = stickybits(`#${componentName}`, { stickyBitStickyOffset: verticalOffset, verticalPosition: verticalPosition })
    window.scrollTo({ top: 1 }) // фикс для ИЕ

    return () => {
      pinned?.cleanup()
    }
  })

  return (<Container style={style} verticalPosition={verticalPosition} verticalOffset={verticalOffset} id={componentName}>
    {children}
  </Container>)
}

export default Sticky
