import styled from 'styled-components'
import { TableCell } from '@mui/material'

export const getColor = (value: number) => {
  if (isNaN(value)) return 'transparent'
  if (value <= 50) return 'rgba(215,90,90,0.4)'
  if (value <= 80) return 'rgba(253,228,155,0.6)'
  else return 'rgba(122,214,148,0.6)'
}

export const TableCellStyled = styled(TableCell)`
  border-left: 1px solid rgba(224, 224, 224, 1);
  font-size: 14px;
`

export const TableCellBoldStyled = styled(TableCell)`
  font-weight: bold;
  font-size: 14px;
  &.MuiTableCell-footer{
    color: black;
    font-size: 14px;
    font-weight: bold;
  }
  &.MuiTableCell-head{
    color: black;
    font-size: 14px;
    font-weight: bold;
  }
`

export const TableCellWithBackgroundStyled = styled(TableCellStyled)<{ value: string }>`
  text-align: center;
  ${props => `background-color: ${props.value.length === 0 ? '' : getColor(Number(props.value))};`}
`
