import { useEffect, useState } from "react";

export const useMouseMove = (onMove: () => void) => {
  useEffect(() => {
    const onMoveEvent = onMove;
    window.addEventListener("mousemove", onMoveEvent);

    return () => {
      window.removeEventListener("mousemove", onMoveEvent);
    };
  }, []);
};