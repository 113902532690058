import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import bem from '@/Helpers/BemClass'

import './style.scss'

const cnPollsVersion = bem()('version')

function VersionLabel() {
  const muiTheme = useTheme()
  // const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))

  return (
    <Box className={cnPollsVersion(/* { 'non-sticky': UIisMobile } */)}>
      <Typography variant='caption' component='div' className={cnPollsVersion('wrapper')}>
        <div className={cnPollsVersion('label', { left: true })}>{ import.meta.env.VITE_APP_VERSION }</div>
        <div className={cnPollsVersion('label', { right: true })}>{ import.meta.env.VITE_BUILD_DATETIME }</div>
      </Typography>
    </Box>
  )
}

export default VersionLabel
