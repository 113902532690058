import { Children, ReactNode } from 'react'

import { rootStore } from '@/Stores/rootStore'
import { IPermissionOut } from '@/Stores/sharedModels/models'
import { IPermissionRequisites } from '@/Stores/UserStores'

interface IRoleQualifier {
  roles: IPermissionRequisites[] | IPermissionRequisites
  permissionValidation?: (permission: IPermissionOut) => boolean
  children: ReactNode
}

// TODO: добавить реализацию для коллекции фич
export const isRole = (roles: IPermissionRequisites[]) => {
  return rootStore.user.searchRole(roles)
}

const RoleQualifier = ({ roles, children, permissionValidation = (_) => true }: IRoleQualifier) => {
  const ch = Children.only(children)

  if ((Array.isArray(roles))) {
    const permission = isRole(roles)
    if (permission !== null && permissionValidation(permission)) {
      return <>{ch}</>
    } else {
      return <></>
    }
  }

  const permission = isRole([roles])
  if (permission && permissionValidation(permission)) {
    return <>{ch}</>
  }

  return <></>
}

export default RoleQualifier
