import { useEffect, useRef, useState } from 'react'
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Typography,
  Link,
  Box,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import _ from 'lodash'

import { apiGetFile, apiGetUnknownFile } from '@/Api/file'
import { IOptionsInfo, IQuestionAnswersInfo } from '@/Models/answer'
import {
  AnswersExtraDataTypes,
} from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/PageAnswers'
import ActionComponent from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/Actions'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cntAnswerTab = bem()('answer-tab')

// TODO: улучшить тайпинги
interface ITableQuestionView {
  questionInfo: IQuestionAnswersInfo
}

export const TableQuestionView = ({
  questionInfo,
}: ITableQuestionView) => {
  const [customAnswersAccordionIsOpened, setСustomAnswersAccordionIsOpened] = useState<boolean>(false)

  const accordionRef = useRef<HTMLDivElement>(null)

  const handleEntered = () => {
    setСustomAnswersAccordionIsOpened(prev => !prev)
  }

  useEffect(() => {
    if (customAnswersAccordionIsOpened && accordionRef.current) {
      const elementTop = accordionRef.current.getBoundingClientRect().top
      const offset = window.pageYOffset
      const target = elementTop + offset - 300
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      })
    }
  }, [customAnswersAccordionIsOpened])

  const elNoAnswersAvailGeneral = (
    <Box display='flex' flexDirection='column' paddingY={1} height='100%'>
      <Typography variant='subtitle1' gutterBottom>
        Нет ответов
      </Typography>
    </Box>
  )

  const elAnswersExtraData = (
    type: AnswersExtraDataTypes,
  ) => {
    return (
      <Accordion expanded={customAnswersAccordionIsOpened} elevation={0} variant='outlined' square ref={accordionRef}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={handleEntered}>
          <Button
            color='primary'
            size='large'
            style={{ textTransform: 'none', textDecoration: 'underline' }}
          >
            {type === AnswersExtraDataTypes.CustomVariant ? questionInfo.additionInfo.groupName : 'Комментарии'}
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer style={{ maxHeight: 500 }}>
            <Table
              className={cntAnswerTab('table-question-view-bordered')}
              component={Paper}
              size='small'
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {type === AnswersExtraDataTypes.Comments && <TableCell component='th' scope='row'>
                    <b>
                      Текст комментария
                    </b>
                  </TableCell>}
                  {type === AnswersExtraDataTypes.CustomVariant && questionInfo.additionInfo.headers.map((value, i) => <TableCell align={i === 0 ? 'left' : 'center'} key={value} component='th' scope='row'>
                    {i === 0 && <b>
                      {value}
                    </b>}
                    {i !== 0 && value}
                  </TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {type === AnswersExtraDataTypes.Comments && questionInfo.comments.map((comment, i) => <TableRow key={i}>
                  <TableCell align='left'>
                    {comment}
                  </TableCell>
                </TableRow>)}
                {type === AnswersExtraDataTypes.CustomVariant && questionInfo.additionInfo.cells.map((row, i) => (
                  <TableRow key={i}>
                    {row.map((cell, i) => (
                      <TableCell key={cell.value} align={i === 0 ? 'left' : 'center'}>
                        {cell.fileHash === null && cell.fileUrl === null ? cell.value : <Link
                          component="button"
                          variant="caption"
                          onClick={async () => (cell.fileHash ? await apiGetFile(cell.fileHash) : await apiGetUnknownFile(cell.fileUrl ?? ''))}
                        >
                          {cell.value}
                        </Link>}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {questionInfo.actions.map(action => (
                  action.fileUrl ? (<div key={action.text}>
                    <Link
                      component="button"
                      variant="caption"
                      onClick={async () => await apiGetUnknownFile(action.fileUrl ?? '')}
                    >
                      {action.text}
                    </Link>
                  </div>) : action.type != null ? (<div key={action.text}>
                    <ActionComponent action={action} questionId={questionInfo.questionId}/>
                  </div>) : (<div key={action.text}>
                    {action.text}
                  </div>)
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <>
      {questionInfo.options.length > 0 && questionInfo.options.some(x => x.count > 0) && <TableContainer style={{ maxHeight: 500 }}>
        <Table
          className={cntAnswerTab('table-question-view-bordered')}
          component={Paper}
          size='small'
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell />
              {/* Шапка таблицы в виде вариантов ответов */}
              {questionInfo.options.map(
                (option: IOptionsInfo, i) => (
                  <TableCell className={cntAnswerTab('table-question-answer-variant')} key={i} component='th' scope='row' align='center'>
                    {option.value !== 'Свой вариант' ? option.value : <Link component='button' onClick={handleEntered}>{option.value}</Link>}
                  </TableCell>
                )
              )}
              <TableCell align='center'>Всего</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Ответы</TableCell>
              {questionInfo.options.map(
                (option) => (
                  <TableCell key={option.value} align='center'>
                    {option.count}
                  </TableCell>
                )
              )}
              <TableCell align='center'>
                {_.sum(
                  questionInfo.options.map(x => x.count)
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>%</TableCell>
              {questionInfo.options.map(
                (option) => (
                  <TableCell key={option.value} align='center'>
                    {Number(option.percent)?.toFixed(2) ?? 0}%
                  </TableCell>
                )
              )}
              <TableCell align='center'>100%</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>}
      {questionInfo.options.length > 0 && questionInfo.options.every(x => x.count === 0) && elNoAnswersAvailGeneral}
      <Typography style={{ margin: '8px 0' }}>
        {
          // Раздел 'Свой вариант'
          questionInfo.additionInfo.headers.length > 0 && questionInfo.additionInfo.cells.length > 0 && elAnswersExtraData(AnswersExtraDataTypes.CustomVariant)
        }
        {
          // Раздел 'Комментарии'
          questionInfo.comments.length > 0 && elAnswersExtraData(AnswersExtraDataTypes.Comments)
        }
      </Typography>
    </>
  )
}

export default TableQuestionView
