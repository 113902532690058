import { useState } from 'react'
import { Route, Switch, useHistory } from 'react-router'
import { useMount } from 'react-use'

import { isExpressSmartApp, loadConfig } from '@/applicationConfig'
import UserLayout from '@/Layouts/User'
import PrivateRoute from '@/Components/PrivateRoute'
import { RouteEnum } from '@/Enums/route'
import AdminLayout, { MenuAdminPage } from '@/Layouts/Admin'
import SurveyListPage from '@/Pages/Admin/SurveyListPage'
import SurveyInfoPage from '@/Pages/Admin/SurveyInfoPage'
import OrganizationStructurePage from '@/Pages/Admin/OrganizationStructurePage'
import ServiceListPage from '@/Pages/Admin/ServiceListPage'
import { useMst } from '@/Stores/rootStore'
import { PermissionType } from '@/Stores/sharedModels/models'
import { SurveysStoreFilter } from '@/Stores/AdminStores/surveysStore'
import { configureAxios } from '@/Api/_setupApi'

const Pages = () => {
  const [isConfigLoading, setIsConfigLoading] = useState(true)
  const UserStore = useMst().user
  const history = useHistory()

  useMount(() => {
    (async () => {
      configureAxios(history)
      console.log('Подгрузка конфига с бэкенда')

      await loadConfig()
        .catch(x => console.error('Ошибка загрузки конфига:', x))

      if (isExpressSmartApp()) {
        console.log('[SmartappApp] Конфиг загружен. Запрашиваем информацию о пользователе')
        await UserStore.loadUserInfoFromExpress()
      } else {
        console.log('[WebApp] Конфиг загружен')
      }

      // Обязательно вызываем после loadUserInfoFromExpress чтобы стоял корректный куки авторизации
      const count = await UserStore.loadUserPermissions()
      console.log(`Загрузили [${count}] прав`)

      setIsConfigLoading(false)
    })()
  })

  if (isConfigLoading) return <></>

  return (<Switch>
    <Route path={`*/${RouteEnum.estimate}`}>
      <UserLayout routeEnum={RouteEnum.estimate}/>
    </Route>
    <Route path={`*/${RouteEnum.statistic}/:surveyId/`}>
      <UserLayout additionNavigation='Результаты' routeEnum={RouteEnum.statistic} />
    </Route>
    <Route path={`*/${RouteEnum.questionPage}/:surveyId/:pageId?`}>
      <UserLayout routeEnum={RouteEnum.questionPage}/>
    </Route>
    <Route path={`*/${RouteEnum.startPage}/:surveyId`}>
      <UserLayout routeEnum={RouteEnum.startPage}/>
    </Route>
    <Route path={`*/${RouteEnum.directLink}/:surveyId`}>
      <UserLayout routeEnum={RouteEnum.directLink}/>
    </Route>

    <PrivateRoute roles={[{ type: PermissionType.admin }, { type: PermissionType.expert }, { type: PermissionType.editor }]} path={`*/${RouteEnum.survey}/:surveyId`}>
      <AdminLayout menuItem={MenuAdminPage.surveyList} disableMainTabs leftRightMargin={20}>
        <SurveyInfoPage/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute roles={[{ type: PermissionType.admin }, { type: PermissionType.expert }, { type: PermissionType.editor }]} path={`*/${RouteEnum.survey}`}>
      <AdminLayout menuItem={MenuAdminPage.surveyList} leftRightMargin={50}>
        <SurveyListPage key='survey-list-not-archived' surveyListFilter={SurveysStoreFilter.Default} showAddSurveyButton/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute roles={[{ type: PermissionType.admin }, { type: PermissionType.expert }, { type: PermissionType.editor }]} path={`*/${RouteEnum.archive}/:surveyId`}>
      <AdminLayout menuItem={MenuAdminPage.archive} disableMainTabs leftRightMargin={20}>
        <SurveyInfoPage/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute roles={[{ type: PermissionType.admin }, { type: PermissionType.expert }, { type: PermissionType.editor }]} path={`*/${RouteEnum.archive}`}>
      <AdminLayout menuItem={MenuAdminPage.archive} leftRightMargin={50}>
        <SurveyListPage key='survey-list-archived' surveyListFilter={SurveysStoreFilter.Archived}/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute roles={[{ type: PermissionType.admin }, { type: PermissionType.editor }]} path={`*/${RouteEnum.review}/:surveyId`}>
      <AdminLayout menuItem={MenuAdminPage.review} disableMainTabs leftRightMargin={20}>
        <SurveyInfoPage/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute roles={[{ type: PermissionType.admin }, { type: PermissionType.editor }]} path={`*/${RouteEnum.review}`}>
      <AdminLayout menuItem={MenuAdminPage.review} leftRightMargin={50}>
        <SurveyListPage key='survey-list-review' surveyListFilter={SurveysStoreFilter.Review}/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute roles={[{ type: PermissionType.admin }]} path={`*/${RouteEnum.organizationStructure}`}>
      <AdminLayout menuItem={MenuAdminPage.organizationStructure} leftRightMargin={50}>
        <OrganizationStructurePage/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute roles={[{ type: PermissionType.admin }]} path={`*/${RouteEnum.services}`}>
      <AdminLayout menuItem={MenuAdminPage.services} leftRightMargin={50}>
        <ServiceListPage/>
      </AdminLayout>
    </PrivateRoute>
    <Route path={`*/${RouteEnum.clientSurveys}`}>
      <UserLayout routeEnum={RouteEnum.clientSurveys}/>
    </Route>
  </Switch>)
}

export default Pages
