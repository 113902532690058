import { ReactNode } from 'react'
import { observer } from 'mobx-react'

import bem from '@/Helpers/BemClass'

import './style.scss'

const cnOrganizationStructureSectionData = bem()('organization-structure-tree-view-with-extending')

interface IOrganizationStructureSectionData {
  children: ReactNode
  halfWidth?: boolean
  disableRounding?: boolean
}

const OrganizationStructureSectionData = ({ children, halfWidth = false, disableRounding = false }: IOrganizationStructureSectionData) => {
  return <div className={cnOrganizationStructureSectionData({ 'full-width': !halfWidth, 'half-width': halfWidth, 'disable-rounding': disableRounding })}>
    {children}
  </div>
}

export default observer(OrganizationStructureSectionData)
