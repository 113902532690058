import { Button } from '@mui/material'
import { observer } from 'mobx-react'

import { useMst } from '@/Stores/rootStore'
import { Dialog } from '@/Components'

interface IDialogExitWithoutSave {
  onConfirm: () => void
  onClose: () => void
}

const DialogExitWithoutSave = ({ onConfirm, onClose }: IDialogExitWithoutSave) => {
  const store = useMst()
  const tabStore = store.admin.tabStore

  const handleRedirect = () => {
    onConfirm()
    tabStore.setWasChange(false)
    onClose()
  }

  const actions = [
    <Button onClick={onClose} key="no">Нет</Button>,
    <Button onClick={handleRedirect} key="yes">Да</Button>,
  ]

  return (<Dialog
    open={true}
    handleClose={onClose}
    title={tabStore.title}
    text={'Выйти без сохранения?'}
    actions={actions}
  />)
}

export default observer(DialogExitWithoutSave)
