import { MouseEvent, useState } from 'react'
import { observer } from 'mobx-react'
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import DragLabel from '@/Components/DragAndDrop/DragLabel'
import Dialog, { IDialog } from '@/Components/Dialog'
import Button from '@/Components/Button'
import { IPageInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'
import { useMst } from '@/Stores/rootStore'

import { ActionsContainer, HeaderContainer, PageOrderTextStyled, PageTitleStyled, PageTitleSpaceStyled } from './styled'

interface IPageHeader {
  page: IPageInstance
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null
  isDraggable: boolean
  disabled: boolean
}

const CustomPageHeader = ({ page, dragHandleProps, isDraggable, disabled }: IPageHeader) => {
  const store = useMst()
  const pageStore = store.admin.surveyInfoRootStore.pagesStore
  const [dialogState, setDialogState] = useState<IDialog | undefined>()

  const handleToggleIsRequired = (event: MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      page.toggleIsRequired()
    }

    event.stopPropagation()
  }

  return (<HeaderContainer>
    <PageOrderTextStyled>Страница {page.order}</PageOrderTextStyled>
    <PageTitleSpaceStyled>
      <Tooltip title={page.title}>
        <PageTitleStyled>
          {page.title}
        </PageTitleStyled>
      </Tooltip>
    </PageTitleSpaceStyled>

    <ActionsContainer disabled={disabled}>
      <FormControlLabel
        value="requiredPage"
        control={<Checkbox color="primary" disabled={disabled} checked={page.isRequired} onClick={(event) => handleToggleIsRequired(event)}/>}
        label="Обязательная страница"
        labelPlacement="start"
      />

      {!disabled && <DeleteOutlineOutlinedIcon onClick={() => {
        const errors: string[] = []

        const pageFilterDependency = pageStore.pageFiltersPagesDependency.filter(x => x.dependencyPageId === page.id)

        if (pageFilterDependency.length > 0) {
          errors.push(`Удаляемая страница указана в качестве ссылки в фильтрах страниц:\n- ${pageFilterDependency.map(x => x.currentPageName).join('\n- ')}`)
        }

        if (page.order === 1 && pageStore.getPageAt(2)?.pageFilterPageDependency.some(x => x.dependencyPageId === page.id)) {
          errors.push('Удаляемая страница стоит первой и участвует в фильтре страниц следующей страницы. Фильтр страницы будет удален')
        }

        const pageRulesDependency = pageStore.pageRulesPagesDependency.filter(x => x.dependencyPageId === page.id)

        if (pageRulesDependency.length > 0) {
          errors.push(`Удаляемая страница указана в качестве ссылки в правилах страниц:\n- ${pageRulesDependency.map(x => x.currentPageName).join('\n- ')}`)
        }

        if (errors.length > 0) {
          setDialogState({
            open: true,
            handleClose: () => setDialogState(undefined),
            title: 'Удаление страницы влияет на логику ветвления. Удалить страницу?',
            text: errors.join('\n\n'),
            actions: [
              <Button key='delete-no-btn' onClick={() => setDialogState(undefined)}>Нет</Button>,
              <Button key='delete-yes-btn' onClick={() => {
                pageStore.deletePage(page)
                setDialogState(undefined)
              }}>Да</Button>,
            ],
            multileneText: true,
          })
        } else {
          pageStore.deletePage(page)
        }
      }}/>}
      {!disabled && <FileCopyOutlinedIcon onClick={(e) => {
        e.stopPropagation()
        page.setExpanded(false)
        page.copy()
      }}/>}
      <DragLabel isVisible={isDraggable && !disabled} dragHandleProps={dragHandleProps}/>
    </ActionsContainer>
    {dialogState && <Dialog {...dialogState} />}
  </HeaderContainer>)
}

export default observer(CustomPageHeader)
