import { stringify } from 'qs'

import { rootStore } from '@/Stores/rootStore'
import { getExpressBasePath, isExpressSmartApp } from '@/applicationConfig'

// Генерирует относительную ссылку к статике, исходя из того где открыт фронт: 1) Смартапп eXpress или 2) обычный браузер
export const getStaticsHybridUrl = (path: string) => {
  if (!isExpressSmartApp()) {
    return path
  }

  const queryParams = { 'x-auth-user': rootStore.user.cookieUserId }
  const query = window.btoa(stringify(queryParams))
  const queryPath = `/QUERY_${query}`
  return `${getExpressBasePath()}/proxy${path}${queryPath}`
}
