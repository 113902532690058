import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import { DragDropBox, DragLableContainer, LableStyled } from './styled'

interface IDragLabel {
  isVisible?: boolean
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null
  color?: string
}

const DragLabel = ({ isVisible = true, dragHandleProps, color = 'black' }: IDragLabel) => {
  return (
    <DragLableContainer isVisible={isVisible}>
      <DragDropBox {...(dragHandleProps ?? {})}/>
      <LableStyled className="sort-starter" style={{ color }} />
    </DragLableContainer>
  )
}

export default DragLabel
