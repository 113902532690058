import { ReactNode, useEffect, useRef, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ButtonClasses } from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { ClassNameMap, Fade, Menu, MenuItem } from '@mui/material'

import bem from '@/Helpers/BemClass'

import './style.scss'

const cnDropdownButton = bem()('dropdown-button')

interface IDropdownButton<LableType> {
  actions: LableType[]
  onClick: ((action: LableType) => void) | ((action: LableType) => Promise<void>)
  classes?: (Partial<ButtonClasses> & Partial<ClassNameMap<never>>) | undefined
  render?: (action: LableType) => ReactNode
}

function DropdownButton<LableType>({ actions, onClick, classes, render }: IDropdownButton<LableType>) {
  const [isLoading, setIsLoading] = useState(false)
  const [activeAction, setActiveAction] = useState(actions[0])
  const btnRef = useRef<HTMLButtonElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const showActionMenu = Boolean(anchorEl)

  useEffect(() => {
    setActiveAction(actions[0])
  }, [actions])

  const handleAction = async () => {
    try {
      setIsLoading(true)
      await onClick(activeAction)
    } finally {
      setIsLoading(false)
    }
  }

  const handleToggleMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setAnchorEl(prev => (prev === null ? btnRef.current : null))
  }

  const handleChangeActionType = (actionType: LableType) => () => {
    setActiveAction(actionType)
    setAnchorEl(null)
  }

  const menuButton = <div className={cnDropdownButton('menu-button')} onClick={handleToggleMenu}>
    <KeyboardArrowDownIcon className={cnDropdownButton('menu-button__icon')} />
  </div>

  return <>
    <LoadingButton loading={isLoading} ref={btnRef} className={cnDropdownButton({ variants: actions.length > 1 })} endIcon={actions.length > 1 ? menuButton : undefined} classes={classes} onClick={handleAction}>
      {render ? render(activeAction) : activeAction}
    </LoadingButton>
    {actions.length > 1 && <Menu
      open={showActionMenu}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      TransitionComponent={Fade}
    >
      {actions.map(x => <MenuItem key={x as string} onClick={handleChangeActionType(x)}>{x}</MenuItem>)}
    </Menu>}
  </>
}

export default DropdownButton
