import { useCallback, useEffect, useRef, useState } from 'react'
import { useMount } from 'react-use'
import { useParams } from 'react-router'
import { observer } from 'mobx-react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Button, Skeleton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { defaultImageHash } from '@/applicationConfig'
import { useMst } from '@/Stores/rootStore'
import StyledButton from '@/Components/Button'
import { Dialog, Error } from '@/Components'
import { apiSetCustomPages } from '@/Api/page'
import { IDialog } from '@/Components/Dialog'
import DndSortableList from '@/Components/DragAndDrop/DndSortableList'
import { SurveyStatus, SurveyType } from '@/Stores/AdminStores/surveysStore'
import { isSurveyedWithMainQuestionHelper } from '@/Helpers/isDisabledHelper'
import bem from '@/Helpers/BemClass'
import { IPageInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'
import { TrafficLightGroupType, TrafficLightPartType } from '@/Models/trafficLight'
import { IPage } from '@/Models/page'

import Page from '../Components/Page'

import './style.scss'

const cnEditPageCustom = bem()('edit-page-custom')

const normalizePageForSaveHelper = (page: IPage) => ({
  ...page,
  category: page.category,
  questions: page.questions.map(x => (
    {
      ...x,
      payload: x?.payload,
      options: x.options,
    })),
})

export interface ICustomEditPage {
  isCardState: boolean
  readonly: boolean
}

const CustomEditPage = ({ isCardState, readonly }: ICustomEditPage) => {
  const { surveyId } = useParams<{ surveyId: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const store = useMst()
  const pageStore = store.admin.surveyInfoRootStore.pagesStore
  const surveysStore = store.admin.surveysStore
  const trafficLightStateStore = store.admin.trafficLightStateStore
  const survey = surveysStore.surveys.find(x => x.surveyId === surveyId)
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const [defaultImageId, setDefaultImageId] = useState<string | null>(null)
  const [needScrollToError, setNeedScrollToError] = useState<boolean>(false)
  const isRepeatableSurveyed = isSurveyedWithMainQuestionHelper(survey)
  const pageRef = useRef<HTMLDivElement>(null)
  const [pagesCount, setPageCound] = useState(pageStore.pages.length)

  const onLoad = async () => {
    await pageStore.load(surveyId)

    setDefaultImageId(defaultImageHash)

    setIsLoading(false)
  }

  useEffect(() => {
    if (store.admin.surveyEditingTimeSpan === null && !isLoading) {
      setIsLoading(true)
      onLoad()
        .catch(err => {
          setIsLoading(false)
          throw err
        })
    }
  }, [store.admin.surveyEditingTimeSpan])

  useMount(() => {
    onLoad()
      .catch(err => {
        setIsLoading(false)
        throw err
      })
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isLoading])

  useEffect(() => {
    if (!needScrollToError) return

    const scrollTarget = document.getElementsByClassName('error')

    if (scrollTarget.length <= 0) return

    const parent = scrollTarget.item(0)?.parentElement

    if (!parent) return

    const parentRect = parent.getBoundingClientRect()
    const parentOffset = parentRect.top - parentRect.height
    window.scrollBy({
      top: parentOffset,
      behavior: 'smooth',
    })

    setNeedScrollToError(false)
  }, [needScrollToError])

  useEffect(() => {
    if (pageStore.pages.length > pagesCount && pageRef.current) {
      const elementTop = pageRef.current.getBoundingClientRect().top
      const offset = window.scrollY
      const target = elementTop + offset - 100
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      })
    }

    setPageCound(pageStore.pages.length)
  }, [pageStore.pages.length])

  const handlePageDragEnd = useCallback((oldIndex: number, newIndex: number) => {
    const errors: string[] = []

    const movedPage = pageStore.getPageAt(oldIndex + 1)

    if (newIndex === 0 && (movedPage?.pageFilters.length ?? 0) > 0) {
      errors.push('При сохранении, у первой страницы будут удалены фильтры страниц')
    }

    const brokenOwnedPageFiltersPageTitles = movedPage?.pageFilterPageDependency.map(x => {
      const dependency = pageStore.getPage(x.dependencyPageId)
      if (dependency && (oldIndex > newIndex ? dependency.order >= (newIndex + 1) : dependency.order > (newIndex + 1))) {
        return dependency.title
      } else {
        return null
      }
    }).filter(x => x !== null)

    if (brokenOwnedPageFiltersPageTitles && brokenOwnedPageFiltersPageTitles.length > 0) {
      errors.push(`Перемещаемая страница станет выше страниц, указанных в фильтрах страниц:\n- ${brokenOwnedPageFiltersPageTitles.join('\n- ')}`)
    }

    const dependentPages = pageStore.pageFiltersPagesDependency.filter(x => x.dependencyPageId === movedPage?.id)

    const brokenPageFiltersPageTitles = dependentPages.map(x => {
      const dependentPage = pageStore.getPage(x.currentPageId)

      if (dependentPage && (oldIndex > newIndex ? dependentPage.order < (newIndex + 1) : dependentPage.order <= (newIndex + 1))) {
        return dependentPage.title
      } else {
        return null
      }
    }).filter(x => x !== null)

    if (brokenPageFiltersPageTitles && brokenPageFiltersPageTitles.length > 0) {
      errors.push(`Перемещаемая страница станет ниже страниц, в фильтрах которых она указана:\n- ${brokenPageFiltersPageTitles.join('\n- ')}`)
    }

    if (errors.length > 0) {
      setDialogState({
        open: true,
        handleClose: () => setDialogState(undefined),
        title: 'Перемещение этой страницы повлияет на ветвление. Переместить страницу?',
        text: errors.join('\n\n'),
        actions: [
          <Button key='move-no-btn' onClick={() => setDialogState(undefined)}>Нет</Button>,
          <Button key='move-yes-btn' onClick={() => {
            pageStore.movePage(oldIndex, newIndex)
            setDialogState(undefined)
          }}>Да</Button>,
        ],
        multileneText: true,
      })
    } else {
      pageStore.movePage(oldIndex, newIndex)
    }
  }, [pageStore])

  const handleSave = async () => {
    setIsLoading(true)
    let hasBranchingChanges = false

    pageStore.validate()

    if (!pageStore.hasValidationErrors) {
      const res = pageStore.orderedPages.map(x => ({
        ...x,
        questions: x.orderedQuestions.map(x => ({
          ...x,
          options: x.orderedOptions.map(x => ({ ...x })),
        })),
      })).map(normalizePageForSaveHelper)
      hasBranchingChanges = await apiSetCustomPages(surveyId, res)

      await pageStore.load(surveyId)
      await trafficLightStateStore.createIfNotExistAndGet(surveyId).loadTrafficLight(false)
    } else {
      setNeedScrollToError(true)
    }

    setIsLoading(false)

    if (survey?.type !== SurveyType.Repeatable && hasBranchingChanges) {
      setDialogState({
        open: true,
        handleClose: () => setDialogState(undefined),
        title: 'Некоторые правила могли перестать работать!',
        text: 'Необходимо скорректировать настройки на вкладке Ветвление',
        actions: [<Button onClick={() => setDialogState(undefined)} key="ok">ОК</Button>],
      })
    }

    store.admin.tabStore.setWasChange(false)
  }

  const renderPage = (item: IPageInstance, index: number, dragHandleProps: DraggableProvidedDragHandleProps | undefined | null) => {
    return <div ref={(index + 1) === pageStore.pages.length ? pageRef : undefined}>
      <Page
        page={item}
        hasActivePeriod={(survey?.version ?? 0) > 0 || survey?.status === SurveyStatus.Surveyed}
        service={null}
        isCardState={isCardState}
        dragHandleProps={dragHandleProps}
        isRepeatableSurveyed={isRepeatableSurveyed}
        readonly={readonly}
      />
    </div>
  }

  const addButton = (
    <div className={cnEditPageCustom('button', { add: true })}>
      <Button color='secondary' disabled={isLoading || !defaultImageId} fullWidth onClick={() => {
        const page = pageStore.addPage()
        trafficLightStateStore.getState(surveyId)
          ?.getGroup(TrafficLightGroupType.Pages)
          ?.getState(TrafficLightPartType.NoPages)
          ?.clear()
        const mainQuestions = survey?.getMainQuestionsAsIQuestionVersion() ?? []
        mainQuestions.length > 0 && page.addMainQuestionIfNotExist(mainQuestions)
      }}>
        <AddIcon fontSize="large"/>
        <h4 className={cnEditPageCustom('button-text', { add: true })}>Добавить страницу</h4>
      </Button>
    </div>
  )

  const saveButton = (
    <div className={cnEditPageCustom('action', { 'save-button': true })}>
      <StyledButton disabled={isLoading || !defaultImageId || readonly} onClick={handleSave} height="40px">Сохранить</StyledButton>
    </div>
  )

  return (<div className={cnEditPageCustom()}>
    {(isLoading || !defaultImageId) ? <>
      <Skeleton variant='rectangular' height={66} className={cnEditPageCustom('skeleton')} animation="wave"/>
      <Skeleton variant='rectangular' height={66} className={cnEditPageCustom('skeleton')} animation="wave"/>
    </> : <div className={cnEditPageCustom('pages-list-container')}>
      <DndSortableList
        data={pageStore.orderedPages}
        renderItem={renderPage}
        onDragEnd={handlePageDragEnd}
        dndIdBuilder={page => page.dndId}
      />
    </div>}
    {!readonly && !isRepeatableSurveyed && addButton}
    <div className={cnEditPageCustom('action', { save: true })}>
      <div className={cnEditPageCustom('action', { 'save-notification': true })}>
        <Error isVisible={pageStore.hasValidationErrors}>Заполните все поля</Error>
      </div>
      {!readonly && saveButton}
    </div>
    {!isLoading && dialogState && <Dialog {...dialogState} />}
  </div>)
}

export default observer(CustomEditPage)
