import { types as t } from 'mobx-state-tree'

import { BranchingStore } from '@/Stores/AdminStores/SurveyInfoStores/branchingStore'
import { PageStore } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'
import { CopyLogicStore } from '@/Stores/AdminStores/SurveyInfoStores/copyLogicStore'

export const SurveyInfoRootStore = t.model({
  branchingStore: BranchingStore,
  pagesStore: PageStore,
  copyLogicStore: CopyLogicStore,
})

