import { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { Box } from '@mui/material'

import { apiCheckUserPermissions } from '@/Api/permission'
import { RouteEnum } from '@/Enums/route'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { AnswerTab } from '@/Pages/Admin/SurveyInfoPage/AnswerTab'
import { ILoaderState, LoaderStateContext } from '@/Components/LoaderContext'
import { DisplayMode } from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/PageAnswers'
import { PermissionType } from '@/Stores/sharedModels/models'

const StatisticPage = () => {
  const loadingContext = useContext<ILoaderState>(LoaderStateContext)
  const { surveyId } = useParams<{ surveyId: string }>()
  const routerManager = useMemo(() => useRouteManager(), [])

  useEffect(() => {
    (async () => {
      loadingContext.changeIsLoading(true)
      const res = await apiCheckUserPermissions([{ id: '', type: PermissionType.expert, surveyId: surveyId }])

      if (!res) {
        routerManager.redirectTo(RouteEnum.clientSurveys)
      }

      loadingContext.changeIsLoading(false)
    })()
  }, [surveyId, routerManager])

  if (loadingContext.isLoading) return <></>

  return <Box ml={2} mr={2}>
    <AnswerTab surveyId={surveyId} displayMode={DisplayMode.Expert} />
  </Box>
}

export default StatisticPage

