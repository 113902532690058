import { useState, ChangeEvent, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react'

import bem from '@/Helpers/BemClass'
import { ILoaderState, LoaderStateContext } from '@/Components/LoaderContext'
import { Button, Error as ErrorComponent, MultilineTextField, TextLink } from '@/Components'
import { apiSetEstimate } from '@/Api/feedback'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { RouteEnum } from '@/Enums/route'
import { useMst } from '@/Stores/rootStore'
import { ReactComponent as ArrowIcon } from '@/Images/arrow.svg'

import Smile from './Smile'
import './style.scss'


export enum FeedbackEstimateType {
  bad = 1,
  normal,
  good,
}

interface IErrors {
  estimateIsEmpty?: boolean
  commentIsEmpty?: boolean
}

const cnFeedbackPage = bem()('feedback-page')

const FinalServiceEstimatePage = () => {
  const loadingContext = useContext<ILoaderState>(LoaderStateContext)

  const [comment, setComment] = useState<string>('')
  const [estimate, setEstimate] = useState<FeedbackEstimateType>()
  const [errors, setErrors] = useState<IErrors>({})
  const routeManager = useRouteManager()
  const store = useMst()
  const survey = store.user.pageStore.currentSurvey

  loadingContext.changeIsLoading(false)

  const handleSkip = () => {
    loadingContext.changeIsLoading(true)
    routeManager.redirectTo(RouteEnum.clientSurveys)
  }
  const handleTextChange = (e: ChangeEvent<{ value: string }>) => {
    setComment(e.target.value)
    setErrors(prev => ({ ...prev, commentIsEmpty: false }))
  }
  const handleEstimateChange = (estimate: FeedbackEstimateType) => {
    setEstimate(estimate)
    setErrors(prev => ({ ...prev, estimateIsEmpty: false, commentIsEmpty: false }))
  }
  const handleSendFeedback = async () => {
    loadingContext.changeIsLoading(true)
    if (estimate === undefined) {
      setErrors(prev => ({ ...prev, estimateIsEmpty: true }))
      loadingContext.changeIsLoading(false)
      return
    }
    if (estimate === FeedbackEstimateType.bad && comment.length === 0) {
      setErrors(prev => ({ ...prev, commentIsEmpty: true }))
      loadingContext.changeIsLoading(false)
      return
    }
    if (!survey) throw new Error('Ошибка консистентности данных, нет currentSurvey')
    loadingContext.changeIsLoading(true)
    await apiSetEstimate(survey.surveyId, estimate, comment)
    routeManager.redirectTo(RouteEnum.clientSurveys)
  }

  if (loadingContext.isLoading) return <></>

  return (<>
    <Container maxWidth='xl' className={cnFeedbackPage()}>
      <Helmet>
        <title>{survey?.title ?? 'Обратная связь'}</title>
      </Helmet>
      <Grid container direction='column' rowSpacing={{ xs: 2, sm: 2 }} columnSpacing={0}>
        <Grid item>
          <Typography variant='h1' component='h1' className={cnFeedbackPage('text-header')}>
            Спасибо за участие в опросе!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='h5' component='h5' className={cnFeedbackPage('text-subheader')}>
            Как вы оцените удобство прохождения опроса?
          </Typography>
        </Grid>
        <br/>
        <Grid item container rowSpacing={{ xs: 3, sm: 0 }} columnSpacing={{ xs: 2, md: 4 }} direction='row' alignItems='center' justifyContent='center'>
          <Grid item xs={4}>
            <Smile drawingEstimateType={FeedbackEstimateType.good} selectedEstimate={estimate} handleEstimateChange={handleEstimateChange}/>
          </Grid>
          <Grid item xs={4}>
            <Smile drawingEstimateType={FeedbackEstimateType.normal} selectedEstimate={estimate} handleEstimateChange={handleEstimateChange}/>
          </Grid>
          <Grid item xs={4}>
            <Smile drawingEstimateType={FeedbackEstimateType.bad} selectedEstimate={estimate} handleEstimateChange={handleEstimateChange}/>
          </Grid>
        </Grid>
        <Grid item container direction='row' alignItems='center' justifyContent='center'>
          <ErrorComponent isVisible={errors.estimateIsEmpty}>Укажите оценку</ErrorComponent>
        </Grid>
        <Grid className={cnFeedbackPage('comment')} item>
          <MultilineTextField
            placeholder='Введите комментарий..'
            label='Комментарий'
            value={comment ?? ''}
            onChange={handleTextChange}
          />
          <ErrorComponent isVisible={errors.commentIsEmpty}>Введите комментарий</ErrorComponent>
        </Grid>
        <Grid item container rowSpacing={{ xs: 2 }} columnSpacing={{ xs: 1, sm: 3, md: 4 }} direction='row' alignItems='center' justifyContent='flex-end'>
          <Grid item>
            <TextLink text='Пропустить' color='#8B98A7' EndIcon={ArrowIcon} onClick={handleSkip}/>
          </Grid>
          <Grid item>
            <Button onClick={handleSendFeedback}>Оценить</Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </>)
}

export default observer(FinalServiceEstimatePage)
