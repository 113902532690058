import { ReactNode } from 'react'
import { observer } from 'mobx-react'
import { Stack } from '@mui/material'

import bem from '@/Helpers/BemClass'

import './style.scss'

const cnOrganizationStructureTreeViewWithExpanding = bem()('organization-structure-section-header')

interface IOrganizationStructureSectionHeader {
  children: ReactNode
  onClick?: () => void
  disableRounding?: boolean
  disableBackgroundColor?: boolean
}

const OrganizationStructureSectionHeader = ({ children, onClick, disableRounding = false, disableBackgroundColor = false }: IOrganizationStructureSectionHeader) => {
  return <Stack
    direction='row'
    justifyContent='space-between'
    alignItems='center'
    spacing={1}
    className={cnOrganizationStructureTreeViewWithExpanding({ 'rounded': !disableRounding, 'background-color': !disableBackgroundColor, 'with-action': !!onClick })}
    onClick={onClick}
  >
    {children}
  </Stack>
}

export default observer(OrganizationStructureSectionHeader)
