import { MouseEvent } from 'react'

import CopyButton from '../../../../../../Components/IconButtons/Copy'
import CancelButton from '../../../../../../Components/IconButtons/Cancel'

import { CounterContainer, CounterStyled } from './styled'

interface ICounterProps {
  text: string
  count: number
  onCopy?: ((e: MouseEvent) => void)
  onCancelCopy?: ((e: MouseEvent) => void)
  disabled?: boolean
  visible?: boolean
}

const Counter = ({ text, count, onCopy, onCancelCopy, disabled, visible = true }: ICounterProps) => {
  return <CounterContainer>
    {!onCancelCopy && onCopy && (visible ? <CopyButton onClick={onCopy} disabled={disabled} /> : <div style={{ width: 44 }}/>)}
    {onCancelCopy && <CancelButton onClick={onCancelCopy}/>}

    <CounterStyled>
      <div>{text}:</div>
      <div>{count}</div>
    </CounterStyled>
  </CounterContainer>
}

export default Counter
