import { applySnapshot, flow, types as t } from 'mobx-state-tree'

import { AdminServicesStore } from '@/Stores/AdminStores/adminServicesStore'
import { SurveyEditingTimeSpan, SurveysStore, SurveysStoreFilter } from '@/Stores/AdminStores/surveysStore'
import { TabStore } from '@/Stores/AdminStores/tabStore'
import { SurveyInfoRootStore } from '@/Stores/AdminStores/SurveyInfoStores/surveyInfoRootStore'
import { OrganizationStructureStore } from '@/Stores/AdminStores/organizationStructureStore'
import { TrafficLightStateStore } from '@/Stores/AdminStores/trafficLightStateSore'
import { CopyItemType } from '@/Stores/AdminStores/SurveyInfoStores/copyLogicStore'
import { apiTryLockEditing, apiTryUnlockEditing } from '@/Api/survey'
import { rootStore } from '@/Stores/rootStore'

const defaultState = {
  adminServicesStore: { services: [] },
  surveysStore: { surveys: [], filter: SurveysStoreFilter.Default },
  tabStore: { wasChanged: false, title: 'На странице были изменения' },
  surveyInfoRootStore: { branchingStore: { pagesLogic: {} }, pagesStore: { pages: [] }, copyLogicStore: { enableCopyMode: false, type: CopyItemType.none } },
  organizationStructureStore: {},
  trafficLightStateStore: {},
  surveyEditTimeSpanId: null,
  surveyInEditing: null,
  lastActivityTime: new Date(),
}

const model = t.model({
  adminServicesStore: AdminServicesStore,
  surveysStore: SurveysStore,
  tabStore: TabStore,
  surveyInfoRootStore: SurveyInfoRootStore,
  organizationStructureStore: OrganizationStructureStore,
  trafficLightStateStore: TrafficLightStateStore,
  surveyEditingTimeSpan: t.maybeNull(SurveyEditingTimeSpan),
}).actions(self => ({
  updateSurveyInEditing: flow(function * load(surveyId: string | null) {
    if (self.tabStore.wasChanged) {
      self.tabStore.setWasChange(false)
    }

    if (self.surveyEditingTimeSpan) {
      const unlockResult = yield apiTryUnlockEditing(self.surveyEditingTimeSpan.surveyId, self.surveyEditingTimeSpan.id)
      self.surveyEditingTimeSpan = null
      window.onbeforeunload = null
    }

    if (!surveyId) {
      return true
    }

    let surveyEditTimeSpanId: string | null = null

    try {
      surveyEditTimeSpanId = yield apiTryLockEditing(surveyId)
    } catch (err: any) {
      console.log(err)
      yield self.surveysStore.loadSurveyInfo(surveyId)
    }

    if (surveyEditTimeSpanId) {
      self.surveyEditingTimeSpan = {
        id: surveyEditTimeSpanId,
        surveyId: surveyId,
      }

      window.onbeforeunload = async (event) => {
        rootStore.admin.updateSurveyInEditing(null)
      }

      return true
    } else {
      return false
    }
  }),
  isEditorFor: (surveyId: string) => {
    return !!self.surveyEditingTimeSpan && self.surveyEditingTimeSpan.surveyId === surveyId
  },
}))

export default {
  defaultState,
  model,
}
