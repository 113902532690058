import { useState, ChangeEvent } from 'react'
import { useLongPress, useMount } from 'react-use'
import { sortBy } from 'lodash'
import {
  Grid,
  Typography,
  Button,
  Autocomplete,
  createFilterOptions,
  Skeleton,
  TextField,
  Divider,
  Chip,
  Link,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Cookies from 'js-cookie'
import LoadingButton from '@mui/lab/LoadingButton'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded'

import bem from '@/Helpers/BemClass'
import { cookieUserId } from '@/Helpers/globalConst'
import { useMst } from '@/Stores/rootStore'
import { apiGetUserPermissions } from '@/Api/permission'
import { apiGetAllUsersTest } from '@/Api/user'
import { apiGetSurveyTitlesForTest } from '@/Api/survey'
import { IUser } from '@/Models/user'
import { RouteEnum } from '@/Enums/route'
import { FrontendFeature, isExpressSmartApp } from '@/applicationConfig'
import { isFeatureEnabled } from '@/Components/Feature'

import './style.scss'
import { IPermissionOut, PermissionType } from '@/Stores/sharedModels/models'

const cnAuthView = bem()('auth-view')

interface IAuthView {
  onClose: (login?: string) => void
}

const AuthView = ({ onClose }: IAuthView) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('md'))
  const UserStore = useMst().user
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [initialUser, setInitialUser] = useState(UserStore.cookieUserId)
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
  const [isUserWasSelected, setIsUserWasSelected] = useState<boolean>(false)
  const [users, setUsers] = useState<IUser[]>([])
  const [userPermissions, setUserPermissions] = useState<IPermissionOut[]>([])
  const [surveysTitles, setSurveysTitles] = useState<Map<string, string>>(new Map())

  useMount(() => {
    (async () => {
      setIsLoading(true)

      const allUsers = await apiGetAllUsersTest()
      setUsers(sortBy(allUsers, ['surname', 'name', 'patronymic']))

      let user = allUsers.find((x) => x.uid === UserStore.cookieUserId)
      if (!user) {
        user = allUsers[0]
        UserStore.setCookieUserId(user.uid)
      }
      setSelectedUser(user)

      const permissions = await apiGetUserPermissions()
      setUserPermissions(permissions)

      const response = await apiGetSurveyTitlesForTest()
      setSurveysTitles(new Map(Object.entries(response)))

      setIsLoading(false)
    })()
  })

  const handleChangeUser = async (
    e: ChangeEvent<{}>,
    value: string | IUser | null
  ) => {
    if (typeof value === 'string' || value === null) return

    setIsLoading(true)

    // временно устанавливаем куки целевого юзера
    Cookies.set(cookieUserId, value.uid)
    // получаем его права
    const permissions = await apiGetUserPermissions()
    setUserPermissions(permissions)
    // возвращаем куку на первоначальное значение
    selectedUser?.uid && Cookies.set(cookieUserId, initialUser ?? selectedUser.uid)

    setSelectedUser(value)
    setIsUserWasSelected(true)
    setIsLoading(false)
  }

  const handleApply = async () => {
    if (selectedUser?.uid) {
      setInitialUser(selectedUser.uid)
      UserStore.setCookieUserId(selectedUser.uid)

      setIsLoading(true)
      await UserStore.userSurveysStore.getUserSurveys()
      setIsLoading(false)
    }
    // чтобы успела приятным образом сработать анимация смены цвета у кнопки Применить
    setTimeout(() => onClose(selectedUser?.uid), 400)
    localStorage.setItem('selectedPages', JSON.stringify({}))
    UserStore.selectedPagesState.clear()

    if (isExpressSmartApp()) {
      await UserStore.loadUserInfoFromExpress()
    }

    const count = await UserStore.loadUserPermissions()
    console.log(`Загрузили [${count}] прав`)
  }

  const getSurveyTitleById = (surveyId: string | null) => {
    return surveysTitles.get(surveyId ?? '') ?? 'Неизвестный опрос'
  }

  const getUserFullName = (user: IUser) => {
    if ((user).surname || (user).name || (user).patronymic) {
      return `${(user).surname ?? ''} ${(user).name ?? ''} ${(user).patronymic ?? ''}`
    } else {
      return user.uid
    }
  }

  const newUser = () => {
    (async () => {
      try {
        setIsLoading(true)

        await UserStore.generateUserWithRandomUserId()

        console.log('Пользователь синхронизирован', UserStore.cookieUserId)

        const allUsers = await apiGetAllUsersTest()
        setUsers(sortBy(allUsers, ['surname', 'name', 'patronymic']))

        let user = allUsers.find((x) => x.uid === UserStore.cookieUserId)
        if (!user) {
          user = allUsers[0]
          UserStore.setCookieUserId(user.uid)
        }
        setSelectedUser(user)

        const permissions = await apiGetUserPermissions()
        setUserPermissions(permissions)

        const surveysTitles = await apiGetSurveyTitlesForTest()
        setSurveysTitles(new Map(Object.entries(surveysTitles)))
      } catch (err: any) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    })()
  }

  const longPressHook = useLongPress(() => { newUser() }, { isPreventDefault: true, delay: 300 })

  const longPressEventForRolesSwitch = isFeatureEnabled(FrontendFeature.randomUser) ? longPressHook : undefined

  return (
    <Grid container spacing={1} direction='column' wrap='nowrap' className={cnAuthView()}>
      <Grid {...longPressEventForRolesSwitch} item alignSelf='center' className='--no-select'>
        <Typography variant='h4' component='h4' gutterBottom>
          Смена пользователя
        </Typography>
      </Grid>
      <Divider variant='middle' className='--no-select'>
        <Chip label='Выберите пользователя'/>
      </Divider>
      <Grid item xs>
        <br />
        <Autocomplete
          selectOnFocus
          clearOnBlur
          value={selectedUser}
          options={users}
          noOptionsText='Пользователь не найден'
          filterOptions={
            (options, params) => createFilterOptions<IUser>()(options, params)
          }
          onChange={handleChangeUser}
          getOptionLabel={
            (option) => getUserFullName(option)
          }
          renderOption={(props, option) => (
            <li {...props}>
              <Typography variant='subtitle1' component='div' style={{ whiteSpace: 'normal' }}>
                {getUserFullName(option)}
              </Typography>
            </li>
          )}
          renderInput={(params) => (isLoading ? (<Skeleton animation='wave' height={50} />) : (
            <TextField {...params} label='Пользователь' multiline={true}/>
          ))
          }
        />
      </Grid>
      <Grid item>
        <Typography>
          <strong>ФИО: </strong>
          {isLoading ? <Skeleton animation='wave' height={40}/> : (
            selectedUser ? getUserFullName(selectedUser) : '-'
          )}
        </Typography>
      </Grid>
      <Grid item>
        {isLoading ? <Skeleton animation='wave' height={40}/> : (
          userPermissions.some(permission => permission.type === PermissionType.admin) && <Typography>
            <strong>Роль:</strong> Администратор
          </Typography>
        )}
      </Grid>
      <Grid item zeroMinWidth>
        {isLoading ? <Skeleton animation='wave' height={40}/> : (
          userPermissions.some(permission => permission.type === PermissionType.expert) && <Typography>
            <strong>Роль Эксперта:</strong>
          </Typography>
        )}
        {isLoading ? <Skeleton animation='wave' height={40}/> : (
          <List dense>
            {userPermissions.filter(permission => permission.type === PermissionType.expert)
              .map((val, idx) => <ListItem component='div' disablePadding key={`${selectedUser?.uid}_${idx}_${val.surveyId}`}
                onClick={() => {
                  window.open(`${window.location.origin}/${RouteEnum.survey}/${val.surveyId as string ?? ''}`)
                }}
                secondaryAction={
                  <LaunchRoundedIcon sx={{ fontSize: 14 }} color='secondary'/>
                }
              >
                <ListItemButton divider>
                  <Typography variant='body2' component='span' noWrap>
                    <Link component='button' variant='body1' color='inherit' underline='hover'>
                      {getSurveyTitleById(val.surveyId)}
                    </Link>
                  </Typography>
                </ListItemButton>
              </ListItem>)}
          </List>
        )}
      </Grid>
      <Grid item container spacing={1} justifyContent='space-between' alignItems='center'>
        <Grid item>
          <LoadingButton variant='contained' size='medium' loadingPosition='start'
            loading={isLoading} startIcon={<DoneRoundedIcon/>}
            color={isUserWasSelected && UserStore.cookieUserId === selectedUser?.uid ? 'success' : 'primary'}
            onClick={handleApply}>
            Применить
          </LoadingButton>
        </Grid>
        <Grid item>
          <Button variant='outlined' size='small'
            endIcon={<RocketLaunchRoundedIcon fontSize='small'/>}
            onClick={() => window.open(`${window.location.origin}/${RouteEnum.survey}`)}>
            {UIisMobile ? 'Перейти в админку' : 'Перейти к администрированию опросов'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AuthView
