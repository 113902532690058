import { createContext } from 'react'

import type { IWaiterContext } from './types'

// TODO: создавать контекст с реальным значением
export const WaiterContext = createContext<IWaiterContext | undefined>(undefined)
export default WaiterContext

/* TODO: реализовать динамическое создание контекста
export function createWaiterContext(waiter: string) {
  const { anyWaiting, isWaiting, startWait, stopWait } = _isContextAvailable(useContext(WaiterContext))
  return {
    anyWaiting: () => anyWaiting(),
    isWaiting: () => isWaiting(waiter),
    startWait: () => startWait(waiter),
    stopWait: () => stopWait(waiter),
    Wait: (props: IWaitComponentProps) => Wait({ ...props, for: waiter }),
    // Wait: (props: IWaitComponentProps) => <Wait {...props} for={waiter}/>,
  }
}
*/

/**
 * Проверяет контекст на некорректное использование (вне провайдера)
 *
 * @param ctx - Контекст
 * @throws Выбрасывает ошибку если контекст используется вне провайдера
 * @return Возвращает контекст
 */
export function _isContextAvailable(ctx: IWaiterContext | undefined): IWaiterContext {
  if (ctx === undefined) {
    throw Error('Error: Wait\'er cannot be used outside of Context Provider, or Context was empty')
  }
  return ctx
}
