import _ from 'lodash'

import { IPageFilter, IPageFilters, IPageRules, IQuestionFilter, IQuestionFilters, IRule } from '@/Models/rule'
import { IPageFilterSnapshotIn, IQuestionFilterSnapshotIn, IRuleSnapshotIn } from '@/Stores/AdminStores/SurveyInfoStores/branchingStore'
import BackendAdapter from '@/Api/_adapter'

export const apiGetRules = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/rules`, {
      params: {
        filters: 'all',
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetRulesCount = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/rules/count`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetNextPage = async (surveyId: string, pageId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/pages/${pageId}/rules/nextPage`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetRules = async (
  surveyId: string,
  rules: IRuleSnapshotIn[],
  filters: IPageFilterSnapshotIn[],
  questionFilters: IQuestionFilterSnapshotIn[]
) => {
  const rulesByPage = _.groupBy(rules, 'pageId')
  const filtersByPage = _.groupBy(filters, 'pageId')
  const questionFiltersByPage = _.groupBy(questionFilters, 'pageId')

  const pageIds = _.uniq(_.keys(rulesByPage).concat(_.keys(filtersByPage)).concat(_.keys(questionFiltersByPage)))
  const pagesLogic = pageIds.map(x => ({
    pageId: x,
    rules: _.get(rulesByPage, x, []),
    pageFilters: _.get(filtersByPage, x, []),
    questionFilters: _.get(questionFiltersByPage, x, []),
  }))

  try {
    return await BackendAdapter.put(`/api/surveys/${surveyId}/rules`, {
      surveyId,
      pagesLogic,
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetQuestionFilters = async (surveyId: string, activeOnly: boolean) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/rules`, {
      params: {
        filters: 'question',
        activeOnly: activeOnly,
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const castToFilterArray = (filters: Map<string, IPageFilters>) => {
  const newFilters: IPageFilter[] = []
  filters.forEach((filters) => newFilters.push(...filters.filters))
  return newFilters
}

export const castToRulesArray = (rules: Map<string, IPageRules>) => {
  const newRules: IRule[] = []
  rules.forEach((rules) => newRules.push(...rules.rules))
  return newRules
}

export const castToQuestionFilterArray = (filters: Map<string, IQuestionFilters>) => {
  const newFilters: IQuestionFilter[] = []
  filters.forEach((filters) => newFilters.push(...filters.filters))
  return newFilters
}
