import { Link as ReactLink } from 'react-router-dom'
import { observer } from 'mobx-react'
import Typography from '@mui/material/Typography'
import Tab from '@mui/material/Tab'
import { NoStrictEntityMods } from '@bem-react/classname'

import { SurveyInfoTabsEnum } from '@/Enums/surveyInfoTabsEnum'
import { TrafficLightColor } from '@/Models/trafficLight'
import bem from '@/Helpers/BemClass'
import { ITrafficLightGroup } from '@/Stores/AdminStores/trafficLightStateSore'

// TODO: перевести стилевые компоненты в bem
const cnSurveyTabs = bem()('survey-tabs')

const trafficLightStateStyles = new Map<TrafficLightColor | null | undefined, NoStrictEntityMods | null | undefined>([
  [TrafficLightColor.Green, { 'green': true }],
  [TrafficLightColor.Yellow, { 'yellow': true }],
  [TrafficLightColor.Red, { 'red': true }],
  [null, undefined],
  [undefined, undefined],
])

interface ISurveyInfoTab {
  tabTrafficLightGroup: ITrafficLightGroup
  text: string
  url: string
  tab: SurveyInfoTabsEnum
}

const SurveyInfoTab = ({ tabTrafficLightGroup, text, url, tab }: ISurveyInfoTab) => {
  return <Tab
    value={tab}
    component={ReactLink}
    to={`${url}/${tab}`}
    className={cnSurveyTabs('tab')}
    label={<Typography variant='inherit' fontWeight='bold' >{text}</Typography>}
    classes={{
      root: cnSurveyTabs('tab-button', trafficLightStateStyles.get(tabTrafficLightGroup.worstColor)),
    }}
  />
}

export default observer(SurveyInfoTab)
