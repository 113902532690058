import { useMouseDown, useMouseMove, useMouseUp, useScroll, useKeyDown, useKeyUp } from '@/Hooks'

export const useUserActivity = (onActivity: () => void) => {
  useMouseMove(() => {
    onActivity()
  })

  useMouseDown(() => {
    onActivity()
  })

  useMouseUp(() => {
    onActivity()
  })

  useScroll(() => {
    onActivity()
  })

  useKeyDown(() => {
    onActivity()
  })

  useKeyUp(() => {
    onActivity()
  })
}
