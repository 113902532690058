import { dateConverter } from '@/Helpers/dateConverter'
import BackendAdapter from '@/Api/_adapter'

export const apiSetFeedbackService = async (surveyId: string, comment: string) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/feedback`, {
      type: 0,
      comment: comment,
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetFeedbackPage = async (surveyId: string, pageId: string, comment: string) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/feedback`, {
      type: 1,
      comment: comment,
    }, {
      params: {
        pageId,
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetFeedbackBySurvey = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/feedback`).then(res => res.data?.map((x: any) => ({
      user: x.user,
      serviceName: x.serviceName,
      date: dateConverter(x.date),
      comment: x.comment,
      estimate: x.estimate,
      source: x.type,
      surveyTitle: x.surveyTitle,
    })))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetEstimate = async (surveyId: string, estimate: number, comment: string) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/feedback`, {
      comment,
      estimate,
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetIsHaveEstimate = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/feedback/check`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

