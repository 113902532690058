import { ReactElement } from 'react'
import {
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

export interface IDialog {
  open: boolean
  handleClose: () => void
  title?: string
  text?: string
  actions?: ReactElement[]
  multileneText?: boolean
}

const Dialog = ({ open, handleClose, text, title, actions, multileneText = false }: IDialog) => {
  return (
    <MaterialDialog
      open={open}
      onClose={handleClose}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {text && <DialogContent>
        <DialogContentText style={multileneText ? { whiteSpace: 'pre-wrap' } : {}}>{text}</DialogContentText>
      </DialogContent>}
      {actions && <DialogActions>
        {actions}
      </DialogActions>}
    </MaterialDialog>)
}

export default Dialog
