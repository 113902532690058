import { MouseEventHandler, MouseEvent as ReactMouseEvent } from 'react'
import { observer } from 'mobx-react'

import { SpreadsheetCell } from '@/Components/Spreadsheet/styled'
import bem from '@/Helpers/BemClass'
import { ISpreadsheetCellStoreInstance, InputType } from '@/Stores/SpreadsheetStore/spreadsheet'
import { CellInput } from '@/Components/Spreadsheet/Cell/styled'

const cnSpreadsheet = bem()('spreadsheet')

interface ICell {
  cell: ISpreadsheetCellStoreInstance
  onContextMenu: MouseEventHandler<HTMLDivElement>
  disabled: boolean
}

const Cell = ({ cell, onContextMenu, disabled }: ICell) => {
  const handleContextMenu = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    if (cell.inputType !== InputType.None) {
      cell.selectInclusive()
    }

    onContextMenu(e)
  }

  return <SpreadsheetCell
    onContextMenu={handleContextMenu}
    fromColumn={cell.fromColumn + 2}
    toColumn={cell.toColumn + 3}
    fromRow={cell.fromRow + 2}
    toRow={cell.toRow + 3}
    bgcolor={cell.styles.bgColor}
    fontColor={cell.styles.fontColor}
    fontSize={cell.styles.fontSize}
    isItalic={cell.styles.isItalic}
    isBold={cell.styles.isBold}
    borderTop={cell.styles.borderTop}
    borderRight={cell.styles.borderRight}
    borderBottom={cell.styles.borderBottom}
    borderLeft={cell.styles.borderLeft}
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      cell.inputType === InputType.None && cell.togleSelect(!e.shiftKey)
    }}
    className={cnSpreadsheet('cell', {
      'data': true,
      'data-selectable': cell.inputType === InputType.None,
      'selected': cell.isSelected,
    })}
  >
    {cell.inputType === InputType.Text ? (<div className={cnSpreadsheet('cell__input-container')}>
      <CellInput
        type='text'
        value={cell.inputValue}
        onChange={e => cell.setInputValue(e.target.value)}
        bgcolor={cell.styles.bgColor}
        fontColor={cell.styles.fontColor}
        fontSize={cell.styles.fontSize}
        isItalic={cell.styles.isItalic}
        isBold={cell.styles.isBold}
        className={cnSpreadsheet('cell__input')}
      />
    </div>) : cell.inputType === InputType.Number ? (<div className={cnSpreadsheet('cell__input-container')}>
      <CellInput
        type='number'
        value={cell.inputValue}
        onChange={e => cell.setInputValue(e.target.value)}
        bgcolor={cell.styles.bgColor}
        fontColor={cell.styles.fontColor}
        fontSize={cell.styles.fontSize}
        isItalic={cell.styles.isItalic}
        isBold={cell.styles.isBold}
        className={cnSpreadsheet('cell__input')}
      />
    </div>) : cell.value}
  </SpreadsheetCell>
}

export default observer(Cell)
