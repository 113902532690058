import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { v4 } from 'uuid'

import { ISpreadsheetStoreInstance, InputType, SpreadsheetStore } from '@/Stores/SpreadsheetStore/spreadsheet'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'
import { ISpreadsheetCellsData } from '@/Models/spreadsheet'
import Spreadsheet from '@/Components/Spreadsheet'
import bem from '@/Helpers/BemClass'

import './styles.scss'

const cnSpreadsheetSettingsDialog = bem()('spreadsheet-settings-dialog')

interface ISpreadsheetSettingsDialog {
  open: boolean
  onClose: () => void
  data: ISpreadsheetCellsData
  question: IQuestionInstance
  disabled?: boolean
}

const SpreadsheetSettingsDialog = ({ open, onClose, data, question, disabled = false }: ISpreadsheetSettingsDialog) => {
  const [spreadsheetStoreInstance] = useState<ISpreadsheetStoreInstance>(SpreadsheetStore.create())
  const muiTheme = useTheme()
  const UIisTabletMd = useMediaQuery(muiTheme.breakpoints.down('xl'))

  useEffect(() => {
    const editableCells = question.options.map(x => JSON.parse(x.value) as { x: number, y: number, type: InputType })

    spreadsheetStoreInstance.fillFromData(data, editableCells)
  }, [data])

  const handleSave = () => {
    const options = spreadsheetStoreInstance.cells.filter(x => x.inputType !== InputType.None)
      .map((x, i) => ({
        value: JSON.stringify({ x: x.fromColumn, y: x.fromRow, type: x.inputType }),
        imageId: null,
        title: '',
        isCustom: true,
        order: i,
        dndId: v4(),
      }))
    question.replaceOptions(options)
    onClose()
  }

  return <Dialog
    open={open}
    onClose={onClose}
    classes={{
      paper: cnSpreadsheetSettingsDialog('paper', { mobile: UIisTabletMd }),
    }}
  >
    <DialogTitle
      classes={{
        root: cnSpreadsheetSettingsDialog('title'),
      }}
    >
      <Typography variant='h5' fontWeight='bold'>Настройка таблицы</Typography>
      <IconButton onClick={() => {
        spreadsheetStoreInstance?.clearModifications()
        onClose()
      }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent
      classes={{
        root: cnSpreadsheetSettingsDialog('content'),
      }}
    >
      <div className={cnSpreadsheetSettingsDialog('container-window')}>
        {data && <Spreadsheet
          spreadsheetStoreInstance={spreadsheetStoreInstance}
          data={data}
          disabled={disabled}
        />}
      </div>
      {!disabled && <div className={cnSpreadsheetSettingsDialog('btn-line')}>
        <Button
          variant='text'
          onClick={() => spreadsheetStoreInstance?.clearModifications()}
          className={cnSpreadsheetSettingsDialog('btn', { clean: true })}
        >
          Очистить
        </Button>
        <Button
          variant='contained'
          onClick={handleSave}
          className={cnSpreadsheetSettingsDialog('btn', { save: true })}
        >
          Сохранить
        </Button>
      </div>}
    </DialogContent>
  </Dialog>
}

export default observer(SpreadsheetSettingsDialog)
