import { MouseEvent } from 'react'
import { observer } from 'mobx-react'
import { Accordion, AccordionDetails, AccordionSummary, SelectChangeEvent } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import bem from '@/Helpers/BemClass'

import CustomSelect from '../Components/CustomSelect'
import { Condition } from '../../../../../Models/rule'
import { questionRuleConditionItemsByQuestion } from '../../../../../Helpers/branchingHelper'
import AnswersSelector from '../Components/AnswersSelector'
import { getAdminRootStore, useMst } from '../../../../../Stores/rootStore'
import { AccordionSummaryContent, ActionsContainer } from '../Components/Accordion/styled'
import TruncatedText from '../Components/TruncatedText'
import DragLabel from '../../../../../Components/DragAndDrop/DragLabel'
import { IRuleInstance } from '../../../../../Stores/AdminStores/SurveyInfoStores/branchingStore'
import Error, { ErrorLevel } from '../../../../../Components/Error'
import DeleteButton from '../../../../../Components/IconButtons/Delete'

import { QuestionRuleContainer } from './styled'

import '../Components/Accordion/style.scss'

const cnBranchingTab = bem()('branching-tab')

interface IQuestionRuleProps {
  rule: IRuleInstance
  index: number
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null
  disabled: boolean
}

const QuestionRule = ({ rule, index, dragHandleProps, disabled }: IQuestionRuleProps) => {
  const tabStore = useMst().admin.tabStore
  const store = getAdminRootStore().surveyInfoRootStore.pagesStore
  const page = store.getPage(rule.pageId)
  const question = page?.questions.find(x => x.id === rule.questionId)
  const nextPages = store.pages.filter(x => x.id !== rule.pageId)

  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation()
    rule.delete()
    tabStore.setWasChange(true)
  }

  const handleSelectCondition = (e: SelectChangeEvent<string | number>) => {
    rule.setCondition(e.target.value as Condition)
    tabStore.setWasChange(true)
  }

  const handleSelectPage = (e: SelectChangeEvent<string | number>) => {
    rule.setNextPageId(e.target.value as string)
    tabStore.setWasChange(true)
  }

  return <Accordion className={cnBranchingTab('styled-accordion')} expanded={rule.isExpanded} onChange={rule.toggleExpanded}>
    <AccordionSummary
      className={cnBranchingTab('styled-accordion__summary', {
        'error': rule.errors.size > 0,
        'warning': rule.isDeprecated,
        'branching-item-title': true,
      })}
      expandIcon={<ExpandMoreIcon/>}
    >
      <AccordionSummaryContent>
        <TruncatedText text={`Правило ${index}`}/>
        <Error errorLevel={ErrorLevel.Warning} isVisible={rule.errors.size === 0 && rule.isDeprecated}>Устарело</Error>
        <Error isVisible={rule.errors.size > 0}/>
        <ActionsContainer>
          {!disabled && <DeleteButton onClick={handleDelete}/>}
          {!disabled && <DragLabel dragHandleProps={dragHandleProps}/>}
        </ActionsContainer>
      </AccordionSummaryContent>
    </AccordionSummary>
    <AccordionDetails>
      <QuestionRuleContainer>
        <CustomSelect
          label="Пользователь"
          value={rule.condition}
          onChange={handleSelectCondition}
          error={rule.errors.has('condition')}
          errorText={rule.errors.get('condition')}
          data={questionRuleConditionItemsByQuestion(question)}
          valueField="value"
          textField="text"
          disabled={disabled}
        />
        <AnswersSelector question={question} logicItem={rule} disabled={disabled}/>
        <CustomSelect
          label="Переход к странице"
          value={rule.nextPageId ?? undefined}
          onChange={handleSelectPage}
          error={rule.errors.has('nextPageId')}
          errorText={rule.errors.get('nextPageId')}
          data={nextPages}
          valueField={x => x.id ?? ''}
          textField="title"
          disabled={disabled}
        />
      </QuestionRuleContainer>
    </AccordionDetails>
  </Accordion>
}

export default observer(QuestionRule)
