import { useEffect, useState } from "react";

export const useKeyUp = (onKeyUp: () => void) => {
  useEffect(() => {
    const onKeyUpEvent = (event: any) => {
      if (event.isComposing || event.keyCode === 229) {
          return;
      }

      onKeyUp()
    };
    
    window.addEventListener("keyup", onKeyUpEvent);

    return () => {
      window.removeEventListener("keyup", onKeyUpEvent);
    };
  }, []);
};