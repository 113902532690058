import { getExpressBasePath, isExpressSmartApp } from '@/applicationConfig'

// Генерирует относительную ссылку для переадресации, исходя из того где открыт фронт: 1) Смартапп eXpress или 2) обычный браузер
export const getRedirectHybridPath = (path: string) => {
  if (!isExpressSmartApp()) {
    return path
  }

  return `${getExpressBasePath()}${path}`
}
