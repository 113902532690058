import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { observer } from 'mobx-react'
import { Container, Grid, Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { sortBy } from 'lodash'

import { ILoaderState, LoaderStateContext } from '@/Components/LoaderContext'
import { Button } from '@/Components'
import Sticky from '@/Components/Sticky'
import { isFeatureEnabled } from '@/Components/Feature'
import { FrontendFeature } from '@/applicationConfig'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { RouteEnum } from '@/Enums/route'
import ServicesContentGenerator from '@/Components/ServicesContentGenerator'
import { useMst } from '@/Stores/rootStore'
import { SurveyType } from '@/Stores/AdminStores/surveysStore'
import { ISurveyInstance } from '@/Stores/UserStores/userSurveysStore'
import { apiSetStartSurveyEntry } from '@/Api/analytics'
import LabssPollsIcon from '@/Images/labss_polls.png'
import KeyIcon from '@/Images/key.svg'
import { getRedirectHybridPath } from '@/Helpers/redirectHybridPath'
import htmlSafeParse from '@/Helpers/htmlSafeParseHelper'
import bem from '@/Helpers/BemClass'

import { ActionsContainer, ContentContainer, CounterContainer, SeparatorStyled } from './styled'

import './style.scss'

const cnBlocksChooser = bem()('blocks-chooser')
const cnSurveysList = bem()('surveys-list')

enum SelectionState {
  SelectAll,
  DisableAll,
  NoItems,
}

// TODO: отрефакторить совместно с src\Components\ServicesContentGenerator\index.tsx
const CardsSelectionPage = () => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  // пример использования для более мелких экранов
  // const UIisMobileXs = useMediaQuery(muiTheme.breakpoints.down('xs'))
  const UIisTablet = useMediaQuery(muiTheme.breakpoints.down('md'))

  const loadingContext = useContext<ILoaderState>(LoaderStateContext)
  const { surveyId } = useParams<{ surveyId: string }>()
  const routeManager = useRouteManager()
  const store = useMst()
  const [survey, setSurvey] = useState<ISurveyInstance | null>(null)
  const [requiredPages, setRequiredPages] = useState<string[]>([])
  const [selectedPages, setSelectedPages] = useState<string[]>([])
  const [searchText, setSearchText] = useState('')
  const [hoveredPageInfo, setHoveredPageInfo] = useState<{
    name: string | null
    description: string | null
    owner: string | null
  } | null>(null)

  const lowerCaseSearchText = searchText.toLowerCase()
  const filteredPages = survey?.pages.filter(x => x.title.toLowerCase().includes(lowerCaseSearchText)) ?? []

  useEffect(() => {
    (async () => {
      loadingContext.changeIsLoading(true)
      const syncSurvey = await store.user.userSurveysStore.syncSurvey(surveyId)
      setSurvey(syncSurvey)

      const selectedPagesLocal = localStorage.getItem('selectedPages')
      const selectedPagesLocalParsed = selectedPagesLocal ? JSON.parse(selectedPagesLocal) : selectedPagesLocal

      const selectedPages = (!!selectedPagesLocalParsed && selectedPagesLocalParsed.surveyId === syncSurvey?.surveyId && selectedPagesLocalParsed.version === syncSurvey?.version) ? selectedPagesLocalParsed.selectedPages ?? [] : []

      setSelectedPages([...syncSurvey.pages.filter(x => (x.isRequired || store.user.selectedPagesState.all.includes(x.id) || selectedPages.includes(x.id)) && !x.isComplete).map(x => x.id)])

      setRequiredPages(syncSurvey.pages.filter(x => x.isRequired && !x.isComplete).map(x => x.id) ?? [])
      loadingContext.changeIsLoading(false)
    })()
  }, [surveyId])

  const handleSelectPage = (pageId: string) => {
    if (requiredPages.includes(pageId)) return
    if (selectedPages.includes(pageId)) setSelectedPages(prev => prev.filter(x => x !== pageId))
    else setSelectedPages(prev => [...prev, pageId])
  }

  const handleSelectAllPages = () => {
    // Проверяем только отфильтрованные
    if (filteredPages.filter(x => !x.isComplete).every(x => selectedPages.includes(x.id))) {
      // при очистке оставляем обязательные или страницы не из списка отфильтрованных
      setSelectedPages(prev => prev.filter(x => requiredPages.includes(x) || !filteredPages.some(fp => fp.id === x)))
    // все выбранные + отфильтрованные не в выбранных
    } else {
      setSelectedPages(prev => [...prev, ...filteredPages.filter(x => !x.isComplete && !prev.includes(x.id)).map(x => x.id)])
    }
  }

  const getCounterText = () => {
    const count = selectedPages.length
    const cases = [2, 0, 1, 1, 1, 2]

    const titlesGvk = ['Выбран @{count} сервис', 'Выбрано @{count} сервиса', 'Выбрано @{count} сервисов']
    const titlesCustom = ['Выбрана @{count} карточка', 'Выбрано @{count} карточки', 'Выбрано @{count} карточек']
    const titles = survey?.type === SurveyType.Gvk ? titlesGvk : titlesCustom
    const res = titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]]
    return res.replace('@{count}', count.toString())
  }

  const handleStartPage = async () => {
    const sortedSelectedPages = sortBy(selectedPages, ['order'])
    store.user.selectedPagesState.setServices(sortedSelectedPages, sortedSelectedPages[0])
    localStorage.setItem('selectedPages', JSON.stringify({ surveyId: survey?.surveyId, version: survey?.version, selectedPages: sortedSelectedPages }))
    loadingContext.changeIsLoading(true)
    await apiSetStartSurveyEntry(surveyId, sortedSelectedPages)
    routeManager.redirectToUrl(RouteEnum.questionPage, `${survey?.surveyId}/${sortedSelectedPages[0]}`)
  }

  if (!survey || loadingContext.isLoading) return <></>

  const selectionState: SelectionState =
    !filteredPages.filter(x => !x.isComplete).every(x => selectedPages.includes(x.id)) ? SelectionState.SelectAll : (
      filteredPages.filter(x => !x.isComplete).length !== 0 ? SelectionState.DisableAll : SelectionState.NoItems
    )

  const getSelectionStateText = (selectionState: SelectionState) => {
    switch (selectionState) {
      case SelectionState.SelectAll: return 'Выбрать все'
      case SelectionState.DisableAll: return 'Снять все'
      case SelectionState.NoItems: return 'Нет карточек для выбора'
    }
  }

  const handlePageHoverStart = (pageId: string) => {
    const page = survey?.pages.find(x => x.id === pageId)

    const title = page?.title ?? null
    const description = page?.description ?? null
    const owner = page?.owner ?? null

    setHoveredPageInfo({
      name: title && title.trim().length > 0 ? title : null,
      description: description && description.trim().length > 0 ? description : null,
      owner: owner && owner.trim().length > 0 ? owner : null,
    })
  }

  const handlePageHoverEnd = () => {
    setHoveredPageInfo(null)
  }

  return (<>
    <Container maxWidth={false} disableGutters className={cnBlocksChooser({ mobile: UIisMobile })}>
      <Helmet>
        <title>{survey?.title ?? 'Выбор страниц опроса'}</title>
      </Helmet>
      <Grid container direction='row' alignItems='center' className={cnBlocksChooser('container', { mobile: UIisTablet })}>

        { UIisTablet && <Grid container item justifyContent='center' className={cnSurveysList('header', { mobile: UIisTablet })}>
          <Grid item xs={6} sm={5} className={cnSurveysList('header-logo')}>
            <Link color='inherit' component={RouterLink} to={getRedirectHybridPath('/')}>
              <img src={isFeatureEnabled(FrontendFeature.gvk) ? KeyIcon : LabssPollsIcon} alt='Опросы - логотип' loading='lazy' decoding='async'/>
            </Link>
          </Grid>
        </Grid>}

        <Grid container item direction='column' wrap='nowrap' columnSpacing={0}>
          <Grid className={`${cnBlocksChooser('text-header', { mobile: UIisTablet })} ${bem()('bg-gradient')()} --no-select`}>
            <Container maxWidth='xxl'>
              <Grid item>
                <Typography variant='h2' component='h2'>
                  { survey?.type === SurveyType.Gvk ? 'Выберите сервисы для опроса' : 'Выберите карточки для прохождения опросов' }
                </Typography>
              </Grid>
            </Container>
          </Grid>
          <Container maxWidth='xxl' className={cnBlocksChooser('content-container', { mobile: UIisTablet })}>
            <Grid item>
              <ContentContainer>
                <ServicesContentGenerator
                  pages={filteredPages}
                  survey={survey}
                  selectedServices={selectedPages}
                  onSelect={handleSelectPage}
                  searchText={searchText}
                  onSearchTextChange={setSearchText}
                  onPageHoverStart={handlePageHoverStart}
                  onPageHoverEnd={handlePageHoverEnd}
                >
                </ServicesContentGenerator>
                <SeparatorStyled/>
                <ActionsContainer>
                  <Sticky verticalOffset={30} verticalPosition="top" style={{ marginBottom: 'auto' }}>
                    <Grid container alignItems='center'>
                      <Link disabled={selectionState === SelectionState.NoItems} className={cnBlocksChooser('mass-service-selection', { disabled: selectionState === SelectionState.NoItems })} component='button' variant='body1' underline='hover' onClick={handleSelectAllPages}>
                        {getSelectionStateText(selectionState)}
                      </Link>
                    </Grid>
                    {!UIisMobile && hoveredPageInfo && <div className={cnBlocksChooser('page-card-info')}>
                      {hoveredPageInfo.name && <>
                        <Typography className={cnBlocksChooser('page-card-info__name', { title: true })} variant='body2' fontWeight='bold' marginTop={2}>Название:</Typography>
                        <Typography className={cnBlocksChooser('page-card-info__name', { text: true })} variant='caption' fontSize={15} whiteSpace='pre-wrap'>{htmlSafeParse(hoveredPageInfo.name)}</Typography>
                      </>}
                      {hoveredPageInfo.owner && <>
                        <Typography className={cnBlocksChooser('page-card-info__owner', { title: true })} variant='body2' fontWeight='bold' marginTop={2}>Владелец:</Typography>
                        <Typography className={cnBlocksChooser('page-card-info__owner', { text: true })} variant='caption' fontSize={15} whiteSpace='pre-wrap'>{htmlSafeParse(hoveredPageInfo.owner)}</Typography>
                      </>}
                      {hoveredPageInfo.description && <>
                        <Typography className={cnBlocksChooser('page-card-info__description', { title: true })} variant='body2' fontWeight='bold' marginTop={2}>Описание:</Typography>
                        <Typography className={cnBlocksChooser('page-card-info__description', { text: true })} variant='caption' fontSize={15} whiteSpace='pre-wrap' >{htmlSafeParse(hoveredPageInfo.description)}</Typography>
                      </>}
                    </div>}
                  </Sticky>
                  <Sticky verticalOffset={0} verticalPosition="bottom" style={{ marginTop: 'auto' }}>
                    <CounterContainer>{getCounterText()}</CounterContainer>
                    <Button width={190} disabled={selectedPages.length === 0} onClick={handleStartPage}>Пройти опрос</Button>
                  </Sticky>
                </ActionsContainer>
              </ContentContainer>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Container>
  </>)
}


export default observer(CardsSelectionPage)
