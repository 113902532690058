import { observer } from 'mobx-react'
import { List, Skeleton } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { IPage } from '@/Models/page'
import PageServiceRow from '@/Pages/Admin/SurveyInfoPage/TargetAudienceMappingTab/Components/PageServiceRow'
import { IServiceIn } from '@/Stores/AdminStores/adminServicesStore'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnPageServiceList = bem()('page-service-list')

interface IPageServiceList {
  pages: IPage[]
  services: IServiceIn[]
  onPageServiceChange: (pageid: string, serviceId?: string) => void
  isLoading?: boolean
  disabled?: boolean
  onSave?: () => void
}

const PageServiceList = ({ pages, services, onPageServiceChange, isLoading = false, disabled = false, onSave }: IPageServiceList) => {
  const handlePageServiceChange = (pageId: string) => (serviceId?: string) => {
    onPageServiceChange(pageId, serviceId)
  }

  return <>
    <List>
      {isLoading ? [1, 2, 3].map(x => <Skeleton variant="rectangular" key={x} style={{ marginBottom: '10px' }} width='100%' height='50px' />) : pages.map(x => <PageServiceRow
        key={x.id}
        page={x}
        services={services}
        onServiceChange={handlePageServiceChange(x.id ?? '')}
        disabled={disabled}
      />
      )}
    </List>
    <div className={cnPageServiceList('button-container')}>
      {onSave && <LoadingButton
        variant='contained'
        disabled={disabled}
        loading={isLoading}
        onClick={onSave}
        className={cnPageServiceList('button-element')}
      >
        Сохранить
      </LoadingButton>}
    </div>
  </>
}

export default observer(PageServiceList)
