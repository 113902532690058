import { ChangeEvent } from 'react'
import { Switch, Typography } from '@mui/material'

import bem from '@/Helpers/BemClass'

import './style.scss'

const cnStyledSwitch = bem()('styled-switch')

interface IStyledSwitch {
  isSelected: boolean
  onChange: (isCardState: boolean) => void
  disabled: boolean
  leftText: string
  rightText: string
}

const StyledSwitch = ({ isSelected, onChange, disabled, leftText, rightText }: IStyledSwitch) => {
  const handleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
    onChange(value)
  }

  const handleTextClick = (requiredState: boolean) => () => {
    if (!disabled && requiredState === isSelected) {
      onChange(!isSelected)
    }
  }

  return (
    <div className={cnStyledSwitch('container', { disabled })}>
      <Typography onClick={handleTextClick(true)} className={cnStyledSwitch('variant', { disabled: disabled, selected: !isSelected })} variant="h4">{leftText}</Typography>
      <Switch
        disableRipple
        color="default"
        checked={isSelected}
        onChange={handleSwitch}
        disabled={disabled}
        classes={{
          root: cnStyledSwitch('root'),
          track: cnStyledSwitch('track'),
          thumb: cnStyledSwitch('thumb'),
        }}
      />
      <Typography onClick={handleTextClick(false)} className={cnStyledSwitch('variant', { disabled: disabled, selected: isSelected })} variant="h4">{rightText}</Typography>
    </div>
  )
}

export default StyledSwitch
