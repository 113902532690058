import { Box, Typography, Switch } from '@mui/material'

import bem from '@/Helpers/BemClass'

import './style.scss'

const cntAnswerTab = bem()('answer-tab')

// TODO: улучшить тайпинги
interface IStickyTitle {
  isTableViewMode: boolean
  setIsTableViewMode: (isTableViewMode: boolean) => void
}

export const StickyTitle = ({
  isTableViewMode,
  setIsTableViewMode,
}: IStickyTitle) => {
  return (
    <Box className={cntAnswerTab('sticky-title')}>
      <Box className={cntAnswerTab('title-container')}>
        <Box className={cntAnswerTab('display-mode-switch-wrapper')}>
          <Typography
            className={cntAnswerTab('first-mod-switch-label')}
            color={isTableViewMode ? 'primary' : 'textPrimary'}
          >
            Таблицы
          </Typography>
          <Switch
            className={cntAnswerTab('display-mode-switch')}
            disableRipple
            color='default'
            checked={!isTableViewMode}
            onClick={() => setIsTableViewMode(!isTableViewMode)}
          />
          <Typography color={isTableViewMode ? 'textPrimary' : 'primary'}>
            Графики
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default StickyTitle
