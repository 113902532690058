import { sortBy } from 'lodash'

import { apiGetUserPageHistory } from '@/Api/page'
import { RouteEnum } from '@/Enums/route'
import { IRouteManager } from '@/Hooks/useRouteManager'
import { ISurveyInstance } from '@/Stores/UserStores/userSurveysStore'
import { IPageStoreInstance } from '@/Stores/UserStores/pageStore'
import { ISelectedPagesStoreInstance } from '@/Stores/UserStores/selectedPagesStore'
import { apiSetStartSurveyEntry } from '@/Api/analytics'

export const surveyRedirect = async (survey: ISurveyInstance, pageStore: IPageStoreInstance, selectedPagesStore: ISelectedPagesStoreInstance, routerManager: IRouteManager) => {
  pageStore.setSurvey(survey)
  await pageStore.loadQuestionFilters()
  if (!survey.withCards) {
    const history = await apiGetUserPageHistory(survey.surveyId)
    if (history?.length > 0) {
      selectedPagesStore.setServices(history, history[history.length - 1], survey.pages.length)
    } else {
      const selectedPages = (sortBy(survey.pages.filter((x) => !x.isComplete), ['order']).map(x => x.id))
      selectedPagesStore.setServices([selectedPages[0]], selectedPages[0], survey.pages.length)
    }
    await apiSetStartSurveyEntry(survey.surveyId, [])
    routerManager.redirectToUrl(RouteEnum.questionPage, `${survey.surveyId}`)
  } else {
    routerManager.redirectToUrl(RouteEnum.startPage, survey.surveyId)
  }
}
