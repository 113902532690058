import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

import { QuestionType } from '../Models/page'
import { Condition, Overlap } from '../Models/rule'

export const questionFilterConditionItemsByQuestion = (question?: IQuestionInstance) => {
  switch (question?.type) {
    case QuestionType.text:
      return [
        { value: Condition.AnswerEntered, text: 'Ввел ответ' },
      ]
    case QuestionType.attachment:
      if (question?.payload?.isEnabledLoadFileButton) {
        return [
          { value: Condition.AnswerEntered, text: 'Загрузил файл' },
        ]
      } else {
        return [
          { value: Condition.Always, text: 'Всегда' },
        ]
      }
    case undefined:
      return []
    default:
      return [
        { value: Condition.Choose, text: 'Выбрал варианты' },
      ]
  }
}

export const pageFilterConditionItemsByQuestion = (question?: IQuestionInstance) => {
  switch (question?.type) {
    case QuestionType.text:
      return [
        { value: Condition.Skip, text: 'Пропустил вопрос' },
        { value: Condition.AnswerEntered, text: 'Ввел ответ' },
        { value: Condition.Always, text: 'Всегда' },
      ]
    case QuestionType.attachment:
      if (question?.payload?.isEnabledLoadFileButton) {
        return [
          { value: Condition.AnswerEntered, text: 'Загрузил файл' },
          { value: Condition.Skip, text: 'Пропустил вопрос' },
          { value: Condition.Always, text: 'Всегда' },
        ]
      } else {
        return [
          { value: Condition.Always, text: 'Всегда' },
        ]
      }
    case undefined:
      return []
    default:
      return [
        { value: Condition.Skip, text: 'Пропустил вопрос' },
        { value: Condition.Choose, text: 'Выбрал варианты' },
        { value: Condition.NotChoose, text: 'Не выбрал варианты' },
        { value: Condition.Always, text: 'Всегда' },
      ]
  }
}

export const questionRuleConditionItemsByQuestion = (question?: IQuestionInstance) => {
  return pageFilterConditionItemsByQuestion(question)
}

export const createOverlapsMenuItemArray = [
  { value: Overlap.Or, text: 'Или' },
  { value: Overlap.And, text: 'И' },
]
