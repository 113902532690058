import { VariantType } from 'notistack'

export enum ApiErrorEnum {
  userNotFound = 'userNotFound',
}

export interface ISessionStorageErrors {
  text: string
  autoHideDuration?: number
  variant?: VariantType
}
