import { ReactNode } from 'react'
import { observer } from 'mobx-react'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

import { QuestionStyle, QuestionType } from '@/Models/page'
import Feature from '@/Components/Feature'
import { FrontendFeature } from '@/applicationConfig'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import CommentSelector from '@/Pages/Admin/EditPage/Components/Question/QuestionSettings/CommentSelector'
import QuestionTypeSelector from '@/Pages/Admin/EditPage/Components/Question/QuestionSettings/QuestionTypeSelector'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

interface IQuestionSettings {
  question: IQuestionInstance
  isMain: boolean
  disabled: boolean
  showRequiredCheckBox?: boolean
  showQuestionTypeSelector?: boolean
  children?: ReactNode
}

const QuestionSettings = ({ question, isMain, disabled, showRequiredCheckBox = false, showQuestionTypeSelector = false, children }: IQuestionSettings) => {
  return (<div style={{ minWidth: '330px' }}>
    {showQuestionTypeSelector && <QuestionTypeSelector question={question} disabled={isMain || disabled} />}
    {CheckEnumIs(question.type, QuestionType.rating, QuestionType.slider) && <CommentSelector question={question} isMain={isMain} disabled={disabled}/>}
    <div>
      <FormGroup>
        {showRequiredCheckBox && <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }} control={<Checkbox size='small' checked={question.isRequired} onChange={question.toggleIsRequier} disabled={isMain || disabled}/>} label='Обязательный вопрос'/>}
        {CheckEnumIs(question.type, QuestionType.radio, QuestionType.checkbox, QuestionType.radioWithImage, QuestionType.checkboxWithImage) && <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }} control={<Checkbox size='small' checked={question.hasCustomAnswer} onChange={question.toggleHasCustomAnswer} disabled={disabled}/>} label='Добавить свой вариант ответа'/>}
        {CheckEnumIs(question.type, QuestionType.slider) && <Feature features={FrontendFeature.styledQuestions}>
          <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }} control={<Checkbox size='small' checked={CheckEnumIs(question.payload?.questionStyle, QuestionStyle.Colorized) } onChange={question.payload.toggleColorStyle} disabled={isMain || disabled}/>} label='Цветная шкала'/>
        </Feature>}
      </FormGroup>
    </div>
  </div>)
}

export default observer(QuestionSettings)
