import { ChangeEvent } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

import bem from '@/Helpers/BemClass'

import './style.scss'

const cnCheckBox = bem()('checkbox')

interface ICheckBox {
  id?: string
  text?: string
  checked?: boolean
  padding?: string
  onChange: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined
}

const CheckBox = ({ id, text, checked, padding: checkBoxPadding, onChange }: ICheckBox) => {
  return (<FormControlLabel
    className={cnCheckBox({ 'no-label': !text })}
    key={id}
    id={id}
    control={<Checkbox
      disableRipple
      checked={checked}
      onChange={onChange}
      sx={{ padding: checkBoxPadding }}
      classes={{
        root: cnCheckBox('root'),
      }}
    />}
    label={text}
  />)
}

export default CheckBox
