export interface IPageModel {
  id: string
  title: string
  category: string | null
  isRequired: boolean
  order: number
}

export interface ICategory {
  name: string
  pages: IPageModel[]
  order: number
}

export interface IShortPageInfo {
  category: string | null
  isRequired: boolean
  order: number
}

export enum DropType {
  page = 'PAGE',
  category = 'CATEGORY',
}
