import { IOrganizationStructureFreeElement } from '@/Stores/AdminStores/organizationStructureStore'

export interface IPagesTargetAudienceMapping {
  pagesTargetAudienceMapping: Map<string, ITargetAudiencesOrganizationStructureElementId>
}

export interface ITargetAudiencesOrganizationStructureElementId {
  isDraft?: boolean
  organizationStructureElementsId: string[]
  organizationStructureFreeElementsId: IOrganizationStructureFreeElement[]
}

export enum ResultAccess {
  None = 'Никому',
  All = 'Всем респондентам',
}
