import BackendAdapter from '@/Api/_adapter'
import { ITargetAudiencesOrganizationStructureElementId } from '@/Models/targetAudience'
import { IOrganizationStructureFreeElement } from '@/Stores/AdminStores/organizationStructureStore'

export const apiGetSurveyTargetAudiences = async (surveyId: string): Promise<ITargetAudiencesOrganizationStructureElementId> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/targetAudience`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetAllPagesTargetAudienceMappingForSurvey = async (surveyId: string): Promise<Map<string, ITargetAudiencesOrganizationStructureElementId>> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/targetAudience/all`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetSurveyTargetAudiences = async (surveyId: string, isDraft: boolean, organizationStructureElementsId: string[], freeElements: IOrganizationStructureFreeElement[]): Promise<ITargetAudiencesOrganizationStructureElementId> => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/targetAudience`, {
      organizationStructureElementsId,
      freeElements,
    }, {
      params: {
        isDraft,
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetPageTargetAudiences = async (surveyId: string, pageId: string, isDraft: boolean, organizationStructureElementsId: string[], freeElements: IOrganizationStructureFreeElement[]): Promise<ITargetAudiencesOrganizationStructureElementId> => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/targetAudience`, {
      organizationStructureElementsId,
      freeElements,
    }, {
      params: {
        pageId,
        isDraft,
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
