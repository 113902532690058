import { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'


const EditingTimeSpanActivityAlert = ({ showTime, onExpired }: { showTime: number, onExpired: () => void }) => {
  const [timerCounter, setTimerCounter] = useState(showTime)

  useEffect(() => {
    const interval = setInterval(() => {
      if (timerCounter <= 0) {
        return
      }

      setTimerCounter(seconds => {
        if (seconds === 1) {
          onExpired()
        }

        return seconds - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return <Dialog open={timerCounter > 0}>
    <DialogTitle>Проверка активности</DialogTitle>
    <DialogContent>
      <Typography>{`Ожидание активности пользователя (${timerCounter}s)`}</Typography>
    </DialogContent>
  </Dialog>
}

export default EditingTimeSpanActivityAlert
