/* eslint-disable import/first */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'url-search-params-polyfill'
import 'canvas-toBlob'
import 'smoothscroll-for-websites'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@mui/material'
import { StylesProvider } from '@mui/styles'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import bridge from '@expressms/smartapp-bridge'

import '@/Styles/main.scss'

import { WaiterProvider } from '@/Helpers/react-wait'
import { isExpressSmartApp } from '@/applicationConfig'
import { configureErrorLogging } from '@/errorNotifier'
import Pages from '@/Pages'
// подключение MobX было помещено внутрь rootStore из-за бага в IE, подробнее см. в rootStore
import { Provider, rootStore } from '@/Stores/rootStore'
import { theme } from '@/Theme'
import { LoaderStateContextLayout } from '@/Components/LoaderContext'

// eslint-disable-next-line import/no-named-as-default-member
smoothscroll.polyfill()
configureErrorLogging()

// Фикс прокрутки для IE
// @ts-expect-error documentMode может не быть в document
if (false || !!document.documentMode) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.document.body.addEventListener('mousewheel', (evt: any) => {
    evt.preventDefault()
    const wd = evt.wheelDelta
    const csp = window.pageYOffset
    window.scrollTo({ top: csp - wd, behavior: 'auto' })
  })
}

if (isExpressSmartApp()) {
  try {
    void bridge?.sendClientEvent({
      method: 'ready',
      params: {},
    })
  } catch (err) {
    err && console.error(err)
  }
}

const innerProviders = <WaiterProvider>
  <LoaderStateContextLayout>
    <Provider value={rootStore}>
      <StylesProvider>
        <SnackbarProvider maxSnack={100}>
          <Pages/>
        </SnackbarProvider>
      </StylesProvider>
    </Provider>
  </LoaderStateContextLayout>
</WaiterProvider>

render(
  <HelmetProvider>
    <React.StrictMode>
      <Helmet>
        <title>Сервис опросов</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        {isExpressSmartApp() ?
          <HashRouter>{innerProviders}</HashRouter> :
          <BrowserRouter>{innerProviders}</BrowserRouter>
        }
      </ThemeProvider>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById('polls-app')
)
