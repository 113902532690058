import { useMemo } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Grid, IconButton, TextField, Typography, Link } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import { sortBy, orderBy } from 'lodash'

import bem from '@/Helpers/BemClass'
import { ReactComponent as SadMask } from '@/Images/ph_mask-sad-duotone.svg'
import { ISurveyInstance, IUserPageInstance } from '@/Stores/UserStores/userSurveysStore'
import { SurveyType } from '@/Stores/AdminStores/surveysStore'
import htmlSafeParse from '@/Helpers/htmlSafeParseHelper'
import ServiceCard from '@/Pages/User/StartSurvey/CardsSelectionPage/ServiceCard'

import './style.scss'

interface IServicesContentGenerator {
  pages: IUserPageInstance[]
  survey: ISurveyInstance | undefined
  selectedServices: string[]
  onSelect: (pageId: string) => void
  children: any | undefined
  searchText: string
  onSearchTextChange: (text: string) => void
  onPageHoverStart: (pageid: string) => void
  onPageHoverEnd: () => void
}

interface ICategory {
  name?: string | null
  pages: IUserPageInstance[]
}

const cnServicesContentGenerator = bem()('services-content-generator')
const cnSurveysList = bem()('surveys-list')

const SortableSurveyType = [SurveyType.Custom, SurveyType.Repeatable]

// TODO: отрефакторить совместно с src\Pages\User\StartSurvey\CardsSelectionPage\index.tsx
const ServicesContentGenerator = ({ pages, survey, selectedServices, onSelect, searchText, onSearchTextChange, onPageHoverStart, onPageHoverEnd }: IServicesContentGenerator) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  // пример использования для более мелких экранов
  // const UIisMobileXs = useMediaQuery(muiTheme.breakpoints.down('xs'))

  const categories = useMemo(() => pages.map(x => x.category ?? 'Прочее')
    .filter((v, i, a) => a.indexOf(v) === i)
    .map(x => ({
      name: x,
      order: pages.find(page => page.category === x)?.order,
      pages: orderBy(pages.filter(page => (page.category ?? 'Прочее') === x), ['isRequired'], ['desc']),
    })), [pages])

  const categoriesWithSort = useMemo(() => (survey && SortableSurveyType.includes(survey.type) && survey?.withCards ? (sortBy(categories, ['order'])) : categories), [survey, categories])

  const selectAllWithCategories = (category: ICategory) => () => {
    const pages = category.pages.filter(x => !x.isComplete && !x.isRequired)
    const isAllSelected = pages.every(x => selectedServices.includes(x.id))
    if (isAllSelected) pages.forEach(x => onSelect(x.id))
    else pages.filter(x => !selectedServices.includes(x.id)).forEach(x => onSelect(x.id))
  }

  if (pages.length === 0 && !searchText.length) {
    return (
      <Grid item>
        <Typography variant='h5' component='h5' className={cnSurveysList('text-no-surveys')}>
          У вас нет опросов для прохождения
        </Typography>
        <SadMask className={'cat'}/>
      </Grid>
    )
  }

  return (
    <Grid container direction='column' wrap='nowrap' rowSpacing={{ xs: 1, md: 1 }}>
      <Grid item>
        <Typography variant='h6' component='h6' style={{ fontWeight: 'bold' }}>
          { htmlSafeParse(survey?.title ?? '') }
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='body2' component='p' style={{ color: '#AAA9A9' }}>
          Период: с {survey?.start.toLocaleDateString()} по {survey?.end.toLocaleDateString()}
        </Typography>
      </Grid>

      <TextField
        variant="standard"
        fullWidth
        className={cnServicesContentGenerator('service-search-input')}
        onChange={(e) => onSearchTextChange(e.target.value)}
        value={searchText}
        label={survey?.type === SurveyType.Gvk ? 'Введите название сервиса для поиска' : 'Введите название для поиска'}
        InputProps={{
          endAdornment: searchText && (
            <IconButton size='small' onClick={() => { onSearchTextChange('') }} >
              <CloseIcon fontSize='small'/>
            </IconButton>
          ),
        }}
      />

      {UIisMobile &&
        <Grid item>
          <Typography variant='body2' component='p'>
            { survey?.type === SurveyType.Gvk ? 'Выберите сервисы для опроса' : 'Выберите карточки для прохождения опросов' }
          </Typography>
        </Grid>
      }

      <Grid item>
        {categories.length === 0 && searchText.length &&
          <div className={cnServicesContentGenerator('services-container')}>
            <div className={cnServicesContentGenerator('services-container-empty-text')}>Совпадений не найдено</div>
          </div>
        }
        {categoriesWithSort.map((category, id) => <Accordion TransitionProps={{ unmountOnExit: true }} key={category.name}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            {!category.name ? 'Прочее' : category.name} ({category.pages.length})
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              {category.pages.some(x => !x.isComplete) &&
                <Grid container item xs={12} justifyContent='end'>
                  <Link component='button' variant='body1' underline='hover' onClick={selectAllWithCategories(category)}>
                    {category.pages.every(x => x.isComplete || selectedServices.includes(x.id)) ? 'Снять все' : 'Выбрать все'}
                  </Link>
                </Grid>
              }
              {orderBy(category.pages, 'order').map(page => (
                <Grid key={page.id} className={cnServicesContentGenerator('grid-item')} item xs={6} sm={4} md={4} xxl={3} >
                  <div className={cnServicesContentGenerator('service-card-container')}>
                    <div onMouseEnter={() => onPageHoverStart(page.id)} onMouseLeave={onPageHoverEnd} className={cnServicesContentGenerator('service-card-container-size-fix')}>
                      <ServiceCard
                        page={page}
                        selected={selectedServices.includes(page.id)}
                        handleSelectService={onSelect}
                        isRequired={page.isRequired}
                        survey={survey}
                      />
                    </div>
                  </div>
                </Grid>))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        )}
      </Grid>
    </Grid>
  )
}

export default ServicesContentGenerator

