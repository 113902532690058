import { QuestionType } from '@/Models/page'

export interface IAdditionInfoCell {
  value: string
  fileHash: string | null
  fileUrl: string | null
}

export interface IAdditionInfo {
  groupName: string
  headers: string[]
  cells: IAdditionInfoCell[][]
}

export interface IOptionsInfo {
  value: string
  isSelected: boolean
  percent: number
  count: number
}

export enum ActionType {
  Spreadsheet = 0,
}

export interface IAction {
  text: string
  fileUrl: string | null
  type: ActionType | null
}

export interface IQuestionAnswersInfo {
  questionId: string
  questionText: string
  questionType: QuestionType
  options: IOptionsInfo[]
  additionInfo: IAdditionInfo
  actions: IAction[]
  comments: string[]
  hasCustomAnswer: boolean
  hasNotReadyToRate: boolean
}

export interface IPageAnswersInfo {
  pageId: string
  pageTitle: string
  questionAnswers: IQuestionAnswersInfo[]
}
