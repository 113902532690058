import { useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { useElementSize } from 'usehooks-ts'
import { AutoSizer, List } from 'react-virtualized'
import { observer } from 'mobx-react'
import { Button, Stack, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useMst } from '@/Stores/rootStore'
import bem from '@/Helpers/BemClass'
import { OrganizationStructureElementShortData, OrganizationStructureSectionHeader, OrganizationStructureSectionData } from '@/Components/OrganizationStructure'
import { SectionExpandingState } from '@/Components/OrganizationStructure/TreeViewExtendedWithConfirm'
import { ElementsTypeFilter, IOrganizationStructureFilter } from '@/Stores/AdminStores/organizationStructureStore'

import './style.scss'

const cnOrganizationStructureSectionConfirmedUnits = bem()('organization-structure-section-confirm-units')

interface IOrganizationStructureSectionConfirmedUnits {
  organizationStructureStateSelector: string
  sectionExpandingState: SectionExpandingState
  onExpandChange: () => void
  disabled?: boolean
}

const OrganizationStructureSectionConfirmedUnits = ({ organizationStructureStateSelector, sectionExpandingState, onExpandChange, disabled = false }: IOrganizationStructureSectionConfirmedUnits) => {
  const store = useMst()
  const organizationStructureStore = store.admin.organizationStructureStore
  const tabStore = store.admin.tabStore

  const organizationStructureState = organizationStructureStore.getOrganizationStructureState(organizationStructureStateSelector)
  const [confirmedOrganizationStructureUnitsFilter, setConfirmedOrganizationStructureUnitsfilter] = useState<IOrganizationStructureFilter>()
  const [expandingState, setExpandingState] = useState<SectionExpandingState>(sectionExpandingState)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const isExpanded = [SectionExpandingState.Units, SectionExpandingState.All].includes(expandingState)

  const [ref, { width, height }] = useElementSize()

  useEffect(() => {
    setExpandingState(sectionExpandingState)
  }, [sectionExpandingState])

  useMount(() => {
    setIsLoading(true)

    const filterData = {
      searchText: '',
      isSelectedOnly: false,
      isConfirmedOnly: true,
      elementsTypeFilter: ElementsTypeFilter.NotUset,
    }

    const filter = organizationStructureState?.addFilter(filterData.searchText, filterData.isSelectedOnly, filterData.isConfirmedOnly, filterData.elementsTypeFilter)

    if (filter) {
      setConfirmedOrganizationStructureUnitsfilter(filter)
    }


    setIsLoading(false)

    return () => {
      filter && organizationStructureState?.removeFilter(filter)
    }
  })

  const handleRemoveConfirm = (isFreeElement: boolean) => (elementId: string) => {
    if (isFreeElement) {
      organizationStructureState?.removeFreeElement(elementId)
    } else {
      organizationStructureState?.changeConfirmationAt(false, elementId)
    }

    tabStore.setWasChange(true, 'Были внесены изменения в целевую аудиторию')
  }

  const handleClearUnitsConfirmation = () => {
    confirmedOrganizationStructureUnitsFilter?.changeConfirmationForFiltered(false)
    tabStore.setWasChange(true, 'Были внесены изменения в целевую аудиторию')
  }

  const handleClearUnitsFreeElements = () => {
    confirmedOrganizationStructureUnitsFilter?.removeFilteredFreeElements()
    tabStore.setWasChange(true, 'Были внесены изменения в целевую аудиторию')
  }

  return <Stack direction='column' className={cnOrganizationStructureSectionConfirmedUnits()}>
    <OrganizationStructureSectionHeader onClick={onExpandChange}>
      <Typography fontWeight='bold'>
        {`Подразделения (${confirmedOrganizationStructureUnitsFilter?.filteredOrganizationStructureElements.length ?? 0}):`}
      </Typography>
      <ExpandMoreIcon className={cnOrganizationStructureSectionConfirmedUnits('expand-icon', { opened: isExpanded, closed: !isExpanded })} />
    </OrganizationStructureSectionHeader>
    <OrganizationStructureSectionData halfWidth={true} disableRounding>
      <div ref={ref} className={cnOrganizationStructureSectionConfirmedUnits('section-data-container')}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              rowHeight={30}
              width={width}
              height={height}
              rowCount={
                (confirmedOrganizationStructureUnitsFilter?.filteredOrganizationStructureFreeElements.length ?? 0) +
                (confirmedOrganizationStructureUnitsFilter?.filteredOrganizationStructureElements.length ?? 0)
              }
              rowRenderer={({ key, index, style }) => {
                const freeElementsCount = confirmedOrganizationStructureUnitsFilter?.filteredOrganizationStructureFreeElements.length ?? 0
                const isFreeElement = index < freeElementsCount
                const elementId = isFreeElement ? (confirmedOrganizationStructureUnitsFilter?.filteredOrganizationStructureFreeElements ?? []).at(index) ?? '' : (confirmedOrganizationStructureUnitsFilter?.filteredOrganizationStructureElements ?? []).at(index - freeElementsCount) ?? ''

                return <div key={key} style={style} className={cnOrganizationStructureSectionConfirmedUnits('section-data-row')}>
                  <OrganizationStructureElementShortData
                    organizationStructureStateSelector={organizationStructureStateSelector}
                    elementId={elementId}
                    onDelete={handleRemoveConfirm(isFreeElement)}
                    isLoading={isLoading}
                    disabled={disabled}
                    freeElement={isFreeElement}
                  />
                </div>
              }}
            />
          )}
        </AutoSizer>
      </div>
      {!disabled && <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={0}
        className={cnOrganizationStructureSectionConfirmedUnits('section-data__button-container')}
      >
        <Button
          variant='text'
          disabled={disabled || (confirmedOrganizationStructureUnitsFilter?.filteredOrganizationStructureFreeElements.length ?? 0) === 0}
          className={cnOrganizationStructureSectionConfirmedUnits('section-data__button')}
          onClick={handleClearUnitsFreeElements}
        >
          {`Удалить все конфликты ${(!!confirmedOrganizationStructureUnitsFilter && (confirmedOrganizationStructureUnitsFilter.filteredOrganizationStructureFreeElements.length) !== 0) ? `(${confirmedOrganizationStructureUnitsFilter.filteredOrganizationStructureFreeElements.length})` : ''}`}
        </Button>
        <Button
          variant='text'
          disabled={disabled || (confirmedOrganizationStructureUnitsFilter?.filteredOrganizationStructureElements.length ?? 0) === 0}
          className={cnOrganizationStructureSectionConfirmedUnits('section-data__button')}
          onClick={handleClearUnitsConfirmation}
        >
          Удалить все элементы
        </Button>
      </Stack>}
    </OrganizationStructureSectionData>
  </Stack>
}

export default observer(OrganizationStructureSectionConfirmedUnits)
