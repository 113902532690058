import { useMount } from 'react-use'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SnackbarKey, VariantType, useSnackbar } from 'notistack'

import { NotificationLevel, errorNotifier } from '@/errorNotifier'

const CastLevel = (level: NotificationLevel): VariantType => {
  switch (level) {
    case NotificationLevel.Default: return 'default'
    case NotificationLevel.Success: return 'success'
    case NotificationLevel.Info: return 'info'
    case NotificationLevel.Warn: return 'warning'
    case NotificationLevel.Err: return 'error'
    default: return 'default'
  }
}

/**
 * Организует подписку на ошибки. Ошибки показывает с помощью Snackbar от notistack
 */
export const useNotifyUserForError = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const action = (key: SnackbarKey) => <IconButton onClick={() => closeSnackbar(key)}>
    <CloseIcon/>
  </IconButton>

  const notifyError = (message: string, level: NotificationLevel): void => {
    enqueueSnackbar(message, { persist: true, variant: CastLevel(level), action: action })
  }

  useMount(() => {
    const subId = errorNotifier.subscribe(notifyError)
    return () => {
      errorNotifier.unsubscribe(subId)
    }
  })
}
