import { observer } from 'mobx-react'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'

import { CheckBoxContainer, RowContainer } from '@/Pages/Admin/EditPage/Components/Question/styled'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

interface ITextContainerProps {
  question: IQuestionInstance
  helperTextPlaceholder?: string
  helperTextLable?: string
  disabled?: boolean
}

const SharedAdditionalSettings = (
  {
    question,
    helperTextPlaceholder = 'Введите текст подсказки',
    helperTextLable = 'Текст подсказки',
    disabled = false,
  }: ITextContainerProps) => {

  return <>
    <RowContainer>
      <CheckBoxContainer>
        <FormControlLabel
          labelPlacement='start'
          componentsProps={{ typography: { variant: 'subtitle2' } }}
          label='Подсказка'
          sx={{ margin: 0 }}
          disabled={disabled}
          control={<Checkbox
            disabled={disabled}
            checked={question.showHelperText ?? false}
            onChange={question.toggleShowHelperText}
          />}
        />
      </CheckBoxContainer>
    </RowContainer>

    {question.showHelperText && <TextField
      className={question.emptyHelperTextError ? 'error' : ''}
      fullWidth
      label={helperTextLable}
      placeholder={helperTextPlaceholder}
      disabled={disabled}
      onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
      value={question.helperText}
      name='helperText'
      onChange={e => question.setHelperText(e.target.value)}
      error={question.emptyHelperTextError}
      variant='standard'
    />}
  </>
}

export default observer(SharedAdditionalSettings)
