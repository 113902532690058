export enum SurveyInfoTabsEnum {
  generalInformation = 'generalInformation',
  pageEdit = 'pageEdit',
  servicesList = 'services',
  pagesRepresentation = 'pagesRepresentation',
  branching = 'branching',
  feedback = 'feedback',
  answers = 'answers',
  targetAudienceMapping = 'targetAudienceMapping',
  periodCompare = 'periodCompare',
  respondentActivities = 'respondentActivities',
  reviewSteps = 'reviewSteps',
}
