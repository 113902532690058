import { ReactNode } from 'react'
import { Typography } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import bem from '@/Helpers/BemClass'

import './style.scss'

export enum ErrorLevel {
  Warning,
  Error,
}

interface IError {
  isVisible?: boolean
  children?: ReactNode
  errorLevel?: ErrorLevel
}

const cnError = bem()('error')

const Error = ({ isVisible, children, errorLevel = ErrorLevel.Error }: IError) => {
  if (!isVisible) return <></>
  return (
    <Typography variant='body1' component='span' className={`${cnError({ error: errorLevel === ErrorLevel.Error, warning: errorLevel === ErrorLevel.Warning })} error`}>
      <ErrorOutlineOutlinedIcon/>{children}
    </Typography>
  )
}

export default Error
