import { useState } from 'react'
import { useMount } from 'react-use'
import { observer } from 'mobx-react'
import { Skeleton, Stack, Typography } from '@mui/material'

import { useMst } from '@/Stores/rootStore'
import bem from '@/Helpers/BemClass'
import {
  OrganizationStructureTreeView,
  OrganizationStructureLevelButton,
  OrganizationStructureSectionHeader,
  OrganizationStructureSectionData,
} from '@/Components/OrganizationStructure'
import { ElementsTypeFilter, IOrganizationStructureFilter } from '@/Stores/AdminStores/organizationStructureStore'

import './style.scss'

const cnOrganizationStructureSectionTreeViewWithExpanding = bem()('organization-structure-section-tree-view-with-expanding')

interface IOrganizationStructureSectionTreeViewWithExpanding {
  organizationStructureStateSelector: string
  disabled?: boolean
  readonly?: boolean
}

const OrganizationStructureSectionTreeViewWithExpanding = ({ organizationStructureStateSelector, disabled = false, readonly = false }: IOrganizationStructureSectionTreeViewWithExpanding) => {
  const organizationStructureStore = useMst().admin.organizationStructureStore

  const organizationStructureState = organizationStructureStore.getOrganizationStructureState(organizationStructureStateSelector)

  const [selectedElementsfilter, setSelectedElementsfilter] = useState<IOrganizationStructureFilter>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useMount(() => {
    setIsLoading(true)

    const filterData = {
      searchText: '',
      isSelectedOnly: false,
      isConfirmedOnly: false,
      elementsTypeFilter: ElementsTypeFilter.All,
    }

    const filter = organizationStructureState?.addFilter(filterData.searchText, filterData.isSelectedOnly, filterData.isConfirmedOnly, filterData.elementsTypeFilter)

    if (filter) {
      setSelectedElementsfilter(filter)
    }

    setIsLoading(false)

    return () => filter && organizationStructureState?.removeFilter(filter)
  })

  const handleExpand = (nestingLevel: number) => () => {
    selectedElementsfilter?.updateElementsTypeFilter(ElementsTypeFilter.All)
    const organizationStructureState = organizationStructureStore.getOrganizationStructureState(organizationStructureStateSelector)
    organizationStructureState?.expandOrCollapse(nestingLevel)
  }

  const handleOnlyUsers = () => {
    selectedElementsfilter?.updateElementsTypeFilter(ElementsTypeFilter.User)
  }

  return <Stack direction='column' className={cnOrganizationStructureSectionTreeViewWithExpanding()}>
    <OrganizationStructureSectionHeader>
      <Typography fontWeight='bold'>Оргструктура</Typography>
      <div className={cnOrganizationStructureSectionTreeViewWithExpanding('header-buttons')}>
        {[...Array((organizationStructureState?.nestingLevelsCount ?? 0) + 1).keys()].map(x => <OrganizationStructureLevelButton
          key={x}
          data={x + 1}
          selected={x <= (organizationStructureState?.maxLevelFullOpen ?? 0)}
          secondSelected={!!organizationStructureState?.maxLevelAnyOpen && x <= organizationStructureState.maxLevelAnyOpen && !(x <= organizationStructureState.maxLevelFullOpen)}
          isLastNesting={(organizationStructureState?.maxLevelFullOpen ?? 0) === x}
          onClick={handleExpand(x)}
        />)}
        <OrganizationStructureLevelButton
          data='Сотрудники'
          onClick={handleOnlyUsers}
        />
      </div>
    </OrganizationStructureSectionHeader>
    <OrganizationStructureSectionData>
      {isLoading ? <Skeleton variant='rectangular' width='100%' height={130} /> : <OrganizationStructureTreeView
        organizationStructureStateSelector={organizationStructureStateSelector}
        controlledFilter={selectedElementsfilter}
        useControlledFilter
        disabled={disabled}
        readonly={readonly}
        disableClearButton
        disableBacklight
        disableNesting={selectedElementsfilter?.elementsTypeFilter === ElementsTypeFilter.User}
        addSearchFieldScrollerOffset
      />}
    </OrganizationStructureSectionData>
  </Stack>
}

export default observer(OrganizationStructureSectionTreeViewWithExpanding)
