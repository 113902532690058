import { observer } from 'mobx-react'
import { Dialog, Grid, GridSize, IconButton } from '@mui/material'

import { ReactComponent as ZipDocument } from '@/Images/zipFile.svg'
import { apiGetAnswersReport, apiGetAnswersWithFilesReport, apiGetFeedbackReportSurvey, apiGetFullReport } from '@/Api/manage'
import { PermissionType } from '@/Stores/sharedModels/models'
import { useMst } from '@/Stores/rootStore'
import { SurveyType } from '@/Stores/AdminStores/surveysStore'
import RoleQualifier, { isRole } from '@/Components/RoleQualifier'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnGetReportModal = bem()('get-report-modal')

export enum ReportType {
  XlsxAnswersReport,
  CsvAnswersReport,
  CsvAnswersReportWithFiles,
  CsvFeedbackReport,
  ZipFullReport,
}

interface IGetReportModal {
  surveyId: string
  reportsType: ReportType[]
  onClose: () => void
  onStartDownload: () => void
  onEndDownload: () => void
}

const GetReportModal = ({ surveyId, reportsType, onClose, onStartDownload, onEndDownload }: IGetReportModal) => {
  const isAdmin = !!isRole([{ type: PermissionType.admin }])
  const isExpert = !!isRole([{ type: PermissionType.expert }])

  const store = useMst().admin.surveysStore
  const survey = store.surveys.find(x => x.surveyId === surveyId)
  const isAnonym = survey?.isAnonym

  const handleGetAnswersReport = async (isAnonym: boolean | null) => {
    onStartDownload()
    await apiGetAnswersReport(surveyId, isAnonym)
    onEndDownload()
  }

  const handleGetReportWithFile = async (isAnonym: boolean | null) => {
    onStartDownload()
    await apiGetAnswersWithFilesReport(surveyId, isAnonym)
    onEndDownload()
  }

  const handleGetFeedbackReport = (isAnonym: boolean | null) => async () => {
    onStartDownload()
    await apiGetFeedbackReportSurvey(surveyId, isAnonym)
    onEndDownload()
  }

  const handleGetFullReport = async () => {
    onStartDownload()
    await apiGetFullReport(surveyId)
    onEndDownload()
  }

  const columnSize: boolean | GridSize = reportsType.length === 1 ? 12 : 'auto'

  return (<>
    <Dialog className={cnGetReportModal()} onClose={onClose} open={true}>
      <div className={cnGetReportModal('container')}>
        <h3>Скачать отчёт</h3>
        <span>Выберите вариант отчёта</span>
        <Grid className={cnGetReportModal('button-grid-container')} style={{ maxWidth: '600px' }} container>
          {reportsType.includes(ReportType.CsvAnswersReport) && <RoleQualifier roles={[{ type: PermissionType.admin }, { type: PermissionType.expert, surveyId: surveyId }]}>
            <Grid
              className={cnGetReportModal('button__container')}
              xl={columnSize}
              lg={columnSize}
              md={columnSize}
              sm={columnSize}
              xs={columnSize}
            >
              <IconButton className={cnGetReportModal('button__icon')} onClick={async () => await handleGetAnswersReport(isAdmin ? false : null)}>
                <ZipDocument height='100%' width='100%'/>
              </IconButton>
              <div className={cnGetReportModal('button__text')}>
                Ответы респондентов без вложений <br/>
              </div>
            </Grid>
          </RoleQualifier>}
          {survey?.type !== SurveyType.Gvk && reportsType.includes(ReportType.CsvAnswersReport) && <RoleQualifier roles={[{ type: PermissionType.admin }]}>
            <Grid
              className={cnGetReportModal('button__container')}
              xl={columnSize}
              lg={columnSize}
              md={columnSize}
              sm={columnSize}
              xs={columnSize}
            >
              <IconButton className={cnGetReportModal('button__icon')} onClick={async () => await handleGetAnswersReport(true)}>
                <ZipDocument height='100%' width='100%'/>
              </IconButton>
              <div className={cnGetReportModal('button__text')}>
                Ответы респондентов без вложений (А) <br/>
              </div>
            </Grid>
          </RoleQualifier>}
          {reportsType.includes(ReportType.CsvAnswersReportWithFiles) && <RoleQualifier roles={[{ type: PermissionType.admin }, { type: PermissionType.expert, surveyId: surveyId }]}>
            <Grid
              className={cnGetReportModal('button__container')}
              xl={columnSize}
              lg={columnSize}
              md={columnSize}
              sm={columnSize}
              xs={columnSize}
            >
              <IconButton className={cnGetReportModal('button__icon')} onClick={async () => await handleGetReportWithFile(isAdmin ? false : null)}>
                <ZipDocument height='100%' width='100%' />
              </IconButton>
              <div className={cnGetReportModal('button__text')}>
                Ответы респондентов с вложениями
              </div>
            </Grid>
          </RoleQualifier>}
          {survey?.type !== SurveyType.Gvk && reportsType.includes(ReportType.CsvAnswersReportWithFiles) && <RoleQualifier roles={[{ type: PermissionType.admin }]}>
            <Grid
              className={cnGetReportModal('button__container')}
              xl={columnSize}
              lg={columnSize}
              md={columnSize}
              sm={columnSize}
              xs={columnSize}
            >
              <IconButton className={cnGetReportModal('button__icon')} onClick={async () => await handleGetReportWithFile(true)}>
                <ZipDocument height='100%' width='100%' />
              </IconButton>
              <div className={cnGetReportModal('button__text')}>
                Ответы респондентов с вложениями (А)
              </div>
            </Grid>
          </RoleQualifier>}
          {reportsType.includes(ReportType.CsvFeedbackReport) && <RoleQualifier roles={[{ type: PermissionType.admin }, { type: PermissionType.expert, surveyId: surveyId }]}>
            <Grid
              className={cnGetReportModal('button__container')}
              xl={columnSize}
              lg={columnSize}
              md={columnSize}
              sm={columnSize}
              xs={columnSize}
            >
              <IconButton className={cnGetReportModal('button__icon')} onClick={handleGetFeedbackReport(isAdmin ? false : null)}>
                <ZipDocument height='100%' width='100%'/>
              </IconButton>
              <div className={cnGetReportModal('button__text')}>
                Обратная <br/>
                связь
              </div>
            </Grid>
          </RoleQualifier>}
          {survey?.type !== SurveyType.Gvk && reportsType.includes(ReportType.CsvFeedbackReport) && <RoleQualifier roles={[{ type: PermissionType.admin }]}>
            <Grid
              className={cnGetReportModal('button__container')}
              xl={columnSize}
              lg={columnSize}
              md={columnSize}
              sm={columnSize}
              xs={columnSize}
            >
              <IconButton className={cnGetReportModal('button__icon')} onClick={handleGetFeedbackReport(true)}>
                <ZipDocument height='100%' width='100%'/>
              </IconButton>
              <div className={cnGetReportModal('button__text')}>
                Обратная <br/>
                связь (А)
              </div>
            </Grid>
          </RoleQualifier>}
          {reportsType.includes(ReportType.ZipFullReport) && <RoleQualifier roles={[{ type: PermissionType.admin }, { type: PermissionType.admin, surveyId: surveyId }]}>
            <Grid
              className={cnGetReportModal('button__container')}
              xl={columnSize}
              lg={columnSize}
              md={columnSize}
              sm={columnSize}
              xs={columnSize}
            >
              <IconButton className={cnGetReportModal('button__icon')} onClick={handleGetFullReport}>
                <ZipDocument height='100%' width='100%' />
              </IconButton>
              <div className={cnGetReportModal('button__text')}>
                Отчёт по респондентам
              </div>
            </Grid>
          </RoleQualifier>}
        </Grid>
      </div>
    </Dialog>
  </>)
}

export default observer(GetReportModal)
