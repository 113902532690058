import { cast, Instance, types as t } from 'mobx-state-tree'

import { apiDeleteAnswersByPages } from '@/Api/answer'

export interface ISelectedPagesStoreInstance extends Instance<typeof SelectedPagesStore> {
}

export const SelectedPagesStore = t.model({
  pages: t.array(t.string),
  currentPage: t.string,
  count: t.number,
}).views(self => ({
  get currentIndex(): number {
    return self.pages.findIndex(x => x === self.currentPage)
  },
  get next(): string | undefined {
    return self.pages.find((x, i) => i === this.currentIndex + 1)
  },
  get prev(): string | undefined {
    return self.pages.find((x, i) => i === this.currentIndex - 1)
  },
  get nextPages(): string[] {
    return self.pages.slice(this.currentIndex + 1)
  },
  get all(): string[] {
    return self.pages
  },
})).actions(self => ({
  moveNext: () => {
    if (self.next) self.currentPage = self.next
  },
  movePrev: () => {
    if (self.prev) self.currentPage = self.prev
  },
  push: (surveyId: string, pageId: string) => {
    const nextPages = self.nextPages

    // Есди страница последняя, мы можем добавлять следующие страницы в список
    if (nextPages.length === 0) {
      self.pages = cast([...self.pages.slice(0, self.currentIndex + 1), pageId])

    // Если страница не последняя (есть nextPages), значит её отмотали.
    // Если следующая страница не равна странице пришедшей с бэка, значит изменили ветку ответов
    } else if (nextPages.length > 0 && pageId !== nextPages[0]) {
      // Удаляем историю ответов прошлой ветки
      // TODO: возможно стоит использовать await
      void apiDeleteAnswersByPages(surveyId, nextPages)

      // Меняем коллекцию только в случае если изменена ветка прохождения (см if)
      self.pages = cast([...self.pages.slice(0, self.currentIndex + 1), pageId])
    }

    // если страница не последняя, но ветку не меняли, ничего не делаем, т.к. следующая страница равна следующей странице из пройденных ранее

    self.currentPage = pageId
  },
  setServices: (services: string[], currentService?: string, count?: number) => {
    self.pages = cast(services)
    if (currentService) self.currentPage = currentService
    else self.currentPage = self.pages[0]
    self.count = count ?? services.length
  },
  clear: () => {
    self.pages = cast(new Array<string>())
    self.currentPage = ''
    self.count = 0
  },
}))
