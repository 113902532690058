import { ChangeEvent, useState } from 'react'
import TextField from '@mui/material/TextField'

import { Container } from './styled'

interface IMultilineTextField {
  placeholder: string
  label: string
  value: string
  onChange: (e: ChangeEvent<{ value: string }>) => void
  onBlur?: () => void
  rows?: number
  disabled?: boolean
}

const MultilineTextField = ({ placeholder, label, value, onChange, onBlur, rows = 3, disabled }: IMultilineTextField) => {
  const [focus, setFocus] = useState<boolean>(false)
  const handleChangeFocus = () => {
    if (focus) onBlur?.()
    setFocus(!focus)
  }

  return (<>
    <Container className={'textfield'}>
      <TextField
        onFocus={handleChangeFocus}
        onBlur={handleChangeFocus}
        multiline
        variant="filled"
        label={value?.length > 0 || focus ? label : placeholder}
        value={value}
        onChange={onChange}
        rows={rows}
        disabled={disabled}
      />
    </Container>
  </>)
}

export default MultilineTextField
