import { useMemo } from 'react'
import { observer } from 'mobx-react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { CommentType, QuestionType } from '@/Models/page'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import { ToolTip } from '@/Components'
import SelectWithNumbers from '@/Components/SelectWithNumbers'
import { ComboBoxContainer } from '@/Pages/Admin/EditPage/Components/Question/styled'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

interface ICommentSelector {
  question: IQuestionInstance
  isMain: boolean
  disabled: boolean
}

const SliderCommentValues = [
  <MenuItem key={CommentType.NoComment} value={CommentType.NoComment}>Отсутствует</MenuItem>,
  <MenuItem key={CommentType.NotRequiredAndVisibleTo} value={CommentType.NotRequiredAndVisibleTo}>Необязательный и виден до</MenuItem>,
  <MenuItem key={CommentType.VisibleToAndRequiredTo} value={CommentType.VisibleToAndRequiredTo}>Обязателен и виден до</MenuItem>,
  <MenuItem key={CommentType.AlwaysVisibleAndRequiredTo} value={CommentType.AlwaysVisibleAndRequiredTo}>Всегда видимый и обязателен до</MenuItem>,
  <MenuItem key={CommentType.AlwaysVisible} value={CommentType.AlwaysVisible}>Всегда видимый</MenuItem>,
]

const GetCommentValues = (questionType: QuestionType) => {
  switch (questionType) {
    case QuestionType.rating:
    case QuestionType.slider:
      return SliderCommentValues
    default:
      return []
  }
}

const CalculateShowRequireCommentValueSelector = (questionType: QuestionType, commentType?: CommentType) => CheckEnumIs(questionType, QuestionType.slider, QuestionType.rating) &&
    CheckEnumIs(commentType, CommentType.NotRequiredAndVisibleTo, CommentType.VisibleToAndRequiredTo, CommentType.AlwaysVisibleAndRequiredTo)

const CommentSelector = ({ question, isMain, disabled }: ICommentSelector) => {

  const commentValues = useMemo(() => GetCommentValues(question.type), [question.type])
  const showRequireCommentValueSelector = useMemo(() => CalculateShowRequireCommentValueSelector(question.type, question.commentType), [question.type, question.commentType])

  const GetCommentTopThreshold = () => {
    switch (question.type) {
      case QuestionType.rating:
        return Number(question.options.at(-1)?.value) + 1
      case QuestionType.slider:
        return Number(question.options[1].value)
      default:
        return 0
    }
  }

  const toolTipTitles = new Map<QuestionType | undefined, string>([
    [QuestionType.rating, 'Окончание интервала обязательного комментария'],
    [QuestionType.slider, 'Окончание интервала шкалы обязательного комментария'],
  ])

  const GetToolTipTitle = () => {
    return toolTipTitles.get(question.type)
  }

  return (<>
    <ComboBoxContainer>
      <FormControl fullWidth variant="standard">
        <InputLabel shrink id="main-question-comment-type-label">
                    Комментарий
        </InputLabel>
        <Select
          labelId="main-question-comment-type-label"
          value={question.commentType ? question.commentType : CommentType.NoComment}
          onChange={e => question.setCommentType(e.target.value as CommentType)}
          disabled={isMain || disabled}
        >
          {commentValues}
        </Select>
      </FormControl>
    </ComboBoxContainer>
    {showRequireCommentValueSelector && <ComboBoxContainer>
      <ToolTip arrow placement="left" title={GetToolTipTitle()}>
        <FormControl fullWidth style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SelectWithNumbers
            label={'Порог'}
            start={Number(question.orderedOptions[0].value)}
            end={GetCommentTopThreshold()}
            value={question.payload?.requireCommentValue && question.payload?.requireCommentValue.length > 0 ? question.payload?.requireCommentValue : Math.floor(GetCommentTopThreshold() / 2)}
            onChange={e => question.payload.setRequireCommentValue(e.target.value as string)}
            disabled={isMain || disabled}
          />
        </FormControl>
      </ToolTip>
    </ComboBoxContainer>}
  </>)
}

export default observer(CommentSelector)
