import { useEffect, useState, ChangeEvent } from 'react'
import { NoStrictEntityMods } from '@bem-react/classname'
import { Button, Dialog, DialogContent, DialogTitle, Link, List, ListItem, Tab, Tabs, TextField, Typography } from '@mui/material'
import ScheduleIcon from '@mui/icons-material/Schedule'
import EditIcon from '@mui/icons-material/Edit'
import { Redirect, Switch, useParams, useRouteMatch } from 'react-router'
import { Link as ReactLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { observer } from 'mobx-react'

import { ReactComponent as PlayIcon } from '@/Images/Play.svg'
import { ReactComponent as StopIcon } from '@/Images/stop.svg'
import { IDialog } from '@/Components/Dialog'
import { useWaiter } from '@/Helpers/react-wait'
import { useStartStopDialog } from '@/Components/StartStopDialog'
import { useRouteManager } from '@/Hooks'
import { RouteEnum } from '@/Enums/route'
import ExitWithoutSaveDialog from '@/Components/DialogExitWithoutSave'
import { ISurveyInstance, SurveyStatus, SurveyType } from '@/Stores/AdminStores/surveysStore'
import { useMst } from '@/Stores/rootStore'
import { ContentLoader, Dialog as InfoDialog } from '@/Components'
import CustomEditPage from '@/Pages/Admin/EditPage/Custom'
import bem from '@/Helpers/BemClass'
import { TrafficLightColor, TrafficLightGroupType } from '@/Models/trafficLight'
import { apiGetActiveEditingLock } from '@/Api/survey'
import { apiRejectReview, apiSendToReview } from '@/Api/review'
import GeneralInformationTab from '@/Pages/Admin/SurveyInfoPage/GeneralInformationTab'
import { Container, BreadcrumbsStyled } from '@/Pages/Admin/SurveyInfoPage/styled'
import FeedbackTab from '@/Pages/Admin/SurveyInfoPage/FeedbackTab'
import ServicesTab from '@/Pages/Admin/SurveyInfoPage/ServicesTab'
import PagesRepresentationTab from '@/Pages/Admin/SurveyInfoPage/PagesRepresentationTab'
import BranchingTab from '@/Pages/Admin/SurveyInfoPage/BranchingTab'
import { AnswerTab } from '@/Pages/Admin/SurveyInfoPage/AnswerTab'
import PeriodCompareTab from '@/Pages/Admin/SurveyInfoPage/PeriodCompareTab'
import TargetAudienceMappingTab from '@/Pages/Admin/SurveyInfoPage/TargetAudienceMappingTab'
import { DisplayMode } from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/PageAnswers'
import RespondentActivitiesTab from '@/Pages/Admin/SurveyInfoPage/RespondentActivitiesTab'
import ReviewStepTab from '@/Pages/Admin/SurveyInfoPage/ReviewStepTab'
import { SurveyInfoTabsEnum } from '@/Enums/surveyInfoTabsEnum'
import PrivateRoute from '@/Components/PrivateRoute'
import StyledButton from '@/Components/Button'
import { PermissionType } from '@/Stores/sharedModels/models'
import RoleQualifier, { isRole } from '@/Components/RoleQualifier'
import ChangeStateButton from '@/Components/ChangeStateButton'
import DropdownButton from '@/Components/DropdownButton'
import SurveyInfoTab from '@/Components/SurveyInfoTab'
import { isArchivedHelper } from '@/Helpers/isDisabledHelper'
import EditPage from '@/Pages/Admin/EditPage'
import { ErrorType, NotificationLevel, errorNotifier } from '@/errorNotifier'

import './style.scss'

enum VerificationAction {
  ToReview = 'Отправить на проверку',
  AdminToReview = 'Забрать себе',
  Reject = 'Передать редактору',
  Ready = 'Готово к запуску',
  NotReady = 'Не готово к запуску',
}

enum StartType {
  Defaulf = 'Запустить',
  Test = 'Тестовый запуск',
}

const surveyTypesWithTestStart = [SurveyStatus.Editing, SurveyStatus.Created, SurveyStatus.Completed, SurveyStatus.Canceled]
const surveyTypesWithStart = [SurveyStatus.Created, SurveyStatus.Completed, SurveyStatus.Canceled]

// Тема сорян, я смешал стили, но тут слишком удобен bem для светофора))
// TODO: перевести стилевые компоненты в bem
const cnSurveyTabs = bem()('survey-tabs')

const SurveyInfoPage = () => {
  const [survey, setSurvey] = useState<ISurveyInstance>()
  const [isLoading, setIsLoading] = useState(true)
  const { surveyId } = useParams<{ surveyId: string }>()
  const match = useRouteMatch()
  const routeManager = useRouteManager()
  const store = useMst()
  const surveysStore = store.admin.surveysStore
  const tabStore = store.admin.tabStore
  const trafficLightStateStore = store.admin.trafficLightStateStore
  const [dialogWindowIsOpen, setDialogWindowIsOpen] = useState<boolean>(false)
  const [goToUrl, setGoToUrl] = useState<SurveyInfoTabsEnum | null>(null)
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const { isWaiting, Wait } = useWaiter()
  const startStopDialog = useStartStopDialog(setDialogState)
  const surveyTrafficLightState = trafficLightStateStore.createIfNotExistAndGet(surveyId)
  const [selectedTab, setSelectedTab] = useState<SurveyInfoTabsEnum>(SurveyInfoTabsEnum.generalInformation)
  const [reviewComment, setReviewComment] = useState<string | null>(null)
  const [avalableStartType, setAvalableStartType] = useState<StartType[]>(() => {
    let statusTypes: StartType[] = []

    if (!survey) {
      return statusTypes
    }

    if (surveyTypesWithStart.includes(survey.status)) {
      statusTypes = [...statusTypes, StartType.Defaulf]
    }

    if (surveyTypesWithTestStart.includes(survey.status)) {
      statusTypes = [...statusTypes, StartType.Test]
    }

    return statusTypes
  })

  useEffect(() => {
    (async () => {
      setIsLoading(true)

      await surveysStore.loadSurveyInfo(surveyId)
      const survey = surveysStore.surveys.find(x => x.surveyId === surveyId)
      setSurvey(survey)
      tabStore.setWasChange(false)
      setDialogWindowIsOpen(false)

      await trafficLightStateStore.createIfNotExistAndGet(surveyId).loadTrafficLight(false)

      setIsLoading(false)
    })()
  }, [surveyId, surveysStore, tabStore, surveyTrafficLightState, trafficLightStateStore])

  useEffect(() => {
    let statusTypes: StartType[] = []

    if (survey) {
      if (surveyTypesWithStart.includes(survey.status)) {
        statusTypes = [...statusTypes, StartType.Defaulf]
      }

      if (surveyTypesWithTestStart.includes(survey.status)) {
        statusTypes = [...statusTypes, StartType.Test]
      }
    }

    setAvalableStartType(statusTypes)
  }, [survey, survey?.status, store.admin.surveyEditingTimeSpan?.surveyId])

  const handleChangeTab = (e: ChangeEvent<{}>, tab: string) => {
    if (tabStore.wasChanged && survey?.status !== SurveyStatus.Archived) {
      e.preventDefault()
      setGoToUrl(tab as SurveyInfoTabsEnum)
      setDialogWindowIsOpen(tabStore.wasChanged)
      return
    }

    setSelectedTab(tab as SurveyInfoTabsEnum)
  }

  const getRouteEnum = (status: SurveyStatus) => {
    if (status === SurveyStatus.Archived) {
      return RouteEnum.archive
    }
    if (survey?.isReviewStatus) {
      return RouteEnum.review
    }

    return RouteEnum.survey
  }

  const getStatusText = (status: SurveyStatus) => {
    if (status === SurveyStatus.Archived) {
      return 'Архив опросов'
    }
    if (survey?.isReviewStatus) {
      return 'На согласовании'
    }

    return 'Опросы'
  }

  const handleRedirectToMainAdminPage = () => {
    setDialogState(undefined)
    routeManager.redirectTo(RouteEnum.survey)
    tabStore.setWasChange(false)
  }

  const redirectToMainAdminPage = () => {
    if (tabStore.wasChanged) {
      setDialogState({
        open: true,
        handleClose: () => setDialogState(undefined),
        title: 'На странице были изменения',
        text: 'Выйти без сохранения?',
        actions: [
          <Button key={'changes-no-btn'} onClick={() => setDialogState(undefined)}>Нет</Button>,
          <Button key={'changes-yes-btn'} onClick={() => handleRedirectToMainAdminPage()}>Да</Button>,
        ],
      })
    } else {
      routeManager.redirectTo(RouteEnum.survey)
      tabStore.setWasChange(false)
    }
  }

  if (isLoading) {
    return (
      <Container id="survey_info_container">
        <ContentLoader/>
      </Container>
    )
  }

  if (!survey) {
    redirectToMainAdminPage()
    return <></>
  }

  const redirectToParentPage = () => {
    if (!tabStore.wasChanged) {
      routeManager.redirectTo(getRouteEnum(survey.status))
      return
    }
    setGoToUrl(null)
    setDialogWindowIsOpen(tabStore.wasChanged)
  }

  const handleSendToReview = async () => {
    survey.status === SurveyStatus.Editing ? await apiSendToReview(surveyId, reviewComment ?? '') : await apiRejectReview(surveyId, reviewComment ?? '')
    await store.admin.updateSurveyInEditing(null)
    await store.admin.surveysStore.loadSurveyInfo(surveyId)
    setReviewComment(null)
  }

  const targetAudienceTabTrafficLightGroup = surveyTrafficLightState.createIfNotExistAndGet(TrafficLightGroupType.TargetAudience)
  const branchingTabTrafficLightGroup = surveyTrafficLightState.createIfNotExistAndGet(TrafficLightGroupType.Branching)
  const pagesTabTrafficLightGroup = surveyTrafficLightState.createIfNotExistAndGet(TrafficLightGroupType.Pages)

  const isSurveyInEditingLock = store.admin.isEditorFor(surveyId)
  const hasEditors = survey.permissions.some(x => x.type === PermissionType.editor)
  const isAdmin = !!isRole([{ type: PermissionType.admin }])

  return (<Container id="survey_info_container">
    <Helmet>
      <title>{survey?.title ?? 'Прохождение опроса'}</title>
    </Helmet>
    <div className="header">
      <BreadcrumbsStyled separator="›" aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="#" onClick={redirectToParentPage}>{getStatusText(survey.status)}</Link>
        <Typography color="textPrimary">{survey.title}</Typography>
      </BreadcrumbsStyled>
      {!survey.archivedDate &&
        <div>
          {isSurveyInEditingLock && <RoleQualifier
            roles={[{ type: PermissionType.admin }, { type: PermissionType.editor, surveyId: surveyId }]}
            permissionValidation={(permission) => (
              permission.type === PermissionType.admin || (permission.type === PermissionType.editor && survey.status === SurveyStatus.Editing)
            )}
          >
            <DropdownButton
              actions={[
                ...(survey.status === SurveyStatus.Editing ? [!isAdmin ? VerificationAction.ToReview : VerificationAction.AdminToReview] : []),
                ...(survey.status === SurveyStatus.Created && isAdmin && hasEditors ? [VerificationAction.Reject] : []),
                ...(isAdmin ? [survey.isVerified ? VerificationAction.NotReady : VerificationAction.Ready] : []),
              ]}
              onClick={async (action: VerificationAction) => {
                switch (action) {
                  case VerificationAction.AdminToReview: {
                    await handleSendToReview()
                    break
                  }
                  case VerificationAction.Reject:
                  case VerificationAction.ToReview: {
                    setReviewComment('')
                    break
                  }
                  case VerificationAction.NotReady: {
                    await survey.changeVerificationState(false)
                    break
                  }
                  case VerificationAction.Ready: {
                    await survey.changeVerificationState(true)
                    break
                  }
                }
              }}
              classes={{
                root: 'review-button',
              }}
            />
          </RoleQualifier>}
          <RoleQualifier
            roles={[{ type: PermissionType.admin }, { type: PermissionType.editor, surveyId: surveyId }]}
            permissionValidation={(permission) => (permission.type === PermissionType.editor ? survey.status === SurveyStatus.Editing : true)}
          >
            <ChangeStateButton
              isActive={store.admin.surveyEditingTimeSpan?.surveyId === surveyId}
              waitState={`change-editing-state-${survey.surveyId}`}
              waitComponent={<ScheduleIcon width={22} height={22}/>}
              activeComponent={<StopIcon width={22} height={22}/>}
              activeLable='Завершить редактирование'
              disabledComponent={<EditIcon width={22} height={22}/>}
              disabledLable={['Начать редактирование']}
              onEnable={async () => {
                const isSuccess = await store.admin.updateSurveyInEditing(surveyId)

                if (!isSuccess) {
                  const lastActiveEditingLock = await apiGetActiveEditingLock(surveyId)
                  lastActiveEditingLock.length > 0 && errorNotifier.notify(ErrorType.Other, `Редактирование опроса заблокировано участником ${lastActiveEditingLock}`, NotificationLevel.Warn)
                }
              }}
              onDisable={async () => {
                if (tabStore.wasChanged) {
                  setDialogState({
                    open: true,
                    handleClose: () => setDialogState(undefined),
                    title: 'На странице были изменения',
                    text: 'Выйти без сохранения?',
                    actions: [
                      <Button key={'changes-no-btn'} onClick={() => setDialogState(undefined)}>Нет</Button>,
                      <Button key={'changes-yes-btn'} onClick={async () => {
                        await store.admin.updateSurveyInEditing(null)
                        setDialogState(undefined)
                      }}>Да</Button>,
                    ],
                  })
                } else {
                  await store.admin.updateSurveyInEditing(null)
                }
              }}
              classes={{
                root: 'editing-button',
              }}
            />
          </RoleQualifier>
          {store.admin.isEditorFor(surveyId) && <RoleQualifier roles={[{ type: PermissionType.admin }, { type: PermissionType.editor, surveyId: surveyId }]}>
            <ChangeStateButton
              isActive={survey.status === SurveyStatus.Surveyed || survey.hasActiveTestingSession}
              waitState={`change-state-${survey.surveyId}`}
              waitComponent={<ScheduleIcon width={22} height={22}/>}
              activeComponent={<StopIcon width={22} height={22}/>}
              activeLable='Остановить'
              disabledComponent={<PlayIcon width={22} height={22}/>}
              disabledLable={avalableStartType}
              onDisable={() => startStopDialog.stopSurvey(survey, survey.hasActiveTestingSession)}
              onEnable={(action) => startStopDialog.startSurvey(survey, action !== StartType.Defaulf)}
            />
          </RoleQualifier>}
        </div>}
    </div>
    <Tabs
      value={selectedTab ?? SurveyInfoTabsEnum.generalInformation}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChangeTab}
      scrollButtons="auto"
    >
      <Tab value={SurveyInfoTabsEnum.generalInformation} component={ReactLink} to={`${match.url}/${SurveyInfoTabsEnum.generalInformation}`} label="Общая информация"/>
      {/* <SurveyTab tab={SurveyInfoTabsEnum.generalInformation} name="Общая информация" surveyTypes={[SurveyType.Gvk, SurveyType.Custom, SurveyType.Repeatable]} /> */}
      {survey.type === SurveyType.Gvk && <Tab value={SurveyInfoTabsEnum.servicesList} component={ReactLink} to={`${match.url}/${SurveyInfoTabsEnum.servicesList}`} label="Список сервисов"/>}
      {survey.type !== SurveyType.Gvk && isRole([{ type: PermissionType.admin }, { type: PermissionType.editor, surveyId: surveyId }]) && <SurveyInfoTab
        tabTrafficLightGroup={pagesTabTrafficLightGroup}
        text='Создание страниц'
        url={match.url}
        tab={SurveyInfoTabsEnum.pageEdit}
      />}
      {survey.type !== SurveyType.Gvk &&
        isRole([{ type: PermissionType.admin }, { type: PermissionType.editor, surveyId: surveyId }]) &&
        <Tab value={SurveyInfoTabsEnum.pagesRepresentation} component={ReactLink} to={`${match.url}/${SurveyInfoTabsEnum.pagesRepresentation}`} label="Представление страниц"/>}
      {survey.type !== SurveyType.Gvk && isRole([{ type: PermissionType.admin }]) && <SurveyInfoTab
        tabTrafficLightGroup={targetAudienceTabTrafficLightGroup}
        text='Респонденты'
        url={match.url}
        tab={SurveyInfoTabsEnum.targetAudienceMapping}
      />}
      {[SurveyType.Custom, SurveyType.Gvk].includes(survey.type) && isRole([{ type: PermissionType.admin }, { type: PermissionType.editor, surveyId: surveyId }]) && <SurveyInfoTab
        tabTrafficLightGroup={branchingTabTrafficLightGroup}
        text='Ветвление'
        url={match.url}
        tab={SurveyInfoTabsEnum.branching}
      />}
      {isRole([{ type: PermissionType.admin }, { type: PermissionType.expert, surveyId: surveyId }]) && <Tab value={SurveyInfoTabsEnum.answers} component={ReactLink} to={`${match.url}/${SurveyInfoTabsEnum.answers}`} label="Ответы"/>}
      {isRole([{ type: PermissionType.admin }, { type: PermissionType.expert, surveyId: surveyId }]) && <Tab value={SurveyInfoTabsEnum.feedback} component={ReactLink} to={`${match.url}/${SurveyInfoTabsEnum.feedback}`} label="Обратная связь"/>}
      {survey.type === SurveyType.Repeatable && isRole([{ type: PermissionType.admin }, { type: PermissionType.expert, surveyId: surveyId }]) && <Tab value={SurveyInfoTabsEnum.periodCompare} component={ReactLink} to={`${match.url}/${SurveyInfoTabsEnum.periodCompare}`} label="Сравнение периодов"/>}
      {survey.type !== SurveyType.Gvk && isRole([{ type: PermissionType.admin }, { type: PermissionType.expert, surveyId: surveyId }]) && (isAdmin || !survey.isAnonym) && <Tab value={SurveyInfoTabsEnum.respondentActivities} component={ReactLink} to={`${match.url}/${SurveyInfoTabsEnum.respondentActivities}`} label="Прохождение"/>}
      {survey.type !== SurveyType.Gvk && isRole([{ type: PermissionType.admin }, { type: PermissionType.editor, surveyId: surveyId }]) && <Tab value={SurveyInfoTabsEnum.reviewSteps} component={ReactLink} to={`${match.url}/${SurveyInfoTabsEnum.reviewSteps}`} label="Журнал изменений"/>}
    </Tabs>

    <Switch>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.expert, surveyId: surveyId },
          { type: PermissionType.editor, surveyId: surveyId },
        ]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.generalInformation)}
        path={`${match.path}/${SurveyInfoTabsEnum.generalInformation}`}
      >
        <GeneralInformationTab readonly={!isSurveyInEditingLock} surveyId={surveyId}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.editor, surveyId: surveyId },
        ]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.servicesList)}
        path={`${match.path}/${SurveyInfoTabsEnum.servicesList}/:serviceId`}
      >
        <EditPage readonly={!isSurveyInEditingLock || isArchivedHelper(survey)} surveyId={surveyId}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[{ type: PermissionType.admin }]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.servicesList)}
        path={`${match.path}/${SurveyInfoTabsEnum.servicesList}`}
      >
        <ServicesTab readonly={!isSurveyInEditingLock || isArchivedHelper(survey)} surveyId={surveyId}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.editor, surveyId: surveyId },
        ]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.pageEdit)}
        path={`${match.path}/${SurveyInfoTabsEnum.pageEdit}`}
      >
        <CustomEditPage readonly={!isSurveyInEditingLock || isArchivedHelper(survey)} isCardState={survey.withCards}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.editor, surveyId: surveyId },
        ]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.pagesRepresentation)}
        path={`${match.path}/${SurveyInfoTabsEnum.pagesRepresentation}`}
      >
        <PagesRepresentationTab readonly={!isSurveyInEditingLock || isArchivedHelper(survey)} surveyId={surveyId}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[{ type: PermissionType.admin }]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.targetAudienceMapping)}
        path={`${match.path}/${SurveyInfoTabsEnum.targetAudienceMapping}`}
      >
        <TargetAudienceMappingTab readonly={!isSurveyInEditingLock || isArchivedHelper(survey)} surveyId={surveyId}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.editor, surveyId: surveyId },
        ]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.branching)}
        path={`${match.path}/${SurveyInfoTabsEnum.branching}`}
      >
        <BranchingTab readonly={!isSurveyInEditingLock || isArchivedHelper(survey)} surveyId={surveyId}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.expert, surveyId: surveyId },
        ]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.answers)}
        path={`${match.path}/${SurveyInfoTabsEnum.answers}`}
      >
        <AnswerTab surveyId={surveyId} displayMode={DisplayMode.Admin}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.expert, surveyId: surveyId },
        ]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.feedback)}
        path={`${match.path}/${SurveyInfoTabsEnum.feedback}`}
      >
        <FeedbackTab surveyId={surveyId}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.expert, surveyId: surveyId },
        ]}
        permissionValidation={permission => permission.type === PermissionType.admin || !survey.isAnonym}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.respondentActivities)}
        path={`${match.path}/${SurveyInfoTabsEnum.respondentActivities}`}
      >
        <RespondentActivitiesTab surveyId={surveyId}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.editor, surveyId: surveyId },
        ]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.reviewSteps)}
        path={`${match.path}/${SurveyInfoTabsEnum.reviewSteps}`}
      >
        <ReviewStepTab surveyId={surveyId}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.expert, surveyId: surveyId },
        ]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.periodCompare)}
        path={`${match.path}/${SurveyInfoTabsEnum.periodCompare}`}
      >
        <PeriodCompareTab surveyId={surveyId}/>
      </PrivateRoute>
      <PrivateRoute
        roles={[
          { type: PermissionType.admin },
          { type: PermissionType.expert, surveyId: surveyId },
          { type: PermissionType.editor, surveyId: surveyId },
        ]}
        onSuccess={() => setSelectedTab(SurveyInfoTabsEnum.generalInformation)}
        path={`${match.path}`}
      >
        <Redirect to={`${surveyId}/${SurveyInfoTabsEnum.generalInformation}`}/>
      </PrivateRoute>
    </Switch>
    {dialogWindowIsOpen && <ExitWithoutSaveDialog
      onConfirm={() => {
        if (goToUrl === null) {
          routeManager.redirectTo(RouteEnum.survey)
          return
        }

        routeManager.redirectToUrl(RouteEnum.survey, `${surveyId}/${goToUrl}`)
        setSelectedTab(goToUrl)
      }}
      onClose={() => setDialogWindowIsOpen(false)}
    />}
    {dialogState && <InfoDialog {...dialogState}/>}
    {<Dialog
      onClose={() => { setReviewComment(null) }}
      open={reviewComment != null}
      fullWidth
    >
      <DialogTitle
        classes={{
          root: cnSurveyTabs('comment__title'),
        }}
      >
        <Typography variant='h5' fontWeight='bold'>{survey.status === SurveyStatus.Editing ? 'Отправка на согласование' : 'Возврат в доработку'}</Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <TextField
              disabled={isWaiting('review-state-update')}
              multiline
              rows={4}
              fullWidth
              label='Комментарий'
              variant='outlined'
              onChange={e => setReviewComment(e.target.value)}
            />
          </ListItem>
          <ListItem
            classes={{
              root: cnSurveyTabs('comment__button'),
            }}
          >
            <StyledButton waitName='review-state-update' variant='contained' onClick={handleSendToReview}>
              Отправить
            </StyledButton>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>}
  </Container>)
}

export default observer(SurveyInfoPage)
