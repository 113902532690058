import { observer } from 'mobx-react'
import { Checkbox, FormControlLabel } from '@mui/material'

import { FileType } from '@/Models/page'

interface ICheckBoxList {
  checked: FileType[]
  onChange: (item: FileType) => void
  disabled?: boolean
}

const CheckBoxList = ({ checked, onChange, disabled = false }: ICheckBoxList) => {
  const items = Object.values(FileType)

  return (<>
    {items.map((x, i) => <>
      <FormControlLabel
        disabled={disabled}
        componentsProps={{ typography: { variant: 'subtitle2' } }}
        control={<Checkbox disabled={disabled} checked={checked?.includes(x)} onChange={() => onChange(x)}/>}
        label={Object.keys(FileType)[i]}
      />
    </>)}
  </>)
}

export default observer(CheckBoxList)
