import styled from 'styled-components'

import { IColor } from '@/Models/spreadsheet'

export const CellInput = styled.input<{
  bgcolor: (IColor | null)
  fontColor: (IColor | null)
  fontSize: number
  isItalic: boolean
  isBold: boolean
}>`
  ${props => (props.width ? `min-width: ${props.width}px;` : '')}
  ${props => (props.height ? `min-height: ${props.height}px;` : '')}

  ${props => (props.bgcolor ? `background-color: rgb(${props.bgcolor.r}, ${props.bgcolor.g}, ${props.bgcolor.b});` : '')}
  ${props => (props.fontColor ? `color: rgb(${props.fontColor.r}, ${props.fontColor.g}, ${props.fontColor.b});` : '')}
  ${props => (props.fontSize ? `font-size: ${props.fontSize}rem;` : '')}

  ${props => (props.isItalic ? 'font-style: italic;' : '')}
  ${props => (props.isBold ? 'font-weight: bold;' : '')}
`
