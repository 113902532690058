import { ReactNode, Context, createContext, useContext } from 'react'
import { DragDropContext, DropResult, ResponderProvided } from 'react-beautiful-dnd'

import { DefaultDndSubscriptionContext, IDndSubscriptionContext } from './Models/dnd-subscription-context'

const DndContext: Context<IDndSubscriptionContext> = createContext(DefaultDndSubscriptionContext)
export default DndContext

interface IChildContainer {
  children?: ReactNode
}

export const AppDndContext = ({ children }: IChildContainer) => {
  const dndContext = useContext<IDndSubscriptionContext>(DndContext)

  const handleOnDragEnd = (result: DropResult, provided: ResponderProvided) => {

    if (!result.destination ||
            (result.source.droppableId === result.destination?.droppableId && result.source.index === result.destination.index)) {
      return
    }

    const callback = dndContext.dragEndCallbackMap.get(result.type)
    callback?.(result, provided)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      {children}
    </DragDropContext>
  )
}

export const DndContextLayout = ({ children }: IChildContainer) => {
  return (
    <DndContext.Provider value={DefaultDndSubscriptionContext}>
      <AppDndContext>{children}</AppDndContext>
    </DndContext.Provider>
  )
}

