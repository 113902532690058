import { useState, ReactNode, MouseEvent } from 'react'
import { SpeedDial as MaterialSpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'

import callOrAccess from '@/Helpers/callOrAccess'

import { DialStyled } from './styled'

interface ISpeedDial {
  actions: IAction[]
  direction?: 'up' | 'down' | 'left' | 'right'
  dialIcon?: ReactNode
  clickItem?: any
}

export interface IAction {
  disabled?: boolean | ((item: any) => boolean)
  title: string | ((item: any) => string)
  icon: ReactNode | ((item: any) => ReactNode)
  onClick: (item: any) => void
  visible?: boolean | ((item: any) => boolean)
}

const SpeedDial = ({ actions, direction, dialIcon, clickItem }: ISpeedDial) => {
  const [openDial, setOpenDial] = useState<boolean>(false)
  const getDisabled = (action: IAction) => (callOrAccess(action.disabled, clickItem))
  const handleClick = (action: IAction) => (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (getDisabled(action)) return
    action.onClick(clickItem)
    setOpenDial(false)
  }

  return <DialStyled>
    <MaterialSpeedDial
      ariaLabel="SpeedDial openIcon"
      icon={dialIcon ?? <SpeedDialIcon/>}
      onClose={() => setOpenDial(false)}
      onOpen={() => setOpenDial(true)}
      open={openDial}
      direction={direction}
    >
      {actions.filter(x => callOrAccess(x.visible, clickItem) !== false).map(action => (
        <SpeedDialAction
          className={getDisabled(action) ? 'disabled' : ''}
          key={callOrAccess(action.title, clickItem)}
          icon={callOrAccess(action.icon, clickItem)}
          tooltipTitle={callOrAccess(action.title, clickItem)}
          onClick={handleClick(action)}
          hidden={!openDial}
        />
      ))}
    </MaterialSpeedDial>
  </DialStyled>
}

export default SpeedDial
