import { useState, ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Dialog, Grid, IconButton, Link, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { getRedirectHybridPath } from '@/Helpers/redirectHybridPath'
import bem from '@/Helpers/BemClass'
import htmlSafeParse from '@/Helpers/htmlSafeParseHelper'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import { TextLink } from '@/Components'
import StyledButton from '@/Components/Button'
import { RouteEnum } from '@/Enums/route'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { ISurveyInstance } from '@/Stores/UserStores/userSurveysStore'
import { DisplayMode } from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/PageAnswers'

import { AnswersView } from './AnswersView'

import './style.scss'
import '@/Pages/User/Styles/round-dialog.scss'
import RoleQualifier from '@/Components/RoleQualifier'
import { PermissionType } from '@/Stores/sharedModels/models'
import { SurveyInfoTabsEnum } from '@/Enums/surveyInfoTabsEnum'


interface ISurveyPreview {
  survey: ISurveyInstance
  contentDirection: 'left' | 'right'
}

const cnSurveyPreview = bem()('survey-preview')
const SurveyPreview = ({
  survey,
  contentDirection,
}: ISurveyPreview) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const UIisTablet = useMediaQuery(muiTheme.breakpoints.down('md'))
  // пример использования для более мелких экранов
  // const UIisMobileXs = useMediaQuery(muiTheme.breakpoints.down('xs'))

  const [showStatisticDialog, setShowStatisticDialog] = useState<boolean>(false)
  const routerManager = useRouteManager()

  const startSurveyUrl = getRedirectHybridPath(`${routerManager.baseUrl}/${!survey.withCards ? RouteEnum.questionPage : RouteEnum.startPage}/${survey.surveyId}`)
  const expertPageUrl = `${routerManager.baseUrl}/${RouteEnum.survey}/${survey.surveyId}/${SurveyInfoTabsEnum.answers}`

  const openSurvey = () => routerManager.redirectToUrl(!survey.withCards ? RouteEnum.questionPage : RouteEnum.startPage, survey.surveyId)

  const getSurveyTextLink = (survey: ISurveyInstance) => {
    if (!survey.pages?.length) {
      return <></>
    }

    const isCompleted = survey.isCompleted
    if (isCompleted) {
      if (survey.withCards && survey.withShortAnswersViewPermission) {
        return (
          <TextLink
            className={cnSurveyPreview('link', { active: true })}
            text='Посмотреть результат'
            color='#266DC2'
            href={startSurveyUrl}
          />
        )
      }

      if (!survey.withCards && survey.withShortAnswersViewPermission) {
        return (
          <TextLink
            className={cnSurveyPreview('link', { active: true })}
            text='Посмотреть результат'
            color='#266DC2'
            onClick={() => setShowStatisticDialog(true)}
          />
        )
      }

      return <TextLink
        className={cnSurveyPreview('link', { active: false })}
        text='Опрос пройден'
        color='#BFBEBE'
        disabled
      />
    }
    return UIisMobile ? <StyledButton
      onClick={openSurvey}
      height='40px'
    >
      Перейти к опросу
    </StyledButton> : <TextLink
      className={cnSurveyPreview('link', { active: true })}
      text='Перейти к опросу'
      color='#266DC2'
      href={startSurveyUrl}
    />
  }

  // Обёртка над карточкой Опроса. Отображает MUI Card/Paper на мобилках, и обычный список на других разрешениях
  const SurveyCard = ({ children }: { children: ReactNode }) => {
    const className = cnSurveyPreview('container', { direction: contentDirection, card: UIisMobile })

    /* eslint-disable multiline-ternary */
    return UIisMobile
      ? <Paper elevation={0} className={className}>{children}</Paper>
      : <div className={className}>{children}</div>
    /* eslint-enable multiline-ternary */
  }

  const completedWithoutVisibleAnswers = survey.isCompleted && !(survey.withCards && survey.withShortAnswersViewPermission)

  return <>
    {survey?.hasActiveTestingSession && <Typography className={cnSurveyPreview('test_session')}>Тестовый запуск</Typography>}
    <SurveyCard>
      <Grid container direction='row' justifyContent='center' rowSpacing={2} columnSpacing={{ xs: 0, sm: 2 }} className={cnSurveyPreview('card')}>

        <Grid item xs={12} sm={5} md={4} className={cnSurveyPreview('card-image', { disabled: completedWithoutVisibleAnswers })} >
          <Link color='black' underline='none' component={completedWithoutVisibleAnswers ? 'button' : RouterLink} to={completedWithoutVisibleAnswers ? undefined : startSurveyUrl}>
            <img src={getStaticsHybridUrl(`/api/file/image/${survey.imageId}`)} className={contentDirection} alt='Изображение' loading='lazy' decoding='async'/>
          </Link>
        </Grid>

        <Grid item container direction='column' xs={12} sm={7} md={8} className={cnSurveyPreview('card-info')}>
          <Grid item className={cnSurveyPreview('title', { disabled: completedWithoutVisibleAnswers })}>
            <Typography variant='h5' component='p'>
              <Link color='black' underline='none' component={completedWithoutVisibleAnswers ? 'button' : RouterLink} to={completedWithoutVisibleAnswers ? undefined : startSurveyUrl}>{htmlSafeParse(survey.title)}</Link>
            </Typography>
          </Grid>
          <Grid item className={cnSurveyPreview('period')}>
            <Typography variant='body2' component='p'>
              Сбор мнений с {survey.start.toLocaleDateString('ru-RU')} по {survey.end.toLocaleDateString('ru-RU')}
            </Typography>
          </Grid>
          <Grid item className={cnSurveyPreview('description')}>
            <Typography variant='body1' component='p'>
              {htmlSafeParse(survey.description ?? '')}
            </Typography>
          </Grid>
          <Grid item container direction='row' alignContent='center' rowSpacing={{ xs: 2 }} columnSpacing={{ xs: 1, md: 2, lg: 4 }} className={cnSurveyPreview('links')}>
            {survey.pages?.length > 0 && <Grid item>
              <Typography variant='body1' component='p'>
                {getSurveyTextLink(survey)}
              </Typography>
            </Grid>}
            <RoleQualifier roles={{ type: PermissionType.expert, surveyId: survey.surveyId }}>
              <Grid item className={cnSurveyPreview('link', { active: true })}>
                <Typography variant='body1' component='p'>
                  <TextLink
                    className={cnSurveyPreview('link', { active: true })}
                    text='Перейти к результатам'
                    color='#266DC2'
                    href={expertPageUrl}
                  />
                </Typography>
              </Grid>
            </RoleQualifier>
          </Grid>
        </Grid>

      </Grid>
    </SurveyCard>
    {showStatisticDialog && (
      <Dialog
        fullScreen={UIisTablet}
        onClose={() => setShowStatisticDialog(false)}
        open={showStatisticDialog}
        PaperProps={{
          classes: {
            rounded: `rounded-paper ${UIisTablet ? 'rounded-paper--mobile' : ''}`,
          },
        }}
      >
        <div>
          <div className='actions'>
            <IconButton
              edge='end'
              color="default"
              onClick={() => setShowStatisticDialog(false)}
              aria-label='close'
              className='btn--close'
            >
              <CloseIcon />
            </IconButton>
          </div>
          <AnswersView
            surveyId={survey?.surveyId ?? ''}
            displayMode={DisplayMode.User}
          />
        </div>
      </Dialog>
    )}
  </>
}

export default SurveyPreview
