import { useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { observer } from 'mobx-react'
import Link from '@mui/material/Link'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'

import { IAction } from '@/Models/answer'
import Spreadsheet from '@/Components/Spreadsheet'
import { ISpreadsheetCellsData, ISpreadsheetSummaryData } from '@/Models/spreadsheet'
import { ISpreadsheetStoreInstance, SpreadsheetStore } from '@/Stores/SpreadsheetStore/spreadsheet'
import { apiGetSpreadsheetSummary, apiParseSpreadsheetByquestionId } from '@/Api/question'
import bem from '@/Helpers/BemClass'

import './styles.scss'

const cnSpreadsheetQuestionSummary = bem()('spreadsheet-question-summary')

interface ISpreadsheetSummary {
  questionId: string
  action: IAction
}

const SpreadsheetSummary = ({ questionId, action }: ISpreadsheetSummary) => {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<ISpreadsheetCellsData | null>(null)
  const [summaryAnswers, setSummaryAnswers] = useState<ISpreadsheetSummaryData | null>(null)
  const [spreadsheetStoreInstance] = useState<ISpreadsheetStoreInstance>(SpreadsheetStore.create())
  const muiTheme = useTheme()
  const UIisTabletMd = useMediaQuery(muiTheme.breakpoints.down('xl'))

  useMount(() => {
    (async () => {
      const [data, summaryAnswers] = await Promise.all([apiParseSpreadsheetByquestionId(questionId), apiGetSpreadsheetSummary(questionId)])
      setData(data)
      setSummaryAnswers(summaryAnswers)
    })()
  })

  useEffect(() => {
    if (data && summaryAnswers) {
      spreadsheetStoreInstance.fillFromData(data, [])
      console.log(summaryAnswers.cells)
      spreadsheetStoreInstance.updateValues(summaryAnswers.cells)
    }
  }, [data, summaryAnswers, spreadsheetStoreInstance])

  return <>
    <Link
      component='button'
      variant='caption'
      onClick={() => setIsOpen(true)}
    >
      {action.text}
    </Link>
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      classes={{
        paper: cnSpreadsheetQuestionSummary('paper', { mobile: UIisTabletMd }),
      }}
    >
      <DialogTitle
        classes={{
          root: cnSpreadsheetQuestionSummary('title'),
        }}
      >
        <Typography variant='h5' fontWeight='bold'>Сводная таблица</Typography>
        <IconButton onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        classes={{
          root: cnSpreadsheetQuestionSummary('content'),
        }}
      >
        <div className={cnSpreadsheetQuestionSummary('container-window')}>
          {data && <Spreadsheet
            spreadsheetStoreInstance={spreadsheetStoreInstance}
            data={data}
            disabled={true}
          />}
        </div>
      </DialogContent>
    </Dialog>
  </>
}

export default observer(SpreadsheetSummary)
