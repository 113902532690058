import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Link,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import _ from 'lodash'
import { Bar } from 'react-chartjs-2'
import { Chart, BarElement } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import { apiGetFile, apiGetUnknownFile } from '@/Api/file'
import { IQuestionAnswersInfo } from '@/Models/answer'
import {
  AnswersExtraDataTypes,
  DisplayMode,
} from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/PageAnswers'
import ActionComponent from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/Actions'
import bem from '@/Helpers/BemClass'

import './style.scss'

Chart.register(BarElement, ChartDataLabels)

const cntAnswerTab = bem()('answer-tab')

// TODO: улучшить тайпинги
interface IDiagramQuestionView {
  questionInfo: IQuestionAnswersInfo
  displayMode: DisplayMode
  showDiagram: boolean
}

export const DiagramQuestionView = ({
  questionInfo,
  displayMode,
  showDiagram,
}: IDiagramQuestionView) => {
  const elAnswersExtraData = (
    type: AnswersExtraDataTypes,
  ) => {
    return (
      <Accordion elevation={0} variant='outlined' square>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Button
            color='primary'
            size='large'
            style={{ textTransform: 'none', textDecoration: 'underline' }}
          >
            {type === AnswersExtraDataTypes.CustomVariant ? questionInfo.additionInfo.groupName : 'Комментарии'}
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer style={{ maxHeight: 500 }}>
            <Table
              className={cntAnswerTab('diagram-question-view-bordered')}
              component={Paper}
              size='small'
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {type === AnswersExtraDataTypes.Comments && <TableCell component='th' scope='row'>
                    <b>
                      Текст комментария
                    </b>
                  </TableCell>}
                  {type === AnswersExtraDataTypes.CustomVariant && questionInfo.additionInfo.headers.map((value, i) => <TableCell align={i === 0 ? 'left' : 'center'} key={value} component='th' scope='row'>
                    {i === 0 && <b>
                      {value}
                    </b>}
                    {i !== 0 && value}
                  </TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {type === AnswersExtraDataTypes.Comments && questionInfo.comments.map((comment, i) => <TableRow key={i}>
                  <TableCell align='left'>
                    {comment}
                  </TableCell>
                </TableRow>)}
                {type === AnswersExtraDataTypes.CustomVariant && questionInfo.additionInfo.cells.map((row, i) => (
                  <TableRow key={i}>
                    {row.map((cell, i) => (
                      <TableCell key={cell.value} align={i === 0 ? 'left' : 'center'}>
                        {cell.fileHash === null && cell.fileUrl === null ? cell.value : <Link
                          component="button"
                          variant="caption"
                          onClick={async () => (cell.fileHash ? await apiGetFile(cell.fileHash) : await apiGetUnknownFile(cell.fileUrl ?? ''))}
                        >
                          {cell.value}
                        </Link>}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {questionInfo.actions.map(action => (
                  action.fileUrl ? (<div key={action.text}>
                    <Link
                      component="button"
                      variant="caption"
                      onClick={async () => await apiGetUnknownFile(action.fileUrl ?? '')}
                    >
                      {action.text}
                    </Link>
                  </div>) : action.type != null ? (<div key={action.text}>
                    <ActionComponent action={action} questionId={questionInfo.questionId}/>
                  </div>) : (<div key={action.text}>
                    {action.text}
                  </div>)
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <>
      {showDiagram && <Box style={{ width: '100%', margin: '0 auto', overflowX: 'auto' }}>
        <div
          style={{
            minHeight: '400px',
            width: `max(100%, ${
              questionInfo.options.length * 150
            }px)`,
          }}
        >
          <Bar
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                tooltip: { enabled: false },
                datalabels: {
                  anchor: 'end',
                  align: 'end',
                  clamp: true,
                  formatter: (val: number) => `${Math.round(val)}%`,
                  font: { weight: 'bold' },
                },
                legend: { display: false },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  min: 0,
                  max: 100,
                },
                x: {
                  stacked: true,
                },
              },
              layout: { padding: { top: 25 } },
            }}
            data={{
              labels: questionInfo.options.map(
                (option) => {
                  const minRowWidth = 20
                  const lineExpectedWidth =
                    100 /
                      questionInfo.options
                        .length -
                    2
                  const lineWidth =
                    lineExpectedWidth > minRowWidth ? lineExpectedWidth : minRowWidth

                  const slicedLabel = option.value.trim().split(/\s+/)
                  const res: string[] = []

                  const lineCount = slicedLabel[0].length / lineWidth
                  for (let i = 0; i < lineCount; i++) {
                    res.push(slicedLabel[0].substr(lineWidth * i, lineWidth))
                  }

                  slicedLabel.slice(1).forEach((x) => {
                    const isAppend =
                      res[res.length - 1].length + x.length < lineWidth
                    if (isAppend) {
                      res[res.length - 1] += ` ${x}`
                      return
                    }

                    if (x.length <= lineWidth) {
                      res.push(x)
                      return
                    }

                    const lineCount = x.length / lineWidth

                    for (let i = 0; i < lineCount; i++) {
                      res.push(x.substr(lineWidth * i, lineWidth))
                    }
                  })

                  return res
                }
              ),
              datasets: [
                {
                  data: questionInfo.options.map(x => x.percent),
                  backgroundColor: '#004C97',
                  borderColor: (displayMode === DisplayMode.User ? questionInfo.options.map((x) => (x.isSelected ? '#5EE030' : '#004C97')) : '#004C97'),
                  borderWidth: 2,
                },
              ],
            }}
          />
        </div>
      </Box>}
      {
        // Раздел 'Свой вариант'
        questionInfo.additionInfo.headers.length > 0 && questionInfo.additionInfo.cells.length > 0 && elAnswersExtraData(AnswersExtraDataTypes.CustomVariant)
      }
    </>
  )
}

export default DiagramQuestionView
