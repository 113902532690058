import { ChangeEvent } from 'react'
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined'

import { useMst } from '../../../../../Stores/rootStore'
import { IDialog } from '../../../../../Components/Dialog'

import { FiltersContainer, SyncContainer } from './styled'

export interface IServiceFilter {
  searchString: string
  hasPage?: boolean
}

interface IServicesFilters {
  surveyId: string
  filters: IServiceFilter
  setFilters: (item: IServiceFilter | ((prevState: IServiceFilter) => IServiceFilter)) => void
  setDialogState: (item: (IDialog | undefined)) => void
  setIsLoading: (item: boolean) => void
  disabled: boolean
}

const ServicesFilters = ({ surveyId, filters, setFilters, setDialogState, setIsLoading, disabled }: IServicesFilters) => {
  const store = useMst().admin
  const services = store.adminServicesStore.services.filter(x => x.surveyId === surveyId)

  const handleChangeHasPageFilter = (e: SelectChangeEvent<string | number>) => {
    const value = e.target.value
    setFilters(prev => ({ ...prev, hasPage: value === undefined ? value : Boolean(value) }))
  }

  const handleChangeNameFilter = (e: ChangeEvent<{ value: string }>) => {
    const value = e.target.value
    setFilters(prevState => ({ ...prevState, searchString: value }))
  }

  const handleSyncServices = async () => {
    setIsLoading(true)
    await store.adminServicesStore.syncServices(surveyId)
    await store.adminServicesStore.loadServices(surveyId)
    setIsLoading(false)
    setDialogState({
      open: true,
      handleClose: () => setDialogState(undefined),
      title: 'Синхронизация данных',
      text: 'Данные успешно синхронизированны.',
      actions: [<Button onClick={() => setDialogState(undefined)} key="ok">ОК</Button>],
    })
  }

  return (<FiltersContainer>
    <SyncContainer>
      {!disabled && <IconButton
        edge="start"
        color="inherit"
        onClick={() => setDialogState(
          {
            open: true,
            handleClose: () => setDialogState(undefined),
            title: 'Синхронизация данных',
            text: 'Вы действительно хотите синхронизировать данные?',
            actions: [
              <Button onClick={() => setDialogState(undefined)} key="no">Нет</Button>,
              <Button onClick={handleSyncServices} key="yes">Да</Button>,
            ],
          })}
        // disabled={applicationState?.pageIsRunning}
      >
        <SyncOutlinedIcon/>
      </IconButton>}
      <TextField
        label="Название сервиса"
        placeholder="Введите название сервиса"
        value={filters.searchString}
        onChange={handleChangeNameFilter}
        fullWidth
        variant="standard"
      />
    </SyncContainer>
    <FormControl style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <InputLabel shrink id="main-question-type-label">
                Наличие опроса
      </InputLabel>
      <Select
        labelId="main-question-type-label"
        value={filters.hasPage === undefined ? 'undefined' : +filters.hasPage}
        onChange={handleChangeHasPageFilter}
        variant="standard"
      >
        <MenuItem value={undefined}>Все ({services.length})</MenuItem>
        <MenuItem value={1}>Есть опрос ({services.filter(x => x.hasPage).length})</MenuItem>
        <MenuItem value={0}>Нет опросов ({services.filter(x => !x.hasPage).length})</MenuItem>
      </Select>
    </FormControl>
  </FiltersContainer>)
}

export default ServicesFilters
