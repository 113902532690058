import { useEffect, useState } from "react";

export const useKeyDown = (onKeyDown: () => void) => {
  useEffect(() => {
    const onKeyDownEvent = (event: any) => {
      if (event.isComposing || event.keyCode === 229) {
          return;
      }

      onKeyDown()
    };
    
    window.addEventListener("keydown", onKeyDownEvent);

    return () => {
      window.removeEventListener("keydown", onKeyDownEvent);
    };
  }, []);
};