import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .requireCommentValue {
    margin-top: 25px;

    label {
      width: max-content;
    }

    .MuiInputBase-root {
      width: 270px;
    }
  }
`
export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;

  & > label {
    margin-right: 10px;
  }
`

export const SwitchContainerCustom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;

  & > label {
    margin-bottom: 10px;
  }
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;

  & > button {
    border: solid #266DC2 1px;
    border-radius: 10px;

    & > span {
      text-transform: capitalize;
      color: #266DC2;
      font-weight: bold;
    }

    & > svg {
      color: #266DC2;
    }
  }
`
export const LabelStyled = styled.span`
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  color: #BDBDBD;
  text-align: center;
  &.selected{
    font-weight: bold;
    color: black;
  }

  &.left {
    margin-right: 10px;
  }

  &.right {
    margin-left: 10px;
  }
`
export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .MuiSwitch-root{
    width: 50px;
    height: 25px;
    padding: 0;
    overflow: inherit;
  }

  .MuiSwitch-switchBase{
    padding: 1px;
  }

  .Mui-checked{
    transform: translateX(23px) !important;
    & + .MuiSwitch-track{
      background-color: #fff !important;
      border: 1px solid #266DC2 !important;
    }
  }

  .MuiSwitch-thumb{
    width: 25px;
    height: 25px;
    background-color: #266DC2;
  }

  .MuiSwitch-track{
    border-radius: 42px;
    border: 1px solid #266DC2;
    background-color: #fff;
  }
`
