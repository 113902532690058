import { useState, useMemo, useCallback, useEffect, RefObject } from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Checkbox, List, ListItem,
  Dialog,
  Box,
  Pagination,
  FormControlLabel,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SettingsIcon from '@mui/icons-material/Settings'
import { AgGridReact } from 'ag-grid-react'
import {
  ApplyColumnStateParams,
  ColDef,
  ColGroupDef,
  ColumnApi,
  DragStoppedEvent,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  RowClassParams,
  SortChangedEvent,
} from 'ag-grid-community'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-material.css'

import { HeadCell } from '@/Models/headCell'
import bem from '@/Helpers/BemClass'

import SpeedDial, { IAction } from '../SpeedDial'

import './style.scss'

const alignToClass = new Map<'left' | 'right' | 'center' | undefined, string>([
  ['left', ''],
  ['right', 'justify-content-end'],
  ['center', 'justify-content-center'],
  [undefined, ''],
])

interface TableProps<T> {
  data: T[]
  pinnedData?: T[]
  headCells: Array<HeadCell<T>>
  rowActions?: IAction[]
  state?: ApplyColumnStateParams
  filter?: Record<string, any>
  gridRef?: RefObject<AgGridReact>
  onSortChanged?: (event: SortChangedEvent<T>) => void
  onColumnsHasChanging?: (columns: Array<HeadCell<T>>) => void
  getCustomRowClass?: (index: number, data: T) => string
  onDragStopped?: (event: DragStoppedEvent<T>) => void
  onFilterChanged?: (event: FilterChangedEvent<T>) => void
  hasPagination?: boolean
  paginationPageSize?: number
  headerHeight?: number
}

const cnAgTable = bem()('ag-table')

function AgTable<T>({
  data,
  pinnedData = [],
  headCells,
  rowActions,
  state,
  filter,
  gridRef,
  onSortChanged,
  onColumnsHasChanging,
  getCustomRowClass,
  onDragStopped,
  onFilterChanged,
  hasPagination = true,
  paginationPageSize = 20,
  headerHeight = 40,
}: TableProps<T>) {
  const muiTheme = useTheme()
  const UIisMobileXs = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const UIisTabletMd = useMediaQuery(muiTheme.breakpoints.down('xl'))
  const [pagination, setPagination] = useState(!hasPagination ? undefined : {
    pagesCount: 0,
    page: 0,
  })
  const [columns, setColumns] = useState<Array<HeadCell<T>>>(headCells)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null)
  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const toggleVisibleColumn = (item: HeadCell<T>) => {
    setColumns(prev => {
      const column = prev.find(x => x.label === item.label)

      if (column) {
        column.isVisible = !column.isVisible
      }

      return [...prev]
    })
  }

  const toggleVisibleAllColumn = () => {
    setColumns(prev => {
      const allVisible = prev.every(x => x.isVisible)

      if (allVisible) {
        prev.forEach((x, i) => { x.isVisible = i === 0 || false })
      } else {
        prev.forEach(x => { x.isVisible = true })
      }

      return [...prev]
    })
  }

  useEffect(() => {
    onColumnsHasChanging?.(columns)
  }, [columns])

  const displayedHeadGearButton = () => (
    <Button className='btn--settings-icon' size='small' onClick={ () => { setIsOpen(true) } }>
      <SettingsIcon color="action" fontSize="small"/>
    </Button>
  )

  const columnDefs: Array<ColDef | ColGroupDef> = useMemo(() => [...columns.filter(x => x.isVisible).map((x, i) => ({
    minWidth: x.minWidth,
    maxWidth: x.maxWidth,
    flex: x.flex,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    field: x.key.toString(),
    filter: true,
    floatingFilter: true,
    headerName: x.label,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    cellRenderer: x.createControl ? (props: ICellRendererParams) => x.createControl?.(props.data) : null,
    equals: x.comparator,
    cellClass: `${x.cellClass ?? ''} ${alignToClass.get(x.align) ?? ''}`,
  })), {
    headerComponent: displayedHeadGearButton,
    cellRenderer: (props: ICellRendererParams) => (
      rowActions ? <SpeedDial
        dialIcon={<MoreVertIcon/>}
        actions={rowActions }
        direction="left"
        clickItem={props.data}
      /> : <></>
    ),
    maxWidth: 100,
    lockPosition: 'right',
  }], [columns, rowActions])

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridColumnApi(params.columnApi)
    setGridApi(params.api)

    state && params.columnApi.applyColumnState(state)
    filter && params.api.setFilterModel(filter)
    params.api.onFilterChanged()

  }, [state, filter])

  useEffect(() => {
    state && gridColumnApi && gridColumnApi.applyColumnState(state)
  }, [state, gridColumnApi])

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagination(prev => ({
      page: value - 1,
      pagesCount: prev?.pagesCount ?? value,
    }))
    gridApi?.paginationGoToPage(value - 1)
  }

  const onPaginationChanged = useCallback(() => {
    setPagination({
      page: gridApi?.paginationGetCurrentPage() ?? 1,
      pagesCount: gridApi?.paginationGetTotalPages() ?? 0,
    })
  }, [gridApi, setPagination])

  const getRowClass = (params: RowClassParams<T>): string | undefined => {
    if (params.node.rowIndex === null || params.data === undefined) {
      return
    }

    if (getCustomRowClass) {
      return getCustomRowClass(params.node.rowIndex, params.data)
    } else {
      return `row-${params.node.rowIndex}`
    }
  }

  const allToggleText = columns.every(x => x.isVisible) ? 'Снять всё' : 'Выбрать всё'

  return <Box width='100%' height='100%' className={cnAgTable('container')}>
    <div className="ag-theme-material" >
      <AgGridReact
        headerHeight={headerHeight}
        ref={gridRef}
        rowData={data}
        pinnedTopRowData={(pagination ? pagination.page + 1 : 0) > 1 ? [] : pinnedData}
        columnDefs={columnDefs}
        animateRows={true}
        domLayout='autoHeight'
        onGridReady={onGridReady}
        suppressCellFocus={true}
        suppressDragLeaveHidesColumns={true}
        onSortChanged={onSortChanged}
        pagination={hasPagination}
        paginationPageSize={paginationPageSize}
        onPaginationChanged={onPaginationChanged}
        suppressPaginationPanel={hasPagination}
        overlayNoRowsTemplate='Нет данных для отображения'
        getRowClass={getRowClass}
        rowClass='polls-row'
        onDragStopped={onDragStopped}
        onFilterChanged={onFilterChanged}
      >
      </AgGridReact>
      {hasPagination && (pagination?.pagesCount ?? 0) > 1 && <div className={cnAgTable('pagination')}>
        <Pagination
          page={pagination ? pagination.page + 1 : 0}
          onChange={handlePageChange}
          count={pagination?.pagesCount ?? 0}
          showFirstButton
          showLastButton
          size={UIisMobileXs ? 'small' : (UIisTabletMd ? 'medium' : 'large')}
        />
      </div>}
      {isOpen && <Dialog open={isOpen} onClose={ () => { setIsOpen(false) } } >
        <Box className='modalContainer' textAlign='center'>
          <span>Отображаемые столбцы</span>
          <List>
            <ListItem key={-1} dense>
              <FormControlLabel
                label={allToggleText}
                control={<Checkbox
                  edge="start"
                  checked={columns.every(x => x.isVisible)}
                  tabIndex={-1}
                  disableRipple
                  onClick={() => { toggleVisibleAllColumn() }}
                />}
              />
            </ListItem>
            {columns.map((item, i) => (
              <ListItem key={i} dense>
                <FormControlLabel
                  disabled={columns.filter(x => x.isVisible).length <= 1 && item.isVisible}
                  label={item.label}
                  control={<Checkbox
                    edge="start"
                    checked={item.isVisible}
                    tabIndex={-1}
                    disableRipple
                    onClick={() => { toggleVisibleColumn(item) }}
                  />}
                />
              </ListItem>
            ))}
          </List>
          <Button variant='outlined' onClick={() => { setIsOpen(false) }} key="Ok">Ok</Button>
        </Box>
      </Dialog>}
    </div>
  </Box>
}

export default observer(AgTable)
