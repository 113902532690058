import { useState } from 'react'
import { Box, Skeleton, IconButton, Tooltip, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCopyToClipboard } from 'usehooks-ts'

import { useMst } from '@/Stores/rootStore'
import {
  ISurveyInstance,
  SurveyStatus,
  SurveyType,
} from '@/Stores/AdminStores/surveysStore'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import { ReactComponent as CopyIcon } from '@/Images/copy.svg'
import CompletionStatistic from '@/Pages/Admin/SurveyInfoPage/GeneralInformationTab/Statistics/Completion'
import RoleQualifier from '@/Components/RoleQualifier'
import { PermissionType } from '@/Stores/sharedModels/models'

import TextLink from '../../../../Components/TextLink'
import SurveyModal from '../../SurveyListPage/SurveyModal'
import htmlSafeParse from '../../../../Helpers/htmlSafeParseHelper'

import {
  Container,
  ImageContainer,
  SurveyContainer,
  SurveyInfoColumn,
  SurveyInfoContainer,
  SurveyInfoRow,
} from './styled'
import VisitsStatistic from './Statistics/Visits'

interface IGeneralInformationTab {
  surveyId: string
  readonly: boolean
}

const GeneralInformationTab = ({ surveyId, readonly }: IGeneralInformationTab) => {
  const [isLoading, setIsLoading] = useState(false)
  const store = useMst()
  const surveysStore = store.admin.surveysStore
  const survey = surveysStore.surveys.find(x => x.surveyId === surveyId)
  const [editingSurvey, setEditingSurvey] = useState<ISurveyInstance | undefined>()
  const [value, copy] = useCopyToClipboard()
  const surveyLink = `${window.location.origin}/directLink/${survey?.surveyId}`
  const muiTheme = useTheme()
  const UIisMobileSm = useMediaQuery(muiTheme.breakpoints.down('md'))

  const handleCloseEditSurveyModal = async () => {
    setEditingSurvey(undefined)
    await loadData()
  }

  const loadData = async () => {
    setIsLoading(true)
    await surveysStore.loadSurveyInfo(surveyId)
    setIsLoading(false)
  }

  const copyLink = async () => await copy(surveyLink)

  const getStatusColor = (survey?: ISurveyInstance) => {
    if (survey?.hasActiveTestingSession) {
      return 'green'
    }
    switch (survey?.status) {
      case SurveyStatus.Surveyed:
        return 'green'
      case SurveyStatus.Created:
      case SurveyStatus.Editing:
        return '#828282'
      default:
        return 'red'
    }
  }

  return (<Container>
    <SurveyContainer>
      <div>
        <SurveyInfoRow>
          <Typography component='span' variant='body2'>Название:</Typography><span>{isLoading ? <Skeleton variant='rectangular' width={760} height={38} /> : htmlSafeParse(survey?.title ?? '')}</span>
        </SurveyInfoRow>
        <SurveyInfoContainer>
          <SurveyInfoColumn>
            <SurveyInfoRow>
              <Typography component='span' variant='body2'>{survey === undefined || [SurveyStatus.Editing, SurveyStatus.Created].includes(survey.status) ? 'Плановая дата начала:' : 'Начало:'}</Typography><span>{`${survey?.start.toLocaleDateString()} ${survey?.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}` ?? new Date()}</span>
            </SurveyInfoRow>
            <SurveyInfoRow>
              <Typography component='span' variant='body2'>Окончание:</Typography><span>{isLoading ? <Skeleton variant='rectangular' width={173} height={19} /> : `${survey?.end.toLocaleDateString()} ${survey?.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}` ?? new Date()}</span>
            </SurveyInfoRow>
            <SurveyInfoRow>
              <Typography component='span' variant='body2'>Автор:</Typography><span>{isLoading ? <Skeleton variant='rectangular' width={173} height={19} /> : survey?.author}</span>
            </SurveyInfoRow>
            <SurveyInfoRow>
              <Typography component='span' variant='body2'>Статус:</Typography><span style={{ color: getStatusColor(survey) }}>{isLoading ? <Skeleton variant='rectangular' width={173} height={19} /> : survey?.textStatus}</span>
            </SurveyInfoRow>
            <SurveyInfoRow>
              <Typography component='span' variant='body2'>Тип:</Typography><span>{isLoading ? <Skeleton variant='rectangular' width={173} height={19} /> : survey?.textType}</span>
            </SurveyInfoRow>
            {survey?.type === SurveyType.Repeatable && <SurveyInfoRow>
              <Typography component='span' variant='body2'>Номер запуска:</Typography><span>{isLoading ? <Skeleton variant='rectangular' width={173} height={19} /> : Number(survey?.version) + 1}</span>
            </SurveyInfoRow>}
          </SurveyInfoColumn>
        </SurveyInfoContainer>
        <SurveyInfoRow>
          <Typography component='span' variant='body2' alignSelf='center'>Ссылка:</Typography>
          {isLoading ? <Skeleton variant='rectangular' width={600} height={19} /> : <Grid container alignItems='center' flexWrap='nowrap'><a href={survey?.surveyId ? surveyLink : 'не_найдено'}>{survey?.surveyId ? surveyLink : 'не_найдено'}</a>
            <Tooltip title='Копировать ссылку'>
              <IconButton onClick={copyLink}>
                <CopyIcon width='25px' height='25px'/>
              </IconButton>
            </Tooltip>
          </Grid>}
        </SurveyInfoRow>
        <SurveyInfoRow>
          <Typography component='span' variant='body2'>Описание:</Typography><span>{isLoading ? <Skeleton variant='rectangular' width={760} height={38} /> : htmlSafeParse(survey?.description ?? '')}</span>
        </SurveyInfoRow>
      </div>
      <ImageContainer>
        <img style={{ borderRadius: '8px' }} src={getStaticsHybridUrl(`/api/file/image/${survey?.imageId ?? ''}`)} width={256} height={149} alt="Изображение" loading="lazy" decoding="async"/>
        {!readonly && <RoleQualifier roles={[{ type: PermissionType.admin }, { type: PermissionType.editor, surveyId: surveyId }]}>
          <TextLink disabled={isLoading} text={'Редактировать'} color="#266DC2" onClick={isLoading ? () => {} : () => setEditingSurvey(survey)}/>
        </RoleQualifier>}
      </ImageContainer>
    </SurveyContainer>
    <Box display='grid' gridTemplateColumns={UIisMobileSm ? '100%' : ' 40% 60%'}>
      <CompletionStatistic surveyId={surveyId}/>
      <VisitsStatistic surveyId={surveyId}/>
    </Box>
    {editingSurvey && <SurveyModal
      survey={editingSurvey}
      isOpen={true}
      handleClose={handleCloseEditSurveyModal}
    />}
  </Container>)
}

export default GeneralInformationTab
