import styled from 'styled-components'

import { SpanCheckMarkStyled as spanCheckMarkStyled } from '@/Pages/Admin/SurveyInfoPage/ServicesTab/ServiceListItem/styled'

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > li{
    padding-left: 0;
    height: 48px;
  }
`

export const SpanCheckMarkStyled = styled(spanCheckMarkStyled)`
  border: solid 3px #ccc;
`
