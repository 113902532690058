import { cast, flow, getParentOfType, getRoot, getSnapshot, Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree'
import { take, orderBy, uniqBy } from 'lodash'
import { v4 } from 'uuid'

import { CommentType, FileType, IPage, IQuestion, QuestionStyle, QuestionType } from '@/Models/page'
import { apiGetActivePagesBySurveyId, apiGetPageByServiceId } from '@/Api/page'
import { IFileInstance, IFileSnapshotIn, File as AdminFile, LogicOption } from '@/Stores/sharedModels/models'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import { htmlTagsIsEmpty } from '@/Helpers/htmlToTextCustom'
import { IRootStore } from '@/Stores/rootStore'
import { customImageHash, defaultImageHash } from '@/applicationConfig'
import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'
import { helperText } from '@/Components/Questions/RatingQuestion'
import { notReadyToRateText } from '@/Helpers/globalConst'
import { IServiceInstance } from '@/Stores/AdminStores/adminServicesStore'
import { apiGetRules } from '@/Api/rule'
import { Condition, Overlap } from '@/Models/rule'

export interface IPageInstance extends Instance<typeof Page> {
}

export interface IPageSnapshotIn extends SnapshotIn<typeof Page> {
}

export interface IQuestionInstance extends Instance<typeof Question> {
}

export interface IQuestionSnapshotIn extends SnapshotIn<typeof Question> {
}

export interface IOptionInstance extends Instance<typeof Option> {
}

export interface IOptionSnapshotIn extends SnapshotIn<typeof Option> {
}

export interface IOptionSnapshotOut extends SnapshotOut<typeof Option> {
}

export interface IRuleBranchingIn extends SnapshotIn<typeof RuleBranching> {
}

export interface IPageFilterBranchingIn extends SnapshotIn<typeof PageFilterBranching> {
}

export interface IQuestionFilterBranchingIn extends SnapshotIn<typeof QuestionFilterBranching> {
}

export const RuleBranching = t.model({
  id: t.string,
  pageId: t.string,
  questionId: t.string,
  nextPageId: t.maybeNull(t.string),
  condition: t.maybe(t.enumeration<Condition>(Object.values(Condition))),
  overlap: t.maybe(t.enumeration<Overlap>(Object.values(Overlap))),
  isQuestionSkip: t.boolean,
  answers: t.optional(t.array(LogicOption), []),
})

export const PageFilterBranching = t.model({
  id: t.string,
  pageId: t.string,
  targetPageId: t.maybeNull(t.string),
  targetQuestionId: t.maybeNull(t.string),
  condition: t.maybe(t.enumeration<Condition>(Object.values(Condition))),
  overlap: t.maybe(t.enumeration<Overlap>(Object.values(Overlap))),
  isQuestionSkip: t.boolean,
  answers: t.optional(t.array(LogicOption), []),
})

export const QuestionFilterBranching = t.model({
  id: t.string,
  pageId: t.string,
  questionId: t.string,
  targetQuestionId: t.maybeNull(t.string),
  condition: t.maybe(t.enumeration<Condition>(Object.values(Condition))),
  overlap: t.maybe(t.enumeration<Overlap>(Object.values(Overlap))),
  isQuestionSkip: t.boolean,
  answers: t.optional(t.array(LogicOption), []),
})

export const Payload = t.model({
  helperStartText: t.string,
  helperEndText: t.string,
  showNotReadyToRate: t.boolean,
  notReadyToRateText: t.string,
  requireCommentValue: t.string,
  isEnabledLoadFileButton: t.boolean,
  maxFileCount: t.number,
  limitFileType: t.boolean,
  acceptFileTypes: t.array(t.enumeration<FileType>(Object.values(FileType))),
  adminFiles: t.array(AdminFile),
  questionStyle: t.enumeration<QuestionStyle>(Object.values(QuestionStyle)),
  size: t.enumeration<SizeEnum>(Object.values(SizeEnum)),
  align: t.enumeration<AlignEnum>(Object.values(AlignEnum)),
}).actions(self => ({
  toggleAcceptFileType: (fileTypes: FileType) => {
    if (self.acceptFileTypes.includes(fileTypes)) {
      self.acceptFileTypes.remove(fileTypes)
    } else {
      self.acceptFileTypes.push(fileTypes)
    }

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  toggleLimitFileType: () => {
    self.limitFileType = !self.limitFileType

    self.acceptFileTypes.clear()

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  toggleColorStyle: () => {
    self.questionStyle = self.questionStyle === QuestionStyle.Colorized ? QuestionStyle.Default : QuestionStyle.Colorized

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  toggleIsEnabledLoadFileButton: () => {
    self.isEnabledLoadFileButton = !self.isEnabledLoadFileButton

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  setHelperStartText: (helperStartText: string) => {
    self.helperStartText = helperStartText

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  setHelperEndText: (helperEndText: string) => {
    self.helperEndText = helperEndText

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  setMaxFileCount: (maxFileCount: number) => {
    self.maxFileCount = maxFileCount

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  setRequireCommentValue: (requireCommentValue: string) => {
    self.requireCommentValue = requireCommentValue

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  toggleShowNotReadyToRate: () => {
    self.showNotReadyToRate = !self.showNotReadyToRate

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  changeSize: (size: SizeEnum) => {
    self.size = size

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  changeAlign: (align: AlignEnum) => {
    self.align = align

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  deleteAdminFile: (file: IFileInstance) => {
    self.adminFiles.remove(file)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  pushAdminFile: (file: IFileSnapshotIn) => {
    self.adminFiles.push(file)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  replaceAdminFile: (file: IFileSnapshotIn) => {
    self.adminFiles.clear()
    self.adminFiles.push(file)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  clearAdminFiles: () => {
    self.adminFiles.clear()

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  setNotReadyToRateText: (notReadyToRateText: string) => {
    self.notReadyToRateText = notReadyToRateText

    const question = getParentOfType(self, Question)

    question.updateError(QuestionValidationResult.EmptySkipText, false)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
}))

export enum OptionValidationResult {
  ImageRequiredError = 'imageRequiredError',
  DuplicateError = 'duplicateError',
}

export const Option = t.model({
  value: t.string,
  imageId: t.maybeNull(t.string),
  // отрефакторить сделать 1 общий опшенс (@Vidrochka)
  title: t.maybeNull(t.string),
  isCustom: t.boolean,
  order: t.number,
  dndId: t.string,
  errors: t.optional(t.map(t.boolean), {}),
}).views(self => ({
  get hasSelfValidationErrors(): boolean {
    return Array.from(self.errors.values() ?? []).some(x => x)
  },
  get imageRequiredError(): boolean {
    return self.errors.get(OptionValidationResult.ImageRequiredError) ?? false
  },
  get duplicateError(): boolean {
    return self.errors.get(OptionValidationResult.DuplicateError) ?? false
  },
})).actions(self => ({
  validate: () => {
    const question = getParentOfType(self, Question)

    self.errors.set(OptionValidationResult.ImageRequiredError, (CheckEnumIs(question.type, QuestionType.checkboxWithImage, QuestionType.radioWithImage) && ((!self.imageId) || (self.value ?? '').length === 0)))
    self.errors.set(OptionValidationResult.DuplicateError, question.options.filter(x => x.value === self.value).length > 1)
  },
  removeError: (error: OptionValidationResult) => {
    self.errors.set(error, false)
  },
  updateError: (error: OptionValidationResult, isError: boolean) => {
    self.errors.set(error, isError)
  },
  setValue: (value: string) => {
    self.value = value

    self.errors.set(OptionValidationResult.DuplicateError, false)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  setImageId: (imageId: string) => {
    self.imageId = imageId

    self.errors.set(OptionValidationResult.ImageRequiredError, false)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
}))

export enum QuestionValidationResult {
  EmptyQuestionText = 'emptyQuestionText',
  EmptyHelperText = 'emptyHelperText',
  EmptySkipText = 'emptySkipText',
}

export const Question = t.model({
  id: t.maybe(t.string),
  text: t.string,
  helperText: t.string,
  type: t.enumeration<QuestionType>(Object.values(QuestionType)),
  commentType: t.enumeration<CommentType>(Object.values(CommentType)),
  payload: Payload,
  showHelperText: t.boolean,
  hasCustomAnswer: t.boolean,
  options: t.array(Option),
  version: t.number,
  isMain: t.boolean,
  isRequired: t.boolean,
  order: t.number,
  mainQuestionId: t.maybeNull(t.string),
  dndId: t.string,
  errors: t.optional(t.map(t.boolean), {}),
  questionFilters: t.optional(t.array(QuestionFilterBranching), []),
}).views(self => ({
  get optionValues() {
    return self.options.map(x => x.value)
  },
  get orderedOptions(): IOptionInstance[] {
    return Array.from(self.options.values()).sort((a, b) => a.order - b.order)
  },
  get hasValidationErrors(): boolean {
    return self.options.some(x => x.hasSelfValidationErrors) || Array.from(self.errors.values() ?? []).some(x => x)
  },
  get hasSelfValidationErrors(): boolean {
    return Array.from(self.errors.values() ?? []).some(x => x)
  },
  get emptyQuestionTextError(): boolean {
    return self.errors.get(QuestionValidationResult.EmptyQuestionText) ?? false
  },
  get emptyHelperTextError(): boolean {
    return self.errors.get(QuestionValidationResult.EmptyHelperText) ?? false
  },
  get emptySkipTextError(): boolean {
    return self.errors.get(QuestionValidationResult.EmptySkipText) ?? false
  },
  get questionFilterQuestionDependency(): Array<{ currentQuestionId: string, currentQuestionText: string, dependencyQuestionId: string }> {
    return uniqBy(self.questionFilters.filter(x => !!x.targetQuestionId && !!self.id), x => x.targetQuestionId).map(x => ({
      currentQuestionId: self.id!,
      currentQuestionText: self.text,
      dependencyQuestionId: x.targetQuestionId!,
    }))
  },
})).actions(self => ({
  validate: () => {
    self.errors.set(QuestionValidationResult.EmptyQuestionText, htmlTagsIsEmpty(self.text))
    self.errors.set(QuestionValidationResult.EmptyHelperText, self.showHelperText && (self.helperText?.length ?? 0) === 0)
    self.errors.set(QuestionValidationResult.EmptySkipText, self.payload?.showNotReadyToRate && !self.payload?.notReadyToRateText.trim())

    self.options.forEach(x => x.validate())
  },
  removeError: (error: QuestionValidationResult) => {
    self.errors.set(error, false)
  },
  updateError: (error: QuestionValidationResult, isError: boolean) => {
    self.errors.set(error, isError)
  },
  setText: (text: string) => {
    self.text = text
    self.errors.set(QuestionValidationResult.EmptyQuestionText, false)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  toggleShowHelperText: () => {
    self.showHelperText = !self.showHelperText

    self.errors.set(QuestionValidationResult.EmptyHelperText, false)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  setHelperText: (helperText: string) => {
    self.helperText = helperText

    self.errors.set(QuestionValidationResult.EmptyHelperText, false)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  setCommentType: (commentType: CommentType) => {
    self.commentType = commentType

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  toggleHasCustomAnswer: () => {
    if ([QuestionType.checkboxWithImage, QuestionType.radioWithImage].includes(self.type)) {
      if (self.hasCustomAnswer) {
        const customAnswer = self.options.find(x => !x.isCustom)
        customAnswer && self.options.remove(customAnswer)
      } else {
        self.options.push({ value: 'Свой вариант', imageId: customImageHash, title: null, isCustom: true, order: self.options.length, dndId: v4() })
      }
    } else {
      self.hasCustomAnswer = !self.hasCustomAnswer
    }

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  toggleIsRequier: () => {
    self.isRequired = !self.isRequired

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  appendOptions: (option: IOptionSnapshotIn[]) => {
    option.forEach(x => self.options.push(x))

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  replaceOptions: (option: IOptionSnapshotIn[]) => {
    self.options.clear()
    option.forEach(x => self.options.push(x))

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  trimOption: (count: number) => {
    Array.from(Array(count), () => self.options.pop())

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  changeOptionTitle: (order: number, title: string) => {
    self.options[order].title = title

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  clearOptions: () => {
    self.options.clear()

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  deleteOption: (option: IOptionInstance) => {
    const order = option.order

    self.options.remove(option)

    self.options.forEach(x => {
      if (x.order > order) {
        x.order = x.order - 1
      }
    })

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  copy: () => {
    const page = getParentOfType(self, Page)

    const question = getSnapshot(self)

    page.appendQuestion({
      ...question,
      id: undefined,
      version: 0,
      dndId: v4(),
      options: question.options.map(x => ({ ...x, dndId: v4() })),
      order: page.questions.length,
    })

    page.updateError(PageValidationError.EmptyQuestionCollectionError, false)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  moveOption: (oldIndex: number, newIndex: number) => {
    const movedOption = self.options.find(x => x.order === oldIndex)

    if (movedOption === undefined || oldIndex === newIndex) {
      return
    }

    if (oldIndex > newIndex) {
      self.options.forEach(x => {
        if (x.order < oldIndex && x.order >= newIndex) {
          x.order = x.order + 1
        }
      })
    } else {
      self.options.forEach(x => {
        if (x.order > oldIndex && x.order <= newIndex) {
          x.order = x.order - 1
        }
      })
    }

    movedOption.order = newIndex

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  changeType: (type: QuestionType) => {
    if (self.type === type) {
      return
    }

    self.type = type
    self.errors.clear()

    if (CheckEnumIs(type,
      QuestionType.text,
      QuestionType.rating,
      QuestionType.checkbox,
      QuestionType.radio,
      QuestionType.checkboxWithImage,
      QuestionType.radioWithImage)
    ) {
      self.payload.helperEndText = ''
      self.payload.helperStartText = ''
      self.payload.notReadyToRateText = ''
      self.payload.showNotReadyToRate = false
      self.payload.requireCommentValue = ''
      self.payload.isEnabledLoadFileButton = false
      self.payload.maxFileCount = 1
      self.payload.limitFileType = false
      self.payload.acceptFileTypes.clear()
      self.payload.adminFiles.clear()
      self.payload.questionStyle = QuestionStyle.Default
      self.payload.size = SizeEnum.medium
      self.payload.align = AlignEnum.left

      self.options.clear()
      self.hasCustomAnswer = false
    }

    if (type === QuestionType.rating) {
      self.helperText = helperText
      self.options.clear()
      Array.from(Array(5), (x, i) => self.options.push({ value: i.toString(), imageId: null, title: '', isCustom: false, order: i, dndId: v4() }))

      self.payload.helperEndText = ''
      self.payload.helperStartText = ''
      self.payload.notReadyToRateText = ''
      self.payload.showNotReadyToRate = false
      self.payload.isEnabledLoadFileButton = false
      self.payload.maxFileCount = 1
      self.payload.limitFileType = false
      self.payload.acceptFileTypes.clear()
      self.payload.adminFiles.clear()
      self.payload.questionStyle = QuestionStyle.Default
      self.payload.size = SizeEnum.medium
      self.payload.align = AlignEnum.left
      self.payload.requireCommentValue = '2'
    }

    if (type === QuestionType.slider) {
      self.options.clear()
      self.options.push({ value: '0', imageId: null, title: null, isCustom: false, order: 0, dndId: v4() })
      self.options.push({ value: '10', imageId: null, title: null, isCustom: false, order: 1, dndId: v4() })

      self.payload.helperEndText = 'Точно порекомендую'
      self.payload.helperStartText = 'Точно не порекомендую'
      self.payload.showNotReadyToRate = false
      self.payload.notReadyToRateText = notReadyToRateText
      self.payload.requireCommentValue = '5'
      self.payload.isEnabledLoadFileButton = false
      self.payload.maxFileCount = 0
      self.payload.limitFileType = false
      self.payload.acceptFileTypes.clear()
      self.payload.adminFiles.clear()
      self.payload.questionStyle = QuestionStyle.Default
      self.payload.size = SizeEnum.medium
      self.payload.align = AlignEnum.left
    }

    if (type === QuestionType.attachment) {
      self.payload.maxFileCount = 1
    }
  },
}))

export enum PageValidationError {
  TitleError = 'titleError',
  EmptyQuestionCollectionError = 'emptyQuestionCollectionError',
}

export const Page = t.model({
  id: t.maybe(t.string),
  serviceId: t.maybeNull(t.string),
  category: t.maybeNull(t.string),
  title: t.string,
  description: t.string,
  imageId: t.string,
  isRequired: t.boolean,
  version: t.number,
  questions: t.array(Question),
  order: t.number,
  rules: t.optional(t.array(RuleBranching), []),
  pageFilters: t.optional(t.array(PageFilterBranching), []),
  expanded: t.optional(t.boolean, false),
  dndId: t.string,
  errors: t.optional(t.map(t.boolean), {}),
}).views(self => ({
  get orderedQuestions(): IQuestionInstance[] {
    return Array.from(self.questions.values()).sort((a, b) => a.order - b.order)
  },
  get questionsInterface(): IQuestion[] {
    return getSnapshot(self.questions)
  },
  get hasValidationErrors(): boolean {
    return self.questions.some(x => x.hasValidationErrors) || Array.from(self.errors.values() ?? []).some(x => x)
  },
  get hasSelfValidationErrors(): boolean {
    return Array.from(self.errors.values() ?? []).some(x => x)
  },
  get titleError(): boolean {
    return self.errors.get(PageValidationError.TitleError) ?? false
  },
  get emptyQuestionCollectionError(): boolean {
    return self.errors.get(PageValidationError.EmptyQuestionCollectionError) ?? false
  },
  get pageFilterPageDependency(): Array<{ currentPageId: string, currentPageName: string, dependencyPageId: string }> {
    return uniqBy(self.pageFilters.filter(x => !!x.targetPageId && !!x.id), x => x.targetPageId).map(x => ({
      currentPageId: self.id!,
      currentPageName: self.title,
      dependencyPageId: x.targetPageId!,
    }))
  },
  get pageFilterQuestionDependency(): Array<{ currentPageName: string, dependencyQuestionId: string }> {
    return uniqBy(self.pageFilters.filter(x => !!x.targetQuestionId), x => x.targetQuestionId).map(x => ({
      currentPageName: self.title,
      dependencyQuestionId: x.targetQuestionId!,
    }))
  },
  get pageRuleDependency(): Array<{ currentPageName: string, dependencyPageId: string }> {
    return uniqBy(self.rules.filter(x => !!x.nextPageId), x => x.nextPageId).map(x => ({
      currentPageName: self.title,
      dependencyPageId: x.nextPageId!,
    }))
  },
  get questionFiltersDependency(): Array<{ currentPageName: string, currentQuestionId: string, currentQuestionText: string, dependencyQuestionId: string }> {
    return self.questions.map(x => x.questionFilterQuestionDependency.map(x => ({ currentPageName: self.title, ...x }))).flat()
  },
})).actions(self => ({
  validate: () => {
    self.errors.set(PageValidationError.TitleError, self.title.length === 0)
    self.errors.set(PageValidationError.EmptyQuestionCollectionError, self.questions.length === 0)

    self.questions.forEach(x => x.validate())
  },
  removeError: (error: PageValidationError) => {
    self.errors.set(error, false)
  },
  updateError: (error: PageValidationError, isError: boolean) => {
    self.errors.set(error, isError)
  },
  setTitle: (title: string) => {
    self.title = title

    self.errors.set(PageValidationError.TitleError, false)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  setDescription: (description: string) => {
    self.description = description

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  setImageId: (imageId: string) => {
    self.imageId = imageId

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  toggleIsRequired: () => {
    self.isRequired = !self.isRequired

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  toggleExpanded: () => {
    self.expanded = !self.expanded
  },
  setExpanded: (isExpanded: boolean) => {
    self.expanded = isExpanded
  },
  deleteQuestion: (question: IQuestionInstance) => {
    const order = question.order

    self.questions.remove(question)

    self.questions.forEach(x => {
      if (x.order > order) {
        x.order = x.order - 1
      }
    })

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  addQuestion: () => {
    self.questions.push({
      id: undefined,
      text: '',
      helperText: helperText,
      type: QuestionType.rating,
      commentType: CommentType.NoComment,
      payload: {
        helperStartText: '',
        helperEndText: '',
        showNotReadyToRate: false,
        notReadyToRateText: '',
        requireCommentValue: '',
        isEnabledLoadFileButton: false,
        maxFileCount: 0,
        limitFileType: false,
        acceptFileTypes: [],
        adminFiles: [],
        questionStyle: QuestionStyle.Default,
        size: SizeEnum.medium,
        align: AlignEnum.left,
      },
      showHelperText: false,
      hasCustomAnswer: false,
      options: Array.from(Array(5), (x, i) => ({ value: i.toString(), imageId: null, title: '', isCustom: false, order: i, dndId: v4() })),
      version: 0,
      isMain: false,
      isRequired: false,
      order: self.questions.length,
      mainQuestionId: null,
      dndId: v4(),
    })

    self.errors.set(PageValidationError.EmptyQuestionCollectionError, false)

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  appendQuestion: (question: IQuestionSnapshotIn) => {
    self.questions.push(question)
  },
  copy: () => {
    const pageStore = getParentOfType(self, PageStore)

    const page = getSnapshot(self)

    pageStore.appendPage({
      ...page,
      questions: page.questions.map(question => ({
        ...question,
        id: undefined,
        dndId: v4(),
        options: question.options.map(option => ({ ...option, dndId: v4() })),
        version: 0,
      })),
      id: undefined,
      order: pageStore.pages.length + 1,
      version: 0,
      expanded: true,
      dndId: v4(),
    })

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  moveQuestion: (oldIndex: number, newIndex: number) => {
    const mainQuestionsOffset = self.questions.filter(x => x.isMain).length

    oldIndex += mainQuestionsOffset
    newIndex += mainQuestionsOffset

    const movedQuestion = self.questions.find(x => x.order === oldIndex)

    if (movedQuestion === undefined || oldIndex === newIndex) {
      return
    }

    if (oldIndex > newIndex) {
      self.questions.forEach(x => {
        if (x.order < oldIndex && x.order >= newIndex) {
          x.order = x.order + 1
        }
      })
    } else {
      self.questions.forEach(x => {
        if (x.order > oldIndex && x.order <= newIndex) {
          x.order = x.order - 1
        }
      })
    }

    movedQuestion.order = newIndex

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  fillFromService: (service: IServiceInstance) => {
    self.title = service.name
    self.description = service.description ?? ''
    self.category = service.category ?? ''
  },
  addMainQuestionIfNotExist: (mainQuestions: IQuestion[]) => {
    if (self.questions.some(x => x.isMain)) {
      return
    }

    self.questions.forEach(x => {
      if (!x.isMain) {
        x.order++
      }
    })

    mainQuestions.forEach(x => {
      self.questions.push(cast({
        ...x,
        options: x.options.map(x => ({
          ...x,
          dndId: v4(),
        })),
        dndId: v4(),
      }))
    })
  },
  getQuestionAt: (order: number): IQuestionInstance | null => {
    return self.questions.find(x => x.order === order) ?? null
  },
  getQuestion: (questionId: string): IQuestionInstance | null => {
    return self.questions.find(x => x.id === questionId) ?? null
  },
}))

export const PageStore = t.model({
  pages: t.array(Page),
}).views(self => ({
  getPage: (pageId?: string) => {
    return self.pages.find(x => x.id === pageId)
  },

  getQuestion: (pageId?: string, questionId?: string) => {
    const page = self.pages.find(x => x.id === pageId)
    return page?.questions.find(x => x.id === questionId)
  },

  getPagesBeforeTargetPage: (pageId: string) => {
    const targetPageIndex = self.pages.findIndex(x => x.id === pageId)
    if (targetPageIndex === -1) throw new Error(`Ошибка консистентности данные страница с id ${pageId} не найдена`)
    return take(self.pages, targetPageIndex)
  },
  get hasValidationErrors(): boolean {
    return self.pages.some(x => x.hasValidationErrors)
  },
  get orderedPages(): IPageInstance[] {
    return Array.from(self.pages.values()).sort((a, b) => a.order - b.order)
  },
  get pageFiltersPagesDependency(): Array<{ currentPageId: string, currentPageName: string, dependencyPageId: string }> {
    return self.pages.map(x => x.pageFilterPageDependency).flat()
  },
  get pageFiltersQuestionsDependency(): Array<{ currentPageName: string, dependencyQuestionId: string }> {
    return self.pages.map(x => x.pageFilterQuestionDependency).flat()
  },
  get pageRulesPagesDependency(): Array<{ currentPageName: string, dependencyPageId: string }> {
    return self.pages.map(x => x.pageRuleDependency).flat()
  },
  get questionFiltersQuestionsDependency(): Array<{ currentPageName: string, currentQuestionId: string, currentQuestionText: string, dependencyQuestionId: string }> {
    return self.pages.map(x => x.questionFiltersDependency).flat()
  },
})).actions(self => ({
  load: flow(function * (surveyId: string) {
    self.pages.clear()
    const pages: IPage[] = (yield apiGetActivePagesBySurveyId(surveyId)).sort((a: IPage, b: IPage) => a.order - b.order)
    const branching = yield apiGetRules(surveyId)

    const rules = new Map<string, IRuleBranchingIn[]>()
    const pageFilters = new Map<string, IPageFilterBranchingIn[]>()
    const questionFilters = new Map<string, IQuestionFilterBranchingIn[]>()

    type branchingRefs = Array<{
      rules: Array<{
        id: string
        pageId: string
        questionId: string
        nextPageId: string | null
        condition: Condition
        overlap: Overlap
        isQuestionSkip: boolean
        answers: Array<{
          value: string
          isCustom: boolean
        }>
      }>
      pageFilters: Array<{
        id: string
        pageId: string
        targetPageId: string | null
        targetQuestionId: string | null
        condition: Condition
        overlap: Overlap
        isQuestionSkip: boolean
        answers: Array<{
          value: string
          isCustom: boolean
        }>
      }>
      questionFilters: Array<{
        id: string
        pageId: string
        questionId: string
        targetQuestionId: string | null
        condition: Condition
        overlap: Overlap
        isQuestionSkip: boolean
        answers: Array<{
          value: string
          isCustom: boolean
        }>
      }>
    }>;

    (branching.pagesLogic as branchingRefs).forEach(pagesLogic => {
      pagesLogic.rules.forEach(x => {
        const pageRules = rules.get(x.pageId)

        if (pageRules) {
          pageRules.push({ ...x })
        } else {
          rules.set(x.pageId, [{ ...x }])
        }
      })
      pagesLogic.pageFilters.forEach(x => {
        const pagePageFilters = pageFilters.get(x.pageId)

        if (pagePageFilters) {
          pagePageFilters.push({ ...x })
        } else {
          pageFilters.set(x.pageId, [{ ...x }])
        }
      })
      pagesLogic.questionFilters.forEach(x => {
        const questionQuestionFilters = questionFilters.get(x.questionId)

        if (questionQuestionFilters) {
          questionQuestionFilters.push({ ...x })
        } else {
          questionFilters.set(x.questionId, [{ ...x }])
        }
      })
    })

    self.pages = cast(pages.map(x => {
      const pageRules = rules.get(x.id ?? '') ?? []
      const pagePageFilters = pageFilters.get(x.id ?? '') ?? []

      return {
        ...x,
        rules: pageRules,
        pageFilters: pagePageFilters,
        questions: x.questions.map(x => {
          const questionQuestionFilters = questionFilters.get(x.id ?? '') ?? []

          return {
            ...x,
            questionFilters: questionQuestionFilters,
            options: x.options.map(x => ({
              ...x,
              dndId: v4(),
            })),
            dndId: v4(),
          }
        }),
        dndId: v4(),
      }
    }))
  }),
  loadWithSortByService: flow(function * (surveyId: string, serviceIds: string[]) {
    self.pages.clear()
    let pages: IPage[] = yield apiGetActivePagesBySurveyId(surveyId)
    pages = serviceIds.map(x => pages.find((p: { serviceId: string | null }) => p.serviceId === x))
      .filter(x => x !== undefined)
      .map(x => {
        x!.questions = orderBy(x!.questions, ['isMain'], ['desc'])
        return x!
      })

    self.pages = cast(pages.map(x => ({
      ...x,
      questions: x.questions.map(x => ({
        ...x,
        options: x.options.map(x => ({
          ...x,
          dndId: v4(),
        })),
        dndId: v4(),
      })),
      dndId: v4(),
    })))
  }),
  loadOrCreate: flow(function * (surveyId: string, serviceId: string) {
    self.pages.clear()
    try {
      const page: IPage = yield apiGetPageByServiceId(surveyId, serviceId)

      self.pages = cast([{
        ...page,
        questions: page.questions.map(x => ({
          ...x,
          options: x.options.map(x => ({
            ...x,
            dndId: v4(),
          })),
          dndId: v4(),
        })),
        dndId: v4(),
      }])
    } catch (err: any) {
      if (err?.status && err.status === 404) {
        self.pages.push({
          id: undefined,
          serviceId: serviceId,
          category: 'Прочее',
          title: '',
          description: '',
          imageId: defaultImageHash,
          isRequired: false,
          questions: [],
          order: self.pages.length + 1,
          version: 0,
          expanded: true,
          dndId: v4(),
        })

        const store = getRoot<IRootStore>(self)
        store.admin?.tabStore.setWasChange(true)
      } else {
        throw err
      }
    }
  }),
  getPageAt: (order: number): IPageInstance | null => {
    return self.pages.find(x => x.order === order) ?? null
  },
  getPageRequired: (serviceId: string): IPageInstance => {
    return self.pages.find(x => x.serviceId === serviceId)!
  },
  validate: () => {
    self.pages.forEach(x => x.validate())
  },
  movePage: (oldIndex: number, newIndex: number) => {
    // Где-то проеб, нумерация страниц не с 0, а с 1
    oldIndex++
    newIndex++

    const movedPage = self.pages.find(x => x.order === oldIndex)

    if (movedPage === undefined || oldIndex === newIndex) {
      return
    }

    if (oldIndex > newIndex) {
      self.pages.forEach(x => {
        if (x.order < oldIndex && x.order >= newIndex) {
          x.order = x.order + 1
        }
      })
    } else {
      self.pages.forEach(x => {
        if (x.order > oldIndex && x.order <= newIndex) {
          x.order = x.order - 1
        }
      })
    }

    movedPage.order = newIndex

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  addPage: (): IPageInstance => {
    const pageIdx = self.pages.push({
      id: undefined,
      serviceId: null,
      category: 'Прочее',
      title: '',
      description: '',
      imageId: defaultImageHash,
      isRequired: false,
      questions: [],
      order: self.pages.length + 1,
      version: 0,
      expanded: true,
      dndId: v4(),
    })

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)

    return self.pages[pageIdx - 1]
  },
  deletePage: (page: IPageInstance) => {
    const order = page.order

    self.pages.remove(page)

    self.pages.forEach(x => {
      if (x.order > order) {
        x.order = x.order - 1
      }
    })

    const store = getRoot<IRootStore>(self)
    store.admin?.tabStore.setWasChange(true)
  },
  appendPage: (page: IPageSnapshotIn) => {
    self.pages.push(page)
  },
}))
