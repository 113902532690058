import { Breadcrumbs } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 0px 25px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      justify-content: center;

      & > button {
        padding: 5px 10px;
        height: fit-content;
        border: solid #266dc2 1px;
        border-radius: 10px;
        min-width: 165px;

        & > svg {
          margin-right: 10px;
        }

        font-weight: 700;
        font-size: 18px;
        text-transform: capitalize;
      }
    }

    & .editing-button {
      margin-right: 10px;
      width: 300px;
    }

    & .review-button {
      margin-right: 10px;
    }
  }

  .MuiTab-root {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 130%;
  }
`

export const BreadcrumbsStyled = styled(Breadcrumbs)`
  margin-top: 30px !important;
  margin-bottom: 30px !important;

  .MuiBreadcrumbs-li > a {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    color: #626261;
  }

  .MuiBreadcrumbs-li > p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
  }
`
