import { useState } from 'react'
import { useMount } from 'react-use'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'

import { Container } from '@/Pages/Admin/EditPage/Components/Question/SliderContainer/styled'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'
import SharedAdditionalSettings from '@/Pages/Admin/EditPage/Components/Question/SharedAdditionalSettings'
import FileItem from '@/Components/FileItem'
import { FileType } from '@/Models/page'
import { IFile } from '@/Models/file'
import { Wait, useWaiter } from '@/Helpers/react-wait/hooks'
import { FileMimeType } from '@/Helpers/fileTypeNumToMime'
import { apiSetFile } from '@/Api/file'
import SpreadsheetSettingsDialog from '@/Pages/Admin/EditPage/Components/Question/SpreadsheetContainer/SpreadsheetSettingsDialog'
import { apiParseSpreadsheetByFileId, apiParseSpreadsheetFile } from '@/Api/question'
import { FileType as SpreadsheetFileType } from '@/Enums/fileType'
import { ISpreadsheetCellsData } from '@/Models/spreadsheet'

enum WaitVariants {
  FileUploading = 'excel-file-uploading',
  FileEditing = 'excel-file-editing',
}

interface ISpreadsheetContainer {
  question: IQuestionInstance
  disabled?: boolean
}

const MAX_FILE_SIZE = 30 * 1024 * 1024

const SpreadsheetContainer = ({ question, disabled = false }: ISpreadsheetContainer) => {
  const [fileId, setFileId] = useState<string | null>(null)
  const [data, setData] = useState<ISpreadsheetCellsData | null>(null)
  const { startWait, stopWait, isWaiting } = useWaiter()
  const { enqueueSnackbar } = useSnackbar()

  useMount(() => {
    (async () => {
      const fileInfo = question.payload.adminFiles.at(0)
      if (fileInfo?.fileId) {
        setFileId(fileInfo.fileId)
        const data = await apiParseSpreadsheetByFileId(fileInfo.fileId)
        setData(data)
      }
    })()
  })

  const handleAddExcelFile = async (file: IFile, formFile: File | null, fileId: string | null) => {
    try {
      if (formFile === null) {
        enqueueSnackbar('Среда не поддерживает загрузку файлов', {
          variant: 'error',
          autoHideDuration: 5000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        })
        return
      }

      if (file.size > MAX_FILE_SIZE) {
        enqueueSnackbar('Превышен лимит на размер файла в 30мб', {
          variant: 'error',
          autoHideDuration: 5000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        })
        return
      }
      if (![FileMimeType.xlsx as string, FileMimeType.xls as string].includes(file.contentType)) {
        enqueueSnackbar('Выбран файл с недопустимым расширением', {
          variant: 'error',
          autoHideDuration: 5000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        })
        return
      }
      startWait(WaitVariants.FileUploading)
      const fileId = await apiSetFile(file)

      const fileType = formFile.type === 'application/vnd.ms-excel' ? SpreadsheetFileType.Xls : SpreadsheetFileType.Xlsx
      const formData = new FormData()
      formData.append('file', formFile)
      const data = await apiParseSpreadsheetFile(formData, fileType)

      question.payload.replaceAdminFile({ fileId: fileId, fileName: formFile.name, fileType: fileType === SpreadsheetFileType.Xls ? FileType.xls : FileType.xlsx })
      setFileId(fileId)
      setData(data)
      startWait(WaitVariants.FileEditing)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      enqueueSnackbar('Ошибка загрузки файла', {
        variant: 'error',
        autoHideDuration: 5000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      })
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      stopWait(WaitVariants.FileUploading)
    }
  }

  return <Container>
    <SharedAdditionalSettings
      question={question}
      disabled={disabled}
    />
    <Wait for={WaitVariants.FileUploading} with={<FileItem displayFileMode={FileItem.DisplayFileMode.PlaceHolder} />}>
      {isWaiting(WaitVariants.FileUploading) ? (<FileItem
        disabled={disabled}
        displayFileMode={FileItem.DisplayFileMode.PlaceHolder}
      />) : fileId === null ? <FileItem
        disabled={disabled}
        displayFileMode={FileItem.DisplayFileMode.Add}
        onInputClick={handleAddExcelFile}
        acceptMimeList={[FileType.xlsx, FileType.xls]}
      /> : <FileItem
        fileId={fileId}
        displayFileMode={FileItem.DisplayFileMode.File}
        onDelete={!disabled ? () => {
          setFileId(null)
          setData(null)
          question.clearOptions()
          question.payload.clearAdminFiles()
        } : undefined}
        onCardClick={() => startWait(WaitVariants.FileEditing)}
      />}
    </Wait>
    {fileId && data && <SpreadsheetSettingsDialog
      open={isWaiting(WaitVariants.FileEditing)}
      onClose={() => stopWait(WaitVariants.FileEditing)}
      data={data}
      question={question}
      disabled={disabled}
    />}
  </Container>
}

export default observer(SpreadsheetContainer)
