import { observer } from 'mobx-react'
import { List, Skeleton } from '@mui/material'

import { IPage } from '@/Models/page'
import { OrganizationStructureCollectionRow } from '@/Components/OrganizationStructure'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnOrganizationStructureMultiViewCollection = bem()('organization-structure-collection')

interface IOrganizationStructureCollection {
  surveyId: string
  pages: IPage[]
  onOrganizationStructureStateSelectorChange: (organizationStructureStateSelector: string | null) => void
  isLoading?: boolean
  highlightDraftOrEmpty?: boolean
}

const OrganizationStructureCollection = ({
  surveyId,
  pages,
  onOrganizationStructureStateSelectorChange,
  isLoading = false,
  highlightDraftOrEmpty = false,
}: IOrganizationStructureCollection) => {
  const handleOrganizationStructureStateSelectorChange = (organizationStructureStateSelector: string | null) => {
    onOrganizationStructureStateSelectorChange(organizationStructureStateSelector)
  }

  return <div className={cnOrganizationStructureMultiViewCollection()}>
    <List>
      {isLoading ? [1, 2, 3].map(x => <Skeleton variant="rectangular" key={x} style={{ marginBottom: '10px' }} width='100%' height='50px' />) : pages.map(x => <OrganizationStructureCollectionRow
        key={x.id}
        surveyId={surveyId}
        page={x}
        onPageEdit={handleOrganizationStructureStateSelectorChange}
        highlightDraftOrEmpty={highlightDraftOrEmpty}
      />
      )}
    </List>
  </div>
}

export default observer(OrganizationStructureCollection)
