import { ChangeEvent, MouseEvent, useState } from 'react'
import { useMount } from 'react-use'
import { observer } from 'mobx-react'
import {
  Button,
  Checkbox,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import StarIcon from '@mui/icons-material/Star'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { v4 } from 'uuid'

import { IQuestionInstance, QuestionValidationResult } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'
import { ToolTip } from '@/Components'
import { ColorTheme } from '@/Models/colorTheme'
import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'
import SharedAdditionalSettings from '@/Pages/Admin/EditPage/Components/Question/SharedAdditionalSettings'
import bem from '@/Helpers/BemClass'

import './style.scss'

interface IRatingContainerProps {
  question: IQuestionInstance
  disabled?: boolean
}

const cnRating = bem()('rating')

const RatingContainer = ({ question, disabled = false }: IRatingContainerProps) => {
  const [starTitles, setStarTitles] = useState<string[]>([])

  useMount(() => {
    (async () => {
      // Если вопрос старый, то у него нет options. Для такого вопроса создаём 5 пустых options
      if (question.text && question.options.length === 0) {
        handleChangeStarCount(5)()
      }
      const emptyArray: string[] = Array.from(Array(10), (x, i) => '')
      emptyArray.splice(0, question.options.length, ...question.orderedOptions.map(x => x.title ?? ''))
      setStarTitles(emptyArray)
    })()
  })

  const handleChangeStarCount = (newValue?: number) => () => {
    if (!newValue || newValue < 2 || newValue > 10) return
    if (question.payload && question.payload.size === SizeEnum.large && newValue > 5) return
    if (question.options.length === newValue) return

    const newOptionsCount = newValue - question.options.length
    const addOptions = newOptionsCount > 0

    if (addOptions) {
      const newOptons = starTitles.slice(newValue - newOptionsCount, newValue)
      question.appendOptions(newOptons.map((x, i) => ({
        value: (question.options.length + i).toString(),
        imageId: null,
        title: x,
        isCustom: false,
        order: question.options.length + i,
        dndId: v4(),
      })))
    } else {
      question.trimOption(newOptionsCount * -1)
    }
  }

  const handleChangeStarSize = (e: MouseEvent<HTMLElement>, value: string) => {
    if (!value) return

    if (value === SizeEnum.large && question.payload && question.options.length > 5) {
      handleChangeStarCount(5)()
    }

    question.payload.changeSize(value as SizeEnum)
  }

  const handleChangeStarTitle = (starNumber: number) => (event: ChangeEvent<{ value: string }>) => {
    setStarTitles(prev => {
      const res = [...prev]
      res[starNumber] = event.target.value
      return res
    })

    question.changeOptionTitle(starNumber, event.target.value)
  }

  return (<Grid container direction='column' rowSpacing={2} className={cnRating()}>
    <Grid item>
      <SharedAdditionalSettings
        question={question}
        disabled={disabled}
      />
    </Grid>

    <Grid item container alignItems='center'>
      <TextField
        variant='standard'
        disabled={disabled}
        name='notReadyToRateText'
        placeholder='Введите текст для варианта не оценивать'
        className={cnRating('textfield-not-ready-to-rate')}
        value={question.payload.notReadyToRateText}
        error={question.emptySkipTextError}
        onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
        onChange={e => question.payload.setNotReadyToRateText(e.target.value)}
      />
      <Checkbox checked={question.payload?.showNotReadyToRate ?? false} onChange={question.payload.toggleShowNotReadyToRate} disabled={disabled}/>
    </Grid>
    <Grid item container xs justifyContent='center' spacing={1}>
      {/* Размер звёзд */}
      <Grid item container xs={12} sm direction='column' alignItems='center' spacing={1}>
        <Grid item>
          <Typography variant='body1' component='div' align='center'>
            <strong>Размер звёзд</strong>
          </Typography>
        </Grid>
        <Grid item container alignItems='center' justifyContent='center'>
          <ToggleButtonGroup value={question.payload?.size} onChange={handleChangeStarSize} exclusive>
            <ToggleButton disabled={disabled} className={cnRating('toggle-button', { starSize: true })} value={SizeEnum.large} key='80'>
              <ToolTip arrow theme={ColorTheme.dark} title='Большой' enterDelay={0}>
                <StarIcon className={cnRating('star-icon', { large: true })}/>
              </ToolTip>
            </ToggleButton>
            <ToggleButton disabled={disabled} className={cnRating('toggle-button', { starSize: true })} value={SizeEnum.medium} key='60'>
              <ToolTip arrow theme={ColorTheme.dark} title='Средний' enterDelay={0}>
                <StarIcon className={cnRating('star-icon', { medium: true })}/>
              </ToolTip>
            </ToggleButton>
            <ToggleButton disabled={disabled} className={cnRating('toggle-button', { starSize: true })} value={SizeEnum.small} key='40'>
              <ToolTip arrow theme={ColorTheme.dark} title='Малый' enterDelay={0}>
                <StarIcon className={cnRating('star-icon', { small: true })}/>
              </ToolTip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {/* Выравнивание звёзд */}
      <Grid item container xs={12} sm direction='column' alignItems='center' spacing={1}>
        <Grid item>
          <Typography variant='body1' component='div' align='center'>
            <strong>Выравнивание звёзд</strong>
          </Typography>
        </Grid>
        <Grid item container flex={1} alignItems='center' justifyContent='center'>
          <ToggleButtonGroup value={question.payload?.align} onChange={(e, v) => v && question.payload.changeAlign(v)} size='large' exclusive>
            <ToggleButton disabled={disabled} className={cnRating('toggle-button', { starAlign: true })} value={AlignEnum.left} key='left'>
              <ToolTip arrow theme={ColorTheme.dark} title='Слева' enterDelay={0}>
                <FormatAlignLeftIcon fontSize='large'/>
              </ToolTip>
            </ToggleButton>
            <ToggleButton disabled={disabled} className={cnRating('toggle-button', { starAlign: true })} value={AlignEnum.center} key='center'>
              <ToolTip arrow theme={ColorTheme.dark} title='По центру' enterDelay={0}>
                <FormatAlignCenterIcon fontSize='large'/>
              </ToolTip>
            </ToggleButton>
            <ToggleButton disabled={disabled} className={cnRating('toggle-button', { starAlign: true })} value={AlignEnum.right} key='right'>
              <ToolTip arrow theme={ColorTheme.dark} title='Справа' enterDelay={0}>
                <FormatAlignRightIcon fontSize='large'/>
              </ToolTip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {/* Количество звёзд */}
      <Grid item container xs={12} xxl direction='column' alignItems='center' spacing={1}>
        <Grid item>
          <Typography variant='body1' component='div' align='center'>
            <strong>Количество звёзд</strong>
            <ToolTip arrow theme={ColorTheme.dark} placement='top' title='Для количества звёзд более 5 доступен только средний и мелкий размер. На экранах с небольшим разрешением мелкий размер будет отображаться как средний.'>
              <InfoOutlinedIcon fontSize='small' htmlColor='grey'/>
            </ToolTip>
          </Typography>
        </Grid>
        <Grid item container xs alignItems='center' justifyContent='center' columnSpacing={2}>
          <Grid item container xs={2} justifyContent='end'>
            <Button disabled={disabled} variant='outlined' size='large' color='secondary' onClick={handleChangeStarCount(question.options.length - 1)} fullWidth><RemoveIcon/></Button>
          </Grid>
          <Grid item container xs={2} sm={3} md={3} lg={3} xl={2} xxl={2} justifyContent='center'>
            <TextField
              className={cnRating('number-picker-field')}
              value={question.options.length}
              onChange={e => handleChangeStarCount(Number(e.target.value))()}
              variant='outlined'
              disabled={disabled}
              InputProps={{
                className: cnRating('number-picker-field', { styled: true }),
              }}
            />
          </Grid>
          <Grid item container xs={2} justifyContent='start'>
            <Button disabled={disabled} variant='outlined' size='large' color='secondary' onClick={handleChangeStarCount(question.options.length + 1)} fullWidth><AddIcon/></Button>
          </Grid>
        </Grid>
      </Grid>
      {/* Подпись для звёзд */}
      <Grid item container xs={12} direction='column' alignItems='center' spacing={1}>
        <Grid item>
          <Typography variant='body1' component='div' align='center'>
            <strong>Подпись для звёзд</strong>
          </Typography>
        </Grid>
        <Grid item container justifyContent='center' spacing={2}>
          {question.orderedOptions?.map((x, i: number) => (<Grid item key={i}>
            <TextField
              value={x.title}
              helperText={`Звезда ${i + 1}`}
              onChange={handleChangeStarTitle(i)}
              variant='outlined'
              size='small'
              inputProps={{ maxLength: 30 }}
              disabled={disabled}
              FormHelperTextProps={{
                variant: 'standard',
              }}
            />
          </Grid>))}
        </Grid>
      </Grid>
    </Grid>

  </Grid>)
}

export default observer(RatingContainer)
