import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import { ISpreadsheetStoreInstance, InputType, SpreadsheetCellColor, SpreadsheetCellStore, SpreadsheetStore } from '@/Stores/SpreadsheetStore/spreadsheet'
import { ISpreadsheetCellsData } from '@/Models/spreadsheet'
import { IAnswerInstance, IQuestionInstance } from '@/Stores/UserStores/pageStore'
import Spreadsheet from '@/Components/Spreadsheet'
import bem from '@/Helpers/BemClass'

import './styles.scss'

const cnSpreadsheetQuestionDialog = bem()('spreadsheet-question-dialog')

interface ISpreadsheetQuestionDialog {
  open: boolean
  onClose: () => void
  data: ISpreadsheetCellsData
  answer: IAnswerInstance
  question: IQuestionInstance
}

const SpreadsheetQuestionDialog = ({ onClose, data, question, answer, open }: ISpreadsheetQuestionDialog) => {
  const [spreadsheetStoreInstance] = useState<ISpreadsheetStoreInstance>(SpreadsheetStore.create())
  const muiTheme = useTheme()
  const UIisTabletMd = useMediaQuery(muiTheme.breakpoints.down('xl'))

  useEffect(() => {
    const editableCells = question.options.map(x => JSON.parse(x.value) as { x: number, y: number, type: InputType })
    const answers = answer.value.map(x => x.value)

    spreadsheetStoreInstance.fillFromData(data, editableCells)
    spreadsheetStoreInstance.fillAnswers(answers)
  }, [data])

  const handleSave = () => {
    const answers = spreadsheetStoreInstance.cells.filter(x => x.inputType !== InputType.None)
      .map(x => ({ value: x.inputValue, isCustom: true }))

    answer.setValue(answers)
    onClose()
  }

  const handleClose = () => {
    spreadsheetStoreInstance.clearInput()
    const answers = answer.value.map(x => x.value)
    spreadsheetStoreInstance.cells.filter(x => x.inputType !== InputType.None)
      .forEach(x => x.setInputValue(answers.shift() ?? ''))

    onClose()
  }

  return <Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paper: cnSpreadsheetQuestionDialog('paper', { mobile: UIisTabletMd }),
    }}
  >
    <DialogTitle
      classes={{
        root: cnSpreadsheetQuestionDialog('title'),
      }}
    >
      <Typography variant='h5' fontWeight='bold'>Ввод ответа</Typography>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent
      classes={{
        root: cnSpreadsheetQuestionDialog('content'),
      }}
    >
      <div className={cnSpreadsheetQuestionDialog('container-window')}>
        {data && <Spreadsheet
          spreadsheetStoreInstance={spreadsheetStoreInstance}
          data={data}
          disabled={true}
        />}
      </div>
      <div className={cnSpreadsheetQuestionDialog('btn-line')}>
        <Button
          variant='text'
          onClick={() => spreadsheetStoreInstance?.clearInput()}
          className={cnSpreadsheetQuestionDialog('btn', { clean: true })}
        >
          Очистить
        </Button>
        <Button
          variant='contained'
          onClick={handleSave}
          className={cnSpreadsheetQuestionDialog('btn', { save: true })}
        >
          Сохранить
        </Button>
      </div>
    </DialogContent>
  </Dialog>
}

export default observer(SpreadsheetQuestionDialog)
