import styled from 'styled-components'

export const CropperContainer = styled.div`
  width: 100%;
  height: calc(100% - 160px);
  margin-top: 20px;
`

export const PreviewContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;

  &>.button-container {
    align-items: center;
    display: flex;
  }
`

export const ImagePreview = styled.div`
  width: 215px;
  height: 150px;
  overflow: hidden;  
  border: 1px solid;
  margin-right: 20px;
`

export const ModalStyled = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 600px;
    background-color: #fff;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
    padding: 16px 32px 44px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    h2{
      text-align: center;
      margin: 10px 0;
    }
    
    &:focus{
    outline: none;
    }
`
