import styled from 'styled-components'

export const PageInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  
  & > *:nth-child(2){
    margin-left: 15px;
  }
`

export const FieldColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;
`
