export enum Condition {
  Choose = '0',
  NotChoose = '1',
  Skip = '2',
  Always = '3',
  AnswerEntered = '4',
}

export enum Overlap {
  Or = '0',
  And = '1',
  None = '2',
}

export interface IRule {
  pageId: string
  questionId: string
  condition: Condition
  overlap: Overlap
  answers: string[]
  nextPageId?: string
  isDeprecated: boolean
  isNewItem: boolean
}

export interface IPageFilter {
  pageId: string
  targetPageId: string
  targetQuestionId: string
  condition: Condition
  overlap: Overlap
  answers: string[]
  isDeprecated: boolean
  isNewItem: boolean
}

export interface IQuestionFilter {
  pageId: string
  questionId: string
  targetQuestionId: string
  condition: Condition
  overlap: Overlap
  answers: string[]
  isDeprecated: boolean
  isNewItem: boolean
}

export interface IPageFilters {
  filters: IPageFilter[]
}

export interface IPageRules {
  rules: IRule[]
}

export interface IQuestionFilters {
  filters: IQuestionFilter[]
}

export interface IRulesCountStatistic {
  rulesCount: number
  pagesFilterCount: number
  questionsFilterCount: number
}
