import AddIcon from '@mui/icons-material/Add'

import { StyledButton } from './styled'

interface IAddButtonProps {
  text: string
  onClick: () => void
}

const AddButton = ({ text, onClick }: IAddButtonProps) => {
  return <StyledButton color='primary' onClick={onClick}>
    <AddIcon/> {text}
  </StyledButton>
}

export default AddButton
