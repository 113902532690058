import styled from 'styled-components'
import { Typography } from '@mui/material'

export const CategoryContainer = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #C5CBD3;
  box-sizing: border-box;
  border-radius: 6px;
`

export const CategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #9DB1CC;
  border-radius: 4px;
  background: linear-gradient(to left, #004C97, #0070C8);
  padding: 10px;
  & > * {
    flex: 1;
  }
`

export const CategoryData = styled.div`
  margin: 10px;
`

export const HeaderText = styled(Typography)`
  color: #FFFFFF;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 21px !important;
  line-height: 130%;
`

export const UseButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  & > *:hover{
    cursor: pointer;
    opacity: 0.6;
  }
`
