import { Skeleton } from '@mui/material'

import bem from '@/Helpers/BemClass'

import './style.scss'

const cnTableSkeleton = bem()('table-skeleton')

const TableSkeleton = () => {
  return <>
    <Skeleton variant='rounded' className={cnTableSkeleton('table-fields-skeleton')} width='100%' height={56}/>
    <Skeleton variant='rounded' className={cnTableSkeleton('table-row-skeleton')} width='100%' height={46} animation="wave" />
    <Skeleton variant='rounded' className={cnTableSkeleton('table-row-skeleton')} width='100%' height={46} animation="wave" />
    <Skeleton variant='rounded' className={cnTableSkeleton('table-row-skeleton', { last: true })} width='100%' height={47} animation="wave" />
  </>
}

export default TableSkeleton
