import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const TransitionContainer = styled.div<{ height: number }>`
  display: flex;
  &.enter {
    max-height: 0;
    opacity: 0;
    height: auto;
  }

  &.enter-active {
    height: auto;
    opacity: 1;
    transition: max-height 700ms ease-in-out, opacity 700ms ease-in-out;
    max-height: 800px;
  }

  &.exit {
    height: auto;
    max-height: ${props => props.height}px;
    opacity: 1;
  }

  &.exit-active {
    max-height: 0;
    opacity: 0;
    transition: max-height 700ms ease-in-out, opacity 700ms ease-in-out;
  }
`

export const StyledQuestionTitle = styled.h4`
  font-style: normal;
  font-weight: normal;
  line-height: 130%;
  word-break: break-word;
  margin: 0;
  width: 100%;
  & > p {
    margin-top: 0;
    margin-bottom: 0.75rem;
  }
    
  & > div {
    & > figure {
      width: auto !important;
    }
  }
`

export const StyledQuestionTitleContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  & > span {
    font-size: 2.2rem;
    color: red;
    cursor: default;
    position: absolute;
    left: -12px;
    margin-top: -16px;
  }
`

