import { MenuItem } from '@mui/material'
import styled from 'styled-components'

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .MuiInputBase-root {
    width: 250px;

    .MuiSelect-root {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  & > div:last-child{
    margin-top: 0;
  }
`

export const MenuItemStyled = styled(MenuItem)`
  display: -webkit-box !important;
  overflow: hidden !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  white-space: normal !important;
`
