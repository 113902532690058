import { useContext } from 'react'

import { WaiterContext, _isContextAvailable } from './context'

import type { IWaitComponentProps, ICreatedWaiterContext } from './types'

export const Wait = (props: IWaitComponentProps) => {
  const ctx = _isContextAvailable(useContext(WaiterContext))
  return <>{ ctx.waiters.has(props.for) ? props.with : props.children }</>
  // return ctx.isWaiting(props.for) ? props.with : props.children
}

/**
 * Хук Wait
 *
 * @example Пример использования:
 * ```ts
 * const { Wait, anyWaiting, isWaiting, startWait, stopWait } = useWaiter()
 * ```
 * @return Возвращает контекст
 */
export const useWaiter = (): ICreatedWaiterContext => {
  const ctx = _isContextAvailable(useContext(WaiterContext))
  return {
    ...ctx,
    Wait,
  }
}
