import BackendAdapter from '@/Api/_adapter'
import { IOrganizationStructureModel, IOrganizationStructureUser } from '@/Models/organizationStructure'
import { IOrganizationStructureFreeElement } from '@/Stores/AdminStores/organizationStructureStore'
import { FileType } from '@/Enums/fileType'

export const apiSyncOrganizationStructure = async (): Promise<null> => {
  try {
    return await BackendAdapter.post('/api/organizationStructure/sync').then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetOrganizationStructure = async (): Promise<IOrganizationStructureModel> => {
  try {
    return await BackendAdapter.get('/api/organizationStructure/').then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

// TODO: Проверить, возможно мусор
export const apiGetOrganizationStructureUsers = async (): Promise<IOrganizationStructureUser[]> => {
  try {
    return await BackendAdapter.get('/api/organizationStructure/users').then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiParseOrganizationStructureFromFile = async (model: FormData, fileType: FileType): Promise<IOrganizationStructureModel> => {
  try {
    return await BackendAdapter.post('/api/organizationStructure/scheme/parse', model, {
      params: {
        type: fileType,
      },
    }).then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSaveOrganizationStructureFromFile = async (model: FormData, fileType: FileType): Promise<void> => {
  try {
    return await BackendAdapter.post('/api/organizationStructure/scheme/save', model, {
      params: {
        type: fileType,
      },
    }).then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiParseElementsArrayFromFile = async (model: FormData, fileType: FileType): Promise<IOrganizationStructureFreeElement[]> => {
  try {
    return await BackendAdapter.post('/api/organizationStructure/elements/parse',
      model,
      {
        params: {
          type: fileType,
        },
      }).then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
