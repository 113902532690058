import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'

export interface IAnswerValidationResult {
  imageRequiredError?: boolean
  duplicateError?: boolean
}

export interface IOption {
  value: string
  imageId: string | null
  title: string | null
  isCustom: boolean
  order: number
}

export interface IFile {
  fileId?: string | null
  fileName?: string | null
  fileType?: string | null
}

export interface IPage {
  id?: string
  serviceId: string | null
  category: string | null
  title: string
  description: string
  imageId: string
  isRequired: boolean
  version: number
  questions: IQuestion[]
  order: number
}

export enum QuestionStyle {
  Default = '0',
  Colorized = '1',
}

export interface IPayload {
  helperStartText: string
  helperEndText: string
  showNotReadyToRate: boolean
  notReadyToRateText: string
  requireCommentValue: string
  isEnabledLoadFileButton: boolean
  maxFileCount: number
  limitFileType: boolean
  acceptFileTypes: FileType[]
  adminFiles: IFile[]
  questionStyle: QuestionStyle
  size: SizeEnum
  align: AlignEnum
}

export const defaultPayload: IPayload = {
  helperEndText: '',
  helperStartText: '',
  notReadyToRateText: '',
  showNotReadyToRate: false,
  requireCommentValue: '',
  isEnabledLoadFileButton: false,
  maxFileCount: 1,
  limitFileType: false,
  acceptFileTypes: [],
  adminFiles: [],
  questionStyle: QuestionStyle.Default,
  size: SizeEnum.medium,
  align: AlignEnum.left,
}

export interface IQuestion {
  id?: string
  text: string
  helperText: string
  type: QuestionType
  commentType: CommentType
  payload: IPayload
  showHelperText: boolean
  hasCustomAnswer: boolean
  options: IOption[]
  version: number
  isMain: boolean
  isRequired: boolean
  mainQuestionId: string | null
  order: number
}

export enum QuestionType {
  text = '0',
  radio = '1',
  checkbox = '2',
  rating = '3',
  slider = '4',
  radioWithImage = '5',
  checkboxWithImage = '6',
  attachment = '7',
  spreadsheet = '8',
}

export enum CommentType {
  NoComment = '0',
  Comment = '1',
  CommentIsRequired = '2',
  AlwaysVisible = '3',
  AlwaysVisibleAndRequired = '4',
  NotRequiredAndVisibleTo = '5',
  VisibleToAndRequiredTo = '6',
  AlwaysVisibleAndRequiredTo = '7',
}

export enum FileType {
  xls = '1', // 'application/vnd.ms-excel',
  xlsx = '2', // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  doc = '3', // 'application/msword',
  docx = '4', // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt = '5', // 'application/vnd.ms-powerpoint',
  pptx = '6', // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  png = '7', // 'image/png',
  jpeg = '8', // 'image/jpeg',
  pdf = '9', // 'application/pdf',
  text = '10', // 'text/plain',
}
