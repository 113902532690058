import { useState, MouseEvent } from 'react'
import { useMount } from 'react-use'
import { Skeleton } from '@mui/material'

import { ReactComponent as AnswerReportIcon } from '@/Images/downloadDocument.svg'
import { DialogState } from '@/Enums/dialogState'
import AgTable from '@/Components/AgGridTable'
import { VerticalButton } from '@/Components/VerticalButton'
import GetReportModal, { ReportType } from '@/Components/GetReportModal'
import { ToolTip } from '@/Components'
import { HeadCell } from '@/Models/headCell'
import { IFeedback } from '@/Models/feedback'
import { apiGetFeedbackBySurvey } from '@/Api/feedback'
import { useWaiter } from '@/Helpers/react-wait'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnFeedbackTab = bem()('feedback-tab')

interface IFeedbackTab {
  surveyId: string
}

const FeedbackTab = ({ surveyId }: IFeedbackTab) => {
  const { startWait, stopWait, isWaiting } = useWaiter()

  const [feedbacks, setFeedbacks] = useState<IFeedback[]>([])
  const [dialogState, setDialogState] = useState(DialogState.Closed)

  useMount(() => {
    (async () => {
      startWait('feedback-page-loading')
      const res = await apiGetFeedbackBySurvey(surveyId)
      setFeedbacks(res)

      stopWait('feedback-page-loading')
    })()
  })

  const handleToggleOpen = (item: IFeedback) => (e: MouseEvent) => {
    setFeedbacks(prev => prev.map(x => (x === item ? { ...x, isOpen: !x.isOpen } : x)))
    e.stopPropagation()
  }

  const header: Array<HeadCell<IFeedback>> = [
    {
      key: 'serviceName',
      label: 'Страница/модуль опросов',
      isVisible: true,
      isSearchable: true,
      flex: 1,
    },
    {
      key: 'date',
      label: 'Дата',
      createControl: obj => <>{obj.date.toLocaleDateString()}</>,
      isVisible: true,
      isSearchable: true,
      flex: 1,
      align: 'center',
    },
    {
      key: 'estimate',
      label: 'Оценка',
      isVisible: true,
      flex: 1,
      align: 'center',
    },
    {
      key: 'comment',
      label: 'Комментарий',
      createControl: obj => <div className={cnFeedbackTab('comment')}
        onClick={handleToggleOpen(obj)}>{obj.comment.length > 71 && !obj.isOpen ? (`${obj.comment.substring(0, 70)}...`) : obj.comment}</div>,
      isVisible: true,
      flex: 1,
    },
  ]
  return (<div className={cnFeedbackTab()}>
    <div className={cnFeedbackTab('report-button-container')}>
      <VerticalButton
        disabled={isWaiting('handleGetAnswersReport') || isWaiting('feedback-page-loading')}
        onClick={() => setDialogState(DialogState.Showed)}
        startIcon={<AnswerReportIcon width={48} height={48} />}
      >
        <ToolTip arrow title='Скачать отчёт "Ответы"'>
          <span>Отчёт <br />ответы</span>
        </ToolTip>
      </VerticalButton>
    </div>
    {isWaiting('feedback-page-loading') ? <>
      <Skeleton variant='rectangular' className={cnFeedbackTab('skeleton', { 'table-fields': true })} height={55}/>
      <Skeleton variant='rectangular' className={cnFeedbackTab('skeleton', { 'table-row': true })} height={45} animation="wave"/>
      <Skeleton variant='rectangular' className={cnFeedbackTab('skeleton', { 'table-row': true })} height={45} animation="wave"/>
    </> : <AgTable
      data={feedbacks}
      headCells={header}
    />}
    {dialogState === DialogState.Showed && (
      <GetReportModal
        surveyId={surveyId}
        onClose={() => setDialogState(DialogState.Closed)}
        onStartDownload={() => {
          startWait('handleGetFeedbackReport')
          setDialogState(DialogState.Closed)
        }}
        onEndDownload={() => stopWait('handleGetFeedbackReport')}
        reportsType={[ReportType.CsvFeedbackReport]}
      />
    )}
  </div>)
}

export default FeedbackTab
