import styled from 'styled-components'
import { Typography } from '@mui/material'

export const PageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    border-radius: 4px;
    background: var(--color-light-grey);
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .5);
    margin: 9px;
    padding: 16px;
`

export const BoldText = styled(Typography)`
    font-weight: bold !important;
    font-size: 18px;
`

export const PageHeader = styled.div`
    text-overflow: ellipsis;
    font-weight: bolder;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
    word-break: break-word;
`

export const UseButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    flex: 1;
`

export const PageNumberStyled = styled.div`
    font-weight: normal;
    display: flex;
    flex: 1;
`
