import styled from 'styled-components'

interface IContainerProps {
  verticalPosition: 'top' | 'bottom'
  verticalOffset: number
}

export const Container = styled.div`
  ${(props: IContainerProps) => `${props.verticalPosition}:${props.verticalOffset}px`};
`
