import { useState } from 'react'
import { useMount } from 'react-use'
import {
  Container,
  Grid,
  Box,
  Typography,
  Grow,
  Collapse,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import _ from 'lodash'
import { Pagination } from '@mui/lab'

import { IPageAnswersInfo } from '@/Models/answer'
import { useWaiter } from '@/Helpers/react-wait'
import GetReportModal, { ReportType } from '@/Components/GetReportModal'
import { apiGetAllAnswersBySurvey } from '@/Api/answer'
import { ContentLoader } from '@/Components'
import {
  ISurveyInstance,
  SurveyType,
} from '@/Stores/AdminStores/surveysStore'
import { useMst } from '@/Stores/rootStore'
import {
  DisplayMode,
  PageAnswers,
} from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/PageAnswers'
import { DialogState } from '@/Enums/dialogState'
import bem from '@/Helpers/BemClass'

import { Header } from './Components/Header'

import './style.scss'

const cntAnswerTab = bem()('answer-tab')

// TODO: улучшить тайпинги
interface IAnswerTab {
  surveyId: string
  displayMode: DisplayMode
}

export const AnswerTab = ({ surveyId, displayMode }: IAnswerTab) => {
  const muiTheme = useTheme()
  const UIisMobileXs = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const UIisTabletLg = useMediaQuery(muiTheme.breakpoints.down('xl'))
  const { startWait, stopWait, isWaiting } = useWaiter()
  const [isLoading, setIsLoading] = useState(true)
  const [survey, setSurvey] = useState<ISurveyInstance>()
  const surveysStore = useMst().admin.surveysStore
  const [isTableViewMode, setIsTableViewMode] = useState(true)
  const [dialogState, setDialogState] = useState(DialogState.Closed)
  const [selectedPage, setSelectedPage] = useState(1)
  const [answers, setAnswers] = useState<IPageAnswersInfo[]>([])

  useMount(() => {
    (async () => {
      const survey = surveysStore.surveys.find((x) => x.surveyId === surveyId)
      setSurvey(survey)
      const apiRes: IPageAnswersInfo[] = await apiGetAllAnswersBySurvey(surveyId)
      setAnswers(apiRes)
      setIsLoading(false)
    })()
  })

  const elNoDataAvailGeneral = (
    <Grid item xs={12} className={`${cntAnswerTab('answers-page')}`}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-center'
        alignItems='center'
        paddingY={1}
        height='100%'
      >
        <Typography variant='h6' gutterBottom>
          Недостаточно данных для отображения
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          Создайте страницы с вопросами и дождитесь появления ответов
        </Typography>
      </Box>
    </Grid>
  )

  return (
    <div className={cntAnswerTab()}>
      <ContentLoader style={isLoading ? {} : { display: 'none' }} />
      <Container maxWidth={false}>
        <Collapse in={!isLoading} timeout={{ enter: 900 }}>
          <Header
            pageAnswersCount={answers.length}
            setDialogState={setDialogState}
            surveyId={surveyId}
            survey={survey}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            startWaiting={startWait}
            endWaiting={stopWait}
            isWaiting={isWaiting}
            displayMode={displayMode}
          />
        </Collapse>
        <Box marginBottom={3}>
          <Grow in={!isLoading} timeout={700}>
            <Grid
              className={cntAnswerTab('answers-pages-wrapper')}
              container
              direction='column'
              justifyContent='space-between'
            >
              {(function() {
                // если опрос пустой (нет вопросов/страниц), показываем сообщение о нехватке данных для отображения
                if (answers.length <= 0) return elNoDataAvailGeneral
                else {
                  // данные есть, можем рендерить их
                  return (
                    <PageAnswers
                      displayMode={displayMode}
                      pageAnswersInfo={answers[selectedPage - 1]}
                      order={selectedPage}
                      isTableViewMode={isTableViewMode}
                      setIsTableViewMode={setIsTableViewMode}
                    />
                  )
                }
              })()}
            </Grid>
          </Grow>
        </Box>
        {answers.length > 1 && <Pagination
          className={cntAnswerTab('pagination-bottom')}
          size={UIisMobileXs ? 'small' : (UIisTabletLg ? 'medium' : 'large')}
          page={selectedPage}
          onChange={(_, number) => setSelectedPage(number)}
          count={answers.length}
        />}
        {dialogState === DialogState.Showed && (
          <GetReportModal
            surveyId={surveyId}
            onClose={() => setDialogState(DialogState.Closed)}
            onStartDownload={() => {
              startWait('handleGetAnswersReport')
              setDialogState(DialogState.Closed)
            }}
            onEndDownload={() => stopWait('handleGetAnswersReport')}
            reportsType={[
              ReportType.CsvAnswersReport,
              ReportType.CsvAnswersReportWithFiles,
              ReportType.ZipFullReport,
              // Т.к. для эксперта нет страницы обратной связи, показываем отчет на странице ответов эксперта
              ...(displayMode === DisplayMode.Expert ? [ReportType.CsvFeedbackReport] : []),
              // Отчет ответов в xls это отчет со сравнением периодов, он есть только в постоянном типе опросов
              ...(survey?.type === SurveyType.Repeatable ? [ReportType.XlsxAnswersReport] : []),
            ]}
          />
        )}
      </Container>
    </div>
  )
}

export default AnswerTab
