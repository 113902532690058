import styled from 'styled-components'

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CounterStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  & > *:nth-child(1){
    margin-right: 4px;
  }
`
