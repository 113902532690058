import styled from 'styled-components'

export const AccordionSummaryContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

export const TitleContainer = styled.div`
  text-overflow: ellipsis;
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: center;
  word-break: break-word;
`

export const ActionText = styled.div`
  user-select: none;
  cursor: pointer;
  color: #266DC2;
  font-weight: bold;
  margin-left: 20px !important;

  &:hover{
    opacity: 0.6;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin: 0 5px
  }
`
