import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 550px;
  margin-top: 10px;
  height: 147px;
`

export const TitleContainer = styled.div`
  margin: 10px 20px;
  height: 70px;
  .MuiFormControl-root{
    display: flex;
  }
`

export const ButtonContainer = styled.div`
  height: 55px;
  width: 100%;
  align-items: flex-end;
  display: flex;
  border-top: 2px lightgrey solid;
  & > button{
    margin: 10px 10px 10px auto;
    width: 120px;
    height: 35px;
    border: 2px #F0F0F0 solid;
  }
`
