import styled, { createGlobalStyle } from 'styled-components'
import { List, ListItemButton } from '@mui/material'

export const Container = styled.div`
    background: white;
    position: relative;

  .MuiDrawer-paper{
    width: 250px;
    height: 100vh;
    position: relative;
    z-index: 0;

  }
  .Mui-selected{
    svg {
      fill: #3F51B5;

      path{
        fill: #3F51B5;
      }
    }
  }
`


export const ContentContainer = styled.div<{ leftRightMargin: number }>`
  // TODO: избавиться от leftRightMargin
  // margin: 0px ${props => props.leftRightMargin}px;
  margin: 0px 1.5rem;
  margin-bottom: 20px;
`

export const GlobalSnackbarStyled = createGlobalStyle`
    .SnackbarItem-contentRoot-24{
      position: relative;
      .SnackbarItem-message-25{
        margin-right: 20px;
      }
      .SnackbarItem-action-26{
        position: absolute;
        right: 7px;
      }
    }
`

export const ListStyled = styled(List)`
  width: fit-content;
`

export const ListItemStyled = styled(ListItemButton)`
  &.Mui-selected > .MuiListItemText-root > .MuiTypography-root {
    color: #000000;
    font-weight: bold;
  }

  &.MuiListItemButton-root {
    background-color: unset !important;
    width: unset !important;
  }

  &.Mui-focusVisible {
    background-color: #FF0000;
  }

  .MuiTouchRipple-root {
    color: unset !important;
  }
`
