import { Table, TableBody, TableCell, TableFooter, TableRow, TableHead } from '@mui/material'

import { TableContainer } from '../../GeneralInformationTab/styled'
import { ISurveyInstance } from '../../../../../Stores/AdminStores/surveysStore'
import { getFormatDate } from '../../../../../Helpers/periodCompareHelper'
import { IPage, ITotalAverage } from '../index'

import { TableCellBoldStyled, TableCellWithBackgroundStyled } from './styled'

interface IGenerateTable {
  pages: IPage[]
  periods: ISurveyInstance[]
  totalAverages: ITotalAverage[]
}

const GenerateTable = ({ pages, periods, totalAverages }: IGenerateTable) => {
  return (<TableContainer>
    <Table aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell/>
          {pages[0]?.departments.map((column) => (
            <TableCellBoldStyled
              key={column.questionText}
              align={'center'}
              colSpan={pages[0]?.departments[0].questions.length}
              style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }}
            >
              {column.questionText}
            </TableCellBoldStyled>
          ))}
          {<TableCellBoldStyled
            align={'center'}
            colSpan={totalAverages.length}
          >
            Удовлетворённость услугами
          </TableCellBoldStyled>}
        </TableRow>
        <TableRow>
          <TableCellBoldStyled>Страницы</TableCellBoldStyled>
          {pages[0]?.departments.map(dep => (
            dep.questions.map((a, i) => (
              <TableCellBoldStyled key={i} align={'center'} style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>
                {getFormatDate(periods.find(x => x.version === a.surveyVersion) as ISurveyInstance)}
              </TableCellBoldStyled>
            ))
          ))}
          {totalAverages.map((a, i) => (
            <TableCellBoldStyled key={i} align={'center'}>
              {getFormatDate(periods.find(x => x.version === a.surveyVersion) as ISurveyInstance)}
            </TableCellBoldStyled>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {pages?.map((page, i) => (
          <TableRow key={i} hover role="checkbox">
            <TableCell>{page.departmentTitle}</TableCell>
            {page.departments.map(dep1 => (
              dep1.questions.map((question, j) => (
                <TableCellWithBackgroundStyled key={j} value={question.percent} align={'center'}>
                  {question.value ?? '-'}
                </TableCellWithBackgroundStyled>
              ))
            ))}
            {page.averages.map((aver, i) => (
              <TableCellWithBackgroundStyled key={i} value={aver.percent} align={'center'}>
                {aver.averageByPageTitle ?? '-'}
              </TableCellWithBackgroundStyled>
            ))}
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCellBoldStyled>Общий итог</TableCellBoldStyled>
          {pages[0]?.departments.map(dep => (
            dep.questions.map((a, i) => (
              <TableCellBoldStyled key={i} style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }} align={'center'}>
                {a.total}
              </TableCellBoldStyled>
            ))
          ))}
          {totalAverages.map((average, i) => (
            <TableCellBoldStyled key={i} style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }} align={'center'}>
              {average.totalAveragesBySurveyVersion}
            </TableCellBoldStyled>
          ))}

        </TableRow>
      </TableFooter>
    </Table>
  </TableContainer>)
}

export default GenerateTable
