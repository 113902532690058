import { htmlToText } from 'html-to-text'

/**
 * Удаляет все img из html
 */
export const htmlToTextWithNoImage = (html: string) => {
  return htmlToText(html, { selectors: [{ selector: 'a', format: 'inlineHtml' }, { selector: 'img', format: 'skip' }] })
}

/**
 * Проверяет есть ли контент в html тегах.
 * Если теги пустые возвращает true
 */
export const htmlTagsIsEmpty = (html: string) => htmlToText(html).length === 0
