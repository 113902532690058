import styled from 'styled-components'

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

export const QuestionStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  flex: 1;

  & > div:nth-child(2) {
    width: 200px;
  }

  & > div:nth-child(1) {
    width: 100%;
    margin-right: 20px;
  }

  .edit-button {
    margin-top: auto;
    height: 100%;
  }
`

export const QuestionContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const QuestionText = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-right: 25%;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionContainer = styled.div<{ isVisible: boolean | undefined }>`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  margin-bottom: auto;
  margin-top: 0;

  & > *:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

export const ComboBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`
