import { useCallback, useEffect, useRef, useState } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { observer } from 'mobx-react'

import Error from '@/Components/Error'
import Button from '@/Components/Button'
import TextLink from '@/Components/TextLink'
import DndSortableList from '@/Components/DragAndDrop/DndSortableList'
import Dialog, { IDialog } from '@/Components/Dialog'
import Question from '@/Pages/Admin/EditPage/Components/Question'
import GvkPageHeader from '@/Pages/Admin/EditPage/Components/Page/GvkPageHeader'
import CustomPageHeader from '@/Pages/Admin/EditPage/Components/Page/CustomPageHeader'
import PageInfo from '@/Pages/Admin/EditPage/Components/Page/PageInfo'
import { IPageInstance, IQuestionInstance, PageValidationError } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'
import { useMst } from '@/Stores/rootStore'
import { ISurveyInstance } from '@/Stores/AdminStores/surveysStore'
import { IServiceInstance } from '@/Stores/AdminStores/adminServicesStore'
import { htmlToTextWithNoImage } from '@/Helpers/htmlToTextCustom'
import { QuestionType } from '@/Models/page'

import './style.scss'

interface IPage {
  page: IPageInstance
  hasActivePeriod: boolean
  service: IServiceInstance | null
  dragHandleProps?: DraggableProvidedDragHandleProps | undefined | null
  isGvk?: boolean
  isCardState: boolean
  isRepeatableSurveyed: boolean
  readonly: boolean
  disableAccordion?: boolean
}

const Page = ({ page, hasActivePeriod, service, isGvk, dragHandleProps, isCardState, isRepeatableSurveyed, readonly, disableAccordion = false }: IPage) => {
  const questionRef = useRef<HTMLDivElement>(null)
  const store = useMst()
  const pageStore = store.admin.surveyInfoRootStore.pagesStore
  const [questionsCount, setQuestionCount] = useState(page.questions.length)
  const [dialogState, setDialogState] = useState<IDialog | undefined>()

  const renderQuestion = (item: IQuestionInstance, index: number, dragHandleProps: DraggableProvidedDragHandleProps | undefined | null) => {
    return <div ref={(index + 1) === page.questions.length ? questionRef : undefined}>
      <Question
        question={item}
        draggableProvided={dragHandleProps}
        onDelete={() => {
          const errors: string[] = []

          const pageFilterDependency = pageStore.pageFiltersQuestionsDependency.filter(x => x.dependencyQuestionId === item.id)

          if (pageFilterDependency.length > 0) {
            errors.push(`Удаляемый вопрос указан в качестве ссылки в фильтрах страниц:\n- ${pageFilterDependency.map(x => x.currentPageName).join('\n- ')}`)
          }

          const questionFilterDependency = pageStore.questionFiltersQuestionsDependency.filter(x => x.dependencyQuestionId === item.id)

          if (questionFilterDependency.length > 0) {
            errors.push(`Удаляемый вопрос указан в качестве ссылки в фильтрах вопросов:\n- ${questionFilterDependency.map(x => `стр. "${x.currentPageName}" вопрос ${htmlToTextWithNoImage(x.currentQuestionText)}`).join('- ')}`)
          }

          if (errors.length > 0) {
            setDialogState({
              open: true,
              handleClose: () => setDialogState(undefined),
              title: 'Удаление вопроса влияет на логику ветвления. Удалить вопрос?',
              text: errors.join('\n\n'),
              actions: [
                <Button key='delete-no-btn' onClick={() => setDialogState(undefined)}>Нет</Button>,
                <Button key='delete-yes-btn' onClick={() => {
                  page.deleteQuestion(item)
                  setDialogState(undefined)
                }}>Да</Button>,
              ],
              multileneText: true,
            })
          } else {
            page.deleteQuestion(item)
          }
        }}
        disabled={readonly}
      />
    </div>
  }

  useEffect(() => {
    if (page.questions.length > questionsCount && questionRef.current) {
      const elementTop = questionRef.current.getBoundingClientRect().top
      const offset = window.scrollY
      const target = elementTop + offset - 100
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      })
    }

    setQuestionCount(page.questions.length)
  }, [page.questions.length])

  const handleQuestionDragEnd = useCallback((oldIndex: number, newIndex: number) => {
    const errors: string[] = []

    const movedQuestion = page.getQuestionAt(oldIndex)

    const brokenOwnedQuestionFiltersQuestionsText = movedQuestion?.questionFilterQuestionDependency.map(x => {
      const dependency = page.getQuestion(x.dependencyQuestionId)
      if (dependency && (oldIndex > newIndex ? dependency.order >= (newIndex) : dependency.order > (newIndex))) {
        return htmlToTextWithNoImage(dependency.text)
      } else {
        return null
      }
    }).filter(x => x !== null)

    if (brokenOwnedQuestionFiltersQuestionsText && brokenOwnedQuestionFiltersQuestionsText.length > 0) {
      errors.push(`Перемещаемый вопрос станет выше вопросов, указанных в фильтрах вопросов:\n- ${brokenOwnedQuestionFiltersQuestionsText.join('- ')}`)
    }

    const dependentQuestions = pageStore.questionFiltersQuestionsDependency.filter(x => x.dependencyQuestionId === movedQuestion?.id)

    const brokenQuestionFiltersQuestionsText = dependentQuestions.map(x => {
      const dependentQuestion = page.getQuestion(x.currentQuestionId)

      if (dependentQuestion && (oldIndex > newIndex ? dependentQuestion.order < (newIndex) : dependentQuestion.order <= (newIndex))) {
        return htmlToTextWithNoImage(dependentQuestion.text)
      } else {
        return null
      }
    }).filter(x => x !== null)

    if (brokenQuestionFiltersQuestionsText && brokenQuestionFiltersQuestionsText.length > 0) {
      errors.push(`Перемещаемый вопрос станет ниже вопросов, в фильтрах которых он указана:\n- ${brokenQuestionFiltersQuestionsText.join('- ')}`)
    }

    if (errors.length > 0) {
      setDialogState({
        open: true,
        handleClose: () => setDialogState(undefined),
        title: 'Перемещение этого вопроса повлияет на ветвление. Переместить вопрос?',
        text: errors.join('\n\n'),
        actions: [
          <Button key='move-no-btn' onClick={() => setDialogState(undefined)}>Нет</Button>,
          <Button key='move-yes-btn' onClick={() => {
            page.moveQuestion(oldIndex, newIndex)
            setDialogState(undefined)
          }}>Да</Button>,
        ],
        multileneText: true,
      })
    } else {
      page.moveQuestion(oldIndex, newIndex)
    }
  }, [pageStore, page])


  if (disableAccordion) {
    return <div className={`${(page.hasValidationErrors && !page.expanded) ? 'error' : ''} page-info`}>
      <div className='page-info-header' style={{ backgroundColor: '#e8eaed' }}>
        {isGvk ? <GvkPageHeader/> : <CustomPageHeader
          page={page}
          dragHandleProps={dragHandleProps}
          isDraggable={!isCardState}
          disabled={readonly || isRepeatableSurveyed}
        />}
      </div>
      <div className='page-info-accordion-details'>
        <div className={`page-info-container ${isGvk ? 'is-gvk' : ''} ${page.hasSelfValidationErrors ? 'error' : ''}`}>
          <PageInfo page={page} service={service} isGvk={isGvk} disabled={readonly}/>
          <div className="question-info-container">
            <DndSortableList
              data={page.orderedQuestions}
              onDragEnd={handleQuestionDragEnd}
              renderItem={renderQuestion}
              dndIdBuilder={question => question.dndId}
            />
            {!readonly && <TextLink
              text='Добавить вопрос'
              color="#266DC2"
              style={{ marginTop: '35px', marginLeft: '15px', lineHeight: '18px' }}
              onClick={() => page.addQuestion()}
            />}
            <Error isVisible={page.emptyQuestionCollectionError}>На странице должен быть хотя-бы один вопрос</Error>
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <>
      <Accordion expanded={page.expanded} className={`${(page.hasValidationErrors && !page.expanded) ? 'error' : ''} page-info page-info--accordion`} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary onClick={() => page.toggleExpanded()}>
          {isGvk ? <GvkPageHeader/> : <CustomPageHeader
            page={page}
            dragHandleProps={dragHandleProps}
            isDraggable={!isCardState}
            disabled={readonly || isRepeatableSurveyed}
          />}
        </AccordionSummary>
        <AccordionDetails className='page-info-accordion-details'>
          <div className={`page-info-container ${isGvk ? 'is-gvk' : ''} ${page.hasSelfValidationErrors ? 'error' : ''}`}>
            <PageInfo page={page} service={service} isGvk={isGvk} disabled={readonly}/>
            <div className="question-info-container">
              {page.orderedQuestions.filter(x => x.isMain).map(x => <Question
                question={x}
                draggableProvided={null}
                onDelete={() => {}}
                disabled={readonly || (x.type === QuestionType.spreadsheet && hasActivePeriod) }
                key={x.dndId}
              />)}
              <DndSortableList
                data={page.orderedQuestions.filter(x => !x.isMain)}
                onDragEnd={handleQuestionDragEnd}
                renderItem={renderQuestion}
                dndIdBuilder={question => question.dndId}
              />
              {!readonly && <TextLink
                text='Добавить вопрос'
                color="#266DC2"
                style={{ marginTop: '35px', marginLeft: '15px', lineHeight: '18px' }}
                onClick={() => page.addQuestion()}
              />}
              <Error isVisible={page.emptyQuestionCollectionError}>На странице должен быть хотя-бы один вопрос</Error>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {dialogState && <Dialog {...dialogState} />}
    </>)
}

export default observer(Page)
