import { useState } from 'react'
import { useMount } from 'react-use'
import { observer } from 'mobx-react'
import { List as VirtyalizedList } from 'react-virtualized'
import { ListRowProps } from 'react-virtualized/dist/es/List'

import { apiCreatePagesByServices } from '@/Api/survey'

import { useMst } from '../../../../Stores/rootStore'
import { IDialog } from '../../../../Components/Dialog'
import { Button, ContentLoader, Dialog } from '../../../../Components'
import { apiSaveImage } from '../../../../Api/file'
import PhotoPlaceholder from '../../../../Images/default.jpg'
import { IServiceInstance } from '../../../../Stores/AdminStores/adminServicesStore'
import { SurveyStatus } from '../../../../Stores/AdminStores/surveysStore'

import ServicesFilters, { IServiceFilter } from './ServicesFilters'
import ServiceListItem from './ServiceListItem'
import { Container, FooterContainer, ServicesContainer } from './styled'
import CategoryListItem from './CategoryListItem'

interface IServicesTab {
  surveyId: string
  readonly: boolean
}

const ServicesTab = ({ surveyId, readonly }: IServicesTab) => {
  const [filters, setFilters] = useState<IServiceFilter>({ searchString: '', hasPage: undefined })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const store = useMst()
  const servicesStore = store.admin.adminServicesStore
  const categories = servicesStore.categoriesWithFilters(surveyId, filters.searchString, filters.hasPage)
  const elements = servicesStore.elementsWithFilters(surveyId, filters.searchString, filters.hasPage)
  const [selectedServices, setSelectedServices] = useState<IServiceInstance[]>([])
  const survey = useMst().admin.surveysStore.surveys.find(x => x.surveyId === surveyId)

  useMount(() => {
    (async () => {
      await servicesStore.loadServices(surveyId)
      setIsLoading(false)
      setDialogState(undefined)
    })()
  })

  const handleFill = async () => {
    setIsLoading(true)
    const defaultImage = PhotoPlaceholder
    const toDataURL = async (url: string) => await fetch(url)
      .then(async response => await response.blob())
      .then(async blob => await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      }))
    let img = (await toDataURL(defaultImage) as string)
    img = (img ?? '').replace(/^data:image\/(png|jpg|jpeg);base64,|^data:image\/(png|jpg|jpeg); charset=utf-8;base64,/, '')
    const imageId = await apiSaveImage(img)
    const selectedServicesWithoutPage = selectedServices.filter(x => !x.hasPage)
    if (selectedServicesWithoutPage.length > 0) {
      await apiCreatePagesByServices(surveyId, imageId, selectedServicesWithoutPage.map(x => x.id))
      await servicesStore.loadServices(surveyId)
    }
    setSelectedServices([])
    setIsLoading(false)
  }

  const handleClear = async () => {
    setIsLoading(true)
    const selectedServicesWithPage = selectedServices.filter(x => x.hasPage)
    if (selectedServicesWithPage.length > 0) {
      await servicesStore.deleteServices(surveyId, selectedServicesWithPage.map(x => x.id))
      await servicesStore.loadServices(surveyId)
    }
    setSelectedServices([])
    setIsLoading(false)
  }

  const handleGroupCheck = (categoryId: string) => {
    const servicesByOneGroup = categories.find(y => y.id === categoryId)?.services
    if (servicesByOneGroup?.every(obj => selectedServices.includes(obj))) servicesByOneGroup?.map(x => setSelectedServices(prev => prev.filter(y => y !== x)))
    else servicesByOneGroup?.map(x => setSelectedServices(prev => [...prev, x]))
  }

  const handleChange = async (service: IServiceInstance) => {
    setSelectedServices(prev => (prev.includes(service) ? prev.filter(x => x !== service) : [...prev, service]))
  }

  const isDeterminate = (serviceId: string) => {
    const services = categories.find(y => y.id === serviceId)?.services
    return (services?.some(obj => selectedServices.includes(obj)) && !services?.every(obj => selectedServices.includes(obj))) ?? false
  }

  const isChecked = (categoryId: string) => {
    return categories.find(y => y.id === categoryId)?.services.every(obj => selectedServices.includes(obj)) ?? false
  }

  const servicesCountByCategory = (categoryId: string) => {
    return categories.find(x => x.id === categoryId)?.services.length ?? 0
  }

  const servicesWithPageCountByCategory = (categoryId: string) => {
    return categories.find(x => x.id === categoryId)?.services.filter(x => x.hasPage).length ?? 0
  }

  const rowRenderer = ({ key, index, style }: ListRowProps) => {
    const element = elements[index]
    // ICategory
    // IServiceInstance
    if ((element as IServiceInstance).surveyId) {
      return <ServiceListItem
        key={key}
        style={style}
        service={element as IServiceInstance}
        setDialogState={setDialogState}
        isChecked={selectedServices.includes(element as IServiceInstance)}
        surveyType={survey?.type}
        handleCheckChange={handleChange}
        disabled={!survey || readonly}
      />
    }
    return <CategoryListItem
      key={key}
      style={style}
      surveyType={survey?.type}
      checked={isChecked(element.id)}
      determinate={isDeterminate(element.id)}
      name={element.name}
      id={element.id}
      servicesCount={servicesCountByCategory(element.id)}
      servicesCountWithPage={servicesWithPageCountByCategory(element.id)}
      handleGroupCheck={handleGroupCheck}
      disabled={!survey || readonly}
    />
  }

  if (isLoading) {
    return (<Container>
      <ContentLoader/>
    </Container>)
  }
  const width = (document.getElementById('survey_info_container')?.clientWidth ?? 600) - 100
  const height = (document.getElementById('survey_info_container')?.clientHeight ?? 800) - 278
  return (<Container>
    <ServicesFilters
      surveyId={surveyId}
      filters={filters}
      setFilters={setFilters}
      setDialogState={setDialogState}
      setIsLoading={setIsLoading}
      disabled={!survey || readonly}
    />
    <ServicesContainer>
      <VirtyalizedList
        width={width}
        height={height}
        rowCount={elements.length}
        rowHeight={50}
        rowRenderer={rowRenderer}
      />
    </ServicesContainer>
    {selectedServices.length > 0 && <FooterContainer>
      <Button disabled={selectedServices.some(x => x.hasPage)} variant="contained" color="primary" onClick={handleFill}>Заполнить</Button>
      <Button disabled={selectedServices.some(x => !x.hasPage)} variant="contained" color="primary" onClick={handleClear}>Очистить</Button>
    </FooterContainer>}
    {!isLoading && dialogState && <Dialog {...dialogState} />}
  </Container>)
}

export default observer(ServicesTab)
