import { observer } from 'mobx-react'
import { v4 } from 'uuid'

import Answer from '@/Pages/Admin/EditPage/Components/Answer'
import { AnswerContainer } from '@/Pages/Admin/EditPage/Components/Question/styled'
import DndSortableList from '@/Components/DragAndDrop/DndSortableList'
import { QuestionType } from '@/Models/page'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'
import TextLink from '@/Components/TextLink'

interface ISelectAnswerContainer {
  question: IQuestionInstance
  disabled: boolean
}

const SelectAnswerContainer = ({ question, disabled }: ISelectAnswerContainer) => {
  return (<AnswerContainer>
    <DndSortableList
      data={question.orderedOptions}
      renderItem={(item, index, dragHandleProps) => <Answer
        option={item}
        onDelete={() => question.deleteOption(item)}
        withImage={CheckEnumIs(question.type, QuestionType.checkboxWithImage, QuestionType.radioWithImage)}
        dragHandleProps={dragHandleProps}
        disabled={disabled}
      />}
      onDragEnd={question.moveOption}
      dndIdBuilder={answer => answer.dndId}
    />
    {!disabled && <TextLink
      text='Добавить ответ'
      color="#266DC2"
      style={{ marginTop: '17px', lineHeight: '18px' }}
      onClick={() => question.appendOptions([
        {
          value: '',
          imageId: null,
          title: '',
          isCustom: false,
          order: question.options.length,
          dndId: v4(),
        },
      ])}
    />}
  </AnswerContainer>)
}

export default observer(SelectAnswerContainer)
