import { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, ArcElement } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Typography, Skeleton } from '@mui/material'

import { apiGetCompletionStatisticCounters } from '@/Api/analytics'
import { apiGetUsersCount } from '@/Api/user'
import bem from '@/Helpers/BemClass'

import './style.scss'

Chart.register(ChartDataLabels, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Tooltip, Legend)

const cnCompletionStatistic = bem()('completion-statistic')

interface ICompletionStatistic {
  surveyId: string
}

const CompletionStatistic = ({ surveyId }: ICompletionStatistic) => {
  const [isLoading, setIsLoading] = useState(true)
  const [completion, setCompletion] = useState(0)
  const [started, setStarted] = useState(0)
  const [notStarted, setNotStarted] = useState(0)
  const [targetRestondentsCount, setTargetRestondentsCount] = useState(0)

  useEffect(() => {
    (async () => {
      const statistic = await Promise.all([apiGetCompletionStatisticCounters(surveyId), apiGetUsersCount(surveyId)])
      setCompletion(statistic[0].completed)
      setStarted(statistic[0].started)
      setNotStarted(statistic[0].notStarted)
      setTargetRestondentsCount(statistic[1])

      setIsLoading(false)
    })()
  }, [surveyId])

  const getChart = (hasData: boolean) => {
    return !hasData ? <Typography className={cnCompletionStatistic('fake-text')}>Ожидается статистика по прохождению</Typography> : <Pie
      data={{
        labels: ['Завершили', 'Начали', 'Не начали'],
        datasets: [
          {
            label: 'Прохождение опроса',
            data: [completion, started, notStarted],
            backgroundColor: ['#004C97', '#7DBDFF', '#D8E8F8'],
            borderWidth: 1,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: { display: true, position: 'right' },
          datalabels: {
            display: false,
          },
        },
        layout: {
          padding: 10,
        },
      }}
    />
  }

  return (
    <div className={cnCompletionStatistic()}>
      {isLoading ? <Skeleton
        variant='rectangular'
        className={cnCompletionStatistic('chart-title-skeleton')}
      /> : <Typography
        className={cnCompletionStatistic('chart-title')}
        whiteSpace='nowrap'
      >
        Завершенные основные прохождения
      </Typography>}
      {isLoading ? <Skeleton
        variant='rectangular'
        className={cnCompletionStatistic('chart-container-skeleton')}
      /> : <div
        className={cnCompletionStatistic('chart-container')}
      >
        {getChart(completion !== 0 || started !== 0 || notStarted !== 0)}
      </div>}
      <div className={cnCompletionStatistic('total-counter-section')}>
        {isLoading ? <Skeleton
          variant='rectangular'
          className={cnCompletionStatistic('total-counter-skeleton')}
          height='24px'
          width='240px'
        /> : <Typography
          className={cnCompletionStatistic('total-counter')}
          variant='caption'
          display='block'
        >
          {`Всего завершили прохождение: ${completion}`}
        </Typography>}
        {isLoading ? <Skeleton
          variant='rectangular'
          className={cnCompletionStatistic('total-counter-skeleton')}
          height='24px'
          width='180px'
        /> : <Typography
          className={cnCompletionStatistic('total-counter')}
          variant='caption'
          display='block'
        >
          {`Всего начали проходить: ${started}`}
        </Typography>}
        {isLoading ? <Skeleton
          variant='rectangular'
          className={cnCompletionStatistic('total-counter-skeleton')}
          height='24px'
          width='200px'
        /> : <Typography
          className={cnCompletionStatistic('total-counter')}
          variant='caption'
          display='block'
        >
          {`Всего не начали проходить: ${notStarted}`}
        </Typography>}
        {isLoading ? <Skeleton
          variant='rectangular'
          className={cnCompletionStatistic('total-counter-skeleton')}
          height='24px'
          width='240px'
        /> : <Typography
          className={cnCompletionStatistic('total-counter')}
          variant='caption'
          display='block'
        >
          {`Количество респондентов в опросе: ${targetRestondentsCount}`}
        </Typography>}
        {isLoading ? <Skeleton
          variant='rectangular'
          className={cnCompletionStatistic('total-counter-skeleton')}
          height='24px'
          width='240px'
        /> : <Typography
          className={cnCompletionStatistic('total-counter')}
          variant='caption'
          display='block'
        >
          {`% прохождения: ${targetRestondentsCount === 0 ? '0' : Math.floor(100 / targetRestondentsCount * completion)}%`}
        </Typography>}
      </div>
    </div>)
}

export default CompletionStatistic
