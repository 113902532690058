import styled from 'styled-components'

export const CountersContainer = styled.div`
  display: contents;
  &>*:nth-child(1){
    margin-left: 44px;
  }
`

export const QuestionFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const QuestionRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const QuestionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
