import { flow, getParentOfType, Instance, types as t } from 'mobx-state-tree'
import { orderBy, max } from 'lodash'

import { apiGetOrganizationStructure } from '@/Api/organizationStructure'
import { apiGetAllPagesTargetAudienceMappingForSurvey, apiGetSurveyTargetAudiences } from '@/Api/targetAudience'
import { IOrganizationStructureModel } from '@/Models/organizationStructure'
import { TargetAudiencesMappingType } from '@/Stores/AdminStores/surveysStore'
import { ITargetAudiencesOrganizationStructureElementId } from '@/Models/targetAudience'

export interface IOrganizationStructureElement extends Instance<typeof OrganizationStructureElement> {}

export interface IOrganizationStructureFilter extends Instance<typeof OrganizationStructureFilter> {}

export interface IOrganizationStructureState extends Instance<typeof OrganizationStructureState> {}

export interface IOrganizationStructureFreeElement extends Instance<typeof OrganizationStructureFreeElement> {}

export const OrganizationStructureElement = t.model({
  id: t.string,
  foreignId: t.string,
  name: t.string,
  isUser: t.boolean,
  childElementsId: t.array(t.string),
  parentElementsId: t.array(t.string),
  nestingLevel: t.number,
  pageId: t.maybeNull(t.string),
  // В выбранной верке верхний элемент isSelected и isChecked, остальные элементы этой ветки только isChecked
  // Это обусловлено логикой работы - ца назначается на верхний элемент в выбранной ветке (по этому он isChecked и isSelected),
  // все остальные попадают под действие каскадно (по этому isChecked), но по факту для них не сохранятся отдельный выбор (по этому не isSelected)
  isSelected: t.boolean,
  // Логика работы с элементами орг.структуры предполагает возможность накликать, но не подтверждать выбранные элементы
  isConfirmed: t.boolean,
  isExpanded: t.boolean,
}).views(self => ({
  get isCascadeSelected(): boolean {
    const parentStore = getParentOfType(self, OrganizationStructureState)
    return self.isSelected || self.parentElementsId.some(x => parentStore.organizationStructureElements.get(x)?.isCascadeSelected ?? false)
  },
  get isCascadeConfirmed(): boolean {
    const parentStore = getParentOfType(self, OrganizationStructureState)
    return self.isConfirmed || self.parentElementsId.some(x => parentStore.organizationStructureElements.get(x)?.isCascadeConfirmed ?? false)
  },
  get hasSelectedChild(): boolean {
    const parentStore = getParentOfType(self, OrganizationStructureState)

    return self.childElementsId.some(x => {
      const child = parentStore.organizationStructureElements.get(x)
      return !child ? false : child.hasSelectedChild || child.isSelected
    })
  },
  get isExpandedAndVisible(): boolean {
    const parentStore = getParentOfType(self, OrganizationStructureState)

    // Если элемент развернут или он пользователь или без дочерних элементов - значит он развернут
    // Если он корневой или один из родителей развернут и виден - значит элемент виден
    // Если и то и то верно, значит элемент развернут и при этом виден
    // Эта логика нужна, т.к. можно развернуть элемент, при этом свернув его родителя, в таком случае элемент будет считаться все еще развернутым, но при этом мы этого не видим
    return (self.isExpanded || self.isUser || self.childElementsId.length === 0) &&
      (self.parentElementsId.length === 0 || self.parentElementsId.some(x => parentStore.getElement(x).isExpandedAndVisible))
  },
})).actions(self => ({
  changeSelectState: (isSelected: boolean) => {
    self.isSelected = isSelected
  },
  changeConfirmState: (isConfirmed: boolean) => {
    self.isConfirmed = isConfirmed
  },
  changeExpandedState: (isExpanded: boolean) => {
    self.isExpanded = isExpanded
  },
  // Изменит состояние элемента удалив выбор с родительских и дочерних элементов и изменив свое состояние на противоположное текущему
  // В случае если какой-то из родителей будет выбран на основании всех выбранных дочерних элементов, выбор текущего элемента может быть отменен
  changeSelectStateWithCascadeEffect: () => {
    const parentStore = getParentOfType(self, OrganizationStructureState)

    if (self.isSelected) {
      self.isSelected = false
    } else {
      // Меняем выбор на противоположное значение исходя из состояния отмеченности
      self.isSelected = !self.isCascadeSelected

      // Если мы выбираем текущий элемент как выбранный + отмеченный, то все дочерние элементы становятся не выбранными, но отмеченными, а родительские элементы не выбранными
      // Так же если мы убираем текущий как отмеченный (если был отмечен ранее), то все дочерние элементы становятся не выбранными, но отмеченными, а родительские элементы не выбранными
      self.childElementsId.forEach(x => parentStore.getElement(x).deselectChildCascade())
      self.parentElementsId.forEach(x => parentStore.getElement(x).updateOrDeselectParentsSelectionCascade(self.id))
    }
  },
  changeConfirmStateWithCascadeEffect: () => {
    const parentStore = getParentOfType(self, OrganizationStructureState)

    if (self.isConfirmed) {
      self.isConfirmed = false
    } else {
      // Меняем выбор на противоположное значение исходя из состояния отмеченности
      self.isConfirmed = !self.isCascadeConfirmed

      // Если мы выбираем текущий элемент как выбранный + отмеченный, то все дочерние элементы становятся не выбранными, но отмеченными, а родительские элементы не выбранными
      // Так же если мы убираем текущий как отмеченный (если был отмечен ранее), то все дочерние элементы становятся не выбранными, но отмеченными, а родительские элементы не выбранными
      self.childElementsId.forEach(x => parentStore.getElement(x).deconfirmChildCascade())
      self.parentElementsId.forEach(x => parentStore.getElement(x).updateOrDeselectParentsConfirmationCascade(self.id))
    }
  },
  // Каскадно удалит выбор всех родительских элементов или выберет элемент если все дочерние элементы выбраны
  updateOrDeselectParentsSelectionCascade: (changeSurceElementId: string) => {
    const parentStore = getParentOfType(self, OrganizationStructureState)

    // Если родитель был до этого выбран, то при отмене дочернего элемента, все дочерние элементы с которых не снимается выделение должны быть выбраны перед деселектом родителя
    if (self.isCascadeSelected) {
      self.childElementsId.filter(x => x !== changeSurceElementId).forEach(x => parentStore.getElement(x).changeSelectState(true))

      // Каскадно убираем выбор или обновляем всех родительских элементов
      self.parentElementsId.forEach(x => parentStore.getElement(x).updateOrDeselectParentsSelectionCascade(self.id))

      self.isSelected = false
    } else if (self.childElementsId.every(x => parentStore.getElement(x).isSelected)) {
      // Если все дочерние элементы выбраны, то отменяем выбор дочерних элементов и выбираем только текущий родительский
      self.childElementsId.forEach(x => parentStore.getElement(x).changeSelectState(false))
      self.isSelected = true

      // Каскадно убираем выбор или обновляем всех родительских элементов
      self.parentElementsId.forEach(x => parentStore.getElement(x).updateOrDeselectParentsSelectionCascade(self.id))
    }

    // Если элемент не выбран, и не все дочерние элементы выбраны, то обновлять нечего
  },
  updateOrDeselectParentsConfirmationCascade: (changeSurceElementId: string) => {
    const parentStore = getParentOfType(self, OrganizationStructureState)

    if (self.isCascadeConfirmed) {
      self.childElementsId.filter(x => x !== changeSurceElementId).forEach(x => parentStore.getElement(x).changeConfirmState(true))

      self.parentElementsId.forEach(x => parentStore.getElement(x).updateOrDeselectParentsConfirmationCascade(self.id))

      self.isConfirmed = false
    } else if (self.childElementsId.every(x => parentStore.getElement(x).isConfirmed)) {
      self.childElementsId.forEach(x => parentStore.getElement(x).changeConfirmState(false))
      self.isConfirmed = true

      self.parentElementsId.forEach(x => parentStore.getElement(x).updateOrDeselectParentsConfirmationCascade(self.id))
    }
  },
  deselectChildCascade: () => {
    self.isSelected = false

    const parentStore = getParentOfType(self, OrganizationStructureState)

    // Каскадно убираем выбор у всех дочерних элементов
    self.childElementsId.forEach(x => parentStore.getElement(x).deselectChildCascade())
  },
  deconfirmChildCascade: () => {
    self.isConfirmed = false

    const parentStore = getParentOfType(self, OrganizationStructureState)

    // Каскадно убираем выбор у всех дочерних элементов
    self.childElementsId.forEach(x => parentStore.getElement(x).deconfirmChildCascade())
  },
}))

export enum ElementsTypeFilter {
  All = '0',
  User = '1',
  NotUset = '2',
}

export const OrganizationStructureFilter = t.model({
  id: t.number,
  searchText: t.string,
  isSelectedOnly: t.boolean,
  isConfirmedOnly: t.boolean,
  elementsTypeFilter: t.enumeration<ElementsTypeFilter>(Object.values(ElementsTypeFilter)),
}).views(self => ({
  get filteredOrganizationStructureElements(): string[] {
    const parentStore = getParentOfType(self, OrganizationStructureState)

    return orderBy(
      Array.from(parentStore.organizationStructureElements.values() ?? [])
        .filter(x => (!self.isSelectedOnly || (self.searchText ? x.isCascadeSelected : x.isSelected)) &&
            (!self.isConfirmedOnly || x.isConfirmed) &&
            // (!self.isSelectedOnly || x.isSelected) &&
            (self.elementsTypeFilter === ElementsTypeFilter.All || (self.elementsTypeFilter === ElementsTypeFilter.User && x.isUser) || (self.elementsTypeFilter === ElementsTypeFilter.NotUset && !x.isUser)) &&
            (!self.searchText || x.name.toLowerCase().includes(self.searchText.toLowerCase()))
        ),
      ['isUser', 'name'],
      ['desc', 'asc']
    ).map(x => x.id)
  },
  get filteredOrganizationStructureFreeElements(): string[] {
    const parentStore = getParentOfType(self, OrganizationStructureState)

    return orderBy(
      Array.from(parentStore.organizationStructureFreeElements.values() ?? [])
        .filter(x => (self.elementsTypeFilter === ElementsTypeFilter.All || (self.elementsTypeFilter === ElementsTypeFilter.User && x.isUser) || (self.elementsTypeFilter === ElementsTypeFilter.NotUset && !x.isUser)) &&
            (!self.searchText || x.name.toLowerCase().includes(self.searchText.toLowerCase()))
        ),
      ['isUser', 'name'],
      ['desc', 'asc']
    ).map(x => x.foreignId)
  },
  get hasAnyFilters(): boolean {
    return (self.searchText.length > 0 || self.isSelectedOnly || self.isConfirmedOnly || self.elementsTypeFilter !== ElementsTypeFilter.All)
  },
})).actions(self => ({
  updateSearchText: (searchText: string) => {
    self.searchText = searchText
  },
  updateIsSelectedOnly: (isSelectedOnly: boolean) => {
    self.isSelectedOnly = isSelectedOnly
  },
  updateIsConfirmedOnly: (isConfirmedOnly: boolean) => {
    self.isConfirmedOnly = isConfirmedOnly
  },
  updateElementsTypeFilter: (elementsTypeFilter: ElementsTypeFilter) => {
    self.elementsTypeFilter = elementsTypeFilter
  },
  changeSelectionForFiltered: (isSelected: boolean) => {
    const organizationStructureState = getParentOfType(self, OrganizationStructureState)

    self.filteredOrganizationStructureElements.forEach(x => {
      organizationStructureState.organizationStructureElements.get(x)?.changeSelectState(isSelected)
    })
  },
  changeConfirmationForFiltered: (isConfirmed: boolean) => {
    const organizationStructureState = getParentOfType(self, OrganizationStructureState)

    self.filteredOrganizationStructureElements.forEach(x => {
      organizationStructureState.organizationStructureElements.get(x)?.changeConfirmState(isConfirmed)
    })
  },
  removeFilteredFreeElements: () => {
    const organizationStructureState = getParentOfType(self, OrganizationStructureState)

    self.filteredOrganizationStructureFreeElements.forEach(x => organizationStructureState.removeFreeElement(x))
  },
}))

export const OrganizationStructureFreeElement = t.model({
  foreignId: t.string,
  name: t.string,
  isUser: t.boolean,
})

export enum OrganizationStructureStateStage {
  Empty = '0',
  Draft = '1',
  Filled = '2',
}

export const OrganizationStructureState = t.model({
  selector: t.string,
  organizationStructureElements: t.map(OrganizationStructureElement),
  organizationStructureFreeElements: t.map(OrganizationStructureFreeElement),
  rootElement: t.maybeNull(t.string),
  isDraft: t.boolean,
  filters: t.array(OrganizationStructureFilter),
  stage: t.enumeration<OrganizationStructureStateStage>(Object.values(OrganizationStructureStateStage)),
}).views(self => ({
  get nestingLevelsCount(): number {
    return max(Array.from(self.organizationStructureElements.values() ?? []).filter(x => !x.isUser).map(x => x.nestingLevel)) ?? 5
  },
  get maxLevelFullOpen(): number {
    let minNotSelectedLevel: number | null = null

    Array.from(self.organizationStructureElements.values() ?? [])
      .forEach(x => {
        if (!x.isUser && x.childElementsId.length > 0 && !x.isExpandedAndVisible && (minNotSelectedLevel === null || minNotSelectedLevel >= x.nestingLevel)) {
          minNotSelectedLevel = x.nestingLevel - 1
        }
      })

    return minNotSelectedLevel ?? max(Array.from(self.organizationStructureElements.values() ?? []).filter(x => !x.isUser).map(x => x.nestingLevel)) ?? 5
  },
  get maxLevelAnyOpen(): number | null {
    let maxSelectedLevel: number | null = null

    Array.from(self.organizationStructureElements.values() ?? [])
      .forEach(x => {
        if (!x.isUser && x.isExpandedAndVisible && (!maxSelectedLevel || maxSelectedLevel < x.nestingLevel)) {
          maxSelectedLevel = x.nestingLevel
        }
      })

    return maxSelectedLevel
  },
  get allExpanded(): string[] {
    return Array.from(self.organizationStructureElements.values() ?? []).filter(x => x.isExpanded).map(x => x.id)
  },
  get isAllSelectedConfirmed(): boolean {
    return !Array.from(self.organizationStructureElements.values() ?? []).some(x => x.isSelected !== x.isConfirmed)
  },
  get hasAnySelected(): boolean {
    return Array.from(self.organizationStructureElements.values() ?? []).some(x => x.isSelected)
  },
  get hasAnyConfirmed(): boolean {
    return Array.from(self.organizationStructureElements.values() ?? []).some(x => x.isConfirmed)
  },
  get freeElementsCount(): number {
    return Array.from(self.organizationStructureFreeElements.values() ?? []).length
  },
})).actions(self => ({
  changeIsDraftState: (isDraft: boolean) => {
    self.isDraft = isDraft
  },
  addFilter: (searchText: string, isSelectedOnly: boolean, isConfirmedOnly: boolean, elementsTypeFilter: ElementsTypeFilter): IOrganizationStructureFilter => {
    self.filters.push({
      id: self.filters.length,
      searchText: searchText,
      isSelectedOnly: isSelectedOnly,
      isConfirmedOnly: isConfirmedOnly,
      elementsTypeFilter: elementsTypeFilter,
    })

    const filter = self.filters.at(self.filters.length - 1)

    if (!filter) {
      throw new Error('Не найден только что созданный фильтр')
    }

    return filter
  },
  removeFilter: (filter: IOrganizationStructureFilter) => {
    self.filters.remove(filter)
  },
  getElement: (elementId: string): IOrganizationStructureElement => {
    const element = self.organizationStructureElements.get(elementId)

    if (!element) {
      throw Error('Ошибка запроса элемента, элемент не найден')
    }

    return element
  },
  getFreeElement: (elementId: string): IOrganizationStructureFreeElement => {
    const element = self.organizationStructureFreeElements.get(elementId)

    if (!element) {
      throw Error('Ошибка запроса элемента, свободный элемент не найден')
    }

    return element
  },
  removeFreeElement: (elementId: string) => {
    self.organizationStructureFreeElements.delete(elementId)
  },
  changeExpandedState: (elementIds: string[]) => {
    Array.from(self.organizationStructureElements.values() ?? []).forEach(x => x.changeExpandedState(elementIds.includes(x.id)))

    const expandedElements = Array.from(self.organizationStructureElements.values() ?? []).filter(x => x.isExpanded).map(x => x.id)

    localStorage.setItem(self.selector, JSON.stringify(expandedElements))
  },
  syncConfirmationWithIsSelected: () => {
    Array.from(self.organizationStructureElements.values() ?? []).forEach((x) => {
      if (x.isSelected) {
        x.changeConfirmState(true)
      } else {
        x.changeConfirmState(false)
      }
    })
  },
  changeConfirmationAt: (isConfirmed: boolean, elementId: string) => {
    self.organizationStructureElements.get(elementId)?.changeConfirmState(isConfirmed)
  },
  expandOrCollapse: (nestingLevel: number) => {
    const needCollapse = Array.from(self.organizationStructureElements.values() ?? [])
      .filter(x => x.nestingLevel === nestingLevel)
      .every(x => x.isExpandedAndVisible)

    if (needCollapse) {
      Array.from(self.organizationStructureElements.values() ?? []).forEach(x => {
        if (x.nestingLevel >= nestingLevel) {
          x.changeExpandedState(false)
        }
      })
    } else {
      Array.from(self.organizationStructureElements.values() ?? []).forEach(x => {
        if (x.nestingLevel <= nestingLevel) {
          x.changeExpandedState(true)
        }
      })
    }

    const expandedElements = Array.from(self.organizationStructureElements.values() ?? []).filter(x => x.isExpanded).map(x => x.id)

    localStorage.setItem(self.selector, JSON.stringify(expandedElements))
  },
  getConfirmedElementsName: (): string[] => {
    return Array.from(self.organizationStructureElements.values() ?? []).filter(x => x.isConfirmed).map(x => x.name)
  },
  getAllConfirmedElements: (): string[] => {
    return Array.from(self.organizationStructureElements.values() ?? []).filter(x => x.isConfirmed).map(x => x.id)
  },
  getAllFreeElements: (): IOrganizationStructureFreeElement[] => {
    return Array.from(self.organizationStructureFreeElements.values() ?? [])
  },
  selectElements: (elements: IOrganizationStructureFreeElement[]): IOrganizationStructureFreeElement[] => {
    const notFoundElements: IOrganizationStructureFreeElement[] = []

    const orgStructureElements = Array.from(self.organizationStructureElements.values() ?? [])

    elements.forEach(el => {
      const element = orgStructureElements.find(x => x.foreignId === el.foreignId)

      if (element) {
        // Только если элемент еще не выбран
        if (!element.isCascadeSelected) {
          element.changeSelectStateWithCascadeEffect()
        }

        // Только если элемент еще не подтвержден
        if (!element.isCascadeConfirmed) {
          element.changeConfirmStateWithCascadeEffect()
        }
      } else {
        notFoundElements.push(el)
      }
    })

    notFoundElements.forEach(x => {
      self.organizationStructureFreeElements.set(x.foreignId, x)
    })

    return notFoundElements
  },
  updateStage: (): OrganizationStructureStateStage => {
    if (self.isDraft) {
      self.stage = OrganizationStructureStateStage.Draft
    } else if (self.hasAnyConfirmed) {
      self.stage = OrganizationStructureStateStage.Filled
    } else {
      self.stage = OrganizationStructureStateStage.Empty
    }

    return self.stage
  },
}))

export const OrganizationStructureStore = t.model({
  // Первый map - карта по страницам, второй map - карта по элементам, если ца по опросу, верхний map содержит 1 элемент с ключем ""
  organizationStructureStates: t.map(OrganizationStructureState),
  rootElement: t.maybeNull(t.string),
}).actions(self => ({
  fill: (selector: string, organizationStructure: IOrganizationStructureModel, selected: string[], isConfirmed?: boolean, isDraft?: boolean) => {
    self.rootElement = null
    self.organizationStructureStates.clear()

    self.rootElement = organizationStructure.rootElementId

    self.organizationStructureStates.set(selector, {
      selector: selector,
      organizationStructureElements: {},
      organizationStructureFreeElements: {},
      rootElement: organizationStructure.rootElementId,
      isDraft: isDraft ?? false,
      filters: [],
      stage: OrganizationStructureStateStage.Empty,
    })

    const state = self.organizationStructureStates.get(selector)

    if (!state) {
      throw new Error('Не удалось получить сохраненное состояние орг.структуры')
    }

    organizationStructure.organizationStructureElements.forEach(x => state.organizationStructureElements.set(x.id, { ...x, pageId: null, isSelected: selected.includes(x.id), isConfirmed: (isConfirmed ?? true) ? selected.includes(x.id) : false, isExpanded: false }))

    state.updateStage()

    const expandingStoredState = localStorage.getItem(selector)

    if (expandingStoredState) {
      const expandingElements = JSON.parse(expandingStoredState) as string[]
      state.changeExpandedState(expandingElements)
    } else {
      state.expandOrCollapse(1)
    }
  },
  loadWithSelected: flow(function * load(selector: string, selected: string[], isConfirmed?: boolean, isDraft?: boolean) {
    self.rootElement = null
    self.organizationStructureStates.clear()

    const organizationStructure: IOrganizationStructureModel = yield apiGetOrganizationStructure()
    self.rootElement = organizationStructure.rootElementId

    self.organizationStructureStates.set(selector, {
      selector: selector,
      organizationStructureElements: {},
      organizationStructureFreeElements: {},
      rootElement: organizationStructure.rootElementId,
      isDraft: isDraft ?? false,
      filters: [],
      stage: OrganizationStructureStateStage.Empty,
    })

    const state = self.organizationStructureStates.get(selector)

    if (!state) {
      throw new Error('Не удалось получить сохраненное состояние орг.структуры')
    }

    organizationStructure.organizationStructureElements.forEach(x => state.organizationStructureElements.set(x.id, { ...x, pageId: null, isSelected: selected.includes(x.id), isConfirmed: (isConfirmed ?? true) ? selected.includes(x.id) : false, isExpanded: false }))

    state.updateStage()

    const expandingStoredState = localStorage.getItem(selector)

    if (expandingStoredState) {
      const expandingElements = JSON.parse(expandingStoredState) as string[]
      state.changeExpandedState(expandingElements)
    } else {
      state.expandOrCollapse(1)
    }
  }),
  loadTargetAudience: flow(function * load(surveyId: string, mappingType: TargetAudiencesMappingType, pages?: string[]) {
    self.rootElement = null
    self.organizationStructureStates.clear()

    const organizationStructure: IOrganizationStructureModel = yield apiGetOrganizationStructure()
    self.rootElement = organizationStructure.rootElementId

    switch (mappingType) {
      case TargetAudiencesMappingType.ByPageTargetAudience: {
        if (pages === undefined) {
          throw new Error('Не переданы страницы для маппинга ца по страницам')
        }

        const targetAudiencesMap: Map<string, ITargetAudiencesOrganizationStructureElementId> = new Map(Object.entries(yield apiGetAllPagesTargetAudienceMappingForSurvey(surveyId)))

        pages.forEach(x => {
          const pageTargetAudience: ITargetAudiencesOrganizationStructureElementId | undefined = targetAudiencesMap.get(x)


          self.organizationStructureStates.set(x, {
            selector: x,
            organizationStructureElements: {},
            organizationStructureFreeElements: {},
            rootElement: organizationStructure.rootElementId,
            isDraft: pageTargetAudience?.isDraft ?? false,
            filters: [],
            stage: OrganizationStructureStateStage.Empty,
          })

          const orgStructureForPage = self.organizationStructureStates.get(x)

          if (!orgStructureForPage) {
            throw Error('Ошибка запроса орг.структуры страницы из коллекции')
          }

          pageTargetAudience?.organizationStructureFreeElementsId.forEach(x => orgStructureForPage.organizationStructureFreeElements.set(x.foreignId, x))
          organizationStructure.organizationStructureElements.forEach(orgEl => orgStructureForPage.organizationStructureElements.set(orgEl.id, { ...orgEl, pageId: x, isSelected: pageTargetAudience?.organizationStructureElementsId.includes(orgEl.id) ?? false, isConfirmed: pageTargetAudience?.organizationStructureElementsId.includes(orgEl.id) ?? false, isExpanded: false }))

          orgStructureForPage.updateStage()

          const expandingStoredState = localStorage.getItem(x)

          if (expandingStoredState) {
            const expandingElements = JSON.parse(expandingStoredState) as string[]
            orgStructureForPage.changeExpandedState(expandingElements)
          } else {
            orgStructureForPage.expandOrCollapse(1)
          }
        })

        break
      }
      case TargetAudiencesMappingType.BySurveyTargetAudience: {
        const targetAudiences: ITargetAudiencesOrganizationStructureElementId = yield apiGetSurveyTargetAudiences(surveyId)

        self.organizationStructureStates.set(surveyId, {
          selector: surveyId,
          organizationStructureElements: {},
          organizationStructureFreeElements: {},
          rootElement: organizationStructure.rootElementId,
          isDraft: targetAudiences?.isDraft ?? false,
          filters: [],
          stage: OrganizationStructureStateStage.Empty,
        })
        const orgStructureForSurvey = self.organizationStructureStates.get(surveyId)

        if (!orgStructureForSurvey) {
          throw Error('Ошибка запроса орг.структуры из коллекции')
        }

        targetAudiences.organizationStructureFreeElementsId.forEach(x => orgStructureForSurvey.organizationStructureFreeElements.set(x.foreignId, x))
        organizationStructure.organizationStructureElements.forEach(x => orgStructureForSurvey.organizationStructureElements.set(x.id, { ...x, pageId: null, isSelected: targetAudiences.organizationStructureElementsId.includes(x.id), isConfirmed: targetAudiences.organizationStructureElementsId.includes(x.id), isExpanded: false }))

        orgStructureForSurvey.updateStage()

        const expandingStoredState = localStorage.getItem(surveyId)

        if (expandingStoredState) {
          const expandingElements = JSON.parse(expandingStoredState) as string[]
          orgStructureForSurvey.changeExpandedState(expandingElements)
        } else {
          orgStructureForSurvey.expandOrCollapse(1)
        }

        break
      }
      default: throw new Error('Недопустимый тип маппинга аудитории')
    }
  }),
  clear: () => {
    self.rootElement = null
    self.organizationStructureStates.clear()
  },
  getOrganizationStructureState: (organizationStructureStateSelector: string): IOrganizationStructureState | undefined => {
    return self.organizationStructureStates.get(organizationStructureStateSelector)
  },
  getElement: (elementId: string, selector: string): IOrganizationStructureElement => {
    const organizationStructureState = self.organizationStructureStates.get(selector)
    if (!organizationStructureState) {
      throw Error('Ошибка запроса элемента, элемент не найден')
    }

    return organizationStructureState.getElement(elementId)
  },
  addFilter: (searchText: string, isSelectedOnly: boolean, isConfirmedOnly: boolean, elementsTypeFilter: ElementsTypeFilter, selector: string): IOrganizationStructureFilter => {
    const organizationStructureState = self.organizationStructureStates.get(selector)

    if (!organizationStructureState) {
      throw Error('Ошибка добавления фильтра, состояние орг.структуры не найдено')
    }

    return organizationStructureState.addFilter(searchText, isSelectedOnly, isConfirmedOnly, elementsTypeFilter)
  },
  removeFilter: (filter: IOrganizationStructureFilter, selector: string) => {
    self.organizationStructureStates.get(selector)?.filters.remove(filter)
  },
  deselectAll: (selector: string) => {
    Array.from(self.organizationStructureStates.get(selector)?.organizationStructureElements.values() ?? []).forEach(x => { x.isSelected = false })
  },
  getAllSelectedElements: (selector: string): string[] => {
    return Array.from(self.organizationStructureStates.get(selector)?.organizationStructureElements.values() ?? []).filter(x => x.isSelected).map(x => x.id)
  },
  getSelectedElementsName: (selector: string): string[] => {
    return Array.from(self.organizationStructureStates.get(selector)?.organizationStructureElements.values() ?? []).filter(x => x.isSelected).map(x => x.name)
  },
  getAllOrganizationStructureElements: (selector: string): IOrganizationStructureElement[] => {
    return Array.from(self.organizationStructureStates.get(selector)?.organizationStructureElements.values() ?? [])
  },
  hasAnySelectedElements: (selector: string): boolean => {
    return Array.from(self.organizationStructureStates.get(selector)?.organizationStructureElements.values() ?? []).some(x => x.isCascadeSelected)
  },
  changeConfirmationWhereSelected: (isConfirmed: boolean, selector: string) => {
    Array.from(self.organizationStructureStates.get(selector)?.organizationStructureElements.values() ?? []).forEach((x) => {
      if (x.isCascadeSelected) {
        x.isConfirmed = isConfirmed
      }
    })
  },
}))
