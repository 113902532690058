import BackendAdapter from '@/Api/_adapter'
import { FileType } from '@/Enums/fileType'
import { ISpreadsheetCellsData, ISpreadsheetSummaryData } from '@/Models/spreadsheet'

export const apiParseSpreadsheetFile = async (model: FormData, fileType: FileType): Promise<ISpreadsheetCellsData> => {
  try {
    return await BackendAdapter.post('/api/questions/spreadsheet/parse', model, {
      params: {
        type: fileType,
      },
    })
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiParseSpreadsheetByFileId = async (fileId: string): Promise<ISpreadsheetCellsData> => {
  try {
    return await BackendAdapter.get('/api/questions/spreadsheet/parse', {
      params: {
        fileId,
      },
    })
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiParseSpreadsheetByquestionId = async (questionId: string): Promise<ISpreadsheetCellsData> => {
  try {
    return await BackendAdapter.get(`/api/questions/${questionId}/spreadsheet/parse`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetSpreadsheetSummary = async (questionId: string): Promise<ISpreadsheetSummaryData> => {
  try {
    return await BackendAdapter.get(`/api/questions/${questionId}/spreadsheet/summary/answers`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
