import { Children, ReactNode } from 'react'

import { applicationConfig, FrontendFeature } from '@/applicationConfig'

interface IFeature {
  features: FrontendFeature[] | FrontendFeature
  children: ReactNode
}

// TODO: добавить реализацию для коллекции фич
export const isFeatureEnabled = (feature: FrontendFeature) => {
  return applicationConfig?.frontendFeatures.includes(feature)
}

const Feature = ({ features, children }: IFeature) => {
  const ch = Children.only(children)

  if ((Array.isArray(features))) {
    if (features.every(feature => isFeatureEnabled(feature))) {
      return <>{ch}</>
    } else {
      return <></>
    }
  } else if (isFeatureEnabled(features)) {
    return <>{ch}</>
  }

  return <></>
}

export default Feature
