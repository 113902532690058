import { applySnapshot, castToSnapshot, flow, getParent, Instance, SnapshotIn, types as t } from 'mobx-state-tree'
import { arrayMoveImmutable } from 'array-move'
import _ from 'lodash'

import { Condition, Overlap } from '@/Models/rule'
import { apiGetRules } from '@/Api/rule'
import { getAdminRootStore } from '@/Stores/rootStore'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import { LogicOption } from '@/Stores/sharedModels/models'

export interface IPageFilterInstance extends Instance<typeof PageFilter> {
}

export interface IPageFilterSnapshotIn extends SnapshotIn<typeof PageFilter> {
}

export interface IRuleInstance extends Instance<typeof Rule> {
}

export interface IRuleSnapshotIn extends SnapshotIn<typeof Rule> {
}

export interface IBaseLogicItemInstance extends Instance<typeof BaseLogicItem> {
}

export interface IQuestionFilterInstance extends Instance<typeof QuestionFilter> {
}

export interface IQuestionFilterSnapshotIn extends SnapshotIn<typeof QuestionFilter> {
}

const BaseLogicItem = t.model({
  condition: t.maybe(t.enumeration<Condition>(Object.values(Condition))),
  overlap: t.maybe(t.enumeration<Overlap>(Object.values(Overlap))),
  isQuestionSkip: t.boolean,
  answers: t.optional(t.array(LogicOption), []),
  isDeprecated: t.optional(t.boolean, false),
  /** Данные необходимые для управления UI **/
  isExpanded: t.optional(t.boolean, false),
  errors: t.optional(t.map(t.string), {}),
}).views(self => ({
  answerHasSelected: (answer: string): boolean => {
    return self?.answers?.some(x => x.value === answer) ?? false
  },
})).actions(self => ({
  setCondition: (value: Condition) => {
    self.condition = value
    self.errors.delete('condition')
  },
  setOverlap: (value: Overlap) => {
    self.overlap = value
    self.errors.delete('overlap')
  },
  toggleSelectAnswer: (answer: string) => {
    if (self.answers.some(x => x.value === answer)) applySnapshot(self.answers, self.answers.filter(x => x.value !== answer))
    else applySnapshot(self.answers, [...self.answers, { value: answer, isCustom: false }])
    self.errors.delete('answers')
  },
  setIsExpanded: (isExpanded: boolean) => {
    self.isExpanded = isExpanded
  },
  toggleExpanded: () => {
    self.isExpanded = !self.isExpanded
  },
  delete: () => {
    const type = t.array(BaseLogicItem)
    const parent = (getParent<typeof type>(self, 1))
    applySnapshot(parent, castToSnapshot(parent.filter(x => x !== self)))
  },
  validate: () => {
    if (!self.condition) self.errors.set('condition', 'Обязательное поле')
    if (CheckEnumIs(self.condition, Condition.Choose, Condition.NotChoose) && !self.overlap) self.errors.set('overlap', 'Обязательное поле')
    if (CheckEnumIs(self.condition, Condition.Choose, Condition.NotChoose) && (!self.answers || self.answers.length === 0)) self.errors.set('answers', 'Выберите ответы')
  },
  setHasCustom: (isCustom: boolean) => {
    if (isCustom) {
      if (self.answers.some(x => x.isCustom)) {
        return
      }

      applySnapshot(self.answers, [...self.answers, { value: '', isCustom: true }])
    } else {
      applySnapshot(self.answers, self.answers.filter(x => !x.isCustom))
    }
  },
  toggleCustom: () => {
    if (self.answers.every(x => !x.isCustom)) {
      if (self.answers.some(x => x.isCustom)) {
        return
      }

      applySnapshot(self.answers, [...self.answers, { value: '', isCustom: true }])
    } else {
      applySnapshot(self.answers, self.answers.filter(x => !x.isCustom))
    }
  },
}))

const Rule = t.compose(BaseLogicItem, t.model({
  pageId: t.string,
  questionId: t.string,
  nextPageId: t.maybeNull(t.string),
})).actions(self => {
  const baseValidate = self.validate
  return {
    setNextPageId: (nextPageId: string) => {
      self.nextPageId = nextPageId
      self.errors.delete('nextPageId')
    },
    validate: () => {
      baseValidate()
      if (!self.nextPageId) self.errors.set('nextPageId', 'Обязательное поле')
    },
  }
})

const PageFilter = t.compose(BaseLogicItem, t.model({
  pageId: t.string,
  targetPageId: t.maybeNull(t.string),
  targetQuestionId: t.maybeNull(t.string),
})).actions(self => {
  const baseValidate = self.validate
  return {
    setTargetPageId: (pageId: string) => {
      self.targetPageId = pageId
      applySnapshot(self.answers, [])
      self.errors.delete('targetPageId')
      self.errors.delete('answers')
    },
    setTargetQuestionId: (questionId: string) => {
      self.targetQuestionId = questionId
      applySnapshot(self.answers, [])
      self.errors.delete('targetQuestionId')
      self.errors.delete('answers')
    },
    validate: () => {
      baseValidate()
      if (!self.targetPageId) self.errors.set('targetPageId', 'Обязательное поле')
      if (!self.targetQuestionId) self.errors.set('targetQuestionId', 'Обязательное поле')
    },
  }
})

const QuestionFilter = t.compose(BaseLogicItem, t.model({
  pageId: t.string,
  questionId: t.maybe(t.string),
  targetQuestionId: t.maybeNull(t.string),
})).actions(self => {
  const baseValidate = self.validate
  return {
    setTargetQuestionId: (value: string) => {
      self.targetQuestionId = value
      applySnapshot(self.answers, [])
      self.errors.delete('targetQuestionId')
      self.errors.delete('answers')
    },
    validate: () => {
      baseValidate()
      if (!self.targetQuestionId) self.errors.set('targetQuestionId', 'Обязательное поле')
    },
  }
})

const PageLogic = t.model({
  pageId: t.identifier,
  questionRules: t.array(Rule),
  pageFilters: t.array(PageFilter),
  questionFilters: t.array(QuestionFilter),
  /** Данные необходимые для управления UI **/
  isExpanded: t.optional(t.boolean, false),
}).views(self => ({
  rulesByQuestion: (questionId: string) => self.questionRules.filter(x => x.questionId === questionId),

  filtersByQuestion: (questionId: string) => self.questionFilters.filter(x => x.questionId === questionId),

  get hasLogic() {
    const surveyWithCards = (getParent<Instance<typeof BranchingStore>>(self, 2)).surveyWithCards
    if (surveyWithCards) return self.questionFilters.length > 0
    else return self.questionFilters.length > 0 || self.questionRules.length > 0 || self.pageFilters.length > 0
  },
})).views(self => ({
  hasQuestionRulesErrors: (questionId: string) => self.rulesByQuestion(questionId)?.some(x => x.errors.size > 0) ?? false,

  hasQuestionFiltersErrors: (questionId: string) => self.filtersByQuestion(questionId)?.some(x => x.errors.size > 0) ?? false,

  hasPageFiltersErrors: () => self.pageFilters.some(x => x.errors.size > 0),

  get hasErrors() {
    const surveyWithCards = getParent<typeof BranchingStore>(self, 2).surveyWithCards
    if (surveyWithCards) return self.questionFilters.some(x => x.errors.size > 0)

    return self.pageFilters.some(x => x.errors.size > 0) ||
      self.questionFilters.some(x => x.errors.size > 0) ||
      self.questionRules.some(x => x.errors.size > 0)
  },
  get hasDeprecatedItems() {
    const surveyWithCards = getParent<typeof BranchingStore>(self, 2).surveyWithCards
    if (surveyWithCards) return self.questionFilters.some(x => x.isDeprecated)

    return self.pageFilters.some(x => x.isDeprecated) ||
        self.questionFilters.some(x => x.isDeprecated) ||
        self.questionRules.some(x => x.isDeprecated)
  },
})).actions(self => ({
  addNewPageFilter: (pageId: string) => {
    self.pageFilters.push({ pageId: pageId, condition: Condition.Choose, overlap: Overlap.Or, isExpanded: true, isQuestionSkip: false })
  },

  addNewQuestionRule: (pageId: string, questionId: string) => {
    self.questionRules.push({ pageId: pageId, questionId: questionId, condition: Condition.Choose, overlap: Overlap.Or, isExpanded: true, isQuestionSkip: false })
  },

  addNewQuestionFilter: (pageId: string, questionId: string) => {
    self.questionFilters.push({ pageId: pageId, questionId: questionId, condition: Condition.Choose, overlap: Overlap.Or, isExpanded: true, isQuestionSkip: false })
  },

  movePageFilter: (fromIndex: number, toIndex: number) => {
    applySnapshot(self.pageFilters, castToSnapshot(arrayMoveImmutable(self.pageFilters, fromIndex, toIndex)))
  },

  moveQuestionRule: (fromIndex: number, toIndex: number) => {
    applySnapshot(self.questionRules, castToSnapshot(arrayMoveImmutable(self.questionRules, fromIndex, toIndex)))
  },

  moveQuestionFilter: (fromIndex: number, toIndex: number) => {
    applySnapshot(self.questionFilters, castToSnapshot(arrayMoveImmutable(self.questionFilters, fromIndex, toIndex)))
  },

  pushRules: (newRules: IRuleSnapshotIn[]) => {
    self.questionRules.push(...newRules)
  },

  pushPageFilters: (newFilters: IPageFilterSnapshotIn[]) => {
    self.pageFilters.push(...newFilters)
  },

  setIsExpanded: (isExpanded: boolean) => {
    self.isExpanded = isExpanded
  },

  toggleExpanded: () => {
    self.isExpanded = !self.isExpanded
  },
}))

export const BranchingStore = t.model({
  pagesLogic: t.map(PageLogic),
  surveyWithCards: t.optional(t.boolean, false),
}).views(self => ({
  get hasErrors(): boolean {
    const keys = Array.from(self.pagesLogic.keys())
    return _.some(keys, (key: string) => self.pagesLogic.get(key)?.hasErrors ?? false)
  },
})).actions(self => ({
  load: flow(function * (surveyId: string) {
    self.pagesLogic.clear()

    const rootStore = getAdminRootStore()
    rootStore.surveyInfoRootStore.pagesStore.pages.forEach((x: any) => self.pagesLogic.put({ pageId: x.id }))

    const surveyRules = yield apiGetRules(surveyId)

    const pages = rootStore.surveyInfoRootStore.pagesStore

    Object.entries(surveyRules.pagesLogic).forEach((pageLogicKV: any) => {
      const pageLogic = pageLogicKV[1]

      self.pagesLogic.put({
        ...pageLogic,
        pageFilters: pageLogic.pageFilters,
        questionRules: pageLogic.rules,
        questionFilters: pageLogic.questionFilters.map((filter: IQuestionFilterInstance) => {
          const page = pages.getPage(pageLogic.pageId)
          const targetQuestionOrder = page?.questions.findIndex(x => x.id === filter.targetQuestionId) ?? 0
          const questionOrder = page?.questions.findIndex(x => x.id === filter.questionId) ?? 0
          if (targetQuestionOrder > questionOrder) filter.targetQuestionId = null
          return filter
        }),
      })
    })

    const survey = rootStore.surveysStore.surveys.find(x => x.surveyId === surveyId)
    self.surveyWithCards = survey?.withCards ?? false
  }),
  validate: () => {
    self.pagesLogic.forEach(logic => {
      logic.questionFilters.forEach(x => x.validate())
      if (!self.surveyWithCards) {
        logic.pageFilters.forEach(x => x.validate())
        logic.questionRules.forEach(x => x.validate())
      }
    })
  },
}))

