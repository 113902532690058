import styled from 'styled-components'

export const SurveyInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    & > *:last-child{
      margin-right: 50px;
    }
`

export const SurveyInfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 70px;
    justify-content: start;
`

export const SurveyInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    cursor: default;
    margin-bottom: 5px;
    max-width: calc(100vw - 800px);
    & > span:nth-child(1){
      min-width: 200px;
    }
    & > span:nth-child(2){
      color: #828282;
    }
    a {
      word-break: break-all;
    }
`

export const SurveyInfoFooter = styled.div`
    display: flex;
    flex-direction: row;
    cursor: default;
    margin-bottom: 5px;

    & > span:nth-child(2){
      color: #828282;
      margin-left: 10px;
    }
`

export const Container = styled.div`
    margin-top: 30px;
`

export const TableContainer = styled.div`
    margin-top: 40px;
`

export const ImageContainer = styled.div`
  & > div{
    margin-left: auto;
    padding-top: 10px;
  }
`

export const SurveyContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  justify-content: space-between;
  & > div:first-child{
    margin-right: 30px;
  }
  & > div{
    display: flex;
    flex-direction: column;
  }
`
