import { CommentType } from '@/Models/page'
import { IAnswerInstance, IQuestionInstance } from '@/Stores/UserStores/pageStore'


export const isCommentVisibleFor = (question: IQuestionInstance, answer: IAnswerInstance) => {
  switch (question.commentType) {
    case CommentType.AlwaysVisibleAndRequired:
    case CommentType.AlwaysVisible:
    case CommentType.AlwaysVisibleAndRequiredTo:
      return true
    case CommentType.Comment:
    case CommentType.CommentIsRequired:
      return answer.singleValue.length > 0
    case CommentType.NotRequiredAndVisibleTo:
    case CommentType.VisibleToAndRequiredTo:
      return answer.singleValue.length > 0 && Number(answer.singleValue) <= Number(question.payload?.requireCommentValue ?? 0)
    default:
      return false
  }
}
