import { DropResult, ResponderProvided } from 'react-beautiful-dnd'

export interface IDndSubscriptionContext {
  dragEndCallbackMap: Map<string, (result: DropResult, provided: ResponderProvided) => void>
  subscribeToDrugEnd: (draggableType: string, callback: (result: DropResult, provided: ResponderProvided) => void) => void
  unsubscribeFromDrugEnd: (draggableType: string) => void
}

export const DefaultDndSubscriptionContext: IDndSubscriptionContext = {
  dragEndCallbackMap: new Map<string, (result: DropResult, provided: ResponderProvided) => void>(),
  subscribeToDrugEnd: function(draggableType: string, callback: (result: DropResult, provided: ResponderProvided) => void): void {
    if (this.dragEndCallbackMap.has(draggableType)) {
      this.dragEndCallbackMap.delete(draggableType)
    }

    this.dragEndCallbackMap.set(draggableType, callback)
  },
  unsubscribeFromDrugEnd: function(draggableType: string): void {
    if (this.dragEndCallbackMap.has(draggableType)) {
      this.dragEndCallbackMap.delete(draggableType)
    }
  },
}
