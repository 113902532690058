import styled, { css } from 'styled-components'
import { range } from 'lodash'

import { device } from '@/Models/device'

interface IContainer {
  hasAnswer: boolean
  answerPercent: number
  answerNumber: number
  isColorized: boolean
  percentPerValue: number
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${(props: IContainer) => !props.hasAnswer && css`
    .MuiSlider-thumb {
      display: none;
    }

    .MuiSlider-markActive {
      ${(props: IContainer) => (!props.isColorized ? 'background-color: #FFFFFF !important;' : `
        opacity: 1 !important;
      `)}
    }
  `}

  .MuiSlider-root span:nth-child(${(props: IContainer) => 2 + ((props.answerNumber + 1) * 2)}) {
    top: -30px !important;
  }

  .MuiSlider-root {
    width: auto;
    top: 30px;
    height: 20px;
    ${(props: IContainer) => (!props.isColorized ? `
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    ` : `
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      padding: 13px 0;
      transition: all 0.2s ease-in-out;
    `)}
  }

  .MuiSlider-rail {
    height: 8px;
    color: white;
    opacity: 1;
    ${(props: IContainer) => (!props.isColorized ? 'border: 1px solid #266DC2;' : `
      background: linear-gradient(to right, #ff3535, yellow, green);
      transition: all 0.2s ease-in-out;
      box-shadow: 0 0 16px rgb(0 0 0 / 10%);
      width: calc(100% + 14px);
      left: -7px;
      border-radius: 3.4px;
    `)}
  }


  .MuiSlider-track {
    height: 10px;
    ${(props: IContainer) => (!props.isColorized ? 'background-color: #266DC2;' : `
      opacity: 0.65;
      left: ${props.answerPercent || 0}% !important;
      height: 8px;
      border: 0;
      border-radius: 3.4px;
      width: calc(${100 - props.answerPercent}% + 7px) !important;
      color: #FFFFFF;
      transition: all 0.2s ease-in-out;
    `)}
  }

  .MuiSlider-mark {
    border-radius: 50%;
    color: white;
    width: 12px;
    height: 12px;
    margin-left: -7px;
    ${(props: IContainer) => (!props.isColorized ? 'border: 1px solid #266DC2;' : `
      box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
      transition: all 0.2s ease-in-out;
      border: 1px solid transparent;
    `)}
  }

  .MuiSlider-markActive {
    opacity: 1;
    ${(props: IContainer) => (!props.isColorized ? `
      background-color: #266DC2;
    ` : `
      background-color: rgb(38 109 194 / 75%);
      box-shadow: inset 0px 0px 2px 0px rgb(255 255 255 / 75%);
      width: 1.3rem;
      height: 1.3rem;
      margin-left: -0.65rem;

      ${range(0, 10).map(x => `&[data-index="${x}"] {
        background-color: ${props.percentPerValue * x < 33 ? '#f14848' : (props.percentPerValue * x < 66 ? '#fcdb5c' : '#0bc709')};
      }`)}
    `)}
  }

  .MuiSlider-thumb {
    ${(props: IContainer) => (!props.isColorized ? `
      background-color: #266DC2;
      opacity: 1;
      width: 14px;
      height: 14px;
      margin-top: 0;
    ` : `
      background-color: ${(props.answerPercent < 33 ? '#f14848' : (props.answerPercent < 66 ? '#fcdb5c' : '#0bc709'))};
      width: 29px;
      height: 29px;
      border: 1px solid rgb(255 255 255 / 25%);
    `)}
  }

  .MuiSlider-markLabel {
    top: -22px;
    font-family: Proxima Nova;
    font-style: normal;
    font-size: 21px;
    line-height: 22px;
    color: #BCC5CD;
    ${(props: IContainer) => (!props.isColorized ? 'font-weight: bold;' : 'transition: all 0.2s ease-in-out;')}
  }

  ${(props: IContainer) => (!props.isColorized ? '' : `.MuiSlider-markLabelActive {
    font-size: 23px;
  }`)}

  ${(props: IContainer) => (!props.isColorized ? '' : `.MuiSlider-root:not(.skip-slider) .MuiSlider-markLabelActive {
    top: -30px;
    color: rgb(0 0 0 / 70%);
    font-weight: bold;
  }`)}

  .skip-slider {
    .MuiSlider-rail {
      ${(props: IContainer) => (!props.isColorized ? `
        border: 1px solid #8B98A7;
      ` : `
        opacity: 0.65;
        filter: grayscale(0.85);
      `)}
    }

    .MuiSlider-track {
      ${(props: IContainer) => (!props.isColorized ? `
        background-color: #8B98A7;
      ` : '')}
    }

    ${(props: IContainer) => (!props.isColorized ? `.MuiSlider-mark {
      border: 1px solid #8B98A7;
    }` : '')}

    .MuiSlider-markActive {
      background-color: #8B98A7;
    }

    ${(props: IContainer) => (!props.isColorized ? `.MuiSlider-thumb {
        background-color: #8B98A7;
    }` : '')}
  }

  @media screen and ${device.mobile} {
    .MuiSlider-root {
      left: auto;
      margin: 0 10px 20px 10px;
      width: auto;
      padding: 13px 0;
    }
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

export const HelperTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  // Тут из-за отступов происходил overflow контейнера и появлялся горизонтальный скролл
  // margin-left: 0.5rem;
  // margin-right: 0.5rem;
  width: auto;
  justify-content: space-between;

  & > span:first-child {
    width: 140px;
  }

  & > span:last-child {
    width: 140px;
    text-align: right;
  }

  @media screen and ${device.mobile} {
    width: auto;
  }
`


export const LabelStyled = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 14px;
  cursor: pointer;
  width: fit-content;
  user-select: none;

  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-size: 18px;
  word-break: break-word;
  color: #8B98A7;

  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
  }

  :hover span {
    border-color: #8B98A7;
  }

  input:checked ~ span {
    background: #266DC2;
    border-color: #266DC2;

    img {
      display: flex;
    }
  }

  &.skip-slider {
    color: black;
  }

`

export const SpanCheckMarkStyled = styled.span`
  height: 26px;
  width: 26px;
  min-height: 26px;
  min-width: 26px;
  background-color: #FFFFFF;
  border: solid 3px #ccc;
  box-sizing: border-box;
  margin-right: 10px;
  margin-left: 12px;
  border-radius: 3px;

  img {
    width: 20px;
    height: 20px;
    display: none;
  }
`

