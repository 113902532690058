import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Typography, Skeleton } from '@mui/material'
import { uniq } from 'lodash'

import { apiGetCompletionStatisticByDate, apiGetVisitsStatisticByDate } from '@/Api/analytics'
import bem from '@/Helpers/BemClass'

import './style.scss'

Chart.register(ChartDataLabels, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend)

const cnVisitsStatistic = bem()('visits-statistic')

interface IVisitsStatistic {
  surveyId: string
}

const VisitsStatistic = ({ surveyId }: IVisitsStatistic) => {
  const [data, setData] = useState<{ lables: Date[], visits: number[], completitions: number[] }>({ lables: [], visits: [], completitions: [] })
  const [counters, setCounters] = useState<{
    dayVisitsCount: number
    allVisitsCount: number
    allCompletitionsCount: number
  }>({ dayVisitsCount: 0, allVisitsCount: 0, allCompletitionsCount: 0 })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    (async () => {
      const counters = await Promise.all([apiGetVisitsStatisticByDate(surveyId), apiGetCompletionStatisticByDate(surveyId)])

      const lables = uniq([...counters[0].map(x => x.date), ...counters[1].map(x => x.date)]).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      const visits = lables.map(x => counters[0].find(c => c.date === x)?.count ?? 0)
      const completitions = lables.map(x => counters[1].find(c => c.date === x)?.count ?? 0)

      setData({
        lables,
        visits,
        completitions,
      })
      setCounters({
        dayVisitsCount: counters[0].length === 0 ? 0 : counters[0][counters[0].length - 1].count,
        allVisitsCount: counters[0].map(x => x.count).reduce((a, b) => a + b, 0),
        allCompletitionsCount: counters[1].map(x => x.count).reduce((a, b) => a + b, 0),
      })
      setIsLoading(false)
    })()
  }, [surveyId])


  const getChartData = (data?: { lables: Date[], visits: number[], completitions: number[] }) => {
    return {
      labels: data?.lables.map(x => (new Date(x)).toLocaleDateString()),
      datasets: [
        {
          label: 'Посещение опроса',
          backgroundColor: '#7DBDFF',
          borderColor: '#000274',
          borderWidth: 1,
          hoverBackgroundColor: '#d7d7d7',
          hoverBorderColor: '#5969c3',
          data: data?.visits,
        },
        {
          label: 'Завершили опрос',
          backgroundColor: '#5969c3',
          borderColor: '#000274',
          borderWidth: 1,
          hoverBackgroundColor: '#004C97',
          hoverBorderColor: '#5969c3',
          data: data?.completitions,
        },
      ],
    }
  }

  return (
    <div className={cnVisitsStatistic()}>
      <p className={cnVisitsStatistic('chart-title')}></p>
      {isLoading ? <Skeleton
        variant='rectangular'
        className={cnVisitsStatistic('chart-container-skeleton')}
      /> : <div
        className={cnVisitsStatistic('chart-container')}
      >
        <Line
          data={getChartData(data)}
          options={{
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: false, // Отключает отображение чисел позади точек
              },
            },
          }}
        />
      </div>}
      <div className={cnVisitsStatistic('total-counter-section')}>
        {isLoading ? <Skeleton
          variant='rectangular'
          className={cnVisitsStatistic('total-counter-skeleton')}
          height='24px'
          width='300px'
        /> : <Typography
          className={cnVisitsStatistic('total-counter')}
          variant='caption'
          display='block'
        >
          {`Посещений за период действия опроса: ${counters.allVisitsCount}`}
        </Typography>}
        {isLoading ? <Skeleton
          variant='rectangular'
          className={cnVisitsStatistic('total-counter-skeleton')}
          height='24px'
          width='240px'
        /> : <Typography
          className={cnVisitsStatistic('total-counter')}
          variant='caption'
          display='block'
        >
          {`Посещений за текущий день: ${counters.dayVisitsCount}`}
        </Typography>}
      </div>
    </div>)
}

export default VisitsStatistic
