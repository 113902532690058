import { createGlobalStyle } from 'styled-components'

export const ToolTipGlobalStyled = createGlobalStyle`
  .MuiTooltip-tooltip {
    font-size: 16px !important;
    max-width: 500px !important;
    font-weight: 500 !important;
  }

  &.light {
    background: #e6eaf0 !important;
    color: #8B98A7 !important;

    .MuiTooltip-arrow {
      color: #e6eaf0 !important;
    }
  }

  &.dark {
    background: rgba(97, 97, 97, 0.9) !important;
    color: #fff; !important;

    .MuiTooltip-arrow {
      color: rgba(97, 97, 97, 0.9) !important;
    }
  }
`

