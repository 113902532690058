import { observer } from 'mobx-react'
import { TextField } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import DragLabel from '@/Components/DragAndDrop/DragLabel'
import { PhotoInput } from '@/Components'
import { IOptionInstance, OptionValidationResult } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

import { ActionContainer, AnswerStyled, ImageContainer, BodyContainer, PhotoImageContainer, AddPhotoAlternateIconStyled } from './styled'

export interface IAnswerProps {
  option: IOptionInstance
  showActions?: boolean
  withImage: boolean
  dragHandleProps?: DraggableProvidedDragHandleProps | undefined | null
  onDelete: () => void
  disabled: boolean
}

const Answer = ({ option, onDelete, showActions = true, withImage, dragHandleProps, disabled }: IAnswerProps) => {
  const photoImage = <PhotoImageContainer> <AddPhotoAlternateIconStyled/> </PhotoImageContainer>

  return (<AnswerStyled>
    {withImage &&
        <ImageContainer>
          <PhotoInput
            imageUrl={getStaticsHybridUrl(`/api/file/image/${option.imageId ?? ''}`)}
            onChange={imageId => option.setImageId(imageId)}
            width={60}
            height={60}
            cropHeight={60}
            cropWidth={60}
            disabled={disabled}
          >
            {!option.imageId ? photoImage : undefined}
          </PhotoInput>
        </ImageContainer>
    }
    <BodyContainer>
      {option.isCustom ? <TextField
        variant='standard'
        value='Свой вариант'
        disabled
      >
      </TextField> : <TextField
        className={option.hasSelfValidationErrors ? 'error' : ''}
        label="Вариант ответа"
        placeholder="Введите текст ответа"
        value={option.value}
        onChange={e => option.setValue(e.target.value)}
        onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
        autoFocus={showActions}
        disabled={disabled}
        variant="standard"
        error={option.hasSelfValidationErrors}
        helperText={option.hasSelfValidationErrors && (option.imageRequiredError ? 'Обязательное поле' : 'Дублирующееся поле')}
        inputProps={{ maxLength: 200 }}
      />}
      <ActionContainer isVisible={showActions}>
        {!disabled && <DeleteOutlineIcon onClick={onDelete}/>}
        {!disabled && <DragLabel dragHandleProps={dragHandleProps}/>}
      </ActionContainer>
    </BodyContainer>
  </AnswerStyled>)
}

export default observer(Answer)
