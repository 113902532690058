import { SpreadsheetCellBorderStyleVariant } from '@/Models/spreadsheet'


export const borderVariantToStyle = new Map<SpreadsheetCellBorderStyleVariant, string>([
  [SpreadsheetCellBorderStyleVariant.None, 'solid'],
  [SpreadsheetCellBorderStyleVariant.Dotted, 'dotted'],
  [SpreadsheetCellBorderStyleVariant.Dashed, 'dashed'],
  [SpreadsheetCellBorderStyleVariant.DashDot, 'dotted'],
  [SpreadsheetCellBorderStyleVariant.DashDotDot, 'dotted'],
  [SpreadsheetCellBorderStyleVariant.Double, 'double'],
  [SpreadsheetCellBorderStyleVariant.Hair, 'solid'],
  [SpreadsheetCellBorderStyleVariant.Medium, 'solid'],
  [SpreadsheetCellBorderStyleVariant.MediumDashed, 'dashed'],
  [SpreadsheetCellBorderStyleVariant.MediumDashDot, 'dotted'],
  [SpreadsheetCellBorderStyleVariant.MediumDashDotDot, 'dotted'],
  [SpreadsheetCellBorderStyleVariant.SlantDashDot, 'dotted'],
  [SpreadsheetCellBorderStyleVariant.Thick, 'solid'],
  [SpreadsheetCellBorderStyleVariant.Thin, 'solid'],
])
