import { useEffect, useState } from 'react'
import { Container, Grid, Box, Typography, Grow, Skeleton, Paper } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import _ from 'lodash'

import { IPageAnswersInfo } from '@/Models/answer'
import { apiGetAnswersBySurveyPagesWithUserAnswers } from '@/Api/answer'
import {
  DisplayMode,
  PageAnswers,
} from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/PageAnswers'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnServiceCardAnswerView = bem()('service-card-answer-view')

// TODO: улучшить тайпинги
interface IAnswersView {
  surveyId: string
  pageId: string
  onClose: () => void
}

export const AnswersView = ({ surveyId, pageId, onClose }: IAnswersView) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('md'))
  const [isLoading, setIsLoading] = useState(true)
  const [answers, setAnswers] = useState<IPageAnswersInfo>()
  const [order, setOrder] = useState<number>(0)
  useEffect(() => {
    (async () => {
      const pagesAnswer: IPageAnswersInfo[] = await apiGetAnswersBySurveyPagesWithUserAnswers(surveyId)

      const pageAnswer = pagesAnswer.find((x) => x.pageId === pageId)

      if (!pageAnswer) {
        onClose()
        return
      }

      setAnswers(pageAnswer)
      setOrder(pagesAnswer.indexOf(pageAnswer) + 1)
      setIsLoading(false)
    })()
  }, [])

  const elNoDataAvailGeneral = (
    <Grid item xs={12} className={cnServiceCardAnswerView('answers-page')}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-center'
        alignItems='center'
        paddingY={1}
        height='100%'
      >
        <Typography variant='h6' gutterBottom>
          Недостаточно данных для отображения
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          Создайте страницы с вопросами и дождитесь появления ответов
        </Typography>
      </Box>
    </Grid>
  )

  if (isLoading || !answers) {
    return (
      <div className={cnServiceCardAnswerView('skeleton')}>
        <Skeleton
          variant='rectangular'
          width='109px'
          height='32px'
          style={{ margin: '8px' }}
        />
        <Skeleton
          variant='rectangular'
          width='590px'
          height='154px'
          style={{ margin: '8px' }}
        />
        <Skeleton variant='rectangular' width='590px' height='154px' />
      </div>
    )
  }

  return (
    <div className={cnServiceCardAnswerView()}>
      <Container className={cnServiceCardAnswerView({ mobile: UIisMobile })} maxWidth={false}>
        <Box paddingY={1}>
          <Grow
            in={!isLoading}
            timeout={700}
          >
            {answers.questionAnswers.length === 0 ? elNoDataAvailGeneral : <Paper>
              <PageAnswers
                displayMode={DisplayMode.User}
                pageAnswersInfo={answers}
                order={order}
                isTableViewMode={false}
                setIsTableViewMode={(isTableViewMode) => {}}
              />
            </Paper>}
          </Grow>
        </Box>
      </Container>
    </div>
  )
}

export default AnswersView
