import parse, { HTMLReactParserOptions } from 'html-react-parser'
import DOMPurify from 'dompurify'
import domToReact from 'html-react-parser/lib/dom-to-react'

DOMPurify.addHook('afterSanitizeAttributes', (node: Element) => {
  if ('target' in node) {
    node.setAttribute('rel', 'noopener')
  }
})

DOMPurify.addHook('afterSanitizeAttributes', (node: Element) => {
  if ('target' in node) {
    node.setAttribute('rel', 'noopener')
  }
})

export default function htmlSafeParse(html: string, options: HTMLReactParserOptions = {}): ReturnType<typeof domToReact> {
  return parse(DOMPurify.sanitize(html, { ADD_ATTR: ['target'] }), { ...options })
}
