import { observer } from 'mobx-react'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'

import { PhotoInput } from '@/Components'
import { IServiceInstance } from '@/Stores/AdminStores/adminServicesStore'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import { IPageInstance, PageValidationError } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

import { FieldColumnContainer, PageInfoContainer } from './styled'

interface IPageInfo {
  page: IPageInstance
  service: IServiceInstance | null
  isGvk?: boolean
  disabled: boolean
}

const PageInfo = ({ page, service, isGvk, disabled }: IPageInfo) => {
  return (<PageInfoContainer>
    <PhotoInput imageUrl={getStaticsHybridUrl(`/api/file/image/${page.imageId ?? ''}`)} onChange={(imageId, _) => page.setImageId(imageId)} width={125} height={125} cropHeight={125} cropWidth={125} rounded disabled={disabled}/>
    <FieldColumnContainer>
      <TextField
        placeholder="Введите название страницы"
        value={page.title}
        onChange={e => page.setTitle(e.target.value)}
        disabled={(isGvk ?? false) || disabled}
        fullWidth
        multiline
        error={page.titleError}
        variant="standard"
      />
      {service?.owner && <TextField
        label="Владелец сервиса ССП"
        value={service.owner}
        disabled={true}
        fullWidth
        multiline
        variant="standard"
      />}
      <TextField
        placeholder="Введите описание страницы"
        value={page.description}
        onChange={e => page.setDescription(e.target.value)}
        disabled={(isGvk ?? false) || disabled}
        fullWidth
        multiline
        variant="standard"
      />
      {isGvk && <FormControlLabel
        value="requiredPage"
        control={<Checkbox color="primary" disabled={disabled} checked={page.isRequired} onChange={() => page.toggleIsRequired()}/>}
        label="Обязательный сервис"
        labelPlacement="end"
      />}
    </FieldColumnContainer>
  </PageInfoContainer>)
}

export default observer(PageInfo)
