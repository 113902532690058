import { ReactNode } from 'react'
import { Button } from '@mui/material'

import bem from '@/Helpers/BemClass'
import './style.scss'

interface IVerticalButton {
  startIcon: ReactNode | JSX.Element
  disabled: boolean
  onClick: () => void
  children?: ReactNode
}

const cnVerticalButton = bem()('vertical-button')

export const VerticalButton = ({ startIcon, disabled, onClick, children }: IVerticalButton) => {
  return (<div className={cnVerticalButton('container')}><Button
    onClick={onClick}
    startIcon={startIcon}
    className={cnVerticalButton()}
    disabled={disabled}
  >
    {children}
  </Button>
  </div>
  )
}

export default VerticalButton
