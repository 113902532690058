import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const PageTitleSpaceStyled = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

export const PageTitleStyled = styled.div`
  font-weight: bolder;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const PageOrderTextStyled = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: normal;
`

export const ActionsContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  & > * {
    margin-left: 10px;
  }

  ${props => !props.disabled && `& > *:hover{
    cursor: pointer;
    opacity: 0.6;
  }`}

  .MuiFormControlLabel-label{
    user-select: none;
  }
`
