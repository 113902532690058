import { useParams } from 'react-router'

import { useMst } from '../../../Stores/rootStore'
import { SurveyType } from '../../../Stores/AdminStores/surveysStore'
import { useRouteManager } from '../../../Hooks/useRouteManager'
import { RouteEnum } from '../../../Enums/route'

import CustomEditPage from './Custom'
import GvkEditPage from './Gvk'

interface IEditPage {
  surveyId: string
  readonly: boolean
}

const EditPage = ({ surveyId, readonly }: IEditPage) => {
  const survey = useMst().admin.surveysStore.getSurvey(surveyId)
  const routeManager = useRouteManager()
  if (!survey) {
    routeManager.redirectTo(RouteEnum.survey)
    return <></>
  }

  if (survey.type === SurveyType.Gvk) return <GvkEditPage readonly={readonly} />

  return <CustomEditPage readonly={readonly} isCardState={survey.withCards} />
}

export default EditPage
