import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Dialog, Grid, Typography } from '@mui/material'
import { sortBy } from 'lodash'

import { IOrganizationStructureState, OrganizationStructureStateStage } from '@/Stores/AdminStores/organizationStructureStore'
import { ISurveyInstance, SurveyStatus, SurveyType, TargetAudiencesMappingType } from '@/Stores/AdminStores/surveysStore'
import { useMst } from '@/Stores/rootStore'
import { IServiceIn } from '@/Stores/AdminStores/adminServicesStore'
import bem from '@/Helpers/BemClass'
import { getOrganizationStructureStageName } from '@/Helpers/organizationStructureStageName'
import StyledSwitch from '@/Components/StyledSwitch'
import ExitWithoutSaveDialog from '@/Components/DialogExitWithoutSave'
import { OrganizationStructureCollection, OrganizationStructureTreeViewExtendedWithConfirm } from '@/Components/OrganizationStructure'
import { apiSetPageTargetAudiences, apiSetSurveyTargetAudiences } from '@/Api/targetAudience'
import { apiChangeSurveyTargetAudiencesMappingType } from '@/Api/survey'
import { apiChangeServices, apiGetActivePagesBySurveyId } from '@/Api/page'
import { apiGetGlobalServices } from '@/Api/service'
import { TrafficLightGroupType, TrafficLightPartType } from '@/Models/trafficLight'
import { IPage } from '@/Models/page'
import PageServiceList from '@/Pages/Admin/SurveyInfoPage/TargetAudienceMappingTab/Components/PageServiceList'
import SurveyServiceSelector from '@/Pages/Admin/SurveyInfoPage/TargetAudienceMappingTab/Components/SurveyServiceSelector'

import './style.scss'

const cntTargetAudienceMappingTab = bem()('target-audience-mapping-tab')

interface IPageServiceMappingTab {
  surveyId: string
  readonly: boolean
}

export const TargetAudiencesMappingTypeSwitchPair = new Map<TargetAudiencesMappingType, TargetAudiencesMappingType>([
  [TargetAudiencesMappingType.BySurveyTargetAudience, TargetAudiencesMappingType.ByPageTargetAudience],
  [TargetAudiencesMappingType.ByPageTargetAudience, TargetAudiencesMappingType.BySurveyTargetAudience],
  [TargetAudiencesMappingType.BySurveyService, TargetAudiencesMappingType.ByPageService],
  [TargetAudiencesMappingType.ByPageService, TargetAudiencesMappingType.BySurveyService],
])

const selectedStates = [TargetAudiencesMappingType.ByPageTargetAudience, TargetAudiencesMappingType.ByPageService]

const TargetAudienceMappingTab = ({ surveyId, readonly }: IPageServiceMappingTab) => {
  const store = useMst()
  const tabStore = store.admin.tabStore
  const surveysStore = store.admin.surveysStore
  const organizationStructureStore = store.admin.organizationStructureStore
  const trafficLightStateStore = store.admin.trafficLightStateStore
  const survey = surveysStore.surveys.find(x => x.surveyId === surveyId)
  const [pages, setPages] = useState<IPage[]>([])
  const [services, setService] = useState<IServiceIn[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // Если кастомный с без карточек, то есть ветвление, которое не дает сделать корректно ца по страницам (будет ца только по опросу)
  const isTypeSwitchDisabled = ([SurveyType.Custom, SurveyType.Repeatable].includes(survey?.type ?? SurveyType.Custom) && !survey?.withCards)
  const [targetAudiencesMappingType, setTargetAudiencesMappingType] = useState<TargetAudiencesMappingType>(survey?.targetAudiencesMappingType ?? TargetAudiencesMappingType.BySurveyTargetAudience)
  const [editedPageTargetAudienceState, setEditedPageTargetAudienceState] = useState<IOrganizationStructureState | null>(null)
  const canBeChangedInProgress = [SurveyType.Custom, SurveyType.Gvk].includes(survey?.type ?? SurveyType.Custom)
  const isSurveyed = survey?.status === SurveyStatus.Surveyed
  const [warningDialogWindowIsOpen, setWarningDialogWindowIsOpen] = useState<boolean>(false)

  const onLoad = async () => {
    if (targetAudiencesMappingType === TargetAudiencesMappingType.ByPageTargetAudience) {
      // Если ца по странице, то надо подгрузить информацию по страницам
      const data: IPage[] = await apiGetActivePagesBySurveyId(surveyId)
      setPages(data)

      // Подгружает ца для страниц опроса
      await organizationStructureStore.loadTargetAudience(surveyId, TargetAudiencesMappingType.ByPageTargetAudience, data.map(x => x.id ?? ''))
    } else if (targetAudiencesMappingType === TargetAudiencesMappingType.BySurveyTargetAudience) {
      await organizationStructureStore.loadTargetAudience(surveyId, TargetAudiencesMappingType.BySurveyTargetAudience)
    } else if ([TargetAudiencesMappingType.BySurveyService, TargetAudiencesMappingType.ByPageService].includes(targetAudiencesMappingType)) {
      const data: IPage[] = await apiGetActivePagesBySurveyId(surveyId)
      setPages(data)

      const services = await apiGetGlobalServices()
      setService(sortBy(services, ['name']))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    onLoad()
  }, [organizationStructureStore, targetAudiencesMappingType, surveyId])

  useEffect(() => {
    if (store.admin.surveyEditingTimeSpan === null && !isLoading) {
      setIsLoading(true)
      setTargetAudiencesMappingType(survey?.targetAudiencesMappingType ?? TargetAudiencesMappingType.BySurveyTargetAudience)
      onLoad()
    }
  }, [store.admin.surveyEditingTimeSpan])

  const handleChangeTargetAudiencesMappingType = () => {
    setTargetAudiencesMappingType(prev => TargetAudiencesMappingTypeSwitchPair.get(prev) ?? TargetAudiencesMappingType.BySurveyTargetAudience)
    trafficLightStateStore.getState(surveyId)
      ?.getGroup(TrafficLightGroupType.TargetAudience)
      ?.getState(TrafficLightPartType.EmptyTargetAudience)
      ?.clear()
  }

  const handleSave = (isDraft: boolean) => async (organizationStructureStateSelector: string) => {
    setIsLoading(true)

    if (!isDraft) {
      trafficLightStateStore.getState(surveyId)
        ?.getGroup(TrafficLightGroupType.TargetAudience)
        ?.getState(TrafficLightPartType.EmptyTargetAudience)
        ?.remove(targetAudiencesMappingType === TargetAudiencesMappingType.BySurveyTargetAudience ? null : organizationStructureStateSelector)
    }

    if (survey?.targetAudiencesMappingType !== targetAudiencesMappingType) {
      await apiChangeSurveyTargetAudiencesMappingType(surveyId, targetAudiencesMappingType)
      surveysStore.changeSurveyTargetAudiencesMappingType(surveyId, targetAudiencesMappingType)
    }

    const state = organizationStructureStore.getOrganizationStructureState(organizationStructureStateSelector)

    const checkedOrganizationStructureElementsId = state?.getAllConfirmedElements() ?? []
    const freeElements = state?.getAllFreeElements() ?? []

    if (targetAudiencesMappingType === TargetAudiencesMappingType.BySurveyTargetAudience) {
      await apiSetSurveyTargetAudiences(surveyId, isDraft, checkedOrganizationStructureElementsId, freeElements)

      state?.changeIsDraftState(isDraft)
    } else if (targetAudiencesMappingType === TargetAudiencesMappingType.ByPageTargetAudience && organizationStructureStateSelector) {
      await apiSetPageTargetAudiences(surveyId, organizationStructureStateSelector, isDraft, checkedOrganizationStructureElementsId, freeElements)
      state?.changeIsDraftState(isDraft)
    }

    state?.updateStage()

    setEditedPageTargetAudienceState(null)

    tabStore.setWasChange(false)

    await trafficLightStateStore.getState(surveyId)?.loadTrafficLight(false)

    setIsLoading(false)
  }

  const handleSavePagesServiceChanges = async () => {
    setIsLoading(true)

    await survey?.changeService(null)

    if (survey?.targetAudiencesMappingType !== targetAudiencesMappingType) {
      await apiChangeSurveyTargetAudiencesMappingType(surveyId, targetAudiencesMappingType)
      surveysStore.changeSurveyTargetAudiencesMappingType(surveyId, targetAudiencesMappingType)
    }

    const serviceIdByPageId = pages.map(x => ({ pageId: x.id ?? '', serviceId: x.serviceId ?? null }))
    await apiChangeServices(surveyId, serviceIdByPageId)
    setIsLoading(false)
  }

  const handleSaveSurveyServiceChanges = async (serviceId?: string) => {
    setIsLoading(true)

    const serviceIdByPageId = pages.map(x => ({ pageId: x.id ?? '', serviceId: null }))
    await apiChangeServices(surveyId, serviceIdByPageId)

    if (survey?.targetAudiencesMappingType !== targetAudiencesMappingType) {
      await apiChangeSurveyTargetAudiencesMappingType(surveyId, targetAudiencesMappingType)
      surveysStore.changeSurveyTargetAudiencesMappingType(surveyId, targetAudiencesMappingType)
    }

    await survey?.changeService(serviceId ?? null)

    await trafficLightStateStore.createIfNotExistAndGet(surveyId).loadTrafficLight(false)

    setIsLoading(false)
  }

  const handleChangeSelectedPage = (pageId: string | null) => {
    if (pageId) {
      setEditedPageTargetAudienceState(organizationStructureStore.getOrganizationStructureState(pageId) ?? null)
    } else {
      setEditedPageTargetAudienceState(null)
    }
  }

  const handlePageServiceChange = (pageId: string, serviceId?: string) => {
    setPages(prev => [...prev.map(x => (x.id === pageId ? { ...x, serviceId: serviceId ?? null } : x))])
  }

  return <Grid className={cntTargetAudienceMappingTab('')}>
    <StyledSwitch
      isSelected={selectedStates.includes(targetAudiencesMappingType)}
      onChange={handleChangeTargetAudiencesMappingType}
      disabled={readonly || isTypeSwitchDisabled || (!canBeChangedInProgress && isSurveyed)}
      leftText='Для всего опроса'
      rightText='Для каждой страницы'
    />
    {targetAudiencesMappingType === TargetAudiencesMappingType.BySurveyService && <SurveyServiceSelector
      survey={survey as ISurveyInstance}
      services={services}
      isLoading={isLoading}
      disabled={readonly || (!canBeChangedInProgress && isSurveyed)}
      onSave={handleSaveSurveyServiceChanges}
    />}
    {targetAudiencesMappingType === TargetAudiencesMappingType.ByPageService && <PageServiceList
      pages={pages}
      services={services}
      onPageServiceChange={handlePageServiceChange}
      isLoading={isLoading}
      disabled={readonly || (!canBeChangedInProgress && isSurveyed)}
      onSave={handleSavePagesServiceChanges}
    />}
    {targetAudiencesMappingType === TargetAudiencesMappingType.BySurveyTargetAudience && <div
      className={cntTargetAudienceMappingTab('organization-structure-tree-view-extended-with-confirm-container', { 'in-page': true })}
    >
      <Typography variant='subtitle1' fontWeight='bold' className={cntTargetAudienceMappingTab('state-title')}>
        {getOrganizationStructureStageName(organizationStructureStore.getOrganizationStructureState(surveyId))}
      </Typography>
      <OrganizationStructureTreeViewExtendedWithConfirm
        organizationStructureStateSelector={surveyId}
        onSave={handleSave(false)}
        onDraftSave={handleSave(true)}
        disabled={readonly || (!canBeChangedInProgress && isSurveyed)}
        disableSaveButton={organizationStructureStore.getOrganizationStructureState(surveyId)?.freeElementsCount !== 0 || (!tabStore.wasChanged && organizationStructureStore.getOrganizationStructureState(surveyId)?.stage === OrganizationStructureStateStage.Filled)}
        disableDraftSaveButton={!tabStore.wasChanged && organizationStructureStore.getOrganizationStructureState(surveyId)?.stage === OrganizationStructureStateStage.Draft}
        externalLoading={isLoading}
        readonly={readonly}
      />
    </div>}
    {targetAudiencesMappingType === TargetAudiencesMappingType.ByPageTargetAudience && <OrganizationStructureCollection
      surveyId={surveyId}
      pages={pages}
      onOrganizationStructureStateSelectorChange={handleChangeSelectedPage}
      isLoading={isLoading}
      highlightDraftOrEmpty
    />}
    {editedPageTargetAudienceState && <Dialog
      onClose={() => {
        if (tabStore.wasChanged) {
          setWarningDialogWindowIsOpen(true)
        } else {
          setEditedPageTargetAudienceState(null)
        }
      }}
      open={true}
      classes={{
        paperScrollPaper: cntTargetAudienceMappingTab('editor-dialog-paper'),
      }}
    >
      <Typography fontWeight='bold' variant='h6' className={cntTargetAudienceMappingTab('editor-dialog-page-title')}>
        {pages.find(x => x.id === editedPageTargetAudienceState?.selector)?.title ?? ''}
      </Typography>
      <Typography variant='subtitle1' fontWeight='bold' className={cntTargetAudienceMappingTab('state-title')}>
        {getOrganizationStructureStageName(editedPageTargetAudienceState)}
      </Typography>
      <div
        className={cntTargetAudienceMappingTab('organization-structure-tree-view-extended-with-confirm-container', { 'in-dialog': true })}
      >
        <OrganizationStructureTreeViewExtendedWithConfirm
          onSave={handleSave(false)}
          onDraftSave={handleSave(true)}
          organizationStructureStateSelector={editedPageTargetAudienceState?.selector}
          disabled={readonly || (!canBeChangedInProgress && isSurveyed)}
          disableSaveButton={editedPageTargetAudienceState?.freeElementsCount !== 0 || (!tabStore.wasChanged && editedPageTargetAudienceState?.stage === OrganizationStructureStateStage.Filled)}
          disableDraftSaveButton={!tabStore.wasChanged && editedPageTargetAudienceState?.stage === OrganizationStructureStateStage.Draft}
          externalLoading={isLoading}
          readonly={readonly}
        />
      </div>
    </Dialog>}
    {warningDialogWindowIsOpen && <ExitWithoutSaveDialog
      onConfirm={() => {
        setEditedPageTargetAudienceState(null)
        setWarningDialogWindowIsOpen(false)
      }}
      onClose={() => setWarningDialogWindowIsOpen(false)}
    />}
  </Grid>
}

export default observer(TargetAudienceMappingTab)
