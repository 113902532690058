import styled from 'styled-components'

export const LabelStyled = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: fit-content;
  user-select: none;

  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-size: 20px;
  word-break: break-word;
  margin-right: 16px;
  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
  }

  :hover span {
    border-color: #ccc;
  }
`

export const SpanCheckMarkStyled = styled.span`
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  background-color: #FFFFFF;
  border: solid 3px #EEF1F3;
  box-sizing: border-box;
  border-radius: 3px;

  svg {
    color: #3F51B5;
    height: unset;
    width: unset;
  }

  img {
    width: 25px;
    height: 25px;
    display: none;
  }
`

export const ImgContainer = styled.div`
  width: 40px !important;
  height: 40px !important;

  margin-right: 10px;
`
