import styled from 'styled-components'

import { device } from '@/Models/device'

export const LabelStyled = styled.span`
  margin-bottom: 15px;
  margin-top: 0;
  font-weight: bold;
  display: block;
  vertical-align: middle;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  text-align: center;

  @media screen and ${device.mobile} {
    font-size: 18px;
    margin-bottom: 5px;
  }
`

export const SpanStyled = styled.span`
  border: 3px solid rgba(0,0,0,0);
  box-sizing: border-box;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  :hover {
    border: 3px solid #266DC2;
    box-shadow: 0 0 6px #266DC2;
  }
  &.selected {
    box-shadow: 0 0 6px #266DC2;
    animation: glowing 300ms 2;
    @keyframes glowing {
    0% { border-color: #ffffff; box-shadow: 0 0 6px #ffffff; }
    50% { border-color:#266DC2; box-shadow: 0 0 15px #266DC2; }
    100% { border-color:#ffffff; box-shadow: 0 0 6px #ffffff; }
    }
  }

  @media screen and ${device.mobile} {
    border: 4px solid rgba(0,0,0,0);
    // width: 33%;

    &.selected {
      box-shadow: 0 0 4px #266DC2;
      animation: glowing 300ms 2;
      @keyframes glowing {
        0% {
          border-color: #ffffff;
          box-shadow: 0 0 4px #ffffff;
        }
        50% {
          border-color: #266DC2;
          box-shadow: 0 0 8px #266DC2;
        }
        100% {
          border-color: #ffffff;
          box-shadow: 0 0 4px #ffffff;
        }
      }
    }
  }
`
