import BackendAdapter from '@/Api/_adapter'

export const apiSendToReview = async (surveyId: string, comment: string): Promise<boolean> => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/review/send`, { comment })
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiRejectReview = async (surveyId: string, comment: string): Promise<boolean> => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/review/reject`, { comment })
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetReviewSteps = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/review`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
