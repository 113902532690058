import BackendAdapter from '@/Api/_adapter'
import { IServiceIn } from '@/Stores/AdminStores/adminServicesStore'

export const apiGetServices = async (surveyId: string): Promise<IServiceIn[]> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/services`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSyncServices = async (surveyId: string): Promise<null> => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/services/sync`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetService = async (serviceId: string): Promise<string> => {
  try {
    return await BackendAdapter.get(`/api/services/${serviceId}`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiUpdateSurveyService = async (surveyId: string, serviceId: string | null): Promise<void> => {
  try {
    return await BackendAdapter.patch(`/api/surveys/${surveyId}/service/${serviceId ?? ''}`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSyncGlobalServices = async (): Promise<null> => {
  try {
    return await BackendAdapter.post('/api/services/sync')
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetGlobalServices = async (): Promise<IServiceIn[]> => {
  try {
    return await BackendAdapter.get('/api/services')
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
