import styled from 'styled-components'


export const AddButtonContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 10px;
  & > button{
    background-color: rgba(197, 203, 211, 0.4);
  }
`

export const SaveButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 10px;
  
`
