import { ISurveyInstance, SurveyStatus, SurveyType } from '../Stores/AdminStores/surveysStore'

export const isArchivedHelper = (survey?: ISurveyInstance) => {
  return (survey && survey.status === SurveyStatus.Archived) ?? true
}

export const isSurveyedWithMainQuestionHelper = (survey?: ISurveyInstance) => {
  return (survey && (
    ([SurveyType.Repeatable, SurveyType.Gvk].includes(survey.type)) && survey.status === SurveyStatus.Surveyed
  )) ?? true
}
