import { flow, Instance, types as t } from 'mobx-state-tree'
import { difference } from 'lodash'

import { apiGetSurveyTrafficLightState, apiGetTrafficLightState } from '@/Api/survey'
import { TrafficLightColor, TrafficLightGroupType, TrafficLightPartType } from '@/Models/trafficLight'


export interface ITrafficLightPartElement extends Instance<typeof TrafficLightPartElement> {}

export interface ITrafficLightPart extends Instance<typeof TrafficLightPart> {}

export interface ITrafficLightGroup extends Instance<typeof TrafficLightGroup> {}

export interface ISurveyTrafficLightState extends Instance<typeof SurveyTrafficLightState> {}

export const TrafficLightPartElement = t.model({
  payload: t.string,
  isHidden: t.boolean,
  color: t.enumeration<TrafficLightColor>(Object.values(TrafficLightColor)),
})

export const TrafficLightPart = t.model({
  part: t.enumeration<TrafficLightPartType>(Object.values(TrafficLightPartType)),
  elements: t.map(TrafficLightPartElement),
}).views(self => ({
  get worstColor(): TrafficLightColor | null {
    const colors = Array.from(self.elements.values() ?? []).map(x => x.color)
    return colors.length > 0 ? colors.reduce((acc, curr) => (acc < curr ? curr : acc), TrafficLightColor.Green) : null
  },
})).actions(self => ({
  setElement: (payload: string | null, isHidden: boolean, color: TrafficLightColor) => {
    self.elements.set(payload ?? '', { payload: payload ?? '', isHidden: isHidden, color: color })
  },
  getState: (payload: string | null): ITrafficLightPartElement | undefined => {
    return self.elements.get(payload ?? '')
  },
  remove: (payload: string | null) => {
    self.elements.delete(payload ?? '')
  },
  clear: () => {
    self.elements.clear()
  },
  removeNotHidden: () => {
    Array.from(self.elements.values()).filter(x => !x.isHidden).forEach(x => {
      self.elements.delete(x.payload)
    })
  },
}))

export const TrafficLightGroup = t.model({
  group: t.enumeration<TrafficLightGroupType>(Object.values(TrafficLightGroupType)),
  parts: t.map(TrafficLightPart),
}).views(self => ({
  get worstColor(): TrafficLightColor | null {
    const colors = Array.from(self.parts.values() ?? []).map(x => x.worstColor).filter(x => x != null) as TrafficLightColor[]
    return colors.length > 0 ? colors.reduce((acc, curr) => (acc < curr ? curr : acc), TrafficLightColor.Green) : null
  },
})).actions(self => ({
  syncParts: (parts: Record<TrafficLightPartType, Array<{ payload: string | null, color: TrafficLightColor, isHidden: boolean }>>) => {
    const partsInSync = Object.entries(parts).map(x => x[0] as TrafficLightPartType)
    const partsInStore = Array.from(self.parts.values() ?? []).map(x => x.part)

    const deletedParts = difference(partsInStore, partsInSync)
    const addedParts = difference(partsInSync, partsInStore)

    deletedParts.forEach(x => {
      const part = self.parts.get(x)
      part?.removeNotHidden()
    })

    addedParts.forEach(x => {
      self.parts.set(x, { part: x, elements: {} })
    })

    Object.entries(parts).forEach(([partType, elements]) => {
      const part = self.parts.get(partType)

      if (!part) {
        throw new Error('Часть светофора не найдена при повторной проверке')
      }

      Array.from(part.elements.values()).filter(x => !x.isHidden).forEach(x => {
        self.parts.delete(x.payload)
      })

      elements.forEach(x => {
        part.setElement(x.payload, x.isHidden, x.color)
      })
    })
  },
  getState: (partType: TrafficLightPartType): ITrafficLightPart | undefined => {
    return self.parts.get(partType)
  },
  getStateRequired: (partType: TrafficLightPartType): ITrafficLightPart | undefined => {
    const state = self.parts.get(partType)

    if (state) {
      return state
    }

    throw new Error('Обязательное состояние не найдено')
  },
  createIfNotExistAndGet: (partType: TrafficLightPartType, color: TrafficLightColor): ITrafficLightPart => {
    if (!self.parts.has(partType)) {
      self.parts.set(partType, { part: partType, elements: {} })
    }

    const surveyState = self.parts.get(partType)

    if (!surveyState) {
      throw new Error('Не найдено состояние атрибута')
    }

    return surveyState
  },
  addPartWithElements: (partType: TrafficLightPartType, elements: Array<{ payload: string | null, isHidden: boolean, color: TrafficLightColor }>) => {
    self.parts.set(partType, { part: partType, elements: {} })
    const part = self.parts.get(partType)!

    elements.forEach(x => {
      part.setElement(x.payload, x.isHidden, x.color)
    })
  },
  removeState: (partType: TrafficLightPartType) => {
    self.parts.delete(partType)
  },
  findByPrefix: (prefix: string): string[] => {
    return Array.from(self.parts.keys() ?? []).filter(x => x.startsWith(prefix))
  },
  removeNotHidden: () => {
    self.parts.forEach(x => x.removeNotHidden())
  },
}))

export const SurveyTrafficLightState = t.model({
  surveyId: t.string,
  groups: t.map(TrafficLightGroup),
}).views(self => ({
  get worstColor(): TrafficLightColor | null {
    const colors = Array.from(self.groups.values() ?? []).map(x => x.worstColor).filter(x => x != null) as TrafficLightColor[]
    return colors.length > 0 ? colors.reduce((acc, curr) => (acc < curr ? curr : acc), TrafficLightColor.Green) : null
  },
})).actions(self => ({
  /**
   * @isHidden Фильтрация на основе видимости части светофора
  */
  loadTrafficLight: flow(function * (isHidden?: boolean) {
    const trafficLight: { groups: Record<TrafficLightGroupType, { parts: Record<TrafficLightPartType, Array<{ payload: string | null, color: TrafficLightColor, isHidden: boolean }>> }> } = yield apiGetSurveyTrafficLightState(self.surveyId, isHidden)

    const groupsInSync = Object.entries(trafficLight.groups).map(x => x[0] as TrafficLightGroupType)
    const groupsInStore = Array.from(self.groups.values() ?? []).map(x => x.group)

    const deletedGroups = difference(groupsInStore, groupsInSync)
    const addedGroups = difference(groupsInSync, groupsInStore)

    deletedGroups.forEach(x => {
      const deletedGroup = self.groups.get(x)
      deletedGroup?.removeNotHidden()
    })

    addedGroups.forEach(x => {
      self.groups.set(x, { group: x, parts: {} })
    })

    Object.entries(trafficLight.groups).forEach(([groupType, parts]) => {
      const group = self.groups.get(groupType)

      if (!group) {
        throw new Error('Группа светофора не найдена при повторной проверке')
      }

      group.syncParts(parts.parts)
    })
  }),
  getGroup: (group: TrafficLightGroupType): ITrafficLightGroup | undefined => {
    return self.groups.get(group)
  },
  addGroupIfNotExist: (group: TrafficLightGroupType) => {
    if (!self.groups.has(group)) {
      self.groups.set(group, { group: group, parts: {} })
    }
  },
  createIfNotExistAndGet: (group: TrafficLightGroupType): ITrafficLightGroup => {
    if (!self.groups.has(group)) {
      self.groups.set(group, { group: group, parts: {} })
    }

    const state = self.groups.get(group)

    if (!state) {
      throw new Error('Не найдено состояние вкладки')
    }

    return state
  },
}))

export const TrafficLightStateStore = t.model({
  surveys: t.map(SurveyTrafficLightState),
}).actions(self => ({
  loadTrafficLights: flow(function * () {
    const trafficLights: Record<string, { groups: Record<TrafficLightGroupType, { parts: Record<TrafficLightPartType, Array<{ payload: string | null, color: TrafficLightColor, isHidden: boolean }>> }> }> = yield apiGetTrafficLightState()

    Object.entries(trafficLights).forEach(([surveyId, trafficLight]) => {
      let survey = self.surveys.get(surveyId)

      if (!survey) {
        self.surveys.set(surveyId, { surveyId: surveyId, groups: {} })
        survey = self.surveys.get(surveyId)
      }

      Object.entries(trafficLight.groups).forEach(([groupType, groupParts]) => {
        let group = survey?.groups.get(groupType)

        if (!group) {
          survey?.groups.set(groupType, { group: (groupType as TrafficLightGroupType), parts: {} })
          group = survey?.groups.get(groupType)
        }

        Object.entries(groupParts.parts).forEach(([partType, elements]) => {
          const partElements = elements.map(x => ({ payload: x.payload, isHidden: x.isHidden, color: x.color }))
          group?.addPartWithElements((partType as TrafficLightPartType), partElements)
        })
      })
    })
  }),
  getState: (surveyId: string): ISurveyTrafficLightState | undefined => {
    return self.surveys.get(surveyId)
  },
  addStateIfNotExist: (surveyId: string) => {
    if (!self.surveys.has(surveyId)) {
      self.surveys.set(surveyId, { surveyId: surveyId, groups: {} })
    }
  },
  createIfNotExistAndGet: (surveyId: string): ISurveyTrafficLightState => {
    if (!self.surveys.has(surveyId)) {
      self.surveys.set(surveyId, { surveyId: surveyId, groups: {} })
    }

    const surveyState = self.surveys.get(surveyId)

    if (!surveyState) {
      throw new Error('Не найдено состояние опроса')
    }

    return surveyState
  },
}))

