import { useState, ChangeEvent, FocusEvent } from 'react'
import { Button, Dialog, TextField } from '@mui/material'

import { ButtonContainer, MainContainer, TitleContainer } from './styled'

interface ICategoryCreator {
  defaultValue?: string
  isOpen: boolean
  existCategoryName: string[]
  onClose: () => void
  onSave: (name: string) => void
}

const CategoryCreator = ({ defaultValue, isOpen, existCategoryName, onClose, onSave }: ICategoryCreator) => {
  const [categoryName, setCategoryName] = useState<string>(defaultValue ?? '')
  const [error, setError] = useState<string | undefined>(undefined)

  const validateInput = (value: string) => {
    setError(undefined)

    const formatRegex = /^[a-zA-Zа-яёА-ЯЁ0-9().,!:/?\-"`<>@%*+=№;#_$^&~\\{}[\]'|\s]{1,100}$/

    if (!formatRegex.test(value)) {
      setError('Недоступный символ или превышено количество символов в поле-100)')
      return false
    }

    if (existCategoryName.includes(value)) {
      setError(`Категория с именем ${value} уже существует`)
      return false
    }

    return true
  }

  const handleTrySave = () => {
    if (validateInput(categoryName)) {
      onSave(categoryName)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError(undefined)
    const value = e.target.value
    if (value.length > 100) return
    setCategoryName(value)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const trimedValue = e.target.value.trim()
    if (validateInput(trimedValue)) {
      setCategoryName(trimedValue)
    }
  }

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <MainContainer>
        <TitleContainer>
          <TextField
            label="Название категории"
            placeholder="Введите название"
            value={categoryName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={error}
            error={!!error}
            variant="standard"
          />
        </TitleContainer>
        <ButtonContainer>
          <Button disabled={!!error} onClick={handleTrySave} color="primary">
            {!defaultValue ? 'Создать' : 'Сохранить'}
          </Button>
        </ButtonContainer>
      </MainContainer>
    </Dialog>
  )
}

export default CategoryCreator
