import { Grid, Pagination } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import { DialogState } from '@/Enums/dialogState'
import { ReactComponent as ExcelIcon } from '@/Images/excel.svg'
import { ReactComponent as AnswerReportIcon } from '@/Images/downloadDocument.svg'
import { ToolTip } from '@/Components'
import { apiGetSurveyMainQuestionsStatisticReport } from '@/Api/manage'
import { ISurveyInstance, SurveyType } from '@/Stores/AdminStores/surveysStore'
import { DisplayMode } from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/PageAnswers'
import bem from '@/Helpers/BemClass'
import { VerticalButton } from '@/Components/VerticalButton'

import './style.scss'

const cntAnswerTab = bem()('answer-tab')

// TODO: улучшить тайпинги
interface IHeader {
  surveyId: string
  pageAnswersCount: number
  setDialogState: (dialogState: DialogState) => void
  survey: ISurveyInstance | undefined
  selectedPage: number
  setSelectedPage: (selectedPage: number) => void
  startWaiting: (name: string) => void
  endWaiting: (name: string) => void
  isWaiting: (name: string) => boolean
  displayMode: DisplayMode
}

export const Header = ({
  surveyId,
  pageAnswersCount,
  setDialogState,
  survey,
  selectedPage,
  setSelectedPage,
  startWaiting,
  endWaiting,
  isWaiting,
  displayMode,
}: IHeader) => {
  const muiTheme = useTheme()
  const UIisMobileXs = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const UIisTabletLg = useMediaQuery(muiTheme.breakpoints.down('xl'))

  const handleGetSurveyMainQuestionsStatisticReport = async () => {
    startWaiting('handleGetSurveyMainQuestionsStatisticReport')
    await apiGetSurveyMainQuestionsStatisticReport(surveyId)
    endWaiting('handleGetSurveyMainQuestionsStatisticReport')
  }

  const handleGetAnswersReport = async () => {
    setDialogState(DialogState.Showed)
  }

  return (
    <Grid className={cntAnswerTab('tab-header')}>
      {pageAnswersCount > 1 && <Pagination
        className={cntAnswerTab('pagination-top')}
        size={UIisMobileXs ? 'small' : (UIisTabletLg ? 'medium' : 'large')}
        page={selectedPage}
        onChange={(_, number) => setSelectedPage(number)}
        count={pageAnswersCount}
      />}
      <div className={cntAnswerTab('reports', { empty: pageAnswersCount <= 0 })}>
        {pageAnswersCount > 0 && displayMode !== DisplayMode.User &&
          survey?.type === SurveyType.Repeatable && (
          <Grid>
            <VerticalButton
              disabled={isWaiting('handleGetSurveyMainQuestionsStatisticReport')}
              onClick={handleGetSurveyMainQuestionsStatisticReport}
              startIcon={<ExcelIcon width={48} height={48} />}
            >
              <ToolTip arrow title='Скачать отчёт "Рейтинг"'>
                <span>
                    Отчёт <br />
                    рейтинг
                </span>
              </ToolTip>
            </VerticalButton>
          </Grid>
        )}
        {pageAnswersCount > 0 && displayMode !== DisplayMode.User && (
          <Grid>
            <VerticalButton
              disabled={isWaiting('handleGetAnswersReport')}
              onClick={handleGetAnswersReport}
              startIcon={<AnswerReportIcon width={48} height={48} />}
            >
              <ToolTip arrow title='Скачать отчёт "Ответы"'>
                <span>
                  Отчёт <br />
                  ответы
                </span>
              </ToolTip>
            </VerticalButton>
          </Grid>
        )}
      </div>
    </Grid>
  )
}

export default Header
