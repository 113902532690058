import styled from 'styled-components'

export const StyledQuestionTitle = styled.h4`
  font-style: normal;
  font-weight: normal;
  // line-height: 130%;
  word-break: break-word;
  margin: 0;
`

// Контейнер для комментария у вопроса. Используется внутри CSSTransition
export const CommentContainer = styled.div`
  position: relative;

  &.enter {
    height: 0;
    opacity: 0;
  }

  &.enter-active {
    height: 115px;
    opacity: 1;
    transition: height 700ms ease-in-out, opacity 700ms ease-in-out;
  }

  &.exit {
    height: 115px;
    opacity: 1;
  }

  &.exit-active {
    height: 0;
    opacity: 0;
    transition: height 700ms ease-in-out, opacity 700ms ease-in-out;
  }
`

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90%;
  max-width: 680px;
  max-height: 190px;
  background-color: #fff;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  padding: 10px 3px 44px;
  transform: translate(-50%, -50%);
`
