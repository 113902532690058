import { observer } from 'mobx-react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { QuestionType } from '@/Models/page'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

const allQuestions = [
  QuestionType.rating,
  QuestionType.text,
  QuestionType.radio,
  QuestionType.checkbox,
  QuestionType.slider,
  QuestionType.radioWithImage,
  QuestionType.checkboxWithImage,
  QuestionType.attachment,
  QuestionType.spreadsheet,
]

interface IQuestionTypeSelector {
  question: IQuestionInstance
  disabled?: boolean
  allowQuestions?: QuestionType[]
}

const QuestionTypeSelector = ({ question, disabled, allowQuestions = allQuestions }: IQuestionTypeSelector) => {
  const allQuestionNamesMap = new Map<QuestionType, string>([
    [QuestionType.rating, 'Рейтинг'],
    [QuestionType.text, 'Текст'],
    [QuestionType.radio, 'Один из множества'],
    [QuestionType.checkbox, 'Несколько из множества'],
    [QuestionType.slider, 'Шкала'],
    [QuestionType.radioWithImage, 'Изображение. Одно из множества'],
    [QuestionType.checkboxWithImage, 'Изображение. Несколько из множества'],
    [QuestionType.attachment, 'Вложение'],
    [QuestionType.spreadsheet, 'Таблица'],
  ])

  return (<FormControl fullWidth variant="standard">
    <InputLabel shrink id="main-question-type-label">Тип вопроса</InputLabel>
    <Select
      labelId="main-question-type-label"
      value={question.type}
      onChange={e => question.changeType(e.target.value as QuestionType)}
      disabled={disabled}
    >
      {Array.from(allQuestionNamesMap.entries()).filter(([type, _]: [QuestionType, string]) => allowQuestions.includes(type))
        .map(([type, name]: [QuestionType, string], i) => <MenuItem key={i} value={type}>{name}</MenuItem>)}
    </Select>
  </FormControl>)
}

export default observer(QuestionTypeSelector)
