import styled from 'styled-components'

import { device } from '@/Models/device'

export const MainQuestionContainer = styled.div`
    margin-bottom: 28px;
`

export const MainQuestionFooterContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > .skip {
    margin-left: auto;

    @media screen and ${device.mobile} {
      width: 335px;
      position: absolute;
      bottom: -25px;
      right: 0;
    }
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 24px;
  padding-right: 24px;
}

  @media screen and ${device.mobile} {
    margin-left: 10px;
    margin-right: 10px;
  }

`

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  position: sticky;
  bottom: 0;
  background-color: #fff;

  @media screen and ${device.mobile} {
    margin-bottom: 2.5rem;

    position: unset;
    bottom: unset;
  }
`

export const AdditionalQuestionContainer = styled.div`
  .MuiAccordionDetails-root{
    flex-direction: column;
    padding: 0;
    margin-top: 40px;
    margin-left: 18px;
    & > *:first-child{
      margin-top: 0;
    }
  }
  .MuiAccordion-root{
    border-radius: 0;
    border: none;
    box-shadow: none;
  }
  .MuiAccordionSummary-root{
    padding: 0;
    min-height: 0 !important;
  }
  .MuiAccordionSummary-content{
    margin: 0 !important;
  }
`
export const RightAlignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  @media screen and ${device.mobile} {
    & > div {
      position: relative;
      margin-top: 55%;
      max-width: 30%;
      margin-right: -37%;
    }
  }
`

export const LeftAlignContainer = styled.div`
  @media screen and ${device.mobile} {
    & > div {
      position: absolute;
      max-width: 15%;

      & > button {
        margin-top: -15%;
      }
    }
  }
`
