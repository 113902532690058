import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const AccordionSummaryContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 16px 0px;

  & > * {
    flex: 1;
  }
`
