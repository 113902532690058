import { BarContainer, Container, CountStyle, InfoRowContainer, ProgressStyled } from './styled'

interface IProgressBar {
  totalCount: number
  completed: number
}

const ProgressBar = ({ totalCount, completed }: IProgressBar) => {
  const width = 100 / totalCount
  // иконки занимают примерно 0.2 от ширины всей шкалы
  // const ProgressWidthAndIconsWidthInCount = completed + Math.ceil(totalCount * 0.2)
  const createChunks = () => {
    const result = []
    for (let i = 0; i < totalCount; i++) {
      result.push(<ProgressStyled key={i}
        style={{ background: i < completed + 1 ? '#266DC2' : '#EEF1F3', width: `${width}%` }}/>)
    }
    return result
  }
  return (<Container>
    <InfoRowContainer>
      <CountStyle>{completed + 1}/{totalCount}</CountStyle>
    </InfoRowContainer>
    <BarContainer>
      {createChunks()}
    </BarContainer>
  </Container>)
}

export default ProgressBar
