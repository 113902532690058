import EditorModal from '@/Components/EditorModal'
import { htmlTagsIsEmpty } from '@/Helpers/htmlToTextCustom'
import htmlSafeParse from '@/Helpers/htmlSafeParseHelper'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnTextEditor = bem()('text-editor')

interface ITextEditor {
  /** Заголовок модального окна редактора */
  title: string
  /** Текст если текст в редакторе пуст */
  placeholder: string
  /** Значение введенное пользователем */
  value: string
  /** Признак открытого окна редактирования */
  isOpen: boolean
  /** Событие открытия редактора */
  onOpen: () => void
  /** Событие закрытия диалога */
  onClose: () => void
  /** Событие изменения поля ввода */
  onChange: (value: string) => void
  /** Отображать как ошибку + добавить класс ошибки */
  error: boolean
  /** Признак отключения редактора */
  disabled?: boolean
  /** Признак отключения загрузки картинок */
  disableImage?: boolean
}

const TextEditor = ({ title, placeholder, value, isOpen, onOpen, onClose, onChange, error, disabled = false, disableImage }: ITextEditor) => {
  return <div className={cnTextEditor({ responsive: !disabled })}>
    <div
      className={`${cnTextEditor('preview', { error })}
      ${error ? 'error' : ''}`}
      onClick={!disabled ? onOpen : () => {}}
    >
      {htmlTagsIsEmpty(value) ? <p>{placeholder}</p> : htmlSafeParse(value)}
    </div>
    {/* Да, isOpen выглядит странно т.к. есть props isOpen, но таким образом идет очистка состояния редактора, чтобы сбросить к дефолтным настройкам.
    Это требуется для редактора нового вопроса */}
    {isOpen && <EditorModal
      isOpen={isOpen}
      handleClose={onClose}
      content={value}
      OnChange={onChange}
      title={title}
      disableImage={disableImage}
    />}
  </div>
}

export default TextEditor
