import { CSSProperties } from 'react'
import { useLocation } from 'react-router'
import { observer } from 'mobx-react'
import { Button, IconButton, ListItem } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import { IServiceInstance } from '@/Stores/AdminStores/adminServicesStore'
import { SurveyType } from '@/Stores/AdminStores/surveysStore'
import { PhotoInput } from '@/Components'
import { IDialog } from '@/Components/Dialog'
import { useRouteManager } from '@/Hooks/useRouteManager'

import { ImgContainer, LabelStyled, SpanCheckMarkStyled } from './styled'

interface IServiceListItem {
  service: IServiceInstance
  setDialogState: (item: IDialog | undefined) => void
  isChecked: boolean
  handleCheckChange: (service: IServiceInstance) => void
  surveyType?: SurveyType
  style: CSSProperties
  disabled: boolean
}

const ServiceListItem = ({ service, setDialogState, isChecked, handleCheckChange, surveyType, style, disabled }: IServiceListItem) => {
  const routeManager = useRouteManager()
  const location = useLocation()

  const handleDelete = async () => {
    await service.removePage()
    setDialogState(undefined)
  }

  const handlePhotoChange = async (imageId: string) => {
    await service.changeImageId(imageId)
  }

  return (<ListItem className="listItem" style={{ paddingLeft: surveyType === SurveyType.Gvk ? 46 : 0, ...style }} key={service.id}>
    {surveyType === SurveyType.Gvk && <LabelStyled>
      {!disabled && <input
        type="checkbox"
        checked={isChecked}
        onChange={() => handleCheckChange(service)}
      />}
      {!disabled &&
        <SpanCheckMarkStyled>
          {isChecked && <CheckIcon/>}
        </SpanCheckMarkStyled>
      }
    </LabelStyled>}
    <ImgContainer>
      {service.pageId && <PhotoInput
        width={40}
        height={40}
        cropHeight={125}
        cropWidth={125}
        rounded
        imageUrl={getStaticsHybridUrl(`/api/file/image/${service.imageId ?? ''}`)}
        onChange={handlePhotoChange}
        preLoadImage={false}
        iconColor={'white'}
        disabled={disabled}
      />}
    </ImgContainer>
    {service.name}
    {service.hasPage && <CheckIcon style={{ marginLeft: '10px', color: 'blue' }} fontSize="large"/>}
    <IconButton edge="end"
      onClick={() => routeManager.rawRedirectTo(`${location.pathname}/${service.id}`)}>
      <EditIcon/>
    </IconButton>
    {!disabled &&
      <IconButton edge="end"
        onClick={() => setDialogState(
          {
            open: true,
            handleClose: () => setDialogState(undefined),
            title: 'Удаление опроса',
            text: 'Вы действительно хотите удалить опрос?',
            actions: [
              <Button onClick={() => setDialogState(undefined)} key="no">Нет</Button>,
              <Button onClick={handleDelete} key="yes">Да</Button>,
            ],
          })
        }
      >
        <DeleteIcon/>
      </IconButton>
    }
  </ListItem>)
}

export default observer(ServiceListItem)
