import { useEffect, useState } from 'react'
import {
  Container,
  Grid,
  Box,
  Typography,
  Grow,
  Collapse,
  Pagination,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import _ from 'lodash'

import { IPageAnswersInfo } from '@/Models/answer'
import { useWaiter } from '@/Helpers/react-wait'
import { apiGetAnswersBySurveyPagesWithUserAnswers } from '@/Api/answer'
import { ContentLoader } from '@/Components'
import { ISurveyInstance } from '@/Stores/AdminStores/surveysStore'
import { useMst } from '@/Stores/rootStore'
import {
  DisplayMode,
  PageAnswers,
} from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/PageAnswers'
import { Header } from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/Header'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnSurveyPreviewAnswerView = bem()('survey-preview-answer-view')

// TODO: улучшить тайпинги
interface IAnswersView {
  surveyId: string
  displayMode: DisplayMode
}

export enum DialogState {
  Closed,
  SelectAnswerReport,
}

export const AnswersView = ({ surveyId, displayMode }: IAnswersView) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const UIisTablet = useMediaQuery(muiTheme.breakpoints.down('md'))
  const UIisTabletLg = useMediaQuery(muiTheme.breakpoints.down('xl'))
  const { startWait, stopWait, isWaiting } = useWaiter()
  const [answers, setAnswers] = useState<IPageAnswersInfo[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [survey, setSurvey] = useState<ISurveyInstance>()
  const surveysStore = useMst().admin.surveysStore
  const [selectedPage, setSelectedPage] = useState(1)

  useEffect(() => {
    (async () => {
      const survey = surveysStore.surveys.find((x) => x.surveyId === surveyId)
      setSurvey(survey)
      const apiRes: IPageAnswersInfo[] = await apiGetAnswersBySurveyPagesWithUserAnswers(surveyId)
      setAnswers(apiRes)
      setIsLoading(false)
    })()
  }, [])

  const elNoDataAvailGeneral = (
    <Grid item xs={12} className={cnSurveyPreviewAnswerView('answers-page')}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-center'
        alignItems='center'
        paddingY={1}
        height='100%'
      >
        <Typography variant='h6' gutterBottom>
          Недостаточно данных для отображения
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          Создайте страницы с вопросами и дождитесь появления ответов
        </Typography>
      </Box>
    </Grid>
  )

  return (
    <div className={cnSurveyPreviewAnswerView({ mobile: UIisTablet })}>
      <ContentLoader style={isLoading ? {} : { display: 'none' }} />
      <Container className={cnSurveyPreviewAnswerView('container', { mobile: UIisTablet })} maxWidth={false}>
        <Collapse in={!isLoading} timeout={{ enter: 900 }}>
          <Header
            pageAnswersCount={answers.length}
            setDialogState={() => {}}
            surveyId={surveyId}
            survey={survey}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            startWaiting={startWait}
            endWaiting={stopWait}
            isWaiting={isWaiting}
            displayMode={DisplayMode.User}
          />
        </Collapse>
        <Box paddingY={1}>
          <Grow in={!isLoading} timeout={700}>
            <Grid
              className={cnSurveyPreviewAnswerView('answers-pages-wrapper')}
              container
              direction='column'
              justifyContent='space-between'
            >
              {(function() {
                // если опрос пустой (нет вопросов/страниц), показываем сообщение о нехватке данных для отображения
                if (answers.length === 0) return elNoDataAvailGeneral
                else {
                  // данные есть, можем рендерить их
                  return (
                    <PageAnswers
                      displayMode={displayMode}
                      pageAnswersInfo={answers[selectedPage - 1]}
                      order={selectedPage}
                      isTableViewMode={false}
                      setIsTableViewMode={() => {}}
                    />
                  )
                }
              })()}
            </Grid>
          </Grow>
        </Box>
        <Pagination
          className={cnSurveyPreviewAnswerView('pagination-bottom')}
          size={UIisMobile ? 'small' : (UIisTabletLg ? 'medium' : 'large')}
          page={selectedPage}
          onChange={(_, number) => setSelectedPage(number)}
          count={answers.length}
        />
      </Container>
    </div>
  )
}

export default AnswersView
