import { ChangeEvent, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import SyncIcon from '@mui/icons-material/Sync'
import PostAddIcon from '@mui/icons-material/PostAdd'

import { useMst } from '@/Stores/rootStore'
import bem from '@/Helpers/BemClass'
import {
  OrganizationStructureSectionConfirmedStaff,
  OrganizationStructureSectionConfirmedUnits,
  OrganizationStructureSectionTreeViewWithExpanding,
} from '@/Components/OrganizationStructure'
import Dialog, { IDialog } from '@/Components/Dialog'
import { apiParseElementsArrayFromFile } from '@/Api/organizationStructure'
import { FileType } from '@/Enums/fileType'

import './style.scss'

const cnOrganizationStructureTreeViewExtendedWithConfirm = bem()('organization-structure-tree-view-extended-with-confirm')

export enum SectionExpandingState {
  Units,
  Staff,
  All,
}

interface IOrganizationStructureTreeViewExtendedWithConfirm {
  organizationStructureStateSelector: string
  onSave?: (organizationStructureStateSelector: string) => Promise<void>
  onDraftSave?: (organizationStructureStateSelector: string) => Promise<void>
  disabled?: boolean
  disableSaveButton?: boolean
  disableDraftSaveButton?: boolean
  externalLoading?: boolean
  noActions?: boolean
  readonly: boolean
}

const OrganizationStructureTreeViewExtendedWithConfirm = ({
  organizationStructureStateSelector,
  onSave,
  onDraftSave,
  disabled = false,
  disableSaveButton = false,
  disableDraftSaveButton = false,
  externalLoading = false,
  noActions = false,
  readonly,
}: IOrganizationStructureTreeViewExtendedWithConfirm) => {
  const store = useMst()
  const organizationStructureStore = store.admin.organizationStructureStore
  const tabStore = store.admin.tabStore

  const organizationStructureState = organizationStructureStore.getOrganizationStructureState(organizationStructureStateSelector)
  const [innerLoading, setInnerLoading] = useState<boolean>(false)
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const [sectionExpandingState, setSectionExpandingState] = useState<SectionExpandingState>(SectionExpandingState.All)
  const [updatedState, setUpdatedState] = useState(Date.now())

  const isDisabled = noActions || disabled

  const handleSave = async () => {
    setInnerLoading(true)
    await onSave?.(organizationStructureStateSelector)
    setInnerLoading(false)
  }

  const handleDraftSave = async () => {
    setInnerLoading(true)
    await onDraftSave?.(organizationStructureStateSelector)
    setInnerLoading(false)
  }

  const showMessage = (title: string, text: string) => {
    setDialogState({
      open: true,
      handleClose: () => setDialogState(undefined),
      title: title,
      text: text,
      actions: [<Button onClick={() => setDialogState(undefined)} key='ok'>ОК</Button>],
    })
  }

  const handleConfirm = () => {
    organizationStructureState?.syncConfirmationWithIsSelected()
    tabStore.setWasChange(true, 'Были внесены изменения в целевую аудиторию')
  }

  const handleFileAdd = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const file: File = e.target.files[0]
    if (!['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']?.includes(file.type)) {
      showMessage('Не удалось загрузить файл', `Выбран файл с недопустимым типом: ${file.type}`)
      return
    }
    setInnerLoading(true)
    const formData = new FormData()
    formData.append('file', file)
    try {
      // Проверка на расширение нужна, т.к. по дефолту csv приходит с mime типом xls
      const fileType = (file.name.endsWith('.csv') || file.type === 'text/csv') ? FileType.Csv : (file.type === 'application/vnd.ms-excel' ? FileType.Xls : FileType.Xlsx)
      const data = await apiParseElementsArrayFromFile(formData, fileType)
      const elements = organizationStructureState?.selectElements(data)

      if ((elements?.length ?? 0) > 0) {
        showMessage('', 'В загруженном файле обнаружены элементы, отсутствующие в текущей версии оргструктуры. Они добавлены в качестве конфликтов в блок "Выбранные элементы"')
      }

      tabStore.setWasChange(true, 'Были внесены изменения в целевую аудиторию')
    } catch (e) {
      // Тут ошибка это ок - значит файл был не валидный.
      // Чтобы ошибка не распространялась на код, приводя к невалидному состоянию кода, мы ее просто ловим
      // Сама ошибка отловится через notifyer и будет показана админу
      // eslint-disable-next-line no-console
      console.log(e)
    }

    e.target.value = ''

    setInnerLoading(false)
  }

  const handleExpandChange = (initiator: SectionExpandingState) => () => {
    setSectionExpandingState(prev => {
      if (prev !== SectionExpandingState.All && prev !== initiator) {
        return SectionExpandingState.All
      }

      return [SectionExpandingState.Staff, SectionExpandingState.Units].find(x => x !== initiator) ?? SectionExpandingState.All
    })
  }

  const isLoading = externalLoading || innerLoading

  return <>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={5}
      className={cnOrganizationStructureTreeViewExtendedWithConfirm()}
    >
      <div className={cnOrganizationStructureTreeViewExtendedWithConfirm('section-data-column-container')}>
        <Typography className={cnOrganizationStructureTreeViewExtendedWithConfirm('section-name')}>Доступные элементы</Typography>
        <div className={cnOrganizationStructureTreeViewExtendedWithConfirm('section-data-container', { half: false })}>
          {isLoading ? <Skeleton variant='rounded' width='100%' height='100%' /> : <OrganizationStructureSectionTreeViewWithExpanding
            organizationStructureStateSelector={organizationStructureStateSelector}
            disabled={disabled}
            readonly={noActions}
          />}
        </div>
      </div>
      <div className={cnOrganizationStructureTreeViewExtendedWithConfirm('arrow-space')}>
        <div>
          <div className={cnOrganizationStructureTreeViewExtendedWithConfirm('arrow-backplate', { 'active': !organizationStructureState?.isAllSelectedConfirmed })}>
            <IconButton disabled={disabled || organizationStructureState?.isAllSelectedConfirmed } onClick={handleConfirm} className={cnOrganizationStructureTreeViewExtendedWithConfirm('arrow-button')}>
              {organizationStructureState?.isAllSelectedConfirmed ? <DragHandleIcon fontSize='large'/> : <SyncIcon fontSize='large'/>}
            </IconButton>
          </div>
          {!readonly && <div className={cnOrganizationStructureTreeViewExtendedWithConfirm('arrow-backplate', { 'active': true })}>
            <IconButton component='label' disabled={disabled} className={cnOrganizationStructureTreeViewExtendedWithConfirm('arrow-button')}>
              <input hidden multiple={false} accept='.csv,.xls,.xlsx' type='file' onChange={handleFileAdd} />
              <PostAddIcon fontSize='large'/>
            </IconButton>
          </div>}
        </div>
      </div>
      <div className={cnOrganizationStructureTreeViewExtendedWithConfirm('section-data-column-container')}>
        <Typography className={cnOrganizationStructureTreeViewExtendedWithConfirm('section-name')}>Выбранные элементы</Typography>
        <div
          className={cnOrganizationStructureTreeViewExtendedWithConfirm('section-data-container', {
            'half': sectionExpandingState === SectionExpandingState.All,
            'hidden': sectionExpandingState === SectionExpandingState.Staff,
            'full-visiable': sectionExpandingState === SectionExpandingState.Units,
          })}
          onTransitionEnd={() => setUpdatedState(Date.now()) }
        >
          {isLoading ? <Skeleton variant='rounded' width='100%' height='100%' /> : <OrganizationStructureSectionConfirmedUnits
            organizationStructureStateSelector={organizationStructureStateSelector}
            sectionExpandingState={sectionExpandingState}
            onExpandChange={handleExpandChange(SectionExpandingState.Units)}
            disabled={isDisabled}
          />}
        </div>
        <div
          className={cnOrganizationStructureTreeViewExtendedWithConfirm('section-data-container', {
            'half': sectionExpandingState === SectionExpandingState.All,
            'hidden': sectionExpandingState === SectionExpandingState.Units,
            'full-visiable': sectionExpandingState === SectionExpandingState.Staff,
          })}
        >
          {isLoading ? <Skeleton variant='rounded' width='100%' height='100%' /> : <OrganizationStructureSectionConfirmedStaff
            organizationStructureStateSelector={organizationStructureStateSelector}
            sectionExpandingState={sectionExpandingState}
            onExpandChange={handleExpandChange(SectionExpandingState.Staff)}
            disabled={isDisabled}
          />}
        </div>
      </div>
    </Stack>
    {<div className={cnOrganizationStructureTreeViewExtendedWithConfirm('button-container')}>
      {!readonly && onDraftSave && <LoadingButton
        variant='contained'
        disabled={disableDraftSaveButton || !organizationStructureState?.hasAnyConfirmed}
        loading={innerLoading}
        onClick={handleDraftSave}
        className={cnOrganizationStructureTreeViewExtendedWithConfirm('button-element')}
      >
        Сохранить как черновик
      </LoadingButton>}
      {!readonly && onSave && <LoadingButton
        variant='contained'
        disabled={disableSaveButton || !organizationStructureState?.hasAnyConfirmed}
        loading={innerLoading}
        onClick={handleSave}
        className={cnOrganizationStructureTreeViewExtendedWithConfirm('button-element')}
      >
        Сохранить
      </LoadingButton>}
    </div>}
    {dialogState && <Dialog {...dialogState} />}
  </>
}

export default observer(OrganizationStructureTreeViewExtendedWithConfirm)
