import { useEffect, useState } from "react";

export const useScroll = (onScroll: () => void) => {
  useEffect(() => {
    const onScrollEvent = onScroll;
    window.addEventListener("scroll", onScrollEvent);

    return () => {
      window.removeEventListener("scroll", onScrollEvent);
    };
  }, []);
};