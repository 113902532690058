import { useContext } from 'react'
import { useMount } from 'react-use'
import { useParams } from 'react-router'

import { surveyRedirect } from '@/Helpers/surveyRedirect'
import { ILoaderState, LoaderStateContext } from '@/Components/LoaderContext'

import { RouteEnum } from '../../../Enums/route'
import { useRouteManager } from '../../../Hooks/useRouteManager'
import { useMst } from '../../../Stores/rootStore'
import { ISurveyInstance } from '../../../Stores/UserStores/userSurveysStore'


const DirectLink = () => {
  const loadingContext = useContext<ILoaderState>(LoaderStateContext)
  const { surveyId } = useParams<{ surveyId: string }>()
  const store = useMst()
  const pageStore = store.user.pageStore
  const selectedPagesState = store.user.selectedPagesState
  const routerManager = useRouteManager()

  loadingContext.changeIsLoading(true)

  useMount(() => {
    (async () => {
      await store.user.userSurveysStore.getUserSurveys()
      const survey = store.user.userSurveysStore.runningSurveys.find(x => x.surveyId === surveyId)
      if (!survey) {
        routerManager.redirectTo(RouteEnum.clientSurveys)
      }

      await surveyRedirect(survey as ISurveyInstance, pageStore, selectedPagesState, routerManager)
    })()
  })

  return <></>
}

export default DirectLink
