import { applySnapshot, flow, Instance, SnapshotIn, types as t } from 'mobx-state-tree'

import { apiGetServices, apiSyncServices } from '@/Api/service'
import { apiRemoveListPage, apiSetPageLogo, apiSyncPagesWithService } from '@/Api/page'

export interface IServiceIn extends SnapshotIn<typeof Service> {

}

export interface IServiceInstance extends Instance<typeof Service> {

}

export interface ICategory {
  id: string
  name: string
  services: IServiceInstance[]
}

const Service = t.model({
  /**  Id сервиса в системе опросов */
  id: t.string,
  /**  Id сервиса в внешней системе */
  foreignServiceId: t.string,
  surveyId: t.string,
  name: t.string,
  description: t.maybeNull(t.string),
  owner: t.maybeNull(t.string),
  isGvk: t.boolean,
  category: t.maybeNull(t.string),
  hasPage: false,
  imageId: t.maybe(t.string),
  pageId: t.maybeNull(t.string),
  lastPageVersion: t.maybeNull(t.string),
}).actions(self => ({
  removePage: flow(function * () {
    yield apiRemoveListPage(self.surveyId, [self.id])
    self.pageId = null
    self.lastPageVersion = null
    self.hasPage = false
  }),
  changeImageId: flow(function * (imageId: string) {
    yield apiSetPageLogo(self.surveyId, self.id, imageId)
    self.imageId = imageId
  }),
}))

export const AdminServicesStore = t.model({
  services: t.array(Service),
}).views(self => ({
  get categoryNames(): string[] {
    return [...new Set(self.services.map(x => x.category ?? ''))]
  },
  filteredServices(surveyId: string, nameFilter?: string, hasPageFilter?: boolean): IServiceInstance[] {
    let services: IServiceInstance[] = self.services.filter(x => x.surveyId === surveyId)
    if (hasPageFilter !== undefined) services = services.filter(x => x.hasPage === hasPageFilter)
    if (nameFilter) services = services.filter(x => x.name.toLowerCase().includes(nameFilter.toLowerCase()))
    return services
  },
  get categories(): ICategory[] {
    return this.categoryNames.map((name, i) => ({
      id: i.toString(),
      name: name,
      services: self.services.filter(x => x.category === name),
    }))
  },
  categoriesWithFilters(surveyId: string, nameFilter?: string, hasPageFilter?: boolean): ICategory[] { // TODO Подумать как кэшировать вычисления и не производить перерисовку слишком часто
    const services = this.filteredServices(surveyId, nameFilter, hasPageFilter)
    const categories = this.categoryNames.map((name, i) => ({
      id: i.toString(),
      name: name,
      services: services.filter(x => (x.category ?? '') === name),
    }))
    return categories.filter(x => x.services.length !== 0)
  },
  elementsWithFilters(surveyId: string, nameFilter?: string, hasPageFilter?: boolean): Array<ICategory | IServiceInstance> {
    const categories = this.categoriesWithFilters(surveyId, nameFilter, hasPageFilter)
    const result = new Array<ICategory | IServiceInstance>()
    categories.forEach(x => {
      result.push(x)
      result.push(...x.services)
    })
    return result
  },
})).actions(self => ({
  loadServices: flow(function * (surveyId: string) {
    const services = self.services.filter(x => x.surveyId !== surveyId)
    const res = yield apiGetServices(surveyId)
    applySnapshot(self.services, [...services, ...res])
    return res
  }),
  syncServices: flow(function * (surveyId: string) {
    const services = self.services.filter(x => x.surveyId !== surveyId)
    const res = yield apiSyncServices(surveyId) ?? []
    applySnapshot(self.services, [...services, ...res])
    // Синхронизируем страницы с сервисами (категория, заголовок, описание...)
    yield apiSyncPagesWithService(surveyId)
  }),
  getService: (serviceId: string): IServiceInstance | undefined => {
    return self.services.find(x => x.id === serviceId)
  },
  deleteServices: flow(function * (surveyId: string, serviceIds: string[]) {
    yield apiRemoveListPage(surveyId, serviceIds)
  }),
}))

