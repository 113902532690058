import { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Grid } from '@mui/material'

import { FileType } from '@/Models/page'
import { IFile } from '@/Models/file'
import { fileTypeNumToMime } from '@/Helpers/fileTypeNumToMime'
import { IAnswerInstance } from '@/Stores/UserStores/pageStore'
import { IDialog } from '@/Components/Dialog'
import { Dialog } from '@/Components/index'
import FileItem from '@/Components/FileItem'
import { isExpressSmartApp } from '@/applicationConfig'
import { apiSetFile } from '@/Api/file'

import { UserAnswersTitleTextStyled } from './styled'

interface IUserAddFiles {
  answer: IAnswerInstance
  maxFileCount: number
  acceptList?: FileType[]
}

const UserAddFiles = ({ answer, maxFileCount, acceptList }: IUserAddFiles) => {
  const [isLoading, setIsLoading] = useState(false)
  const maxFileSize = 30 * 1024 * 1024
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const acceptMimeList = acceptList?.map(x => fileTypeNumToMime(x) as string)

  const handleAddFile = (fileId: string) => {
    answer.setValue([...answer.value ?? [], { value: fileId, isCustom: false }])
  }

  const handleDeleteAnswer = (option: string) => () => {
    answer.setValue(answer.value.filter(x => x.value !== option) ?? [])
  }

  const showMessage = (text: string) => {
    setDialogState({
      open: true,
      handleClose: () => setDialogState(undefined),
      title: 'Не удалось загрузить файл',
      text: text,
      actions: [<Button onClick={() => setDialogState(undefined)} key="ok">ОК</Button>],
    })
  }

  const handleFileAdd = async (file: IFile, formFile: File | null, fileId: string | null) => {
    try {
      if (file.size > maxFileSize) {
        showMessage('Превышен лимит на размер файла в 30мб')
        return
      }
      if (acceptMimeList && acceptMimeList?.length > 0 && !acceptMimeList?.includes(file.contentType)) {
        showMessage('Выбран файл с недопустимым расширением')
        return
      }
      setIsLoading(true)

      if (!isExpressSmartApp()) {
        fileId = await apiSetFile(file)
      }

      if (answer.value.includes({ value: fileId ?? '', isCustom: false })) {
        showMessage('Этот файл уже был добавлен ранее')
        setIsLoading(false)
        return
      }

      handleAddFile(fileId ?? '')
      setIsLoading(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)
      setIsLoading(false)
    }
  }

  return (<>
    <UserAnswersTitleTextStyled>Поле ответа</UserAnswersTitleTextStyled>
    <Grid container spacing={2}>
      {answer.value.map((x, i) => <Grid key={x.value} item>
        <FileItem fileId={x.value} onDelete={handleDeleteAnswer(x.value)}/>
      </Grid>)}
      {isLoading && <Grid item ><FileItem displayFileMode={FileItem.DisplayFileMode.PlaceHolder}/></Grid>}
      {!isLoading && answer.value.length < maxFileCount && <Grid item ><FileItem onInputClick={handleFileAdd} displayFileMode={FileItem.DisplayFileMode.Add}/></Grid>}
    </Grid>
    {!isLoading && dialogState && <Dialog {...dialogState} />}
  </>)
}

export default observer(UserAddFiles)
