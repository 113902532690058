import styled from 'styled-components'

import { device } from '@/Models/device'

export const Container = styled.div`
  background: #EEF1F3;
  overflow-y: auto; // нужно в ограниченном по вертикали фрейме шарика
  width: 100%;
  height: 100%;
  padding: 10px 0 20px 0;

  @media screen and ${device.mobile} {
    display: none;
  }
`

export const HeaderBackdropStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  background: #FFFFFF;
  box-shadow: 0 7px 24px rgba(20, 30, 70, 0.15);
`

export const BackgroundStyled = styled.div`
  position: absolute;
  background: #FFFFFF;
  width: 1152px;
  left: calc(50% - 1152px/2);
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and ${device.mobile} {
    width: 90%;
    margin: auto;
  }
`

export const ContentContainer = styled.div`
  z-index: 100;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0 7px 24px rgba(20, 30, 70, 0.15);
  border-radius: 5px;
  padding: 10px 0 10px 0;
  margin: auto;

  @media screen and ${device.mobile} {
    width: 100%;
    height: auto;
    margin: auto;
    left: auto;
    top: 0;
  }
`

export const NavigationContainer = styled.div`
  z-index: 10;
  left: calc((100vw - 1100px) / 2);
  margin: 15px 0px;
  .MuiBreadcrumbs-ol > li {
    z-index: 11;
    & > button {
      vertical-align: initial;
    }
  }
`

export const NavigationElementsCollection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center
`

export const DataAreaContainer = styled.div`
  z-index: 10;
  left: calc((100vw - 1100px) / 2);
  margin-left: auto;
  margin-right: auto;

  @media screen and ${device.mobile} {
    width: unset;
  }
`
