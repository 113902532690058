import { useState, MouseEvent } from 'react'
import { observer } from 'mobx-react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { ISpreadsheetStoreInstance, InputType } from '@/Stores/SpreadsheetStore/spreadsheet'
import { ISpreadsheetCellsData } from '@/Models/spreadsheet'
import { SpreadsheetCell } from '@/Components/Spreadsheet/styled'
import Cell from '@/Components/Spreadsheet/Cell'
import bem from '@/Helpers/BemClass'

import './styles.scss'

const cnSpreadsheet = bem()('spreadsheet')

const numberToLetter = (columnNumber: number) => {
  let columnLetter = ''
  while (columnNumber > 0) {
    const remainder = (columnNumber - 1) % 26
    columnLetter = String.fromCharCode(65 + remainder) + columnLetter
    columnNumber = Math.floor((columnNumber - 1) / 26)
  }
  return columnLetter
}

interface ISpreadsheet {
  spreadsheetStoreInstance: ISpreadsheetStoreInstance
  data: ISpreadsheetCellsData
  disabled?: boolean
}

const Spreadsheet = ({ spreadsheetStoreInstance, disabled = false }: ISpreadsheet) => {
  const [menuPosition, setMenuPosition] = useState<{ x: number, y: number } | null>(null)

  const handleLineSelection = (line: 'row' | 'col', idx: number) => (e: MouseEvent) => {
    spreadsheetStoreInstance.selectLine(idx, line, !e.shiftKey)
  }

  const handleActivateCell = (inputType: InputType) => () => {
    setMenuPosition(null)
    spreadsheetStoreInstance.setSelectedAsEditable(inputType)
  }

  return <div className={cnSpreadsheet('container')}>
    <SpreadsheetCell
      key="sheet-corner"
      fromColumn={1}
      toColumn={1}
      fromRow={1}
      toRow={1}
      forceAddBorder
      className={cnSpreadsheet('cell', { 'corner': true })}
    >
    </SpreadsheetCell>
    {
      spreadsheetStoreInstance.rowsHeight.map((x, i) => <SpreadsheetCell
        key={`row-${i}`}
        fromColumn={1}
        toColumn={1}
        fromRow={i + 2}
        toRow={i + 2}
        height={x}
        forceAddBorder
        onClick={handleLineSelection('row', i)}
        className={cnSpreadsheet('cell', { 'row-idx': true })}
      >
        <h5>{i + 1}</h5>
      </SpreadsheetCell>)
    }
    {
      spreadsheetStoreInstance.columnsWidth.map((x, i) => <SpreadsheetCell
        key={`col-${i}`}
        fromColumn={i + 2}
        toColumn={i + 2}
        fromRow={1}
        toRow={1}
        width={x}
        forceAddBorder
        onClick={handleLineSelection('col', i)}
        className={cnSpreadsheet('cell', { 'col-idx': true })}
      >
        <h5>{numberToLetter(i + 1)}</h5>
      </SpreadsheetCell>)
    }
    {
      spreadsheetStoreInstance.cells.map((x, i) => <Cell
        key={i}
        cell={x}
        onContextMenu={(e) => {
          e.preventDefault()
          setMenuPosition(prev => (prev === null ? { x: e.clientX + 2, y: e.clientY - 6 } : null))
        }}
        disabled={disabled}
      />)
    }
    {!disabled && <Menu
      open={menuPosition !== null}
      onClose={() => setMenuPosition(null)}
      anchorReference="anchorPosition"
      anchorPosition={
        menuPosition !== null ? { top: menuPosition.y, left: menuPosition.x } : undefined
      }
    >
      {!spreadsheetStoreInstance.hasNotEditableInSelected &&
        <MenuItem onClick={handleActivateCell(InputType.None)}>Запретить ввод</MenuItem>
      }
      {spreadsheetStoreInstance.tryGetSelectedElementsEqualInputType &&
        spreadsheetStoreInstance.tryGetSelectedElementsEqualInputType !== InputType.Text &&
        <MenuItem onClick={handleActivateCell(InputType.Text)}>Текстовый ввод</MenuItem>
      }
      {spreadsheetStoreInstance.tryGetSelectedElementsEqualInputType &&
        spreadsheetStoreInstance.tryGetSelectedElementsEqualInputType !== InputType.Number &&
        <MenuItem onClick={handleActivateCell(InputType.Number)}>Числовой ввод</MenuItem>
      }
    </Menu>}
  </div>
}

export default observer(Spreadsheet)
