import { observer } from 'mobx-react'

import { useMst } from '../../../Stores/rootStore'
import { SurveyType } from '../../../Stores/AdminStores/surveysStore'

import CustomQuestionPage from './CustomQuestionPage'

// TODO: отрефакторить
const QuestionsPage = () => {
  const survey = useMst().user.pageStore.currentSurvey
  return <CustomQuestionPage additionalQuestionsAccordion={survey?.type === SurveyType.Gvk} allowSkipMainQuestion={survey?.type === SurveyType.Gvk}/>
}

export default observer(QuestionsPage)
