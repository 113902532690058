import { useEffect, useState } from "react";

export const useMouseUp = (onUpn: () => void) => {
  useEffect(() => {
    const onMoveEvent = onUpn;
    window.addEventListener("mouseup", onMoveEvent);

    return () => {
      window.removeEventListener("mouseup", onMoveEvent);
    };
  }, []);
};