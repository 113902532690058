import { Dispatch, SetStateAction, useState } from 'react'

import { useMst } from '../../Stores/rootStore'

export function useStateWithChangeTracking<T>(initialValue: T):
[T, Dispatch<SetStateAction<T>>, (value: SetStateAction<T>, withChangeTracking?: boolean) => void, () => void] {
  const tabStore = useMst().admin.tabStore
  const [value, setValue] = useState(initialValue)
  const setValueWithChangeTracker = (value: SetStateAction<T>, withChangeTracking: boolean = true) => {
    setValue(value)
    withChangeTracking && tabStore.setWasChange(true)
  }
  const resetTracking = () => tabStore.setWasChange(false)
  return [value, setValue, setValueWithChangeTracker, resetTracking]
}
