import { ChangeEvent, useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { useElementSize } from 'usehooks-ts'
import { List } from 'react-virtualized'
import { observer } from 'mobx-react'
import { Button, IconButton, Skeleton, Stack, TextField } from '@mui/material'
import { TreeView } from '@mui/lab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'

import { useMst } from '@/Stores/rootStore'
import { ElementsTypeFilter, IOrganizationStructureFilter } from '@/Stores/AdminStores/organizationStructureStore'
import StyledButton from '@/Components/Button'
import { OrganizationStructureTreeElement, OrganizationStructureInfoRow } from '@/Components/OrganizationStructure'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnOrganizationStructureTreeView = bem()('organization-structure-tree-view')

interface IOrganizationStructureTreeView {
  /** organizationStructureStateSelector - селектор состояния орг.структуры из стора, в базовом кейсе это ид страницы для которой строится орг.структура или пустая строка если ца по опросу. Может использоваться для работы с несколькими настройками орг.структуры параллельно */
  organizationStructureStateSelector: string
  /** onSave - если передано, будет отображена кнопка "Сохранить", которую можно обработать */
  onSave?: (organizationStructureStateSelector: string) => Promise<void>
  /** Контролируемый фильтр */
  controlledFilter?: IOrganizationStructureFilter
  /** Нужно ли использовать контролируемый фильтр */
  useControlledFilter?: boolean

  /** disabled - отключение модифицирующих компонентов (фильтрация остается) */
  disabled?: boolean
  /** readonly - скрытие модифицирующих компонентов (фильтрация остается) */
  readonly?: boolean
  /** Опционально отключаем кнопку очистки */
  disableClearButton?: boolean
  /** Опционально отключаем подсветку выбранных элементов */
  disableBacklight?: boolean
  /** По умолчанию отображаются отфильтрованные элементы с вложенностью или основное дерево при отсутствии фильтров. Если отключить вложенность, то элементы будут простым списком */
  disableNesting?: boolean
  /** Отступ строки поиска для выравнивания по скролу */
  addSearchFieldScrollerOffset?: boolean
}

/** Компонент для отображения орг.структуры из стора */
const OrganizationStructureTreeView = ({
  organizationStructureStateSelector,
  onSave,
  controlledFilter,
  useControlledFilter = false,
  disabled = false,
  readonly = false,
  disableClearButton = false,
  disableBacklight = false,
  disableNesting = false,
  addSearchFieldScrollerOffset = false,
}: IOrganizationStructureTreeView) => {
  const organizationStructureStore = useMst().admin.organizationStructureStore
  const organizationStructureState = organizationStructureStore.getOrganizationStructureState(organizationStructureStateSelector)

  const [filter, setFilter] = useState<IOrganizationStructureFilter>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [ref, { width, height }] = useElementSize()

  useMount(() => {
    setIsLoading(true)
    let filter: IOrganizationStructureFilter | undefined

    if (!useControlledFilter) {
      const filterData = {
        searchText: '',
        isSelectedOnly: false,
        isConfirmedOnly: false,
        elementsTypeFilter: ElementsTypeFilter.All,
      }

      filter = organizationStructureState?.addFilter(filterData.searchText, filterData.isSelectedOnly, filterData.isConfirmedOnly, filterData.elementsTypeFilter)

      if (filter) {
        setFilter(filter)
      }
    }

    setIsLoading(false)

    return () => {
      if (filter && !useControlledFilter) {
        organizationStructureState?.removeFilter(filter)
      }
    }
  })

  useEffect(() => {
    if (controlledFilter && useControlledFilter) {
      setFilter(controlledFilter)
    }
  }, [controlledFilter, useControlledFilter])


  const handleClearSearch = () => {
    filter?.updateSearchText('')
  }

  const handleSearchTextUpdate = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    filter?.updateSearchText(e.target.value)
  }

  const handleSave = async () => {
    setIsLoading(true)
    await onSave?.(organizationStructureStateSelector)
    setIsLoading(false)
  }

  const handleElementExpandChanged = (event: React.SyntheticEvent, nodeIds: string[]) => {
    organizationStructureState?.changeExpandedState?.(nodeIds)
  }

  return <div className={cnOrganizationStructureTreeView()}>
    {isLoading ? <Skeleton variant='rectangular' width='100%' height={130} /> : <Stack
      className={cnOrganizationStructureTreeView('organization-structure-container')}
    >
      <div className={cnOrganizationStructureTreeView('search')}>
        <TextField
          className={cnOrganizationStructureTreeView('search-element-name', { 'with-scroller-offset': addSearchFieldScrollerOffset })}
          placeholder='Введите название элемента орг.структуры'
          label='Поиск по названию'
          onChange={handleSearchTextUpdate}
          value={filter?.searchText ?? ''}
          variant='standard'
          InputProps={{
            endAdornment: filter?.searchText && (
              <IconButton size='small' onClick={handleClearSearch} >
                <CloseIcon fontSize='small'/>
              </IconButton>
            ),
          }}
        />
      </div>
      {disableNesting && organizationStructureState && <div ref={ref} className={cnOrganizationStructureTreeView('top-elements-container')}>
        <List
          rowHeight={54}
          width={width}
          height={height}
          rowCount={filter?.filteredOrganizationStructureElements.length ?? 0}
          rowRenderer={({ key, index, style }) => {
            return <div key={key} style={style}>
              <OrganizationStructureInfoRow
                element={organizationStructureState.getElement((filter?.filteredOrganizationStructureElements ?? []).at(index) ?? '')}
                organizationStructureStateSelector={organizationStructureStateSelector}
                readonly={readonly}
                disabled={disabled}
              />
            </div>
          }}
        />
      </div>}
      {
        filter?.hasAnyFilters && !disableNesting && <div ref={ref} className={cnOrganizationStructureTreeView('top-elements-container')}>
          {filter?.filteredOrganizationStructureElements.map(x => <TreeView
            key={x}
            className={cnOrganizationStructureTreeView('tree-view')}
            defaultCollapseIcon={<ExpandMoreIcon />}
            // defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            <OrganizationStructureTreeElement
              key={x}
              organizationStructureStateSelector={organizationStructureStateSelector}
              element={organizationStructureStore.getElement(x, organizationStructureStateSelector)}
              disabled={disabled}
              readonly={readonly}
              disableBacklight={disableBacklight}
            />
          </TreeView>
          )}
        </div>
      }
      {
        organizationStructureStore.rootElement && !filter?.hasAnyFilters && !disableNesting && <div ref={ref} className={cnOrganizationStructureTreeView('top-elements-container')}>
          <TreeView
            className={cnOrganizationStructureTreeView('tree-view')}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={organizationStructureStore.hasAnySelectedElements(organizationStructureStateSelector) ? [] : [organizationStructureStore.rootElement]}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={organizationStructureState?.allExpanded ?? []}
            onNodeToggle={handleElementExpandChanged}
          >
            <OrganizationStructureTreeElement
              key={organizationStructureStore.getElement(organizationStructureStore.rootElement, organizationStructureStateSelector).id}
              organizationStructureStateSelector={organizationStructureStateSelector}
              element={organizationStructureStore.getElement(organizationStructureStore.rootElement, organizationStructureStateSelector)}
              disabled={disabled}
              readonly={readonly}
              disableBacklight={disableBacklight}
            />
          </TreeView>
        </div>
      }
    </Stack>}
    {<div className={cnOrganizationStructureTreeView('button', { container: true })}>
      {!readonly && !disableClearButton && <Button disabled={disabled || isLoading} variant='outlined' className={cnOrganizationStructureTreeView('button', { reset: true })} onClick={() => organizationStructureStore.deselectAll(organizationStructureStateSelector)}>Очистить</Button>}
      {onSave && <StyledButton disabled={disabled || isLoading} onClick={handleSave} height="40px">Сохранить</StyledButton>}
    </div>}
  </div>
}

export default observer(OrganizationStructureTreeView)
