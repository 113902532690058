import Lottie from 'react-lottie-player'

import badAnimationData from '@/Images/Animations/bad.json'
import goodAnimationData from '@/Images/Animations/good.json'
import normalAnimationData from '@/Images/Animations/normal.json'
import { FeedbackEstimateType } from '@/Pages/User/FinalServiceEstimatePage/index'
import { LabelStyled, SpanStyled } from '@/Pages/User/FinalServiceEstimatePage/styled'

const animationsData = [undefined, badAnimationData, normalAnimationData, goodAnimationData]
const estimateText = [undefined, 'Плохо', 'Нормально', 'Отлично!']

interface ISmile {
  drawingEstimateType: FeedbackEstimateType
  selectedEstimate: FeedbackEstimateType | undefined
  handleEstimateChange: (estimate: FeedbackEstimateType) => void
}

const Smile = ({ drawingEstimateType, selectedEstimate, handleEstimateChange }: ISmile) => {
  return (<SpanStyled
    onClick={() => handleEstimateChange(drawingEstimateType)}
    className={(selectedEstimate === drawingEstimateType) ? 'selected' : ''}
  >
    <Lottie
      loop
      play={(selectedEstimate === drawingEstimateType)}
      animationData={animationsData[drawingEstimateType]}
      style={{ width: '60%', height: '60%' }}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice',
      }}
    />
    <LabelStyled>{estimateText[drawingEstimateType]}</LabelStyled>
  </SpanStyled>)
}

export default Smile
