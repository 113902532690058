import styled from 'styled-components'
import { Card, CardActions, CardContent, IconButton, InputLabel, Typography } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'

export const DeleteButtonStyled = styled(IconButton)`
  padding: 0;

  &:hover > svg {
    color: #bf0404;
  }
`

export const CardActionsStyled = styled(CardActions)`
  &.hidden {
    @media (hover: hover) and (pointer: fine) {
      display: none;
    }
  }
`

export const CardNameStyled = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100px;
  margin-left: 10px;
  color: #FFFFFF;

  &.hidden {
    @media (hover: hover) and (pointer: fine) {
      display: none;
    }
  }

  @media (hover: none) or (pointer: none) or (pointer: coarse) {
    font-size: 0.8rem;
    line-height: 0.7rem;
  }
`

export const CardContentStyled = styled(CardContent)`
  position: relative;
  padding: 10px 0px 0 0px !important;
`

export const CardData = styled(InputLabel)`
  position: relative;
  width: 100%;
  height: 100%;

  @media (hover: hover) and (pointer: fine) {
    & > .blur-image {
      filter: blur(5px) contrast(60%);
      transition: filter .1s ease-in-out;
    }
  }

  @media (hover: none) or (pointer: none) or (pointer: coarse) {
    & img {
      filter: blur(1px) contrast(60%);
    }
  }

  &.action-card-data {
    &:hover {
      cursor: pointer;
    }
  }
`

export const ExtensionMark = styled.div`
  width: fit-content;
  margin-left: 9px;
  padding: 1px 8px;
  border-radius: 3px;
  color: #FFFFFF;

  &.hidden {
    @media (hover: hover) and (pointer: fine) {
      display: none;
    }
  }

  @media (hover: none) or (pointer: none) or (pointer: coarse) {
    font-size: 0.7rem;
    line-height: 1rem;
    padding: 1px 4px;
    margin-bottom: 5px;
  }
`

export const CardStyled = styled(Card)`
  width: 124px;
  height: 80px;

  &.action-card {
    border-color: #266dc2;
  }
`

export const AttachFileIconStyled = styled(AttachFileIcon)`
  color: #266dc2;
`

export const AddFileTextStyled = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100px;
  line-height: 1.2em;
  color: #266dc2;
  text-align: center;
`
