import { FormControl, Select, SelectChangeEvent } from '@mui/material'

import { htmlToTextWithNoImage } from '@/Helpers/htmlToTextCustom'
import { closureOrField } from '@/Helpers/callOrAccess'
import Error from '@/Components/Error'
import { KeysOfType } from '@/Models/headCell'
import { LabelContainer } from '@/Pages/Admin/SurveyInfoPage/BranchingTab/Components/SharedStyle'

import { SelectContainer, MenuItemStyled } from './styled'

interface ICustomSelect<T> {
  label: string
  value?: string | number
  data: T[]
  valueField: (KeysOfType<T, string | number> | ((item: T) => string | number))
  textField: (KeysOfType<T, string> | ((item: T) => string))
  onChange: (e: SelectChangeEvent<string | number>) => void
  error?: boolean
  errorText?: string
  disabled?: boolean
}

function CustomSelect<T>({ label, value, data, valueField, textField, onChange, error, errorText, disabled }: ICustomSelect<T>) {
  // TODO: отрефакторить тайпинги value и htmlToText @ Vidrochka
  return (
    <SelectContainer>
      <LabelContainer>{label}</LabelContainer>
      <FormControl size='small'>
        <Select
          variant="outlined"
          value={value ?? ''}
          onChange={onChange}
          error={error}
          disabled={disabled}
        >
          {data.map((x, i) => <MenuItemStyled key={i} value={(closureOrField(valueField, x) as string | readonly string[] | number | undefined)}>{htmlToTextWithNoImage((closureOrField(textField, x)) as unknown as string)}</MenuItemStyled>)}
        </Select>
      </FormControl>
      <Error isVisible={error}>{errorText}</Error>
    </SelectContainer>
  )
}

export default CustomSelect
