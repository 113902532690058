import { useState } from 'react'
import { observer } from 'mobx-react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Card, CardContent } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

import { QuestionType } from '@/Models/page'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import TextEditor from '@/Components/TextEditor'
import DragLabel from '@/Components/DragAndDrop/DragLabel'
import { IQuestionInstance, QuestionValidationResult } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

import { ActionContainer, QuestionContainer, QuestionContent, QuestionStyled, QuestionText } from './styled'
import SelectAnswerContainer from './SelectAnswerContainer'
import SliderContainer from './SliderContainer'
import QuestionSettings from './QuestionSettings'
import AttachmentContainer from './AttachmentContainer'
import RatingContainer from './RatingContainer'
import TextContainer from './TextContainer'
import RadioContainer from './RadioContainer'
import CheckboxContainer from './CheckboxContainer'
import RadioWithImageContainer from './RadioWithImageContainer'
import CheckboxWithImageContainer from './CheckboxWithImageContainer'
import SpreadsheetContainer from './SpreadsheetContainer'


interface IQuestionProps {
  question: IQuestionInstance
  draggableProvided: DraggableProvidedDragHandleProps | undefined | null
  disabled: boolean
  onDelete: () => void
}

const Question = ({ question, draggableProvided, disabled, onDelete }: IQuestionProps) => {
  const [editorIsOpen, setEditorIsOpen] = useState(false)

  const getQuestionContainer = () => {
    // TODO: отрефакторить (from Тёма)
    switch (question.type) {
      case QuestionType.slider: return <SliderContainer question={question} disabled={question.isMain || disabled}/>
      case QuestionType.rating: return <RatingContainer question={question} disabled={question.isMain || disabled}/>
      case QuestionType.attachment: return <AttachmentContainer question={question} disabled={question.isMain || disabled}/>
      case QuestionType.text: return <TextContainer question={question} disabled={question.isMain || disabled}/>
      case QuestionType.radio: return <RadioContainer question={question} disabled={question.isMain || disabled}/>
      case QuestionType.checkbox: return <CheckboxContainer question={question} disabled={question.isMain || disabled}/>
      case QuestionType.radioWithImage: return <RadioWithImageContainer question={question} disabled={question.isMain || disabled}/>
      case QuestionType.checkboxWithImage: return <CheckboxWithImageContainer question={question} disabled={question.isMain || disabled}/>
      case QuestionType.spreadsheet: return <SpreadsheetContainer question={question} disabled={question.isMain || disabled}/>
      default: <></>
    }
  }

  return (<Card style={{ marginBottom: '20px', overflow: 'visible' }}>
    <CardContent>
      <QuestionContainer>
        <QuestionStyled>
          <QuestionContent>
            <QuestionText>
              <TextEditor
                title='Редактирование вопроса'
                placeholder='Введите текст вопроса'
                value={question.text}
                isOpen={editorIsOpen}
                onOpen={() => setEditorIsOpen(true)}
                onClose={() => setEditorIsOpen(false)}
                onChange={question.setText}
                error={question.emptyQuestionTextError}
                disabled={disabled || question.isMain}
              />
            </QuestionText>
            {getQuestionContainer()}
            {CheckEnumIs(question.type,
              QuestionType.checkbox,
              QuestionType.radio,
              QuestionType.checkboxWithImage,
              QuestionType.radioWithImage
            ) && <SelectAnswerContainer question={question} disabled={disabled}/>}
          </QuestionContent>
          <QuestionSettings
            showRequiredCheckBox
            showQuestionTypeSelector
            question={question}
            isMain={question.isMain}
            disabled={disabled}
          />
        </QuestionStyled>
        <ActionContainer isVisible={!question.isMain}>
          {!disabled && <DeleteOutlineIcon onClick={onDelete}/>}
          {!disabled && <FileCopyOutlinedIcon onClick={() => question.copy()}/>}
          {!disabled && <DragLabel dragHandleProps={draggableProvided}/>}
        </ActionContainer>
      </QuestionContainer>
    </CardContent>
  </Card>)
}

export default observer(Question)
