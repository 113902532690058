import axios from 'axios'

import { isExpressSmartApp } from '@/applicationConfig'

export function configureAxios(history: any) {
  axios.defaults.headers.common.Pragma = 'no-cache'
  if (isExpressSmartApp()) {
    const prefix = window.location.pathname.split('/').splice(0, 6).join('/')
    axios.defaults.baseURL = `${window.location.origin}${prefix}/static/proxy`
  } else {
    // TODO: вынести в отдельный файл с настройками/константами
    axios.defaults.baseURL = `${window.location.origin}`
    axios.defaults.paramsSerializer = { indexes: true }
    axios.interceptors.response.use(response => {
      return response
    }, error => {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.push('/')
        }
      }

      throw error
    })
  }
}
