import { useState } from 'react'
import { useMount } from 'react-use'
import { observer } from 'mobx-react'
import Link from '@mui/material/Link'

import { useMst } from '@/Stores/rootStore'
import QuestionLayout from '@/Components/Questions/QuestionLayout'
import FileItem from '@/Components/FileItem'
import SpreadsheetQuestionDialog from '@/Components/Questions/SpreadsheetQuestion/SpreadsheetQuestionDialog'
import { ISpreadsheetCellsData } from '@/Models/spreadsheet'
import { useWaiter } from '@/Helpers/react-wait'
import { apiParseSpreadsheetByFileId } from '@/Api/question'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnQuestion = bem()('question')

enum WaitVariants {
  FileEditing = 'excel-file-editing',
}

interface ISpreadsheetQuestion {
  questionId: string
  onChangesCommit: () => void
}

const SpreadsheetQuestion = ({ questionId, onChangesCommit }: ISpreadsheetQuestion) => {
  const [data, setData] = useState<ISpreadsheetCellsData | null>(null)
  const { startWait, stopWait, isWaiting } = useWaiter()
  const pageStore = useMst().user.pageStore
  const question = pageStore.getQuestion(questionId)
  const answer = pageStore.getAnswer(questionId)
  const file = question.payload?.adminFiles.at(0)

  useMount(() => {
    (async () => {
      if (file?.fileId) {
        const data = await apiParseSpreadsheetByFileId(file.fileId)
        setData(data)
      }
    })()
  })

  if (!file) {
    return <div>Вопрос не заполнен. Пожалуйста, обратитесь к админимтратору</div>
  }

  const handleFileClick = () => {
    startWait(WaitVariants.FileEditing)
  }

  return <QuestionLayout question={question}>
    <div className={cnQuestion({ spreadsheet: true })}>
      <FileItem onCardClick={handleFileClick} fileId={file.fileId} name={file.fileName} mimeType={file.fileType ?? undefined}/>
      <div className={cnQuestion('actions')}>
        <Link
          component='button'
          variant='caption'
          onClick={handleFileClick}
        >
          Редактировать
        </Link>
        <Link
          className={cnQuestion('btn-clear', { disabled: !answer.hasFilledValue })}
          component='button'
          variant='caption'
          underline={answer.hasFilledValue ? 'always' : 'none'}
          onClick={answer.hasFilledValue ? answer.clearValue : () => {}}
          disabled={!answer.hasFilledValue}
        >
          Очистить
        </Link>
      </div>
      {data && <SpreadsheetQuestionDialog
        open={isWaiting(WaitVariants.FileEditing)}
        onClose={() => stopWait(WaitVariants.FileEditing)}
        data={data}
        answer={answer}
        question={question}
      />}
    </div>
  </QuestionLayout>
}

export default observer(SpreadsheetQuestion)
