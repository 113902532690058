import { dateConverter } from '@/Helpers/dateConverter'
import { CreteOrUpdateSurveyInterface, TargetAudiencesMappingType } from '@/Stores/AdminStores/surveysStore'
import { ISurveyIn } from '@/Stores/UserStores/userSurveysStore'
import { IPermissionsWithUsers, ISurveyPermissions } from '@/Models/permission'
import BackendAdapter from '@/Api/_adapter'
import { TrafficLightColor, TrafficLightGroupType, TrafficLightPartType } from '@/Models/trafficLight'

/*
Таймаут в /start и /stop нужен для ситуации, когда стенд перегружен и опрос на самом деле запустился, но ответ от сервера приходит с большой задержкой,
что заставляет модальное окно "Вы действительно хотите запустить опрос?" висеть очень долго в админке, хотя на юзер-части опрос уже может быть виден
*/
/* TODO по хорошему с бэка должно возвращаться актуальное состояние опроса:
** Напр: false - остановлен, true - запущен, а также флаг success или соответствующий http статус - выполнилась ли успешно операция стоп/старт или нет
*/
export const apiStopSurvey = async (surveyId: string) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/stop`, {})
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiStartSurvey = async (surveyId: string) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/start`, {})
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetSurveyList = async () => {
  try {
    return await BackendAdapter.get('/api/surveys/list/all')
      .then(res => res.data.map(castToSurveyModel))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetSurveyInfo = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}`)
      .then(res => castToSurveyModel(res.data))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiDuplicateSurvey = async (surveyId: string) => {
  try {
    return castToSurveyModel(await BackendAdapter.post(`/api/surveys/${surveyId}/duplicate`, {})
      .then(res => res.data))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiArchiveSurvey = async (surveyId: string) => {
  try {
    return castToSurveyModel(await BackendAdapter.post(`/api/surveys/${surveyId}/archive`, {})
      .then(res => res.data))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiUpdateSurvey = async (model: CreteOrUpdateSurveyInterface) => {
  try {
    return await BackendAdapter.put(`/api/surveys/${model.surveyId}/update`, model)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiCreateSurvey = async (model: CreteOrUpdateSurveyInterface) => {
  try {
    return castToSurveyModel(await BackendAdapter.post('/api/surveys', model)
      .then(res => res.data))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiChangeSurveyMode = async (surveyId: string, withCards: boolean): Promise<TargetAudiencesMappingType> => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/settings/withCard`, { withCards })
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiChangeSurveyTargetAudiencesMappingType = async (surveyId: string, targetAudiencesMappingType: TargetAudiencesMappingType) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/settings/targetAudiences`, { targetAudiencesMappingType })
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiChangeSurveyImage = async (surveyId: string, imageId: string) => {
  try {
    return await BackendAdapter.patch(`/api/surveys/${surveyId}/${imageId}`, {})
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiPinSurvey = async (surveyId: string) => {
  try {
    return await BackendAdapter.patch(`/api/surveys/${surveyId}/pin`, {})
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiUnpinSurvey = async (surveyId: string) => {
  try {
    return await BackendAdapter.patch(`/api/surveys/${surveyId}/unpin`, {})
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetRunningSurveys = async (): Promise<ISurveyIn> => {
  try {
    return await BackendAdapter.get('/api/surveys/list').then(res => res.data.map(castToSurveyModel))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetAllPeriodsBySurvey = async (surveyId: string): Promise<ISurveyIn[]> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}`, {
      params: {
        periods: true,
      },
    }).then(res => res.data.map(castToSurveyModel))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetRunningSurvey = async (surveyId: string): Promise<ISurveyIn> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/runningSurvey`)
      .then(res => (res.data ? castToSurveyModel(res.data) : null))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetSurveyPermissions = async (surveyId: string, permissions: ISurveyPermissions) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/permissions`, permissions)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetSurveyPermissions = async (surveyId: string): Promise<IPermissionsWithUsers[]> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/permissions`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

// TODO: Скорее всего сделать опциональной для настроек фронта с включенным rolesSwitch
// Ручка для работы функционала теста пользователей (rolesSwitch)
export const apiGetSurveyTitlesForTest = async (): Promise<any> => {
  try {
    return await BackendAdapter.get('/api/surveys/titles/forTest')
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiCreatePagesByServices = async (surveyId: string, defaultImageId: string, serviceIds: string[]) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/services/createPages`,
      serviceIds,
      {
        params: {
          defaultImageId,
        },
      }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

/**
  * @surveyId id опроса
  * @isHidden Фильтрация на основе видимости части светофора
*/
export const apiGetSurveyTrafficLightState = async (surveyId: string, isHidden?: boolean): Promise<{ groups: Record<TrafficLightGroupType, { parts: Record<TrafficLightPartType, Array<{ payload: string | null, color: TrafficLightColor, isHidden: boolean }>> }> }> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/trafficLightState`, {
      params: {
        isHidden,
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

// TODO: добавить тайпинги: await axios.get<тайпинг[]>(/* ... */)
export const apiGetTrafficLightState = async (): Promise<Record<string, { groups: Record<TrafficLightGroupType, { parts: Record<TrafficLightPartType, Array<{ payload: string | null, color: TrafficLightColor, isHidden: boolean }>> }> }>> => {
  try {
    return await BackendAdapter.get('/api/surveys/trafficLightState')
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiTryLockEditing = async (surveyId: string): Promise<string | null> => {
  try {
    return await BackendAdapter.patch(`/api/surveys/${surveyId}/editing/lock`).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiTryUnlockEditing = async (surveyId: string, surveyEditTimeSpanId: string): Promise<boolean> => {
  try {
    return await BackendAdapter.patch(`/api/surveys/${surveyId}/editing/${surveyEditTimeSpanId}/unlock`).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiTryRefreshEditingLock = async (surveyId: string, surveyEditTimeSpanId: string): Promise<boolean> => {
  try {
    return await BackendAdapter.patch(`/api/surveys/${surveyId}/editing/${surveyEditTimeSpanId}/refresh`).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetActiveEditingLock = async (surveyId: string): Promise<string> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/editing/active`).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetVerificationState = async (surveyId: string, isVerified: boolean): Promise<string> => {
  try {
    return await BackendAdapter.patch(`/api/surveys/${surveyId}/verification`, {}, { params: { isVerified } }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const castToSurveyModel = (model: any) => {
  return {
    ...model,
    start: dateConverter(model.start),
    end: dateConverter(model.end),
    type: model.type,
    status: model.status,
    archivedDate: (model.archivedDate ? dateConverter(model.archivedDate) : null),
    createdAt: dateConverter(model.createdAt),
    pages: model.pages.map((x: any) => ({ ...x, id: x.pageId })),
  }
}
