import { useEffect, useState } from 'react'
import { Skeleton } from '@mui/material'

import { apiGetReviewSteps } from '@/Api/review'
import { IReviewStep } from '@/Models/review'
import { HeadCell } from '@/Models/headCell'
import AgTable from '@/Components/AgGridTable'
import { SurveyStatus } from '@/Stores/AdminStores/surveysStore'
import { useWaiter } from '@/Helpers/react-wait'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnReviewStepTab = bem()('review-step-tab')

interface IReviewStepTab {
  surveyId: string
}

const ReviewStepTab = ({ surveyId }: IReviewStepTab) => {
  const [data, setData] = useState<IReviewStep[]>([])
  const { startWait, stopWait, isWaiting } = useWaiter()

  useEffect(() => {
    (async () => {
      startWait('review-steps-page-loading')
      const completion = await apiGetReviewSteps(surveyId)
      setData(completion)

      stopWait('review-steps-page-loading')
    })()
  }, [surveyId])

  const agreementToText = new Map<string, string>([
    [SurveyStatus.Editing, 'Отправил на доработку'],
    [SurveyStatus.Created, 'Отправил на согласование'],
  ])

  const header: Array<HeadCell<IReviewStep>> = [
    {
      key: 'toStatus',
      label: 'Согласование',
      createControl: obj => <>{agreementToText.get(obj.toStatus)}</>,
      isVisible: true,
      isSearchable: true,
      flex: 1,
      minWidth: 200,
      maxWidth: 450,
    },
    {
      key: 'author',
      label: 'Автор',
      isVisible: true,
      isSearchable: true,
      flex: 1,
      align: 'center',
      minWidth: 200,
      maxWidth: 450,
    },
    {
      key: 'createdAt',
      label: 'Дата',
      createControl: obj => {
        const date = new Date(obj.createdAt)
        return <>{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</>
      },
      isVisible: true,
      flex: 0.5,
      align: 'center',
      minWidth: 150,
      maxWidth: 350,
    },
    {
      key: 'comment',
      label: 'Комментарий',
      isVisible: true,
      flex: 2,
      minWidth: 350,
      maxWidth: 700,
    },
  ]

  return (<div>
    {isWaiting('review-steps-page-loading') ? <>
      <Skeleton variant='rectangular' className={cnReviewStepTab('skeleton', { 'table-fields': true })} height={55}/>
      <Skeleton variant='rectangular' className={cnReviewStepTab('skeleton', { 'table-row': true })} height={45} animation="wave"/>
      <Skeleton variant='rectangular' className={cnReviewStepTab('skeleton', { 'table-row': true })} height={45} animation="wave"/>
    </> : <AgTable
      data={data}
      headCells={header}
    />}
  </div>)
}

export default ReviewStepTab
