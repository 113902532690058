import styled from 'styled-components'

import { device } from '@/Models/device'

export const Container = styled.div`
  margin-top: 1.7rem;
  margin-bottom: 0.75rem;
  width: 100%;
  position: relative;
`

export const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > div:first-child{
    border-radius: 5px 0px 0px 5px;
  }
  & > div:last-child{
    border-radius: 0px 5px 5px 0px;
  }
`

export const ProgressStyled = styled.div`
  height: 20px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;

  @media screen and ${device.mobile} {
    height: 10px;
  }
`

export const InfoRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  @media screen and ${device.mobile} {
    & > svg:first-child {
      height: 25px;
    }
    & > span {
      font-size: 18px;
    }
    & > svg:last-child {
      margin-left: 6px !important;
      height: 25px;
      width: auto;
    }
  }
`

export const CountStyle = styled.span`
  margin-left: auto;
  font-weight: 600;
  font-size: 36px;
  line-height: 20px;
`
