import { observer } from 'mobx-react'
import { Button, Stack } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import bem from '@/Helpers/BemClass'

import './style.scss'

const cnOrganizationStructureLevelButton = bem()('organization-structure-level-button')

interface IOrganizationStructureLevelButton {
  data: number | string
  selected?: boolean
  secondSelected?: boolean
  isLastNesting?: boolean
  onClick: () => void
}

const OrganizationStructureLevelButton = ({ data, selected = false, secondSelected = false, isLastNesting = false, onClick }: IOrganizationStructureLevelButton) => {
  return <Stack
    direction='row'
    justifyContent='space-between'
    alignItems='center'
    spacing={0}
    className={cnOrganizationStructureLevelButton()}
  >
    <Button onClick={onClick} className={cnOrganizationStructureLevelButton('button', { 'selected': selected, 'second-selected': secondSelected, 'not-selected': !selected && !secondSelected })} variant='outlined'>
      {data}
    </Button>
    {selected && !isLastNesting && <PlayArrowIcon
      fontSize='small'
    />}
  </Stack>
}

export default observer(OrganizationStructureLevelButton)
