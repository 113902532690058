import { intersection } from 'lodash'

import { Condition, Overlap } from '@/Models/rule'
import { IOptionAnswer } from '@/Stores/UserStores/pageStore'
import { ILogicOption } from '@/Stores/sharedModels/models'

export const IsTrue = (condition: Condition, overlap: Overlap, actualAnswers: IOptionAnswer[], isExpectedQuestionSkipped: boolean, isQuestionSkipped: boolean, isPageSkip: boolean, expectedAnswers: ILogicOption[] | null) => {
  switch (condition) {
    case Condition.Choose: {
      switch (overlap) {
        case Overlap.Or: {
          return !isPageSkip && expectedAnswers &&
          (
            intersection(expectedAnswers.map(x => x.value), actualAnswers.map(x => x.value)).length > 0 ||
            (
              expectedAnswers.some(x => x.isCustom) &&
              actualAnswers.some(x => x.isCustom)
            ) ||
            (
              isExpectedQuestionSkipped &&
              isQuestionSkipped
            )
          )
        }
        case Overlap.And: {
          if (isPageSkip || !expectedAnswers) return false

          let matchCount = intersection(expectedAnswers.map(x => x.value && x.isCustom), actualAnswers.map(x => x.value && x.isCustom)).length

          // TODO: перепроверить, возможно отрефакторить
          if (expectedAnswers.some(x => x.isCustom) && actualAnswers.some(x => x.isCustom)) {
            matchCount++
          }

          if (isExpectedQuestionSkipped && isQuestionSkipped) {
            matchCount++
          }

          return matchCount === expectedAnswers.length
        }
        default: {
          // Так не должно быть
          return false
        }
      }
    }
    case Condition.NotChoose: {
      switch (overlap) {
        case Overlap.Or: {
          if (isPageSkip) return true
          return expectedAnswers && (
            !(intersection(expectedAnswers.map(x => x.value), actualAnswers.map(x => x.value)).length > 0) &&
            !(
              expectedAnswers.some(x => x.isCustom) &&
              actualAnswers.some(x => x.isCustom)
            ) &&
            !(
              isExpectedQuestionSkipped &&
              isQuestionSkipped
            )
          )
        }
        case Overlap.And: {
          if (isPageSkip || !expectedAnswers) return true
          let matchCount = intersection(expectedAnswers.map(x => x.value && x.isCustom), actualAnswers.map(x => x.value && x.isCustom)).length

          if (expectedAnswers.some(x => x.isCustom) && actualAnswers.some(x => x.isCustom)) {
            matchCount++
          }

          if (isExpectedQuestionSkipped && isQuestionSkipped) {
            matchCount++
          }

          return matchCount !== expectedAnswers.length
        }
        default: {
          // Так не должно быть
          return false
        }
      }
    }
    case Condition.Skip: {
      return isPageSkip
    }
    case Condition.Always: {
      return true
    }
    case Condition.AnswerEntered: {
      return !isPageSkip && actualAnswers.some(x => !!x.value)
    }
    default: {
      // Так не должно быть
      return false
    }
  }
}
