import './style.scss'

const ContentLoader = (props: any) => {
  return (
    <div className='polls-loader' {...props}>
      <div className='polls-loader__spinner'></div>
    </div>
  )
}

export default ContentLoader
