import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > button {
    margin-left: 20px;
    font-weight: bold;
    font-size: 16px;
    border: solid 1px lightgray;
  }
  & > button:last-child{
    display: flex;
    margin-left: auto;
    padding-right: 36px;

    .circular-progress {
      margin-right: 10px;
    }

    .circular-progress-space {
      width: 20px;
      margin-right: 10px;
    }
  }

  & > .Mui-disabled {
    padding-left: 6px !important;
  }
`

export const TableTitleContainer = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: #004C97;
  margin-top: 50px;
  margin-left: 10px;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  margin-left: 10px;
`

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;

  & > div {
    width: 275px;
    font-weight: bold;
    margin-left: 20px;
  }

  & > label {
    margin-right: 10px;
  }

  & > button {
    position: absolute;
    margin-left: 25px;
    z-index: 1;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-left: 60px;
  }
`

export const ComparePanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
