import { Context, createContext, ReactNode, useMemo, useState } from 'react'

export interface ILoaderState {
  isLoading: boolean
  changeIsLoading: (isLoading: boolean) => void
}

export const DefaultLoaderState: ILoaderState = {
  isLoading: true,
  changeIsLoading: function(isLoading: boolean): void {
    this.isLoading = isLoading
  },
}

export const LoaderStateContext: Context<ILoaderState> = createContext(DefaultLoaderState)

interface IChildContainer {
  children?: ReactNode
}

export const LoaderStateContextLayout = ({ children }: IChildContainer) => {
  const [isLoading, setIsLoading] = useState(true)
  const value = useMemo(
    () => ({ isLoading: isLoading, changeIsLoading: setIsLoading }),
    [isLoading]
  )

  return (
    <LoaderStateContext.Provider value={value}>
      {children}
    </LoaderStateContext.Provider>
  )
}
