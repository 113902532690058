import { useContext, useEffect, useRef, useState } from 'react'
import { useMount } from 'react-use'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { Container, Stack, Grid, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { sortBy } from 'lodash'

import bem from '@/Helpers/BemClass'
import { Button, QuestionRouter } from '@/Components'
import TextLink from '@/Components/TextLink'
import { apiSetAnswer, apiSetSaveAndExitAnswer, apiSetSkipPageAnswer } from '@/Api/answer'
import { RouteEnum } from '@/Enums/route'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { useMst } from '@/Stores/rootStore'
import { apiSetSurveyCompleteEntry } from '@/Api/analytics'
import { apiGetIsHaveEstimate } from '@/Api/feedback'
import { SurveyType } from '@/Stores/AdminStores/surveysStore'
import { IErrorIn } from '@/Stores/UserStores/pageStore'
import { ReactComponent as CircledArrow } from '@/Images/circled_arrow.svg'
import { QuestionType } from '@/Models/page'
import { apiGetNextPage, apiGetRulesCount } from '@/Api/rule'
import { commentIsValid } from '@/Helpers/QuestionHelper'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import { apiGetUserPageHistory } from '@/Api/page'
import { IRulesCountStatistic } from '@/Models/rule'
import CheckBox from '@/Components/CheckBox'
import { ILoaderState, LoaderStateContext } from '@/Components/LoaderContext'

import ProgressBar from '../ProgressBar'
import PageHeader from '../PageHeader'
import { ActionsContainer, LeftAlignContainer, RightAlignContainer } from '../styled'

import './style.scss'

const cnCustomQuestionPage = bem()('custom-question-page')

interface ICustomQuestionPage {
  additionalQuestionsAccordion?: boolean
  allowSkipMainQuestion?: boolean
}

const CustomQuestionPage = ({ additionalQuestionsAccordion = false, allowSkipMainQuestion = false }: ICustomQuestionPage) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  // пример использования для более мелких экранов
  // const UIisMobileXs = useMediaQuery(muiTheme.breakpoints.down('xs'))

  const loadingContext = useContext<ILoaderState>(LoaderStateContext)
  const { pageId } = useParams<{ pageId: string }>()
  const questionContainerRef = useRef<HTMLDivElement>(null)
  const store = useMst()
  const survey = useMst().user.pageStore.currentSurvey
  const selectedPagesState = useMst().user.selectedPagesState
  const pageStore = useMst().user.pageStore
  const page = pageStore.page
  const routeManager = useRouteManager()
  const answers = pageStore.answers
  const [rulesCount, setRulesCount] = useState<IRulesCountStatistic>()
  const [scrollTo, setScrollTo] = useState<Element | null>(null)
  const [showAdditionalQuestions, setShowAdditionalQuestions] = useState<boolean>(true)
  const service = pageStore.service
  const [isSkip, setIsSkip] = useState<boolean>(false)
  const [isAnonym, setIsAnonym] = useState<boolean>(survey?.type === SurveyType.Gvk ? false : (survey?.isAnonym ?? false))

  useEffect(() => {
    if (!scrollTo) return

    window.scrollTo({ top: (scrollTo as HTMLElement).offsetTop, behavior: 'smooth' })

    setScrollTo(null)
  }, [scrollTo])

  useMount(() => {
    (async () => {
      loadingContext.changeIsLoading(true)
      if (survey) {
        if (survey.withCards) {
          if (!pageId) {
            routeManager.redirectTo(RouteEnum.clientSurveys)
            selectedPagesState.clear()
            return
          }
          const selectedPagesLocal = localStorage.getItem('selectedPages')
          if (selectedPagesLocal) {
            const selectedPagesLocalParsed = JSON.parse(selectedPagesLocal)
            if (!selectedPagesLocalParsed.selectedPages.includes(pageId) && selectedPagesLocalParsed.surveyId === survey.surveyId && selectedPagesLocalParsed.version === survey.version) {
              routeManager.redirectToUrl(RouteEnum.startPage, `${survey.surveyId}`)
              selectedPagesState.clear()
              return
            }
            selectedPagesState.setServices(selectedPagesLocalParsed.selectedPages, pageId, selectedPagesLocalParsed.selectedPages.length)
          }
        } else if (!selectedPagesState.currentPage) {
          const history = await apiGetUserPageHistory(survey.surveyId)
          if (history?.length > 0) {
            store.user.selectedPagesState.setServices(history, history[history.length - 1], survey.pages.length)
          } else {
            const selectedPages = (sortBy(survey.pages.filter((x) => !x.isComplete), ['order']).map(x => x.id))
            store.user.selectedPagesState.setServices([selectedPages[0]], selectedPages[0], survey.pages.length)
          }
        }

        const rulesCount = await apiGetRulesCount(survey.surveyId)
        setRulesCount(rulesCount)

        await updateCurrentPage()
      }
      loadingContext.changeIsLoading(false)
    })()
  })

  const updateCurrentPage = async () => {
    loadingContext.changeIsLoading(true)
    await pageStore.loadPage(selectedPagesState.currentPage)
    window.scrollTo({ top: 0 })
    loadingContext.changeIsLoading(false)
  }

  const nextQuestion = async () => {
    if (!survey) return
    if (survey?.withCards) {
      if (selectedPagesState.next !== undefined) {
        selectedPagesState.moveNext()
        routeManager.redirectToUrl(RouteEnum.questionPage, `${survey.surveyId}/${selectedPagesState.currentPage}`)
        await updateCurrentPage()
        return
      }
    } else {
      const { isFinished, nextPageId } = await apiGetNextPage(page.surveyId, selectedPagesState.currentPage)

      if (!isFinished) {
        selectedPagesState.push(survey.surveyId, nextPageId)
        await updateCurrentPage()
        return
      }
    }

    loadingContext.changeIsLoading(true)

    await apiSetSurveyCompleteEntry(page.surveyId)
    if (await apiGetIsHaveEstimate(page.surveyId)) {
      routeManager.redirectTo(RouteEnum.clientSurveys)
    } else {
      routeManager.redirectTo(RouteEnum.estimate)
    }

    selectedPagesState.clear()
  }

  const validateAfterChange = (questionId: string) => (): boolean => {
    validateQuestion(questionId)
    return true
  }

  const validation = (): boolean => {
    pageStore.cleanErrors()
    const errors = page?.questions?.map(x => validateQuestion(x.id)).filter(x => x !== null) as IErrorIn[]

    if (errors.length > 0) {
      // const questionWithError = questionContainerRef.current?.getElementById(errors[0]?.questionId)[0]
      const questionWithError = document.getElementById(errors[0]?.questionId)
      if (questionWithError) setScrollTo(questionWithError)

      errors.forEach(x => x && pageStore.getError(x.questionId).setError(x.error))
    }
    return !pageStore.errors.some(x => x.error.length > 0)
  }

  const validateQuestion = (questionId: string): IErrorIn | null => {
    let error: IErrorIn | null = null
    const question = page?.questions?.find(x => x.isVisible && x.id === questionId)
    if (!question) return null
    const questionResult = answers.find(x => x.questionId === question.id)
    const value = toJS(questionResult?.value)

    if (CheckEnumIs(question.type, QuestionType.slider, QuestionType.rating) && !commentIsValid(question, questionResult) && !(question.isMain && isSkip)) {
      error = { questionId: question.id, error: 'Введите комментарий' }
    }

    if (question.isRequired && (!questionResult || value?.length === 0 || value?.every(x => !x))) {
      error = { questionId: question.id, error: 'Этот вопрос является обязательным' }
    }

    if (value?.some(x => x.isCustom && x.value.length === 0)) {
      error = { questionId: question.id, error: 'Введите ответ' }
    }

    if (question.type === QuestionType.spreadsheet && value?.length !== question.options.length) {
      error = { questionId: question.id, error: 'Введите ответ' }
    }

    error && pageStore.getError(error.questionId).setError(error.error)
    return error
  }

  /**
   * Пользователь выбрал "не пользовался сервисом".
   * Скип страницы, но в отличии от обычного скипа помечаем как isConfirmed,
   * т.к. это один из вариантов завершенного ответа на страницу
   * */
  const handleSkipQuestion = async () => {
    await apiSetSkipPageAnswer(page, isAnonym ?? true, true)
    await nextQuestion()
  }

  const handleSetAnswers = async () => {
    await apiSetAnswer(page, answers, isAnonym ?? true, true)
  }

  const handleNext = async () => {
    if (isSkip) {
      await handleSkipQuestion()
      setIsSkip(false)
      return
    }

    if (validation()) {
      await handleSetAnswers()
      await nextQuestion()
    }
  }

  const handleBack = async () => {
    if (validation()) await apiSetAnswer(page, answers, isAnonym, false)
    if (survey) {
      if (survey?.withCards) {
        selectedPagesState.movePrev()
        routeManager.redirectToUrl(RouteEnum.questionPage, `${survey.surveyId}/${selectedPagesState.currentPage}`)
        await updateCurrentPage()
      } else {
        selectedPagesState.movePrev()
        await updateCurrentPage()
      }

    }
  }

  const saveAndExit = async () => {
    if (validation()) await apiSetSaveAndExitAnswer(page, answers, isAnonym)
    loadingContext.changeIsLoading(true)
    setShowAdditionalQuestions(true)
    routeManager.redirectTo(RouteEnum.clientSurveys)
  }

  const getProgress = () => {
    return (rulesCount?.rulesCount ?? 0) + (rulesCount?.pagesFilterCount ?? 0) === 0 && <ProgressBar totalCount={selectedPagesState.count} completed={selectedPagesState.currentIndex}/>
  }

  if (loadingContext.isLoading) return <></>

  const handleToggleIsAnonym = () => setIsAnonym(prev => !prev)

  const additionalQuestion = <Stack className={cnCustomQuestionPage('questions-stack', { additional: true })} spacing={3}>
    {pageStore.page.questions.filter(x => !x.isMain).map((question, i) => <>
      { question.isVisible && (i !== 0 || (pageStore.page.questions.some(x => x.isMain) && !additionalQuestionsAccordion)) && <hr className='polls-hr-custom1' />}
      <Grid item className={cnCustomQuestionPage('question', { hidden: !question.isVisible, mobile: UIisMobile })} id={question.id} key={question.id}>
        <QuestionRouter
          questionId={question.id}
          questionType={question.type}
          isVisible={question.isVisible}
          onValidate={validateAfterChange(question.id)}
        />
      </Grid>
    </>
    )}
  </Stack>

  return (
    <div className={cnCustomQuestionPage({ mobile: UIisMobile })}>
      <Helmet>
        <title>{survey?.title ?? 'Прохождение опроса'}</title>
      </Helmet>
      <PageHeader
        name={page?.title ?? ''}
        description={page?.description ?? ''}
        pageId={pageId}
        imageId={page?.imageId ?? ''}
        surveyId={page.surveyId}
        surveyType={survey?.type}
        withCards={survey?.withCards}
        service={service}
      />
      <Container maxWidth='lg' className={cnCustomQuestionPage('container')} ref={questionContainerRef}>
        <Stack className={cnCustomQuestionPage('questions-stack', { main: true })} spacing={3}>
          <Stack spacing={3}>
            {UIisMobile && <><Divider/>{getProgress()}</>}
            {pageStore.page.questions.filter(x => x.isMain).map((question, i) => <>
              {i !== 0 && question.isVisible && <hr className='polls-hr-custom1' />}
              <Grid item className={cnCustomQuestionPage('question', { hidden: !question.isVisible, mobile: UIisMobile })} id={question.id} key={question.id}>
                <QuestionRouter
                  questionId={question.id}
                  questionType={question.type}
                  isVisible={question.isVisible}
                  onValidate={validateAfterChange(question.id)}
                  onSetIsSkip={setIsSkip}
                  allowSkipMainQuestion={allowSkipMainQuestion}
                />
              </Grid>
            </>
            )}
          </Stack>
          {survey?.type === SurveyType.Gvk && (survey?.isAnonym ?? false) && <CheckBox
            text={'Опубликовать ответ от своего имени (не анонимно)'}
            checked={!isAnonym}
            onChange={handleToggleIsAnonym}
          />}
          {additionalQuestionsAccordion && page?.questions?.filter(x => !x.isMain).length !== 0 ? <Accordion className={cnCustomQuestionPage('questions-accordion', { main: true })} expanded={showAdditionalQuestions}>
            <AccordionSummary>
              <TextLink
                text={showAdditionalQuestions ? 'Скрыть дополнительные вопросы' : 'Ответить на дополнительные вопросы'}
                color="#266DC2"
                style={{ marginTop: '17px', lineHeight: '18px' }}
                onClick={() => setShowAdditionalQuestions(prev => !prev)}
              />
            </AccordionSummary>
            <AccordionDetails>
              {additionalQuestion}
            </AccordionDetails>
          </Accordion> : additionalQuestion}
        </Stack>
        <ActionsContainer>
          <LeftAlignContainer>
            {selectedPagesState.currentIndex > 0 && <TextLink
              text="К предыдущему вопросу"
              color="#266DC2"
              StartIcon={CircledArrow}
              onClick={handleBack}
            />}
          </LeftAlignContainer>
          <RightAlignContainer>
            <TextLink text='Сохранить и выйти' color="#8B98A7" onClick={saveAndExit}/>
            <Button style={{ marginLeft: '23px' }} isError={pageStore.errors.some(x => x.error.length > 0)} width="158px" height="44px" onClick={handleNext}>
              Далее
            </Button>
          </RightAlignContainer>
        </ActionsContainer>
        {!UIisMobile && getProgress()}
      </Container>
    </div>
  )
}

export default observer(CustomQuestionPage)
