import { observer } from 'mobx-react'
import { Checkbox, FormControlLabel, MenuItem, Select, Switch, Typography } from '@mui/material'

import { ToolTip } from '@/Components'
import { FileType, IFile, defaultPayload, IPayload } from '@/Models/page'
import AddFiles from '@/Pages/Admin/EditPage/Components/Question/AttachmentContainer/AddFiles'
import CheckBoxList from '@/Pages/Admin/EditPage/Components/Question/AttachmentContainer/CheckboxList'
import HelperText from '@/Pages/Admin/EditPage/Components/Question/SharedAdditionalSettings'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

import {
  CheckBoxContainer,
  Container,
  LabelStyled,
  ListContainer,
  SelectContainer,
  SwitchContainer,
  SwitchContainerCustom,
} from './styled'

interface IAttachmentContainer {
  question: IQuestionInstance
  disabled?: boolean
}

const AttachmentContainer = ({ question, disabled = false }: IAttachmentContainer) => {
  return (<Container>
    <ToolTip arrow title={'Окончание интервала шкалы обязательного комментария'}>
      <>
        <HelperText
          question={question}
          disabled={disabled}
        />
      </>
    </ToolTip>
    <AddFiles question={question} disabled={disabled}/>
    <CheckBoxContainer>
      <FormControlLabel
        componentsProps={{ typography: { variant: 'subtitle2' } }}
        control={<Checkbox
          disabled={disabled}
          checked={question.payload.isEnabledLoadFileButton}
          onChange={question.payload.toggleIsEnabledLoadFileButton}
        />}
        label='Добавить поле “Загрузить файл” у пользователя'
      />
    </CheckBoxContainer>
    {question.payload.isEnabledLoadFileButton && <SelectContainer>
      <FormControlLabel
        labelPlacement='start'
        componentsProps={{ typography: { variant: 'subtitle2', sx: { marginRight: '10px' } } }}
        label='Максимально файлов:'
        sx={{ margin: 0 }}
        control={<Select
          labelId="main-question-type-label"
          value={question.payload.maxFileCount}
          onChange={e => question.payload.setMaxFileCount(e.target.value as number)}
          disabled={disabled}
          variant='standard'
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </Select>}
      />
    </SelectContainer>}

    {question.payload.isEnabledLoadFileButton && <SwitchContainerCustom>
      <Typography sx={{ color: '#666666', marginBottom: '10px' }}>Тип файла от пользователя:</Typography>
      <SwitchContainer>
        <LabelStyled className={!question.payload.limitFileType && !disabled ? 'selected left' : 'left'}
          onClick={disabled ? () => {} : question.payload.toggleLimitFileType}>Любой</LabelStyled>
        <Switch
          disableRipple
          checked={question.payload.limitFileType}
          onChange={question.payload.toggleLimitFileType}
          disabled={disabled}
        />
        <LabelStyled className={question.payload.limitFileType && !disabled ? 'selected right' : 'right'}
          onClick={disabled ? () => {} : question.payload.toggleLimitFileType}>Ограничить</LabelStyled>
      </SwitchContainer>
    </SwitchContainerCustom>}
    {question.payload.isEnabledLoadFileButton && question.payload.limitFileType && <ListContainer>
      <CheckBoxList disabled={disabled} checked={question.payload ? question.payload.acceptFileTypes : []}
        onChange={question.payload.toggleAcceptFileType}/>
    </ListContainer>}
  </Container>)
}

export default observer(AttachmentContainer)
