import { MouseEvent } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { observer } from 'mobx-react'

import bem from '@/Helpers/BemClass'

import PageFilter from '../../PageFilter'
import Counter from '../../Components/Counter'
import AddButton from '../../Components/AddButton'
import { AccordionSummaryContent, ActionsContainer, ActionText } from '../../Components/Accordion/styled'
import { useMst } from '../../../../../../Stores/rootStore'
import DndSortableList from '../../../../../../Components/DragAndDrop/DndSortableList'
import { IPageFilterInstance } from '../../../../../../Stores/AdminStores/SurveyInfoStores/branchingStore'
import { CopyItemType } from '../../../../../../Stores/AdminStores/SurveyInfoStores/copyLogicStore'

import { PageFilterBlockContent } from './styled'

import '@/Pages/Admin/SurveyInfoPage/BranchingTab/Components/Accordion/style.scss'

const cnBranchingTab = bem()('branching-tab')

interface IPageFiltersProps {
  pageId: string
  disabled: boolean
}

const PageFiltersBlock = ({ pageId, disabled }: IPageFiltersProps) => {
  const store = useMst()
  const tabStore = store.admin.tabStore
  const surveyInfoRootStore = store.admin.surveyInfoRootStore
  const pageLogic = surveyInfoRootStore.branchingStore.pagesLogic.get(pageId)
  const copyLogic = surveyInfoRootStore.copyLogicStore
  const canPaste = copyLogic.enableCopyMode && copyLogic.type === CopyItemType.pageFilter && copyLogic.appropriateCopyPages().includes(pageId)
  const canCancelPaste = copyLogic.enableCopyMode && copyLogic.type === CopyItemType.pageFilter && copyLogic.pageId === pageId
  const hasDeprecatedItems = pageLogic?.pageFilters.some(x => x.isDeprecated) ?? false
  const hasErrors = pageLogic?.hasPageFiltersErrors()
  if (!pageLogic) return <></>

  const handleAddPageFilter = () => {
    pageLogic.addNewPageFilter(pageId)
    tabStore.setWasChange(true)
  }

  const handleCopy = (e: MouseEvent) => {
    e.stopPropagation()
    copyLogic.setCopyPageFilter(pageId)
  }

  const handleCancelCopy = (e: MouseEvent) => {
    e.stopPropagation()
    copyLogic.cancelCopy()
  }

  const handlePaste = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    copyLogic.pasteFilters(pageId)
    tabStore.setWasChange(true)
  }

  return <Accordion className={cnBranchingTab('styled-accordion')} TransitionProps={{ unmountOnExit: true }}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
      className={cnBranchingTab('styled-accordion__summary', {
        'error': hasErrors,
        'warning': hasDeprecatedItems,
        'can-paste': canPaste,
      })}
    >
      <AccordionSummaryContent onClick={canPaste ? handlePaste : undefined}>
        <div>Фильтры для страницы</div>
        <ActionsContainer>
          <Counter
            text="Фильтров"
            count={pageLogic.pageFilters.length}
            onCopy={handleCopy}
            onCancelCopy={canCancelPaste ? handleCancelCopy : undefined}
            disabled={pageLogic.pageFilters.length === 0 || disabled}
            visible={!disabled}
          />
          {!disabled && <ActionText>{!canPaste ? 'Добавить фильтр' : 'ВСТАВИТЬ'}</ActionText>}
        </ActionsContainer>
      </AccordionSummaryContent>
    </AccordionSummary>
    <AccordionDetails>
      <PageFilterBlockContent>
        <DndSortableList
          data={pageLogic.pageFilters}
          onDragEnd={pageLogic.movePageFilter}
          renderItem={(item, i, dragHandleProps) => <PageFilter key={i} pageId={pageId} filter={item as IPageFilterInstance} index={i + 1} dragHandleProps={dragHandleProps} disabled={disabled}/>}
        />
        {!disabled && <AddButton text="Добавить фильтр" onClick={handleAddPageFilter}/>}
      </PageFilterBlockContent>
    </AccordionDetails>
  </Accordion>
}

export default observer(PageFiltersBlock)
