import { observer } from 'mobx-react'
import { TreeItem } from '@mui/lab'
import PersonIcon from '@mui/icons-material/Person'
import _ from 'lodash'

import { useMst } from '@/Stores/rootStore'
import { IOrganizationStructureElement } from '@/Stores/AdminStores/organizationStructureStore'
import { OrganizationStructureInfoRow } from '@/Components/OrganizationStructure'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnOrganizationStructureTreeElement = bem()('organization-structure-tree-element')

interface ITargetAudienceMapping {
  organizationStructureStateSelector: string
  element: IOrganizationStructureElement
  disabled?: boolean
  readonly: boolean
  disableBacklight?: boolean
}

const OrganizationStructureTreeElement = observer(({ organizationStructureStateSelector, element, disabled = false, readonly, disableBacklight = false }: ITargetAudienceMapping) => {
  const organizationStructureStore = useMst().admin.organizationStructureStore

  return <TreeItem
    TransitionProps={{ unmountOnExit: true }}
    label={
      <OrganizationStructureInfoRow
        element={element}
        organizationStructureStateSelector={organizationStructureStateSelector}
        disabled={disabled}
        readonly={readonly}
      />
    }
    icon={element.isUser && <PersonIcon />}
    key={element.id}
    nodeId={element.id}
    className={cnOrganizationStructureTreeElement()}
    classes={{
      root: cnOrganizationStructureTreeElement('tree-item-root'),
      selected: cnOrganizationStructureTreeElement('tree-item-selected'),
      content: cnOrganizationStructureTreeElement('tree-item-content', { backlight: element.isCascadeSelected && !disableBacklight }),
      label: cnOrganizationStructureTreeElement('tree-item-label'),
      iconContainer: cnOrganizationStructureTreeElement('tree-item-icon-container'),
      expanded: cnOrganizationStructureTreeElement('tree-item-expanded'),
    }}
  >
    {element.childElementsId.length ? _.sortBy(element.childElementsId.map(id => organizationStructureStore.getElement(id, organizationStructureStateSelector)), ['isUser', 'name']).map(x => <OrganizationStructureTreeElement
      key={x.id}
      organizationStructureStateSelector={organizationStructureStateSelector}
      element={x}
      disabled={disabled}
      readonly={readonly}
      disableBacklight={disableBacklight}
    />) : null}
  </TreeItem>
})

export default OrganizationStructureTreeElement
