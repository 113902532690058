/* eslint-disable multiline-ternary */
import { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { useMount } from 'react-use'
import { Grid, Box, Container, Breadcrumbs, IconButton, Link } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SnackbarKey, useSnackbar } from 'notistack'
import { useSessionStorage } from 'usehooks-ts'
import CloseIcon from '@mui/icons-material/Close'
import { sortBy } from 'lodash'

import ContentLoader from '@/Components/ContentLoader'
import { VersionLabel } from '@/Components'
import { ILoaderState, LoaderStateContext } from '@/Components/LoaderContext'
import { RouteEnum } from '@/Enums/route'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { SurveyType } from '@/Stores/AdminStores/surveysStore'
import { useMst } from '@/Stores/rootStore'
import { ISurveyInstance } from '@/Stores/UserStores/userSurveysStore'
import SurveyList from '@/Pages/User/SurveyList'
import DirectLink from '@/Pages/User/DirectLink'
import StartSurvey from '@/Pages/User/StartSurvey'
import QuestionsPage from '@/Pages/User/QuestionsPage'
import StatisticPage from '@/Pages/User/StatisticPage'
import FinalServiceEstimatePage from '@/Pages/User/FinalServiceEstimatePage'
import { errorNotifier, ErrorType, NotificationLevel } from '@/errorNotifier'
import { ApiErrorEnum, ISessionStorageErrors } from '@/Enums/apiError'
import { apiGetUserPageHistory } from '@/Api/page'
import { apiSetStartSurveyEntry } from '@/Api/analytics'
import { NavigationElementsCollection } from '@/Layouts/User/styled'
import { getRedirectHybridPath } from '@/Helpers/redirectHybridPath'
import bem from '@/Helpers/BemClass'

import './style.scss'


enum NavigationPartState {
  Hidden,
  Link,
  Text,
}

interface IUserLayout {
  additionNavigation?: string
  routeEnum: RouteEnum
}

const cnPollsLayoutUser = bem()('layout')

const UserLayout = ({ additionNavigation, routeEnum }: IUserLayout) => {
  const muiTheme = useTheme()
  const UIisTablet = useMediaQuery(muiTheme.breakpoints.down('md'))
  // пример использования для более мелких экранов
  // const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  // const UIisMobileXs = useMediaQuery(muiTheme.breakpoints.down('xs'))

  const { closeSnackbar } = useSnackbar()
  const loadingContext = useContext<ILoaderState>(LoaderStateContext)
  const [isPreloadingInfo, setIsPreloadingInfo] = useState(true)
  const { surveyId, pageId } = useParams<{ surveyId?: string, pageId?: string }>()
  const [survey, setSurvey] = useState<ISurveyInstance>()
  const [selectServiceNavigationPartState, setSelectServiceNavigationPartState] = useState<NavigationPartState>(NavigationPartState.Hidden)
  const [selectPageNavigationPartState, setSelectPageNavigationPartState] = useState<NavigationPartState>(NavigationPartState.Hidden)
  const routeManager = useMemo(() => useRouteManager(), [])
  const store = useMst()
  const selectedPagesState = store.user.selectedPagesState

  // TODO: вынести работу с ошибками и flash-сообщениями в глобальный стор
  const [apiError, setApiError] = useState<ApiErrorEnum>()
  const { enqueueSnackbar } = useSnackbar()
  const [sessionStorageErrors, setSessionStorageErrors] = useSessionStorage<ISessionStorageErrors[]>('errors', [])
  const action = (key: SnackbarKey) => <IconButton onClick={() => closeSnackbar(key)}>
    <CloseIcon/>
  </IconButton>

  useMount(() => {
    const subId = errorNotifier.subscribe(notifyUserNotFoundError, [ErrorType.Err401])
    return () => {
      errorNotifier.unsubscribe(subId)
    }
  })

  useEffect(() => {
    (async () => {
      setIsPreloadingInfo(true)
      if (surveyId) {
        await store.user.userSurveysStore.getUserSurveys()
        const survey = store.user.userSurveysStore.runningSurveys.find(x => x.surveyId === surveyId)
        if (!survey) {
          routeManager.replaceTo(RouteEnum.clientSurveys)
          return
        }

        store.user.pageStore.setSurvey(survey as ISurveyInstance)
        await store.user.pageStore.loadQuestionFilters()
        setSurvey(survey)

        if (!survey.withCards) {
          const history = await apiGetUserPageHistory(survey.surveyId)
          if (history?.length > 0) {
            selectedPagesState.setServices(history, history[history.length - 1], survey.pages.length)
          } else {
            const selectedPages = (sortBy(survey.pages.filter((x) => !x.isComplete), ['order']).map(x => x.id))
            selectedPagesState.setServices([selectedPages[0]], selectedPages[0], survey.pages.length)
          }
          await apiSetStartSurveyEntry(survey.surveyId, [])
        }
      } else {
        setSurvey(undefined)
      }
      setIsPreloadingInfo(false)
      if (sessionStorageErrors) {
        sessionStorageErrors.forEach(error => enqueueSnackbar(error.text, { ...error, action: action }))
        setSessionStorageErrors([])
      }
    })()
  }, [surveyId, store.user.userSurveysStore, routeManager, store.user])

  const notifyUserNotFoundError = (message: string, level: NotificationLevel): void => {
    setIsPreloadingInfo(true)
    setApiError(ApiErrorEnum.userNotFound)
    setIsPreloadingInfo(false)
  }

  useEffect(() => {
    if (!survey) {
      setSelectServiceNavigationPartState(NavigationPartState.Hidden)
      return
    }

    if (survey.type === SurveyType.Gvk || survey.withCards) {
      setSelectServiceNavigationPartState(NavigationPartState.Link)
      return
    }

    setSelectServiceNavigationPartState(NavigationPartState.Text)
  }, [surveyId, survey])

  useEffect(() => {
    if (!pageId) {
      setSelectPageNavigationPartState(NavigationPartState.Hidden)
      return
    }

    if (!!pageId && (survey?.type === SurveyType.Gvk || survey?.withCards)) {
      setSelectPageNavigationPartState(NavigationPartState.Link)
      return
    }

    setSelectPageNavigationPartState(NavigationPartState.Text)
  }, [pageId, surveyId, survey])

  const getView = () => {
    switch (routeEnum) {
      case RouteEnum.estimate: {
        return <FinalServiceEstimatePage/>
      }
      case RouteEnum.statistic: {
        return <StatisticPage/>
      }
      case RouteEnum.questionPage: {
        return <QuestionsPage/>
      }
      case RouteEnum.startPage: {
        return <StartSurvey/>
      }
      case RouteEnum.directLink: {
        return <DirectLink/>
      }
      case RouteEnum.clientSurveys: {
        return <SurveyList apiError={apiError} routeEnum={routeEnum}/>
      }
    }
  }

  return (
    <div className={cnPollsLayoutUser({ user: true, mobile: UIisTablet })}>
      <Container maxWidth='xxl' className={cnPollsLayoutUser('container', { 'mobile': UIisTablet, 'no-surveys': !store.user.userSurveysStore.runningSurveys.length })}>
        <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
          <NavigationElementsCollection>
            <Breadcrumbs className={cnPollsLayoutUser('breadcrumbs', { user: true })} separator='›' aria-label='breadcrumb'>
              {!apiError && routeEnum !== RouteEnum.clientSurveys && <Link className={cnPollsLayoutUser('breadcrumbs-link')} component={RouterLink} to={getRedirectHybridPath(`${routeManager.baseUrl}/`)} underline='hover' color='inherit'>Главная</Link>}
              {/* TODO: refactor: использовать встроенные возможности React Router'а, отрефакторить роутинг */}
              {selectServiceNavigationPartState === NavigationPartState.Link && <Link className={cnPollsLayoutUser('breadcrumbs-link')} component={RouterLink} to={getRedirectHybridPath(`${routeManager.baseUrl}/${RouteEnum.startPage}/${surveyId}/`)} underline='hover' color='inherit'>{survey?.title ?? ''}</Link>}
              {selectServiceNavigationPartState === NavigationPartState.Text && <div>{survey?.title ?? ''}</div>}
              {selectPageNavigationPartState === NavigationPartState.Link && <Link className={cnPollsLayoutUser('breadcrumbs-link')} component={RouterLink} to={getRedirectHybridPath(`${routeManager.baseUrl}/${RouteEnum.questionPage}/${surveyId}/${pageId}/`)} underline='hover' color='inherit'>{survey?.pages.find(x => x.id === pageId)?.title ?? 'Страница без заголовка'}</Link>}
              {selectPageNavigationPartState === NavigationPartState.Text && <div>{survey?.pages.find(x => x.id === pageId)?.title ?? 'Страница без заголовка'}</div>}
              {additionNavigation && <div>{additionNavigation}</div>}
            </Breadcrumbs>
          </NavigationElementsCollection>
        </Box>
      </Container>
      <Grid container className={cnPollsLayoutUser('content')}>
        {(isPreloadingInfo || loadingContext.isLoading) && <ContentLoader/>}
        {!isPreloadingInfo && <Grid item xs className={cnPollsLayoutUser('content-wrapper', { visible: !loadingContext.isLoading })}>
          {getView()}
        </Grid>}
      </Grid>
      <VersionLabel/>
    </div>)
}

export default UserLayout
