import { useState } from 'react'
import { Button as MaterialButton, CircularProgress } from '@mui/material'
import { ButtonTypeMap } from '@mui/material/Button/Button'
import { OverrideProps } from '@mui/material/OverridableComponent'
import { ExtendButtonBaseTypeMap } from '@mui/material/ButtonBase/ButtonBase'

import { useWaiter } from '@/Helpers/react-wait/hooks'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnStyledButton = bem()('styled-button')

// Если не появляется лоэдер, стоит onLoading пренести на конец замыкания, передаваемолго в onClick
// После вызова onIsLoading, страницп перерисовывается из за возврата пустого дерева, что убирает лодер кнопки
export const ButtonStyled = (props: { href: string, waitName?: string } & OverrideProps<ExtendButtonBaseTypeMap<ButtonTypeMap<{ isError?: boolean, width?: string, height?: string, onClick: (() => void) | (() => Promise<any>) }>>, 'a'> & any) => {
  const [isWorking, setIsWorking] = useState<boolean>(false)
  const { startWait, stopWait } = useWaiter()

  const handleClick = async () => {
    if (isWorking) return
    setIsWorking(true)
    props.waitName && startWait(props.waitName)
    try {
      if (props.onClick) await props.onClick()
    } finally {
      props.waitName && stopWait(props.waitName)
      setIsWorking(false)
    }
  }

  return (<MaterialButton
    className={cnStyledButton(props.isError ? 'error' : '')}
    style={{
      minWidth: props.width ? 0 : 100,
      minHeight: props.height ? 0 : 40,
      width: props.width ?? 'auto',
      height: props.height ?? 'auto',
    }}
    {...props}
    disabled={props.disabled || isWorking}
    onClick={handleClick}
  >
    <div className={cnStyledButton('wrapper')}>
      <CircularProgress color='inherit' className={cnStyledButton('spinner', { hidden: !isWorking })} size={20} />
      <div className={cnStyledButton('content', { hidden: isWorking })}>{props.children}</div>
    </div>
  </MaterialButton>)
}

export default ButtonStyled
