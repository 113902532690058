import { ChangeEvent, useState } from 'react'
import { observer } from 'mobx-react'
import { Box, Button, Grid, Modal, TextField } from '@mui/material'

import checkMark from '@/Images/check_icon.svg'
import { useMst } from '@/Stores/rootStore'
import { IOption } from '@/Models/page'
import { ModalStyled } from '@/Components/Questions/styled'
import QuestionLayout from '@/Components/Questions/QuestionLayout'
import { ToolTip } from '@/Components/index'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import htmlSafeParse from '@/Helpers/htmlSafeParseHelper'
import bem from '@/Helpers/BemClass'

const cnQuestion = bem()('question')

interface IImageRadioQuestion {
  questionId: string
  onChangesCommit: () => void
}

const maxLength = 99
const minLength = 0

const ImageRadioQuestion = ({ questionId, onChangesCommit }: IImageRadioQuestion) => {
  const pageStore = useMst().user.pageStore
  const question = pageStore.getQuestion(questionId)
  const answer = pageStore.getAnswer(questionId)

  const [error, setError] = useState<string | null>(null)
  const [open, setOpen] = useState(false)

  const handleOpen = (option: IOption) => () => {
    if (answer.value.every(x => !x.isCustom)) {
      setOpen(true)
    }
  }

  const handleClose = () => setOpen(false)

  const [customValue, setCustomValue] = useState<string>(
    answer?.value?.find(x => x.isCustom)?.value ?? ''
  )

  const handleSetCustom = (option: IOption) => () => {
    if (customValue.length === minLength) {
      setError('Поле не должно быть пустым')
      return
    }

    answer.setValue({ value: customValue, isCustom: true })
    setCustomValue(customValue)
    handleClose()
  }

  const handleChangeCustomAnswer = (e: ChangeEvent<{ value: string }>) => {
    const value = e.target.value
    if (!maxLength || customValue.length <= maxLength) {
      setCustomValue(value)
    } else {
      setCustomValue(value.substring(0, maxLength + 1))
    }

    setError(null)
  }

  const handleChangeToggleAnswer = (value: string, option: IOption) => () => {
    if (answer.singleValue !== value) {
      answer.setValue({ value: value, isCustom: false })
    } else {
      answer.setValue([])
    }

    onChangesCommit()
  }

  const customOption = question.options.find(x => x.isCustom)

  return <QuestionLayout
    question={question}
  >
    <Grid item container flexWrap='wrap' spacing={2.5} columnSpacing={2}>
      {question.options?.map((option, i) => <Grid
        item
        container
        key={i}
        xs={6}
        sm={4}
        md={3}
        lg={3}
        xl={2}
        rowSpacing={1}
        component='label'
        htmlFor={`quest_${questionId}_answ_${i}`}
        className={cnQuestion('card-wrapper')}
      >

        <Grid className={cnQuestion('card')}>
          <div className={cnQuestion('card-image')}>
            <img src={getStaticsHybridUrl(`/api/file/image/${option.imageId ?? 'unknown'}`)} alt='Изображение' loading='lazy' decoding='async'/>
          </div>
          <div className={cnQuestion('card-input')}>
            <input
              id={`quest_${question.id}_answ_${i}`}
              type='radio'
              checked={option.isCustom ? (answer.singleValue === customValue && answer.singleValue.length !== minLength) : answer.singleValue === option.value}
              onChange={option.isCustom ? () => {} : handleChangeToggleAnswer(option.value, option)}
              onClick={option.isCustom ? handleOpen(option) : undefined}
            />
            <div id='image' className={cnQuestion('card-icon')}>
              <img src={checkMark} alt='Иконка' loading='lazy' decoding='async'/>
            </div>
            <ToolTip arrow className={cnQuestion('card-text')} title={htmlSafeParse(option.isCustom ? customValue : option.value)}>
              <div id='text-change'>
                {option.isCustom ? answer.value?.find(x => x.isCustom)?.value ?? option.value : option.value}
              </div>
            </ToolTip>
          </div>
        </Grid>
      </Grid>
      )}
    </Grid>
    {customOption && <Modal
      open={open}
      onClose={handleClose}
    >
      <ModalStyled>
        <Box sx={{ p: 4 }}>
          <TextField
            label='Свой вариант'
            placeholder='Введите текст ответа'
            fullWidth
            rows={3}
            multiline
            value={customValue}
            onChange={handleChangeCustomAnswer}
            error={error !== null}
            helperText={error ?? `${customValue.length}/${maxLength + 1}`}
            inputProps={{ maxLength: 100 }}
          >
          </TextField>
          <Button variant="contained" style={{ width: '160px', float: 'right' }} onClick={handleSetCustom(customOption)}>Добавить</Button>
        </Box>
      </ModalStyled>
    </Modal>}
  </QuestionLayout>
}

export default observer(ImageRadioQuestion)
