import styled from 'styled-components'

export const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 20px;
    flex: 1;

    & > div:nth-child(2){
        width: 200px;
    }
    & > div:nth-child(1){
        width: 60%;
    }
`

export const SyncContainer = styled.div`
    display: flex;
    flex-direction: row;
    & > button{
      padding: 12px;
      margin-top: 12px;
    }
`
