import { IOrganizationStructureState, OrganizationStructureStateStage } from '@/Stores/AdminStores/organizationStructureStore'

const UNKNOWN = 'Неизвестный статус'

export const OrganizationStructureStateStagesNames = new Map<OrganizationStructureStateStage | undefined, string>([
  [OrganizationStructureStateStage.Empty, 'Не сформирована целевая аудитория'],
  [OrganizationStructureStateStage.Draft, 'Черновик целевой аудитории'],
  [OrganizationStructureStateStage.Filled, 'Сформирована целевая аудитория'],
])

/**
 * Текст состояния настройки целевой аудитории
 *
 * @returns Возвращает `'Неизвестный статус'` или {@link OrganizationStructureStateStagesNames|текст}
 */
export const getOrganizationStructureStageName = (state?: IOrganizationStructureState) => {
  return OrganizationStructureStateStagesNames.get(state?.stage) ?? UNKNOWN
}

