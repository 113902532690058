import { HTMLAttributes, FunctionComponent, SVGProps } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { getRedirectHybridPath } from '@/Helpers/redirectHybridPath'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnTextLink = bem()('text-link')

interface ITextLink extends HTMLAttributes<HTMLDivElement> {
  text: string
  StartIcon?: FunctionComponent<SVGProps<SVGSVGElement>>
  EndIcon?: FunctionComponent<SVGProps<SVGSVGElement>>
  href?: string
  disabled?: boolean
  onClick?: () => void
}

const TextLink = ({ text, color, EndIcon, StartIcon, href, disabled = false, onClick, ...other }: ITextLink) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))

  return (<div className={cnTextLink({ disabled: disabled, mobile: UIisMobile })} {...other}>
    {StartIcon && <StartIcon/>}
    <Link
      color={color}
      underline='none'
      component={href ? RouterLink : 'button'}
      to={href ? getRedirectHybridPath(href) : undefined}
      className={cnTextLink('link', { 'end-offset': !!EndIcon, 'start-offset': !!StartIcon, 'disabled': disabled })}
      onClick={onClick}
    >
      {text}
    </Link>
    {EndIcon && <EndIcon/>}
  </div>)
}

export default TextLink
