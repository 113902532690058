import styled from 'styled-components'


export const ServiceCheckContainer = styled.div`
  display: flex;
  position: absolute;
  width: 10%;
  top: 10px;
  right: 10px;

  & > svg {
    height: 100%;
    width: 100%;
    z-index: 0;
  }
`

export const ServiceCheckBackgroundContainer = styled.div`
  margin-top: 100%; // хак для правильной высоты пустого чекбокса
`

export const ServiceCheckBackground = styled.div`
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background-color: #ffffff;
  border-radius: 50%;
  padding-top: 45%;
  text-align: center;
`
