import { useEffect, useRef, useState } from 'react'
import { useMount } from 'react-use'
import { Link as RouterLink } from 'react-router-dom'
import { NoStrictEntityMods } from '@bem-react/classname'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import { ApplyColumnStateParams, DragStoppedEvent, FilterChangedEvent, SortChangedEvent } from 'ag-grid-community'
import { useLocalStorage } from 'usehooks-ts'
import { Button, Link, Skeleton, Tooltip } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import ArchiveIcon from '@mui/icons-material/Archive'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import TableRowsIcon from '@mui/icons-material/TableRows'

import { useRouteManager } from '@/Hooks'
import { RouteEnum } from '@/Enums/route'
import { useMst } from '@/Stores/rootStore'
import { ISurveyInstance, SurveyStatus, SurveysStoreFilter } from '@/Stores/AdminStores/surveysStore'
import { PermissionType } from '@/Stores/sharedModels/models'
import { TableSkeleton } from '@/Components'
import Dialog, { IDialog } from '@/Components/Dialog'
import RoleQualifier, { isRole } from '@/Components/RoleQualifier'
import { useStartStopDialog } from '@/Components/StartStopDialog'
import AgTable from '@/Components/AgGridTable'
import { IAction } from '@/Components/SpeedDial'
import PhotoInput from '@/Components/PhotoInput'
import { ReactComponent as PlayIcon } from '@/Images/Play.svg'
import { ReactComponent as StopIcon } from '@/Images/stop.svg'
import { ReactComponent as PinIcon } from '@/Images/pin.svg'
import { ReactComponent as LockIcon } from '@/Images/lock.svg'
import { ReactComponent as ScheduleIcon } from '@/Images/clock.svg'
import ImageDefault from '@/Images/default.jpg'
import { HeadCell } from '@/Models/headCell'
import { TrafficLightColor } from '@/Models/trafficLight'
import { useWaiter } from '@/Helpers/react-wait'
import bem from '@/Helpers/BemClass'

import './style.scss'
import SurveyModal from './SurveyModal'

const cnSurveyListPage = bem()('survey-list-page')

const trafficLightStateStyles = new Map<TrafficLightColor | null | undefined, NoStrictEntityMods | null | undefined>([
  [TrafficLightColor.Green, { 'green': true }],
  [TrafficLightColor.Yellow, { 'yellow': true }],
  [TrafficLightColor.Red, { 'red': true }],
  [null, undefined],
  [undefined, undefined],
])

interface ISurveyListPage {
  surveyListFilter: SurveysStoreFilter
  showAddSurveyButton?: boolean
}

const SurveyListPage = ({ surveyListFilter, showAddSurveyButton }: ISurveyListPage) => {
  const gridRef = useRef<AgGridReact>(null)
  const [editingSurvey, setEditingSurvey] = useState<ISurveyInstance | undefined>()
  const [addSurvey, setAddSurvey] = useState<boolean>(false)
  const routeManager = useRouteManager()
  const store = useMst()
  const surveyStore = store.admin.surveysStore
  const trafficLightStateStore = store.admin.trafficLightStateStore
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const { startWait, stopWait, isWaiting } = useWaiter()
  const [tableState, setTableState] = useState<ApplyColumnStateParams>({})
  const startStopDialog = useStartStopDialog(setDialogState)
  const columnStateName = 'columnState'
  const columnFilterState = 'columnFilter'
  const isAdmin = !!isRole([{ type: PermissionType.admin }])
  const isEditor = !!isRole([{ type: PermissionType.editor }])
  const [filter, setFilter] = useState<Record<string, any>>({})

  useMount(() => {
    (async () => {
      await loadData()
      setDialogState(undefined)
    })()
  })

  useEffect(() => {
    surveyStore.setFilter(surveyListFilter)
  }, [surveyListFilter, surveyStore])

  const loadData = async () => {
    startWait('survey-list-loading')
    await surveyStore.loadSurveys()
    await store.user.loadUserPermissions()

    surveyStore.sortByCreateAt()

    const model = localStorage.getItem(columnFilterState)

    if (model) {
      if (model.length > 0) {
        setFilter(JSON.parse(model))
      }
    }

    const columnState = localStorage.getItem(columnStateName)

    if (columnState) {
      if (columnState.length > 0) {
        setTableState({ state: JSON.parse(columnState), applyOrder: true })
      }
    }

    await trafficLightStateStore.loadTrafficLights()
    stopWait('survey-list-loading')
  }

  const handleCloseEditSurveyModal = async (surveyId?: string) => {
    setEditingSurvey(undefined)
  }

  const handleDuplicateSurvey = async (surveyId: string) => {
    startWait('survey-list-loading')
    await surveyStore.duplicateSurvey(surveyId)
    // После дублирования опроса у пользователя могут появиться права на этот опрос
    store.user.loadUserPermissions()
    stopWait('survey-list-loading')
  }

  const handleArchivingSurvey = async (surveyId: string) => {
    const handleConfirmArchivingClick = async () => {
      try {
        startWait('survey-list-loading')
        await surveyStore.archiveSurvey(surveyId)
      } finally {
        setDialogState(undefined)
        stopWait('survey-list-loading')
      }
    }

    setDialogState({
      open: true,
      title: 'Архивирование опроса',
      text: 'Вы действительно хотите архивировать опрос?',
      handleClose: () => setDialogState(undefined),
      actions: [
        <Button onClick={() => setDialogState(undefined)} key="no">Нет</Button>,
        <Button onClick={handleConfirmArchivingClick} key="yes">Да</Button>,
      ],
    })
  }

  const handleStartSurvey = (survey: ISurveyInstance) => () => {
    startStopDialog.startSurvey(survey, survey.status === SurveyStatus.Editing)
  }

  const handleStopSurvey = (survey: ISurveyInstance) => async () => {
    startStopDialog.stopSurvey(survey, survey.hasActiveTestingSession)
  }

  const handleLockSurvey = (survey: ISurveyInstance) => async () => {
    startWait(`change-state-${survey.surveyId}`)
    await store.admin.updateSurveyInEditing(survey.surveyId)
    stopWait(`change-state-${survey.surveyId}`)
  }

  const getStatusPriority = (status: any) => {
    switch (status) {
      case 'Запущен': return 1
      case 'Создан': return 2
      case 'Завершен': return 3
      case 'Отменен': return 4
      default: return 5
    }
  }

  const header: Array<HeadCell<ISurveyInstance>> = [
    {
      key: 'title',
      label: 'Название',
      createControl: obj => {
        const worstStateLevel = trafficLightStateStore.getState(obj.surveyId)?.worstColor

        const waitButton = <Button disabled><ScheduleIcon width={22} height={22}/></Button>
        const lockButton = <Button onClick={handleLockSurvey(obj)} ><LockIcon width={22} height={22}/></Button>

        return (<div className={cnSurveyListPage('column-title', trafficLightStateStyles.get(worstStateLevel))}>
          {surveyListFilter !== SurveysStoreFilter.Archived && <RoleQualifier roles={[{ type: PermissionType.admin }]}>
            {isWaiting(`change-state-${obj.surveyId}`) ? waitButton : (!store.admin.isEditorFor(obj.surveyId) ? lockButton : (obj.status === SurveyStatus.Surveyed || obj.hasActiveTestingSession ? <Button key={obj.surveyId} onClick={handleStopSurvey(obj)}>
              <StopIcon width={22} height={22}/>
            </Button> : <Button key={obj.surveyId} onClick={handleStartSurvey(obj)}>
              <PlayIcon width={22} height={22}/>
            </Button>))}
          </RoleQualifier>}
          <div>
            {isWaiting(`change-image-${obj.surveyId}`) ? <Skeleton variant="circular" width={42} height={42} /> : <PhotoInput
              disabled={surveyListFilter === SurveysStoreFilter.Archived || !(isAdmin || isEditor)}
              rounded
              width={38}
              height={38}
              cropWidth={256}
              cropHeight={149}
              onStartUploading={() => startWait(`change-image-${obj.surveyId}`)}
              onEndUploading={() => stopWait(`change-image-${obj.surveyId}`)}
              onChange={obj.ChangeImage}
              imageUrl={obj.imageUrl}
              defaultImage={ImageDefault}
              containerStyle={{ border: '2px solid #E6EAF0', borderRadius: '50%' }}
            />}
          </div>
          <div className={cnSurveyListPage('survey-title')}>
            <Link
              color='black'
              underline='none'
              component={RouterLink}
              to={`${routeManager.baseUrl}/${surveyListFilter === SurveysStoreFilter.Default ? RouteEnum.survey : (surveyListFilter === SurveysStoreFilter.Archived ? RouteEnum.archive : RouteEnum.review)}/${obj.surveyId}`}
              style={{ fontWeight: 'bold', marginBottom: '0', marginTop: '0' }}
            >
              {obj.title}
            </Link>
          </div>
        </div>)
      },
      isVisible: true,
      isSearchable: true,
      flex: 6,
      minWidth: 215,
      comparator: (valueA: any, valueB: any): boolean => {
        const firstValue = (valueA as string).trim()
        const secondValue = (valueB as string).trim()

        const firstValueLoverCase = firstValue.toLowerCase()
        const secondValueLoverCase = secondValue.toLowerCase().trim()

        // Сначала проверяем общий порядок без учета регистра
        if (firstValueLoverCase !== secondValueLoverCase) {
          return firstValueLoverCase > secondValueLoverCase
        }

        // Если значения равны без учета регистра, сортируем по регистру
        if (firstValue === secondValue) {
          return false
        } else {
          return firstValue > secondValue
        }
      },
    },
    {
      key: 'textType',
      label: 'Тип',
      isVisible: true,
      isSearchable: true,
      flex: 1,
      minWidth: 80,
      align: 'center',
    },
    {
      key: 'start',
      label: 'Начало',
      createControl: obj => <>{obj.start.getTime() < 0 ? '' : `${obj.start.toLocaleDateString()} ${obj.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</>,
      isVisible: true,
      isSearchable: true,
      flex: 1,
      minWidth: 110,
      align: 'center',
    },
    {
      key: 'createdAt',
      label: 'Создан',
      createControl: obj => <>{obj.createdAt.getTime() < 0 ? '' : `${obj.createdAt.toLocaleDateString()} ${obj.createdAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</>,
      isVisible: true,
      isSearchable: true,
      flex: 1.6,
      minWidth: 72,
      align: 'center',
    },
    {
      key: 'end',
      label: 'Окончание',
      createControl: obj => <>{`${obj.end.toLocaleDateString()} ${obj.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</>,
      isVisible: true,
      isSearchable: true,
      flex: 1.4,
      minWidth: 110,
      align: 'center',
    },
    {
      key: 'author',
      label: 'Автор',
      createControl: obj => <div className={cnSurveyListPage('column-author')}>{obj.author}</div>,
      isVisible: true,
      isSearchable: true,
      flex: 2,
      minWidth: 110,
      align: 'center',
    },
    {
      key: 'textSurveyType',
      label: 'Представление',
      createControl: obj => (obj.withCards ? <Tooltip title="Карточное" placement='right'>
        <div><DashboardCustomizeIcon className={cnSurveyListPage('survey-view')} width={38} height={38}/></div>
      </Tooltip> : <Tooltip title="Классическое" placement='right'>
        <div><TableRowsIcon className={cnSurveyListPage('survey-view')} width={38} height={38}/></div>
      </Tooltip>),
      isVisible: true,
      isSearchable: true,
      flex: 1.7,
      minWidth: 140,
      align: 'center',
    },
    {
      key: 'version',
      label: 'Запусков',
      createControl: obj => <>{obj.version + 1}</>,
      isVisible: false,
      isSearchable: true,
      flex: 1.1,
      minWidth: 92,
      align: 'center',
    },
    {
      key: 'textStatus',
      label: 'Статус',
      createControl: obj => (obj.status === SurveyStatus.Surveyed ? <div style={{ color: '#219653' }}>{obj.textStatus}</div> : <div>{obj.textStatus}</div>),
      isVisible: true,
      isSearchable: true,
      comparator: (valueA: any, valueB: any): boolean => {
        const num1 = getStatusPriority(valueA)
        const num2 = getStatusPriority(valueB)
        return num1 === num2 ? false : num1 > num2
      },
      flex: 0.9,
      minWidth: 120,
      align: 'center',
    },
    ...(surveyListFilter !== SurveysStoreFilter.Review ? [
      {
        key: 'startedCount',
        label: 'Начали',
        isVisible: true,
        isSearchable: true,
        flex: 0.9,
        minWidth: 72,
        align: 'center',
      },
      {
        key: 'completedCount',
        label: 'Завершили',
        isVisible: true,
        isSearchable: true,
        flex: 1.3,
        minWidth: 107,
        align: 'center',
      },
    ] as Array<HeadCell<ISurveyInstance>> : []),
    {
      key: 'lastReviewStepComment',
      label: 'Комментарий',
      isVisible: surveyListFilter === SurveysStoreFilter.Review,
      isSearchable: true,
      flex: 6,
      minWidth: 300,
    },
  ]

  const [visibleColumns, setVisibleColumns] = useLocalStorage(`survey-list-visible-columns-${surveyListFilter.toLowerCase()}`, header.filter(x => x.isVisible).map(x => x.label).filter(x => x !== 'Номер запуска'))

  if (visibleColumns.length === 0) {
    const defaultHeaderCollection = header.filter(x => x.isVisible).map(x => x.label).filter(x => x !== 'Номер запуска')
    setVisibleColumns(defaultHeaderCollection)
    header.forEach(x => { x.isVisible = defaultHeaderCollection.includes(x.label) })
  } else {
    header.forEach(x => { x.isVisible = visibleColumns.includes(x.label) })
  }

  const actions: IAction[] = [
    {
      visible: (item) => surveyListFilter === SurveysStoreFilter.Default && (isAdmin || !!isRole([{ type: PermissionType.copyingSurveys, surveyId: item.surveyId }])),
      disabled: false,
      icon: <FileCopyIcon/>,
      title: 'Дублировать запуск',
      onClick: async (item: ISurveyInstance) => await handleDuplicateSurvey(item.surveyId),
    },
    {
      visible: surveyListFilter === SurveysStoreFilter.Default && isAdmin,
      disabled: false,
      icon: <ArchiveIcon/>,
      title: 'Архивировать',
      onClick: async (item: ISurveyInstance) => await handleArchivingSurvey(item.surveyId),
    },
    {
      visible: true,
      disabled: false,
      icon: (obj: any) => <PinIcon width={22} height={22} className={cnSurveyListPage('pin', { unpinned: !obj.isPinned })}/>,
      title: (obj: any) => (!obj.isPinned ? 'Закрепить' : 'Открепить'),
      onClick: async (item: ISurveyInstance) => await item.togglePin(),
    },
  ]

  const handleSetAddSurvey = (isOpen: boolean) => async (surveyId?: string) => {
    setAddSurvey(isOpen)
    if (!isOpen) {
      setTableState(prev => ({
        defaultState: { sort: null },
      }))
    }
  }

  const onChange = (event: SortChangedEvent | DragStoppedEvent) => {
    const columnState = event.columnApi.getColumnState()
    if (columnState) {
      localStorage.setItem(columnStateName, JSON.stringify(columnState))
    }
  }

  const onFilterChanged = (event: FilterChangedEvent) => {
    const columnFilter = event.api.getFilterModel()

    if (columnFilter) {
      localStorage.setItem(columnFilterState, JSON.stringify(columnFilter))
    }
  }

  const handleColumnsHasChanging = (columns: Array<HeadCell<ISurveyInstance>>) => {
    const visibleElements = columns.filter(x => x.isVisible).map(x => x.label)
    setVisibleColumns(visibleElements)
  }

  const handleGetCustomRowClass = (index: number, data: ISurveyInstance) => {
    return `${cnSurveyListPage(`survey-row-${data.surveyId}`)} ${cnSurveyListPage('survey-row', { pinned: data.isPinned })}`
  }

  return (<div
    className={cnSurveyListPage({
      'archived': surveyListFilter === SurveysStoreFilter.Archived,
      'not-archived': surveyListFilter === SurveysStoreFilter.Default,
      'review': surveyListFilter === SurveysStoreFilter.Review,
    })}
  >
    <RoleQualifier roles={{ type: PermissionType.admin }}>
      <div className={cnSurveyListPage('button-container', { hidden: !showAddSurveyButton })}>
        <LoadingButton
          className={cnSurveyListPage('button', { uploading: true })}
          loading={isWaiting('survey-list-loading')}
          onClick={() => setAddSurvey(true)}
          variant='contained'
          component='label'
        >
          <AddIcon/>Создать
        </LoadingButton>
      </div>
    </RoleQualifier>
    {isWaiting('survey-list-loading') ? <TableSkeleton/> : <AgTable
      data={surveyStore.filterNotPinned}
      pinnedData={surveyStore.filterPinned}
      headCells={header}
      rowActions={actions.length > 0 ? actions : undefined}
      state={tableState}
      gridRef={gridRef}
      onSortChanged={onChange}
      onColumnsHasChanging={handleColumnsHasChanging}
      getCustomRowClass={handleGetCustomRowClass}
      onDragStopped={onChange}
      onFilterChanged={onFilterChanged}
      filter={filter}
    />}
    {addSurvey && <SurveyModal isOpen={addSurvey} handleClose={handleSetAddSurvey(false)}/>}
    {dialogState && <Dialog {...dialogState}/>}
    {editingSurvey && <SurveyModal
      survey={editingSurvey}
      isOpen={!!editingSurvey}
      handleClose={handleCloseEditSurveyModal}
    />}
  </div>)
}

export default observer(SurveyListPage)
