import { useEffect, useState } from 'react'
import {
  Container,
  Grid,
  Box,
  Button,
  MenuItem,
  Select,
  IconButton, CircularProgress, SelectChangeEvent,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useWaiter } from '@/Helpers/react-wait'
import { apiGetAnswersBySurveyVersions } from '@/Api/answer'
import { apiGetAggregatedReport, apiGetPeriodComparationReport } from '@/Api/manage'
import { apiGetAllPeriodsBySurvey } from '@/Api/survey'
import VerticalButton from '@/Components/VerticalButton'
import RoleQualifier, { isRole } from '@/Components/RoleQualifier'
import { PermissionType } from '@/Stores/sharedModels/models'

import { ToolTip } from '../../../../Components'
import { ISurveyInstance, SurveyStatus } from '../../../../Stores/AdminStores/surveysStore'
import { getFormatDate } from '../../../../Helpers/periodCompareHelper'
import { ReactComponent as ExcelIcon } from '../../../../Images/excel.svg'
import { ReactComponent as DownArrowInBoxIcon } from '../../../../Images/down_arrow_in_box.svg'

import { ButtonContainer, ComparePanelContainer, SelectContainer, TableTitleContainer, TitleContainer } from './styled'
import GenerateTable from './GenerateTable'
import './style.scss'

interface IPeriodCompareTab {
  surveyId: string
}

export interface IAnswer {
  serviceName: string
  pages: IPage[]
  totalAverages: ITotalAverage[]
}

export interface IPage {
  departmentTitle: string
  departments: IDepartment[]
  averages: IAverage[]
}

export interface IDepartment {
  questionText: string
  questions: IValue[]
}

export interface IValue {
  surveyVersion: number
  value: string
  total: string
  percent: string
}

export interface IAverage {
  surveyVersion: number
  averageByPageTitle: string
  percent: string
}

export interface ITotalAverage {
  surveyVersion: number
  totalAveragesBySurveyVersion: string
}

const PeriodCompareTab = ({ surveyId }: IPeriodCompareTab) => {
  const { startWait, stopWait, isWaiting } = useWaiter()
  const [isLoading, setIsLoading] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)
  const [answers, setAnswers] = useState<IAnswer[]>([])
  const [periods, setPeriods] = useState<ISurveyInstance[]>([])
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>([-1])
  const isAdmin = !!isRole([{ type: PermissionType.admin }])

  useEffect(() => {
    (async () => {
      const res = await apiGetAllPeriodsBySurvey(surveyId)
      setPeriods(res.filter(
        x => x.status === SurveyStatus.Completed || x.status === SurveyStatus.Canceled || x.status === SurveyStatus.Surveyed
      ) as unknown as ISurveyInstance[])
      setIsLoading(false)
    })()
  }, [surveyId])

  useEffect(() => {
    if (!isLoading) {
      setIsDisabled(selectedPeriods.filter(x => x !== -1).length === 0)
    }
  }, [selectedPeriods, isLoading])

  const handleAddSelectedPeriod = () => {
    if (selectedPeriods.length < 4) setSelectedPeriods([...selectedPeriods, -1])
  }

  const handleChangeSelectedPeriod = (index: number) => (e: SelectChangeEvent<number>) => {
    setSelectedPeriods(prev => [...prev.map((x, i) => ((i === index) ? e.target.value as number : x))])
  }

  const handleDeleteSelectedPeriod = (index: number) => () => {
    setSelectedPeriods(prev => [...prev.filter((x, i) => i !== index)])
  }

  const handleCompareSelectedPeriod = async () => {
    setIsDisabled(true)
    setIsLoading(true)
    const periods = selectedPeriods.filter(x => x !== -1)
    setSelectedPeriods(periods)
    const res = await apiGetAnswersBySurveyVersions(surveyId, periods)
    setAnswers(res)
    setIsDisabled(false)
    setIsLoading(false)
  }

  const handleGetSurveyMainQuestionsStatisticReport = async () => {
    startWait('handleGetSurveyMainQuestionsStatisticReport')
    await apiGetPeriodComparationReport(surveyId, selectedPeriods.filter(x => x !== -1))
    stopWait('handleGetSurveyMainQuestionsStatisticReport')
  }

  const handleGetAggregatedReport = async () => {
    startWait('handleGetAggregatedReport')
    await apiGetAggregatedReport(surveyId)
    stopWait('handleGetAggregatedReport')
  }

  return <Container maxWidth={false} className='tab-content-wrapper tab__compare'>
    <Grid className='tab-header' container direction='row' justifyContent='flex-end' spacing={1}>
      <Grid item xs={5} sm={5} md={2} lg={1} xl={1}>
        <Box display='flex' flexDirection='row' alignItems='center' height="100%">
          <VerticalButton
            disabled={isWaiting('handleGetSurveyMainQuestionsStatisticReport') || isDisabled || isLoading}
            onClick={handleGetSurveyMainQuestionsStatisticReport}
            startIcon={<ExcelIcon width={48} height={48} />}
          >
            <ToolTip arrow title='Скачать отчёт'>
              <span>Отчёт</span>
            </ToolTip>
          </VerticalButton>
        </Box>
      </Grid>
      <RoleQualifier roles={[{ type: PermissionType.admin }, { type: PermissionType.expert, surveyId: surveyId }]}>
        <Grid item xs={5} sm={5} md={2} lg={1} xl={1}>
          <Box display='flex' flexDirection='row' alignItems='center' height="100%">
            <VerticalButton
              disabled={isWaiting('handleGetAggregatedReport') || isLoading}
              onClick={handleGetAggregatedReport}
              startIcon={<DownArrowInBoxIcon width={48} height={48}/>}
            >
              <ToolTip arrow title='Скачать архив отчётов'>
                <span>Архив отчётов</span>
              </ToolTip>
            </VerticalButton>
          </Box>
        </Grid>
      </RoleQualifier>
    </Grid>

    <Box className='tab-contents' paddingY={5}>
      <ButtonContainer>
        <TitleContainer>Период:</TitleContainer>
        <ComparePanelContainer>
          {selectedPeriods.map((x, i) => (
            <SelectContainer key={i}>
              <IconButton disabled={isDisabled || isLoading} onClick={handleDeleteSelectedPeriod(i)}>
                <CloseIcon/>
              </IconButton>
              <Select
                labelId='main-question-type-label'
                variant='outlined'
                value={x}
                onChange={handleChangeSelectedPeriod(i)}
                disabled={isLoading}
                key={i}
              >
                {periods.map((x) => <MenuItem key={x.version} value={x.version}>{getFormatDate(x)}</MenuItem>)}
              </Select>
            </SelectContainer>
          ))}
        </ComparePanelContainer>
        {selectedPeriods.length < 5 && <Button disabled={isLoading} onClick={handleAddSelectedPeriod} >+</Button>}
        <Button disabled={isDisabled || isLoading} onClick={handleCompareSelectedPeriod} >
          {isLoading ? <CircularProgress className='circular-progress' color='inherit' size={20}/> : <div className='circular-progress-space'/>}
          Сравнить периоды
        </Button>
      </ButtonContainer>
      {/* TODO: Отрефакторить блок ниже с сравнением названий сервисов */}
      {answers.find(x => x.serviceName === 'Внутренний и внешний')?.pages && <>
        <TableTitleContainer>Общая оценка удовлетворённости</TableTitleContainer>
        <GenerateTable
          pages={answers.find(x => x.serviceName === 'Внутренний и внешний')?.pages ?? []}
          periods={periods}
          totalAverages={answers.find(x => x.serviceName === 'Внутренний и внешний')?.totalAverages ?? []}
        />
      </>}
    </Box>

  </Container>
}

export default PeriodCompareTab
