import styled from 'styled-components'

import { device } from '@/Models/device'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px solid #E6EAF0;
    border-radius: 2px;
    background: none;
    div{
      border: none;
      border-radius: 0;
      background: none !important;
      label{
        font-family: 'Proxima Nova', sans-serif;
        font-size: 18px;
        line-height: 16px;
        color: #8B98A7;
      }
      .MuiFormLabel-root.Mui-focused{
        color: #8B98A7;
      }
      .MuiFilledInput-underline:hover:before{
        border: none !important;
        background: none !important;
      }
      :hover{
        background: none !important;
      }
    }
    div :before{
      border: none !important;
      background: none !important;
    }
    div :after{
      border: none !important;
      background: none !important;
    }

  @media screen and ${device.mobile} {
    div {
      label {
        font-size: 16px;
      }
    }
  }

`
