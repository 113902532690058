import styled from 'styled-components'

export const InputStyle = styled.input`
  display: none;
`
export const ContainerStyled = styled.div`
  position: absolute;
`

export const Container = styled.div<{ disabled: boolean }>`
  position: relative;

  .prompt {
    opacity: 0;
    z-index: 100;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  ${props => !props.disabled && `&:hover {
    & > div {
      opacity: 0.5;
    }

    svg {
      cursor: pointer;
      opacity: 1;
    }
  }`}
  .rounded {
    border-radius: 50%;
    object-fit: cover;
  }
`

export const PhotoInputImageContainer = styled.div<{ width: number, height: number }>`
  ${props => `width: ${props.width}px; height: ${props.height}px;`}
  position: relative;
  overflow: hidden;
  border-radius: 13px;
`

export const PhotoLabelStyled = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
