import { types as t } from 'mobx-state-tree'

const defaultAlertTitle = 'На странице были изменения'

export const TabStore = t.model({
  wasChanged: t.boolean,
  title: t.string,
}).actions(self => ({
  setWasChange(value: boolean, title?: string) {
    self.wasChanged = value
    self.title = title ?? defaultAlertTitle

    if (!value) {
      self.title = defaultAlertTitle
    }
  },
}))

