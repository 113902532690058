import { ru } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'

import bem from '@/Helpers/BemClass'

import './styles.scss'

const cnDateTimeRow = bem()('date-time')

interface IDateTimeRow {
  lable: string
  disabled: boolean
  value: Date
  onChange: (date: Date | null) => void
  isValide: boolean
}

const DateTimeRow = ({ lable, disabled, value, onChange, isValide }: IDateTimeRow) => {
  return <div className={cnDateTimeRow()}>
    <InputLabel>{lable}</InputLabel>
    <div className={cnDateTimeRow('row')}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
        <DatePicker
          inputFormat='dd.MM.yyyy'
          value={value}
          disabled={disabled}
          onOpen={() => onChange(value < new Date() ? new Date() : value)}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} variant='standard' />}
          className={cnDateTimeRow('row__date', { error: !isValide })}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
        <TimePicker
          inputFormat='HH.mm'
          views={['hours', 'minutes']}
          disabled={disabled}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} variant='standard' />}
          className={cnDateTimeRow('row__time', { error: !isValide })}
        />
      </LocalizationProvider>
    </div>
  </div>
}

export default DateTimeRow
