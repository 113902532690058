import { useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { useElementSize } from 'usehooks-ts'
import { AutoSizer, List } from 'react-virtualized'
import { observer } from 'mobx-react'
import { Button, Stack, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useMst } from '@/Stores/rootStore'
import bem from '@/Helpers/BemClass'
import { OrganizationStructureElementShortData, OrganizationStructureSectionHeader, OrganizationStructureSectionData } from '@/Components/OrganizationStructure'
import { SectionExpandingState } from '@/Components/OrganizationStructure/TreeViewExtendedWithConfirm'
import { ElementsTypeFilter, IOrganizationStructureFilter } from '@/Stores/AdminStores/organizationStructureStore'

import './style.scss'

const cnOrganizationStructureSectionConfirmedStaff = bem()('organization-structure-section-confirmed-staff')

interface IOrganizationStructureSectionConfirmedStaff {
  organizationStructureStateSelector: string
  sectionExpandingState: SectionExpandingState
  onExpandChange: () => void
  disabled?: boolean
}

const OrganizationStructureSectionConfirmedStaff = ({ organizationStructureStateSelector, sectionExpandingState, onExpandChange, disabled = false }: IOrganizationStructureSectionConfirmedStaff) => {
  const store = useMst()
  const organizationStructureStore = store.admin.organizationStructureStore
  const tabStore = store.admin.tabStore

  const organizationStructureState = organizationStructureStore.getOrganizationStructureState(organizationStructureStateSelector)
  const [confirmedOrganizationStructureStaffFilter, setConfirmedOrganizationStructureStafffilter] = useState<IOrganizationStructureFilter>()
  const [expandingState, setExpandingState] = useState<SectionExpandingState>(sectionExpandingState)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const isExpanded = [SectionExpandingState.Staff, SectionExpandingState.All].includes(expandingState)

  const [ref, { width, height }] = useElementSize()

  useEffect(() => {
    setExpandingState(sectionExpandingState)
  }, [sectionExpandingState])

  useMount(() => {
    setIsLoading(true)

    const filterData = {
      searchText: '',
      isSelectedOnly: false,
      isConfirmedOnly: true,
      elementsTypeFilter: ElementsTypeFilter.User,
    }

    const filter = organizationStructureState?.addFilter(filterData.searchText, filterData.isSelectedOnly, filterData.isConfirmedOnly, filterData.elementsTypeFilter)

    if (filter) {
      setConfirmedOrganizationStructureStafffilter(filter)
    }

    setIsLoading(false)

    return () => { filter && organizationStructureState?.removeFilter(filter) }
  })

  const handleRemoveConfirm = (isFreeElement: boolean) => (elementId: string) => {
    if (isFreeElement) {
      organizationStructureState?.removeFreeElement(elementId)
    } else {
      organizationStructureState?.changeConfirmationAt(false, elementId)
    }

    tabStore.setWasChange(true, 'Были внесены изменения в целевую аудиторию')
  }

  const handleClearStaffConfirmation = () => {
    confirmedOrganizationStructureStaffFilter?.changeConfirmationForFiltered(false)
    tabStore.setWasChange(true, 'Были внесены изменения в целевую аудиторию')
  }

  const handleClearStaffFreeElements = () => {
    confirmedOrganizationStructureStaffFilter?.removeFilteredFreeElements()
    tabStore.setWasChange(true, 'Были внесены изменения в целевую аудиторию')
  }

  return <Stack direction='column' className={cnOrganizationStructureSectionConfirmedStaff()}>
    <OrganizationStructureSectionHeader disableRounding onClick={onExpandChange}>
      <Typography fontWeight='bold'>
        {`Сотрудники (${confirmedOrganizationStructureStaffFilter?.filteredOrganizationStructureElements.length ?? 0}):`}
      </Typography>
      <ExpandMoreIcon className={cnOrganizationStructureSectionConfirmedStaff('expand-icon', { opened: isExpanded, closed: !isExpanded })} />
    </OrganizationStructureSectionHeader>
    <OrganizationStructureSectionData halfWidth={true}>
      <div ref={ref} className={cnOrganizationStructureSectionConfirmedStaff('section-data-container')}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              rowHeight={30}
              width={width}
              height={height}
              rowCount={
                (confirmedOrganizationStructureStaffFilter?.filteredOrganizationStructureFreeElements.length ?? 0) +
            (confirmedOrganizationStructureStaffFilter?.filteredOrganizationStructureElements.length ?? 0)
              }
              rowRenderer={({ key, index, style }) => {
                const freeElementsCount = confirmedOrganizationStructureStaffFilter?.filteredOrganizationStructureFreeElements.length ?? 0
                const isFreeElement = index < freeElementsCount
                const elementId = isFreeElement ? (confirmedOrganizationStructureStaffFilter?.filteredOrganizationStructureFreeElements ?? []).at(index) ?? '' : (confirmedOrganizationStructureStaffFilter?.filteredOrganizationStructureElements ?? []).at(index - freeElementsCount) ?? ''

                return <div key={key} style={style}>
                  <OrganizationStructureElementShortData
                    organizationStructureStateSelector={organizationStructureStateSelector}
                    elementId={elementId}
                    onDelete={handleRemoveConfirm(isFreeElement)}
                    isLoading={isLoading}
                    disabled={disabled}
                    freeElement={isFreeElement}
                  />
                </div>
              }}
            />
          )}
        </AutoSizer>
      </div>
      {!disabled && <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={0}
        className={cnOrganizationStructureSectionConfirmedStaff('section-data__button-container')}
      >
        <Button
          variant='text'
          disabled={disabled || (confirmedOrganizationStructureStaffFilter?.filteredOrganizationStructureFreeElements.length ?? 0) === 0}
          className={cnOrganizationStructureSectionConfirmedStaff('section-data__button')}
          onClick={handleClearStaffFreeElements}
        >
          {`Удалить все конфликты ${(!!confirmedOrganizationStructureStaffFilter && (confirmedOrganizationStructureStaffFilter.filteredOrganizationStructureFreeElements.length) !== 0) ? `(${confirmedOrganizationStructureStaffFilter.filteredOrganizationStructureFreeElements.length})` : ''}`}
        </Button>
        <Button
          variant='text'
          disabled={disabled || (confirmedOrganizationStructureStaffFilter?.filteredOrganizationStructureElements.length ?? 0) === 0}
          className={cnOrganizationStructureSectionConfirmedStaff('section-data__button')}
          onClick={handleClearStaffConfirmation}
        >
          Удалить все элементы
        </Button>
      </Stack>}
    </OrganizationStructureSectionData>
  </Stack>
}

export default observer(OrganizationStructureSectionConfirmedStaff)
