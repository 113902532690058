import { FileMimeType } from '@/Helpers/fileTypeNumToMime'

/**
 * Карта цветов
 *
 * [цвет текста, цвет фона]
 */
type ColorMap = [string, string]

const DEFAULT_KEY = '_default_'

const FilesMimeTypesColors = new Map<string, ColorMap>([
  [DEFAULT_KEY, ['#5e5d5d', '#a3a3a3']],
  [FileMimeType.doc, ['#5280ff', '#8897bf']],
  [FileMimeType.docx, ['#5280ff', '#8897bf']],
  [FileMimeType.pdf, ['#a60000', '#a67474']],
  [FileMimeType.ppt, ['#fa4b4b', '#c26565']],
  [FileMimeType.pptx, ['#fa4b4b', '#c26565']],
  [FileMimeType.text, ['#5e5d5d', '#a3a3a3']],
  [FileMimeType.xls, ['#017001', '#6fa36f']],
  [FileMimeType.xlsx, ['#017001', '#6fa36f']],
])

/**
 * Цвет для текста и фона, на базе mime-типа файла
 *
 * @param fileMimeType mime-тип файла из Enum: {@link FileMimeType}
 * @return Возвращает карту цветов {@link ColorMap} в HEX формате
 */
export const getColorByFileMimeType = (fileMimeType: string) => {
  return FilesMimeTypesColors.get(fileMimeType) ?? FilesMimeTypesColors.get(DEFAULT_KEY) as ColorMap
}
