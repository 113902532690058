import { useState } from 'react'
import { observer } from 'mobx-react'
import { Card, CardContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { SurveyType } from '@/Stores/AdminStores/surveysStore'
import SliderContainer from '@/Pages/Admin/EditPage/Components/Question/SliderContainer'
import TextEditor from '@/Components/TextEditor'
import QuestionSettings from '@/Pages/Admin/EditPage/Components/Question/QuestionSettings'
import { ColumnContentContainer, Container, QuestionContainer, QuestionTypeTitle } from '@/Pages/Admin/SurveyListPage/SurveyModal/MainQuestionBlock/styled'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

export interface IMainQuestionBlock {
  onDelete: () => void
  question: IQuestionInstance
  disabled: boolean
  surveyType: SurveyType
  questionLabel?: string
  showDeleteButton?: boolean
}

export const MainQuestionBlock = ({ question, onDelete, disabled, surveyType, questionLabel = 'Вопрос', showDeleteButton = false }: IMainQuestionBlock) => {
  const [editorIsOpen, setEditorIsOpen] = useState(false)

  return <Card style={{ marginBottom: '20px' }}>
    <CardContent>
      <Container>
        <QuestionContainer>
          <QuestionTypeTitle gutterBottom fontWeight='bold' variant='subtitle2'>Тип обязательного вопроса: Шкала</QuestionTypeTitle>
          <TextEditor
            title='Редактирование вопроса'
            placeholder='Введите текст вопроса'
            value={question.text}
            isOpen={editorIsOpen}
            onOpen={() => setEditorIsOpen(true)}
            onClose={() => setEditorIsOpen(false)}
            onChange={question.setText}
            error={question.emptyQuestionTextError}
            disabled={disabled}
          />
          <SliderContainer
            question={question}
            showNotReadyToRateBlock
            disabled={disabled}
          />
          <QuestionSettings
            question={question}
            isMain={false}
            disabled={disabled}
          />
        </QuestionContainer>
        <ColumnContentContainer>
          <IconButton sx={{ visibility: showDeleteButton ? '' : 'hidden' }} disabled={disabled} onClick={onDelete}>
            <CloseIcon/>
          </IconButton>
        </ColumnContentContainer>
      </Container>
    </CardContent>
  </Card>
}

export default observer(MainQuestionBlock)
