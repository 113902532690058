import { useState, useRef, ReactNode } from 'react'
import { Box, Dialog } from '@mui/material'
import SunEditor from 'suneditor-react'
import SunEditorCore from 'suneditor/src/lib/core'
import { ru } from 'suneditor/src/lang'
import _ from 'lodash'
import { useSnackbar } from 'notistack'

import { apiSaveImage } from '@/Api/file'
import Button from '@/Components/Button'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'

import 'suneditor/dist/css/suneditor.min.css'
import './style.scss'

interface IEditorModal {
  /** Признак открытого окна редактирования */
  isOpen: boolean
  /** Событие закрытия диалога */
  handleClose: () => void
  /** Значение введенное пользователем */
  content: string
  /** Событие изменения поля ввода */
  OnChange: (value: string) => void
  /** Заголовок редактора */
  title: string
  /** Признак отключения загрузки картинок */
  disableImage?: boolean
}

const EditorModal = ({ isOpen, handleClose, content, OnChange, title, disableImage = false }: IEditorModal) => {
  const [val, setVal] = useState<string>(content)
  const editor = useRef<SunEditorCore>()
  const { enqueueSnackbar } = useSnackbar()

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor
  }

  const handleValueChange = (value: string) => {
    if (_.isEmpty(editor.current?.getText())) {
      enqueueSnackbar('Внимание! Стандартные стили были изменены')
    }
    setVal(value)
  }

  // Если тут падает ошибка "uploadHandler is not a function", то:
  // Проверь решение в этом issue (https://github.com/mkhstar/suneditor-react/issues/310).
  // Проверь версии библиотек, в них бага нет:
  // "suneditor": "^2.44.5",
  // "suneditor-react": "^3.4.1",
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleSuccessFileUpload = (files: File[], info: object, uploadHandler: Function) => {
    const image = files[0]
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = async () => {
      if (!reader.result) return
      const img = reader.result as string
      const base64Data = img.slice(img.toString().indexOf('base64,') + 'base64,'.length)
      const imageId = await apiSaveImage(base64Data)
      const response = {
        'result': [
          {
            'url': getStaticsHybridUrl(`/api/file/image/${imageId}`),
            'name': image.name,
            'size': image.size,
          },
        ],
      }

      uploadHandler(response)

      return response
    }

    reader.onerror = function() {
      console.error(reader.error)
    }

    // undefined чтобы работало сохранение как url, а не base64 (механизм uploadHandler)
    // Читай доку к методу onImageUploadBefore: https://github.com/JiHong88/suneditor/blob/master/README.md
    return undefined
  }

  const applyChange = () => {
    OnChange(editor.current?.getContents(false) ?? '')
    handleClose()
  }

  return (<>
    <Dialog className='htmlEditorDialog' onClose={handleClose} open={isOpen}>
      <Box className='editorModalContainer'>
        <h3>
          {title}
        </h3>
        <SunEditor
          lang={ru}
          // Стили для редактора, не для html внутри редактора
          setDefaultStyle='font-family: Proxima Nova; font-size: 21px;'
          placeholder='Введите текст вопроса'
          setOptions={{
            fontSize: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 36, 48],
            resizingBar: false,
            buttonList: [
              ['undo', 'redo'],
              ['formatBlock'],
              ['bold', 'underline', 'italic', 'strike'],
              ['fontSize', 'fontColor', 'hiliteColor', 'align'],
              ['removeFormat'],
              disableImage ? ['link', 'codeView'] : ['link', 'image', 'codeView'],
              ['showBlocks'],
            ],
          }}
          getSunEditorInstance={getSunEditorInstance}
          defaultValue={val}
          onImageUploadBefore={handleSuccessFileUpload}
          onChange={handleValueChange}
          autoFocus={true}
          height={'auto'}
        />
        <Button height="44px" onClick={applyChange}>ОК</Button>
      </Box>
    </Dialog>
  </>)
}

export default EditorModal
