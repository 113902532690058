
export enum SpreadsheetCellBorderStyleVariant {
  DashDot = '0',
  DashDotDot = '1',
  Dashed = '2',
  Dotted = '3',
  Double = '4',
  Hair = '5',
  Medium = '6',
  MediumDashDot = '7',
  MediumDashDotDot = '8',
  MediumDashed = '9',
  None = '10',
  SlantDashDot = '11',
  Thick = '12',
  Thin = '13',
}

export interface IColor {
  r: number
  g: number
  b: number
}

export interface ISpreadsheetCellBorderStyles {
  variant: SpreadsheetCellBorderStyleVariant
  color: IColor
}


export interface ISpreadsheetCellStyles {
  color: IColor | null
  fontColor: IColor | null
  fontSize: number
  isItalic: boolean
  isBold: boolean
  borderTop: ISpreadsheetCellBorderStyles | null
  borderRight: ISpreadsheetCellBorderStyles | null
  borderBottom: ISpreadsheetCellBorderStyles | null
  borderLeft: ISpreadsheetCellBorderStyles | null
}

export interface ISpreadsheetCellData {
  fromRow: number
  toRow: number
  fromColumn: number
  toColumn: number
  value: string
  order: number
  styles: ISpreadsheetCellStyles
}

export interface ISpreadsheetCellsData {
  cells: Record<number, Record<number, ISpreadsheetCellData>>
  columnsWidth: number[]
  rowsHeight: number[]
  mergedCells: Record<number, number[]>
}

export interface ISpreadsheetCellSummaryData {
  x: number
  y: number
  value: string
}

export interface ISpreadsheetSummaryData {
  cells: ISpreadsheetCellSummaryData[]
}
