import Tooltip, { TooltipProps } from '@mui/material/Tooltip'

import { ColorTheme } from '../../Models/colorTheme'

import { ToolTipGlobalStyled } from './styled'


interface ICustomizedTooltip extends TooltipProps {
  theme?: ColorTheme
}

const CustomizedTooltip = (props: ICustomizedTooltip) => {
  return (<>
    <Tooltip arrow {...props} classes={{ tooltip: props.theme ?? ColorTheme.light }} />
    <ToolTipGlobalStyled />
  </>)
}

export default CustomizedTooltip

