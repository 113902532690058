import { observer } from 'mobx-react'
import { Checkbox, FormControl, SelectChangeEvent, TextField } from '@mui/material'

import SelectWithNumbers from '@/Components/SelectWithNumbers'
import { ToolTip } from '@/Components'
import SharedAdditionalSettings from '@/Pages/Admin/EditPage/Components/Question/SharedAdditionalSettings'
import { IQuestionInstance, QuestionValidationResult } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

import { CheckBoxContainer } from '../styled'

import { Container, ScaleSelectValueContainer } from './styled'

interface ISliderContainerProps {
  question: IQuestionInstance
  disabled?: boolean
  showNotReadyToRateBlock?: boolean
  showRequireCommentValueBlock?: boolean
}

const SliderContainer = ({ question, disabled = false, showNotReadyToRateBlock = true, showRequireCommentValueBlock = false }: ISliderContainerProps) => {


  const handleChangeOption = (index: number) => (e: SelectChangeEvent<{ value: unknown }>) => {
    question.options[index].setValue(e.target.value as string)
  }

  return (<Container>
    <ToolTip arrow title={'Окончание интервала шкалы обязательного комментария'}>
      <SharedAdditionalSettings
        question={question}
        disabled={disabled}
      />
    </ToolTip>

    {showNotReadyToRateBlock && <CheckBoxContainer>
      <TextField
        className={question.emptySkipTextError ? 'error' : ''}
        style={{ width: '350px' }}
        disabled={disabled}
        placeholder='Введите текст для варианта не оценивать'
        onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
        value={question.payload.notReadyToRateText}
        name={'notReadyToRateText'}
        onChange={e => question.payload.setNotReadyToRateText(e.target.value)}
        error={question.emptySkipTextError}
        variant='standard'
      />
      <Checkbox checked={question.payload.showNotReadyToRate ?? false} onChange={question.payload.toggleShowNotReadyToRate} disabled={disabled}/>
    </CheckBoxContainer>}

    <ScaleSelectValueContainer>
      <FormControl>
        <SelectWithNumbers
          label={'Начало шкалы'}
          value={question.options[0].value}
          onChange={handleChangeOption(0)}
          disabled={disabled}
          start={0}
          end={8}
        />
        <TextField
          label="Начало шкалы"
          placeholder="Введите текст подсказки"
          value={question.payload?.helperStartText}
          onChange={e => question.payload.setHelperStartText(e.target.value)}
          fullWidth
          onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
          disabled={disabled}
          variant="standard"
        />
      </FormControl>

      <FormControl>
        <SelectWithNumbers
          label={'Окончание шкалы'}
          value={question.options[1].value}
          onChange={handleChangeOption(1)}
          disabled={disabled}
          start={2}
          end={10}
        />
        <TextField
          label="Окончание шкалы"
          placeholder="Введите текст подсказки"
          value={question.payload?.helperEndText}
          onChange={e => question.payload.setHelperEndText(e.target.value)}
          fullWidth
          onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
          disabled={disabled}
          variant="standard"
        />
      </FormControl>
    </ScaleSelectValueContainer>
    {showRequireCommentValueBlock &&
        <FormControl className={'requireCommentValue'} fullWidth style={{ marginTop: '25px' }}>
          <SelectWithNumbers
            label={'Обязательный комментарий для значений от начала шкалы до выбранного значения (включая его)'}
            value={question.payload?.requireCommentValue}
            onChange={e => question.payload.setRequireCommentValue(e.target.value as string)}
            disabled={disabled}
            start={0}
            end={10}
          />
        </FormControl>}
  </Container>)
}

export default observer(SliderContainer)
