import { ChangeEvent } from 'react'
import { observer } from 'mobx-react'
import { IconContainerProps, Rating } from '@mui/lab'
import StarIcon from '@mui/icons-material/Star'
import { Tooltip, useMediaQuery, useTheme } from '@mui/material'

import { useMst } from '@/Stores/rootStore'
import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'
import CheckBox from '@/Components/CheckBox'
import QuestionLayout from '@/Components/Questions/QuestionLayout'
import { notReadyToRateText } from '@/Helpers/globalConst'
import { isCommentVisibleFor } from '@/Helpers/isCommentVisibleFor'
import { commentIsRequired } from '@/Helpers/QuestionHelper'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnQuestion = bem()('question')

interface IRatingQuestion {
  questionId: string
  onChangesCommit: () => void
}

export const helperText = 'Оцените по шкале 1-5, где 1 - совершенно неудовлетворен, 5 - полностью удовлетворен.'

const RatingQuestion = ({ questionId, onChangesCommit }: IRatingQuestion) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const pageStore = useMst().user.pageStore
  const question = pageStore.getQuestion(questionId)
  const answer = pageStore.getAnswer(questionId)
  const handleChange = (event: ChangeEvent<{}>, newValue: number | null) => {
    answer.setValue({ value: newValue?.toString() ?? '', isCustom: false })
    onChangesCommit()
  }
  const isChecked = answer.isNotEstimate()

  const IconContainer = (props: IconContainerProps) => {
    const { value, className, ...other } = props
    // Если "MuiRating-iconFocus" выделение после текущей звезды, если "MuiRating-iconEmpty" то до. Класс "MuiRating-iconActive" установлен у звезды на которую навели
    // Отображаем текст у выбранной звезды если не навели на звезду до или после. Отображаем текст у звезды на которую навели
    const isVisible = (Number.parseInt(answer.singleValue) === value && !className?.includes('MuiRating-iconFocus') && !className?.includes('MuiRating-iconEmpty')) || className?.includes('MuiRating-iconActive')
    return (<Tooltip
      open={isVisible ?? undefined}
      classes={{
        tooltip: cnQuestion('label-tooltip'),
      }}
      title={question.options[value - 1]?.title ?? 'test'}
      placement='top'
      PopperProps={{
        placement: 'top',
        modifiers: [
          {
            name: 'flip',
            enabled: false,
          },
        ],
      }}
    >
      <div>
        <div {...other} className={className}><StarIcon/></div>
      </div>
    </Tooltip>)
  }

  const handleIsCheckedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    if (checked) answer.setValue({ value: (question.payload?.notReadyToRateText ?? '') ?? notReadyToRateText, isCustom: false }, true)
    else answer.setValue({ value: '', isCustom: false })
  }

  const align = question.payload?.align

  return <QuestionLayout
    tooltipSpace
    question={question}
    alternativeHelperText={helperText}
    showComment={isCommentVisibleFor(question, answer)}
    requiredComment={commentIsRequired(question, answer)}
    commentValue={answer.comment ?? ''}
    onCommentChange={val => answer.setComment(val)}
  >
    <div className={cnQuestion({ rating: true })}>
      <div className={cnQuestion('rating-container', {
        left: align === AlignEnum.left,
        right: align === AlignEnum.right,
        center: align === AlignEnum.center,
      })}>
        <Rating
          className={cnQuestion('star', {
            small: question.payload?.size === SizeEnum.small,
            medium: question.payload?.size === SizeEnum.medium,
            large: question.payload?.size === SizeEnum.large,
            mobile: UIisMobile,
            desktop: !UIisMobile,
          })}
          IconContainerComponent={IconContainer}
          value={Number.parseInt(answer.singleValue)}
          onChange={handleChange}
          // Если вопрос старый, то у него нет options. Для такого вопроса генерим старые 5 звёзд
          max={question.options.length !== 0 ? question.options.length : 5}
          size='large'
        />
      </div>
      {question.payload?.showNotReadyToRate && <CheckBox
        id={`question-${question.id}-not-ready-to-rate-checkbox`}
        text={question.payload?.notReadyToRateText ?? notReadyToRateText}
        checked={isChecked}
        onChange={handleIsCheckedChange}
      />}
    </div>
  </QuestionLayout>
}

export default observer(RatingQuestion)
