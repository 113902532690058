import { ChangeEvent, useState } from 'react'
import { useMount } from 'react-use'
import { observer } from 'mobx-react'
import { Button, Skeleton } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useMst } from '@/Stores/rootStore'
import OrganizationStructureTreeView from '@/Components/OrganizationStructure/TreeView'
import Dialog, { IDialog } from '@/Components/Dialog'
import bem from '@/Helpers/BemClass'
import { apiParseOrganizationStructureFromFile, apiSaveOrganizationStructureFromFile } from '@/Api/organizationStructure'
import { FileType } from '@/Enums/fileType'

import './style.scss'

const cnOrganizationStructurePage = bem()('organization-structure-page')

const organizationStructureViewSelector = 'organization-structure-view'

const OrganizationStructurePage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const store = useMst()
  const organizationStructureStore = store.admin.organizationStructureStore

  const loadDefaultData = async () => {
    setIsLoading(true)
    try {
      await organizationStructureStore.loadWithSelected(organizationStructureViewSelector, [])
    } catch {}
    setSelectedFile(null)
    setIsLoading(false)
  }

  useMount(loadDefaultData)

  const showMessage = (title: string, text: string) => {
    setDialogState({
      open: true,
      handleClose: () => setDialogState(undefined),
      title: title,
      text: text,
      actions: [<Button onClick={() => setDialogState(undefined)} key='ok'>ОК</Button>],
    })
  }

  const handleFileAdd = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const file: File = e.target.files[0]
    if (!['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']?.includes(file.type)) {
      showMessage('Не удалось загрузить файл', `Выбран файл с недопустимым расширением ${file.type}`)
      return
    }
    setIsLoading(true)
    const formData = new FormData()
    formData.append('file', file)
    try {
      const fileType = (file.name.endsWith('.csv') || file.type === 'text/csv') ? FileType.Csv : (file.type === 'application/vnd.ms-excel' ? FileType.Xls : FileType.Xlsx)
      const data = await apiParseOrganizationStructureFromFile(formData, fileType)
      organizationStructureStore.fill(organizationStructureViewSelector, data, [])
      setSelectedFile(file)
    } catch { }
    setIsLoading(false)
  }

  const handleSaveChanges = async () => {
    if (!selectedFile) return

    setIsLoading(true)

    const formData = new FormData()
    formData.append('file', selectedFile)

    const fileType = (selectedFile.name.endsWith('.csv') || selectedFile.type === 'text/csv') ? FileType.Csv : (selectedFile.type === 'application/vnd.ms-excel' ? FileType.Xls : FileType.Xlsx)

    await apiSaveOrganizationStructureFromFile(formData, fileType)

    await loadDefaultData()
  }

  return (
    <div className={cnOrganizationStructurePage()}>
      <LoadingButton className={cnOrganizationStructurePage('button', { uploading: true })} loading={isLoading} variant='contained' component='label'>
        Загрузить оргструктуру
        <input hidden multiple={false} accept='.xls, .xlsx, .csv' type='file' onChange={handleFileAdd} />
      </LoadingButton>
      <div className={cnOrganizationStructurePage('tree-view-container', { uploading: true })}>
        {isLoading ? <Skeleton variant='rectangular' width='100%' height={130} /> : <OrganizationStructureTreeView
          organizationStructureStateSelector={organizationStructureViewSelector}
          disabled={false}
          readonly={true}
        />}
      </div>
      <div className={cnOrganizationStructurePage('actions')}>
        {selectedFile && !isLoading && <Button
          className={cnOrganizationStructurePage('button', { save: true })}
          variant='contained'
          onClick={handleSaveChanges}
        >
          Сохранить
        </Button>}
        {selectedFile && !isLoading && <Button
          className={cnOrganizationStructurePage('button', { reset: true })}
          variant='contained'
          onClick={loadDefaultData}
        >
          Отменить
        </Button>}
      </div>
      {dialogState && <Dialog {...dialogState} />}
    </div>
  )
}

export default observer(OrganizationStructurePage)
