import { Component } from 'react'

import { WaiterContext } from './context'

import type { IWaiterContext, WaiterName, Waiters } from './types'

/**
 * Состояние в провайдере
 */
interface IWaiterProviderState {
  waiters: Waiters
}

/**
 * Провайдер для контекста Waiter с компонентом Wait
 */
class WaiterProvider extends Component<{}, { waiters: Waiters }> {
  // TODO: отрефакторить, переведя на функцинальный компонент
  state: IWaiterProviderState = {
    waiters: new Set<WaiterName>(),
  }

  /* TODO: отрефакторить, тк есть возможные проблемы (полный ререндеринг дерева компонентов)
  shouldComponentUpdate(nextProps: Readonly<{}>, nextState: Readonly<{ waiters: Waiters; }>, nextContext: any): boolean {
    console.log('shouldComponentUpdate', this.state.waiters, nextState.waiters)
    return this.state.waiters.size !== nextState.waiters.size
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{ waiters: Waiters; }>, snapshot?: any): void {
    console.log('WaiterProvider.componentDidUpdate')
    console.log(prevState, this.state)
  }
  */

  anyWaiting: IWaiterContext['anyWaiting'] = () => this.state.waiters.size > 0

  isWaiting: IWaiterContext['isWaiting'] = (waiter: WaiterName) => {
    return this.state.waiters.has(waiter)
  }

  startWait: IWaiterContext['startWait'] = (waiter: WaiterName) => {
    this.state.waiters.add(waiter)
    this.setState({ ...this.state })
    /* TODO: отрефакторить, тк есть возможные проблемы (полный ререндеринг дерева компонентов)
    this.setState((state) => ({
      waiters: new Set(state.waiters).add(waiter),
    }),() => {
      console.log('startWait.Updated', this.state.waiters)
    })
    */
  }

  stopWait: IWaiterContext['stopWait'] = (waiter: WaiterName) => {
    this.state.waiters.delete(waiter)
    this.setState({ ...this.state })
    /* TODO: отрефакторить, тк есть возможные проблемы (полный ререндеринг дерева компонентов)
    this.setState((state) => {
      const waiters = new Set(state.waiters)
      waiters.delete(waiter)
      return { waiters: waiters }
    }, () => {
      console.log('stopWait.Updated', this.state.waiters)
    })
    */
  }

  render() {
    return (
      <WaiterContext.Provider value={{
        waiters: this.state.waiters,
        anyWaiting: this.anyWaiting,
        isWaiting: this.isWaiting,
        startWait: this.startWait,
        stopWait: this.stopWait,
      }}>
        {this.props.children}
      </WaiterContext.Provider>
    )
    /* TODO: отрефакторить, тк есть возможные проблемы (полный ререндеринг дерева компонентов)
    return (
      <WaiterContext.Provider value={{
        // createWaiterContext: createWaiterContext,
        // createWaiterContext: (waiter: WaiterName) => ({
        //   anyWaiting: () => this.anyWaiting(),
        //   isWaiting: () => this.isWaiting(waiter),
        //   startWait: () => this.startWait(waiter),
        //   stopWait: () => this.stopWait(waiter),
        //   Wait: (props: IWaitComponentProps) => Wait({ ...props, for: waiter }),
        // }),
        waiters: this.state.waiters,
        anyWaiting: this.anyWaiting,
        isWaiting: this.isWaiting,
        startWait: this.startWait,
        stopWait: this.stopWait,
      }}>
        {this.props.children}
      </WaiterContext.Provider>
    )
    */
  }
}

export { WaiterProvider }

export default WaiterProvider
