/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosRequestConfig } from 'axios'
import { stringify } from 'qs'
import bridge from '@expressms/smartapp-bridge'

import { isExpressSmartApp } from '@/applicationConfig'
import { parseJson } from '@/Helpers/parseJson'
import { rootStore } from '@/Stores/rootStore'

const httpMethods: Record<string, Function> = {
  GET: axios.get,
  POST: axios.post,
  PUT: axios.put,
  PATCH: axios.patch,
  DELETE: axios.delete,
}

async function send(method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE', url: string, data: any = {}, options: AxiosRequestConfig = {}) {
  // если фронт открыли в смартаппке
  if (isExpressSmartApp()) {
    if (!options?.params) {
      options.params = {}
    }

    options.params['x-auth-user'] = rootStore.user.cookieUserId

    let query = options.params

    // если это смартаппка и делается GET запрос через адаптер, надо преобразовать Query параметры, чтобы направить их через прокси-бота
    if (method === 'GET') {
      const fullURL = new URL(`${axios.defaults.baseURL}${url}`)
      query = window.btoa(stringify(query))
      fullURL.pathname += `/QUERY_${query}`
      return await httpMethods[method](fullURL.toString(), data, options)
    } else {
      query = typeof query === 'string' ? query : JSON.stringify(query)
    }

    const response: any = await bridge?.sendBotEvent({
      method: 'PROXY',
      params: {
        http_request_method: method,
        // TODO: переместить этот префикс в некий конфиг в корне проекта как константу, чтобы переиспользовать здесь и в других местах
        url: `${url}`,
        query: query,
        body: typeof data === 'string' ? data : JSON.stringify(data),
      },
    })

    // console.log('[SmartApp] Response:', JSON.stringify(response))

    try {
      const result = response?.payload?.serverResponse ? parseJson(response?.payload?.serverResponse) : ''
      // console.log('[SmartApp] Result:', JSON.stringify(result))
      return { data: result }
    } catch (error: any) {
      console.error('[ApiAdapter] Error: Parse response:', error)
      throw error
    }
  } else {
    const methodWithOptions = ['GET', 'DELETE']
    if (methodWithOptions.includes(method)) {
      data = options
    }
    // eslint-disable-next-line @typescript-eslint/return-await
    return await httpMethods[method](url, data, options)
  }
}

const BackendAdapter = {

  async get(url: string, options: AxiosRequestConfig = {}) {
    return await send('GET', url, {}, options)
    // return applicationConfig?.isExpressSmartApp ? { data: res } : res
  },
  async post(url: string, data?: any, options?: AxiosRequestConfig) {
    return await send('POST', url, data, options)
  },
  async put(url: string, data?: any, options?: AxiosRequestConfig) {
    return await send('PUT', url, data, options)
  },
  async patch(url: string, data?: any, options?: AxiosRequestConfig) {
    return await send('PATCH', url, data, options)
  },
  async delete(url: string, options?: AxiosRequestConfig) {
    return await send('DELETE', url, options?.data ?? {}, options)
  },
}

export default BackendAdapter
