import { ChangeEvent, SyntheticEvent, useState } from 'react'
import { observer } from 'mobx-react'
import { Slider } from '@mui/material'

import { useMst } from '@/Stores/rootStore'
import { QuestionStyle } from '@/Models/page'
import QuestionLayout from '@/Components/Questions/QuestionLayout'
import CheckBox from '@/Components/CheckBox'
import { notReadyToRateText } from '@/Helpers/globalConst'
import { isCommentVisibleFor } from '@/Helpers/isCommentVisibleFor'
import { commentIsRequired } from '@/Helpers/QuestionHelper'
import bem from '@/Helpers/BemClass'

import {
  Container,
  HelperTextContainer,
} from './styled'

import '@/Components/Questions/style.scss'

const cnQuestion = bem()('question')

interface ISliderQuestion {
  questionId: string
  onChangesCommit: () => void
  onSetIsSkip?: (isSkip: boolean) => void
  allowSkipMainQuestion?: boolean
}

const SliderQuestion = ({ questionId, onChangesCommit, onSetIsSkip, allowSkipMainQuestion = false }: ISliderQuestion) => {
  const [lastState, setLastState] = useState<number | undefined>(0)
  const pageStore = useMst().user.pageStore
  const question = pageStore.getQuestion(questionId)
  const answer = pageStore.getAnswer(questionId)
  const isSkip = answer.isNotEstimate()
  const markGenerator = () => {
    const marks = []
    if (question.options) {
      for (let i = Number(question.options[0].value); i <= Number(question.options[1].value); i++) {
        marks.push({ value: i, label: i.toString() })
      }
    }
    return marks
  }
  const marks = markGenerator()

  const handleCommitedChange = (event: SyntheticEvent | Event, newValue: number | number[]) => {
    // для предотвращения двойного срабатывания на мобилках
    event.preventDefault()

    if (lastState === newValue) {
      answer.setValue([])
      setLastState(undefined)
    } else {
      answer.setValue({ value: newValue.toString() ?? '', isCustom: false })
      setLastState(Number.parseInt(newValue?.toString() ?? ''))
    }

    onChangesCommit()
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    // при изменении значения слайдера, сбрасываем флаг того что страница пропускается юзером
    onSetIsSkip?.(false)
    answer.setValue({ value: newValue.toString() ?? '', isCustom: false })

    if (Number.parseInt(newValue?.toString() ?? '') !== lastState) {
      setLastState(undefined)
    }
  }

  const handleIsCheckedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = !isSkip
    if (checked) {
      // TODO: Разобраться с проверкой так как ?? notReadyToRateText никогда не выполнится
      answer.setValue({ value: (question.payload?.notReadyToRateText ?? '') ?? notReadyToRateText, isCustom: false }, true)
    } else {
      // TODO: Проверить зачем пустые ковычки для слайдера
      answer.setValue({ value: '', isCustom: false })
    }

    // ставим флаг что юзер пропустил страницу, если он активировал чекбокс что не пользовался сервисом/не готов оценить
    onSetIsSkip?.(checked)
  }

  return <QuestionLayout
    question={question}
    showComment={isCommentVisibleFor(question, answer)}
    requiredComment={commentIsRequired(question, answer)}
    commentValue={answer.comment ?? ''}
    onCommentChange={val => answer.setComment(val)}
  >
    <Container
      isColorized={(question.payload ?? { questionStyle: QuestionStyle.Default }).questionStyle === QuestionStyle.Colorized}
      hasAnswer={!isNaN(Number.parseInt(answer.singleValue))}
      percentPerValue={100 / (marks.length - 1)}
      answerPercent={100 / (marks.length - 1) * ((Number.parseInt(answer.singleValue) ?? marks[0].value) - marks[0].value)}
      answerNumber={Number.parseInt(answer.singleValue) - marks[0].value}
    >
      <Slider
        className={isSkip ? 'skip-slider' : ''}
        defaultValue={0}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="off"
        step={1}
        marks={marks}
        min={marks[0].value}
        max={marks[marks.length - 1].value}
        onChangeCommitted={handleCommitedChange}
        onChange={handleChange}
        value={isNaN(Number.parseInt(answer.singleValue)) ? marks[0].value : Number.parseInt(answer.singleValue)}
      />
      <HelperTextContainer>
        <div className={cnQuestion('text-helper')}>{question.payload?.helperStartText}</div>
        <div className={cnQuestion('text-helper')}>{question.payload?.helperEndText}</div>
      </HelperTextContainer>
      {((question.payload?.showNotReadyToRate ?? false) || (question.isMain && allowSkipMainQuestion)) && <CheckBox
        id={`skip-question-checkbox-${question.id}`}
        text={question.isMain ? 'Не пользовался(-ась) сервисом – пропустить вопрос' : (question.payload?.notReadyToRateText ?? notReadyToRateText)}
        checked={isSkip}
        onChange={handleIsCheckedChange}
      />}
    </Container>
  </QuestionLayout>
}

export default observer(SliderQuestion)
