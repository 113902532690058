import { useState, ChangeEvent } from 'react'
import { FormGroup, TextField, useMediaQuery, useTheme } from '@mui/material'
import { observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'

import QuestionLayout from '@/Components/Questions/QuestionLayout'
import { useMst } from '@/Stores/rootStore'
import CheckBox from '@/Components/CheckBox'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnQuestion = bem()('question')

interface ICheckBoxQuestion {
  questionId: string
  onChangesCommit: () => void
}

const maxLength = 99

const CheckBoxQuestion = ({ questionId, onChangesCommit }: ICheckBoxQuestion) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const pageStore = useMst().user.pageStore
  const question = pageStore.getQuestion(questionId)
  const answer = pageStore.getAnswer(questionId)

  const [customValue, setCustomValue] = useState<string>(
    answer?.value?.find(x => x.isCustom)?.value ?? ''
  )

  const handleChangeSelectOption = (value: string, isCustom: boolean = false) => (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    if (!checked) {
      answer.setValue(answer.value.filter(x => x.value !== value && x.isCustom !== isCustom) ?? [])
    } else {
      answer.setValue([...answer.value ?? [], { value, isCustom }])
    }
    onChangesCommit()
  }

  const handleChangeCustomAnswer = (e: ChangeEvent<{ value: string }>) => {
    const value = e.target.value
    setCustomValue(value.substring(0, maxLength + 1))
    if (answer.value?.some(x => x.isCustom)) {
      answer.setValue({ value: answer.value?.map(x => (x.isCustom ? value : x.value)).toString(), isCustom: true })
    } else {
      answer.setValue([...answer.value, { value: value, isCustom: true }])
    }
  }

  return <QuestionLayout
    question={question}
  >
    <div className={cnQuestion({ checkbox: true })}>
      <FormGroup className={cnQuestion('answers')}>
        {question.options?.map((option, i) => <CheckBox
          key={option.value}
          id={`quest_${questionId}_answ_${i}`}
          text={option.value}
          checked={answer.value.some(x => x.value === option.value)}
          onChange={handleChangeSelectOption(option.value)}
          padding='0 8px 0 0'
        />
        )}
        {question.hasCustomAnswer && <div className={cnQuestion('custom-answer', { mobile: UIisMobile })}>
          <CheckBox
            id={`quest_${questionId}_self_version_answer`}
            checked={answer.value?.some(x => x.isCustom)}
            onChange={handleChangeSelectOption(customValue, true)}
            padding='0 8px 0 0'
          />
          <TextField
            className={cnQuestion('custom-answer__input')}
            placeholder="Введите свой вариант ответа"
            value={customValue}
            onChange={handleChangeCustomAnswer}
            variant="standard"
          />
        </div>}
      </FormGroup>
    </div>
  </QuestionLayout>
}


export default observer(CheckBoxQuestion)
