import { observer } from 'mobx-react'

import { Container } from '@/Pages/Admin/EditPage/Components/Question/SliderContainer/styled'
import SharedAdditionalSettings from '@/Pages/Admin/EditPage/Components/Question/SharedAdditionalSettings'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

export interface ICheckboxWithImageContainerError {
  emptyHelperText?: boolean
}

interface ICheckboxWithImageContainerProps {
  question: IQuestionInstance
  disabled?: boolean
}

const CheckboxWithImageContainer = ({ question, disabled = false }: ICheckboxWithImageContainerProps) => {
  return (<Container>
    <SharedAdditionalSettings
      question={question}
      disabled={disabled}
    />
  </Container>)
}

export default observer(CheckboxWithImageContainer)
