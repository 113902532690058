export default async function fileToBase64(file: Blob): Promise<string> {
  return await new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      const base64String = reader.result as string
      resolve(base64String)
    }

    reader.onerror = () => {
      reject(new Error('Failed to read the file.'))
    }

    reader.readAsDataURL(file)
  })
}
