import { applySnapshot, flow, types as t } from 'mobx-state-tree'
import Cookies from 'js-cookie'
import { v4 } from 'uuid'

import { cookieUserId } from '@/Helpers/globalConst'
import { SelectedPagesStore } from '@/Stores/UserStores/selectedPagesStore'
import { PageStore } from '@/Stores/UserStores/pageStore'
import { UserSurveysStore } from '@/Stores/UserStores/userSurveysStore'
import { apiSyncUserTest } from '@/Api/user'
import BackendAdapter from '@/Api/_adapter'
import { Permission, IPermissionOut, PermissionType } from '@/Stores/sharedModels/models'
import { apiGetUserPermissions } from '@/Api/permission'

const defaultState = {
  selectedPagesState: { pages: [], currentPage: '', count: 0 },
  pageStore: {
    page: { questions: [], description: '', serviceId: '', title: '', id: '', imageId: '', surveyId: '', version: 0 },
    errors: [],
    answers: [],
    currentSurvey: undefined,
  },
  userSurveysStore: { runningSurveys: [] },
  cookieUserId: Cookies.get(cookieUserId) ?? '',
  permissions: [],
}

/// Если surveyId === undefined, считаем что нам не важен источник прав
export interface IPermissionRequisites {
  type: PermissionType
  surveyId?: string | null
}

export const model = t.model({
  selectedPagesState: SelectedPagesStore,
  pageStore: PageStore,
  userSurveysStore: UserSurveysStore,
  cookieUserId: t.string,
  // Используется для чатов eXpress
  groupChatId: t.maybeNull(t.string),
  permissions: t.array(Permission),
}).actions(self => ({
  setCookieUserId: (value: string) => {
    Cookies.set(cookieUserId, value)
    self.cookieUserId = value
  },
  loadUserInfoFromExpress: flow(function * load() {
    const userInfo = yield BackendAdapter.post('/whoami').then(x => x.data)

    const userId = userInfo.adLogin as string
    const groupChatId = userInfo.groupChatId as string

    Cookies.set(cookieUserId, userId)
    self.cookieUserId = userId
    self.groupChatId = groupChatId
  }),
  loadUserPermissions: flow(function * load() {
    const permissions = yield apiGetUserPermissions()
    applySnapshot(self.permissions, permissions)
    return permissions.length
  }),
  generateUserWithRandomUserId: flow(function * generate() {
    const userId = v4()

    yield apiSyncUserTest(userId)

    Cookies.set(cookieUserId, userId)
    self.cookieUserId = userId
  }),
  searchRole: (permissionRequisitesVariants: IPermissionRequisites[]): IPermissionOut | null => {
    for (const permissionRequisitesVariant of permissionRequisitesVariants) {
      const permission = self.permissions.find(x => x.type === permissionRequisitesVariant.type && (permissionRequisitesVariant.surveyId === undefined || x.surveyId === permissionRequisitesVariant.surveyId))
      if (permission) {
        return permission
      }
    }

    return null
  },
}))

export default {
  defaultState,
  model,
}
