import Cookies from 'js-cookie'
import { v4 } from 'uuid'

import { cookieUserId } from '@/Helpers/globalConst'
import BackendAdapter from '@/Api/_adapter'

export enum AuthorizeType {
  developer = '0',
  domain = '1',
  guid = '2',
  header = '3',
  express = '4',
}

export enum FrontendFeature {
  rolesSwitch = '0',
  randomUser = '1',
  gvk = '2',
  organizationStructure = '3',
  service = '4',
  styledQuestions = '5',
  dynamicConfiguration = '6',
}

export interface IApplicationConfig {
  authorizeType: AuthorizeType
  frontendFeatures: FrontendFeature[]
}

let applicationConfig: IApplicationConfig = {
  authorizeType: AuthorizeType.guid,
  frontendFeatures: [],
}

// TODO: отрефакторить и улучшить механизм
let _isLaunchedAsExpressSmartApp: boolean | null = null
export const isExpressSmartApp = (): boolean => {
  if (_isLaunchedAsExpressSmartApp === null) {
    _isLaunchedAsExpressSmartApp = window.location.pathname.includes('/file_service/smartapp_files/')
  }

  return _isLaunchedAsExpressSmartApp
}

// TODO: отрефакторить и улучшить механизм
let basePath: string | null = null
export const getExpressBasePath = (): string => {
  if (basePath === null) {
    basePath = window.location.pathname.split('/').slice(0, 7).join('/')
  }

  return basePath
}

// TODO: отрефакторить и улучшить логику, убрать логирования
export const loadConfig = async () => {
  applicationConfig = await BackendAdapter.get('/api/settings/front').then(resp => resp.data)

  console.log('AuthorizeType:', applicationConfig?.authorizeType)

  if (applicationConfig?.authorizeType === AuthorizeType.guid) {
    let token = Cookies.get(cookieUserId)
    if (!token) {
      token = v4()
      Cookies.set(cookieUserId, token)
      console.log('AuthorizeType: GUID - Generated UUIDv4 for Cookie', token)
    } else {
      console.log('AuthorizeType: GUID - Got Cookie value', token)
    }
  }

  if (applicationConfig?.authorizeType === AuthorizeType.express) {
    console.log('AuthorizeType: eXpress')
    // если зашли в веб-приложение не из под SmartApp'ки, а через обычный браузер (например в админку)
    if (!isExpressSmartApp()) {
      console.log('AuthorizeType: eXpress + Browser')
      let token = Cookies.get(cookieUserId)
      if (!token) {
        token = 'admin'
        Cookies.set(cookieUserId, token)
        console.log('AuthorizeType: eXpress -', token)
      } else {
        console.log('AuthorizeType: eXpress - Got Cookie value', token)
      }
    } else {
      console.log('AuthorizeType: eXpress - SmartApp')
    }
  }
}

export const defaultImageHash = 'system_DefaultImage'

export const customImageHash = 'system_CustomAnswerImage'

export { applicationConfig }

export default applicationConfig
