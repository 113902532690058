import { useState } from 'react'
import { useMount } from 'react-use'
import { sortBy, groupBy, map } from 'lodash'
import { Card, FormControlLabel, FormGroup, Skeleton, Switch, TextField, Tooltip } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import InfoIcon from '@mui/icons-material/Info'

import { IConfiguration } from '@/Models/configuration'
import { apiGetDynamicConfiguration, apiUpdateDynamicConfiguration } from '@/Api/settings'
import { useWaiter } from '@/Helpers/react-wait'
import { Wait } from '@/Helpers/react-wait/hooks'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnDynamicConfiguration = bem()('dynamic-configuration')

interface IFieldValidationError {
  name: string
  option: string | null
}

const DynamicConfiguration = () => {
  const [configurations, setConfigurations] = useState<IConfiguration[]>([])
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const { startWait, stopWait, isWaiting } = useWaiter()
  const [validationErrors, setValidationErrors] = useState<IFieldValidationError[]>([])

  useMount(() => {
    (async () => {
      startWait('first-loading')
      const response = await apiGetDynamicConfiguration()
      setConfigurations(response)
      stopWait('first-loading')
    })()
  })

  const onAnswerChange = (name: string, option: string | null, value: string) => {
    setValidationErrors(prev => [...prev.filter(x => !(x.name === name && x.option === option))])
    setConfigurations(prev => [...prev.map(x => (x.name === name && x.option === option ? { ...x, value } : x))])
    setHasChanges(true)
  }

  const onSaveConfiguration = async () => {
    const validationErrors: IFieldValidationError[] = configurations.filter(x => x.regex && !new RegExp(x.regex).test(x.value))
      .map(x => ({ name: x.name, option: x.option }))

    setValidationErrors(validationErrors)

    if (validationErrors.length !== 0) {
      return
    }

    startWait('loading')
    await apiUpdateDynamicConfiguration(configurations)
    stopWait('loading')
    startWait('accept-animation')
    setTimeout(() => {
      stopWait('accept-animation')
      setHasChanges(false)
    }, 500)
  }

  return <div className={cnDynamicConfiguration()}>
    <Wait for='first-loading' with={<Skeleton className={cnDynamicConfiguration('skeleton')} width={200} height={200}/>}>
      {
        map(
          // Группируем по названию (набор фич), сортируем по типу (набор фич или одна фича) + первому названию фичи в группе
          sortBy(groupBy(configurations, 'name'), (x) => [!!x[0].option, x[0].name]),
          (configurationGroup) => {
            // Если это опция одной из настроек, значит это группа фич
            const isFeatureCollection = !!configurationGroup[0].option

            configurationGroup = sortBy(configurationGroup, ['readableName'])

            let components: JSX.Element[]
            if (isFeatureCollection) {
              components = configurationGroup.map(configuration => <div key={configuration.option}>
                <FormControlLabel
                  control={<Switch
                    disabled={isWaiting('loading')}
                    onChange={() => onAnswerChange(configuration.name, configuration.option, configuration.value === 'true' ? 'false' : 'true')}
                    checked={configuration.value === 'true'}
                  />}
                  label={configuration.readableName}
                  classes={{
                    root: cnDynamicConfiguration('label'),
                  }}
                />
                <Tooltip classes={{ tooltip: cnDynamicConfiguration('info-tooltip') }} title={configuration.description}>
                  <InfoIcon fontSize='small' className={cnDynamicConfiguration('info-icon')} color='primary'/>
                </Tooltip>
              </div>)
            } else {
              components = configurationGroup.map(configuration => (<TextField
                key={configuration.option}
                disabled={isWaiting('loading')}
                error={!!validationErrors.find(x => x.name === configuration.name && x.option === configuration.option)}
                fullWidth
                className={cnDynamicConfiguration('text-field')}
                label={configuration.readableName}
                variant='standard'
                value={configuration.value}
                onChange={(e) => onAnswerChange(configuration.name, configuration.option, e.target.value)}
                InputProps={{
                  endAdornment:
                  <Tooltip classes={{ tooltip: cnDynamicConfiguration('info-tooltip') }} title={configuration.description}>
                    <InfoIcon className={cnDynamicConfiguration('info-icon')} color='primary'/>
                  </Tooltip>,
                }}
              />))
            }

            return <Card className={cnDynamicConfiguration('group-card')}>
              <FormGroup className={cnDynamicConfiguration('group')}>
                {components}
              </FormGroup>
            </Card>
          })
      }
    </Wait>
    <div className={cnDynamicConfiguration('actions')}>
      <LoadingButton
        fullWidth
        className={cnDynamicConfiguration('button', { accept: true })}
        loading={isWaiting('first-loading') || isWaiting('loading')}
        variant={isWaiting('accept-animation') ? 'contained' : 'outlined'}
        onClick={onSaveConfiguration}
        color={isWaiting('accept-animation') ? 'success' : undefined}
        disabled={!hasChanges}
      >
        Применить
      </LoadingButton>
    </div>
  </div>
}

export default DynamicConfiguration
