import { ReactNode, useEffect, useRef, useState } from 'react'
import { Button, ButtonClasses, ClassNameMap, Fade, Menu, MenuItem } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import DropdownButton from '@/Components/DropdownButton'
import { Wait } from '@/Helpers/react-wait/hooks'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnChangeStateButton = bem()('change-state-button')

interface IChangeStateButton<LableType> {
  isActive: boolean
  waitState: string
  waitComponent: ReactNode | JSX.Element
  activeComponent: ReactNode | JSX.Element
  activeLable: string
  disabledComponent: ReactNode | JSX.Element
  disabledLable: LableType[]
  onEnable: ((action: LableType) => void) | ((action: LableType) => Promise<void>)
  onDisable: (() => void) | (() => Promise<void>)
  classes?: (Partial<ButtonClasses> & Partial<ClassNameMap<never>>) | undefined
}

function ChangeStateButton<LableType>({ isActive, waitState, waitComponent, activeComponent, activeLable, disabledComponent, disabledLable, onEnable, onDisable, classes }: IChangeStateButton<LableType>) {
  return <DropdownButton
    actions={isActive ? [activeLable] : disabledLable as string[]}
    onClick={async (action) => { isActive ? await onDisable() : await onEnable(action as LableType) }}
    classes={classes}
    render={(action) => <>
      <Wait for={waitState} with={waitComponent}>
        {isActive ? activeComponent : disabledComponent}
      </Wait>
      {action}
    </>}
  />
}

export default ChangeStateButton
