import styled from 'styled-components'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

export const DragDropBox = styled.div`
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0px;
  left: 0px;

  cursor: pointer !important;

  .sort-starter {
    cursor: pointer !important;
  }
`

export const DragLableContainer = styled.div<{ isVisible: boolean }>`
  position: relative;
  z-index: 1;
  height: 100%;
  ${props => !props.isVisible && 'display: none;'}
`

export const LableStyled = styled(DragIndicatorIcon)`
  position: relative;
  z-index: 2;
  display: block;
`
