import { applySnapshot, flow, getRoot, Instance, SnapshotIn, types as t } from 'mobx-state-tree'

import { apiCreateSurvey, apiDuplicateSurvey, apiGetSurveyInfo, apiGetSurveyList, apiStartSurvey, apiStopSurvey, apiUpdateSurvey, apiArchiveSurvey, apiChangeSurveyImage, apiPinSurvey, apiUnpinSurvey, apiGetSurveyPermissions, apiSetVerificationState } from '@/Api/survey'
import { CommentType, QuestionType, IQuestion } from '@/Models/page'
import { Option, Payload, File, PermissionType } from '@/Stores/sharedModels/models'
import { IRootStore } from '@/Stores/rootStore'
import { getStatusPriority } from '@/Helpers/surveyListHelper'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import { apiUpdateSurveyService } from '@/Api/service'
import { apiRemoveTestingSession, apiStartTestingSession } from '@/Api/testing'

export interface ISurveyIn extends SnapshotIn<typeof Survey> {

}

export interface ISurveyInstance extends Instance<typeof Survey> {

}

export interface ISurveyPageInfoInstance extends Instance<typeof SurveyPageInfo> {

}

export interface IMainQuestionIn extends SnapshotIn<typeof MainQuestion> {

}

export interface IMainQuestionInstance extends Instance<typeof MainQuestion> {

}

export interface IPermissionsInstance extends Instance<typeof Permission> {

}

export interface CreteOrUpdateSurveyInterface {
  surveyId?: string
  title: string
  description: string
  end: Date
  start: Date
  type: SurveyType
  imageId: string
  mainQuestions: IMainQuestionIn[]
  targetServiceId?: string
  targetAudiencesMappingType: TargetAudiencesMappingType
  withCards: boolean
  isAnonym: boolean
  withEditors: boolean
  totalUsersCount: number
}


export enum SurveyType {
  Gvk = '0',
  Custom = '1',
  Repeatable = '2',
}

export enum TargetAudiencesMappingType {
  BySurveyTargetAudience = '0',
  ByPageTargetAudience = '1',
  BySurveyService = '2',
  ByPageService = '3',
}

export const russianNameFromSurveyType: string[] = ['ГВК', 'Кастомный', 'Постоянный']

const withCards = true
const withNoCards = false

const russianNameFromSurveyRepresentation = new Map<boolean, string>([
  [withCards, 'Карточное'],
  [withNoCards, 'Классическое'],
])

export enum SurveyStatus {
  Created = '0',
  Surveyed = '1',
  Completed = '2',
  Canceled = '3',
  Archived = '4',
  Editing = '5',
}

export const russianNameFromSurveyStatus: string[] = ['Создан', 'Запущен', 'Завершен', 'Отменен', 'В архиве', 'Редактирование']

const SurveyPageInfo = t.model({
  pageId: t.string,
  title: t.optional(t.maybeNull(t.string), ''),
  userCount: t.number,
  answersCount: t.number,
  feedbackCount: t.number,
  order: t.number,
})

const Permission = t.model({
  id: t.string,
  type: t.enumeration<PermissionType>(Object.values(PermissionType)),
  surveyId: t.string,
  organizationStructureElementsId: t.array(t.string),
})

const MainQuestion = t.model({
  id: t.maybe(t.string),
  text: t.string,
  type: t.enumeration<QuestionType>(Object.values(QuestionType)),
  options: t.maybe(t.array(Option)),
  hasCustomAnswer: t.boolean,
  commentType: t.enumeration<CommentType>(Object.values(CommentType)),
  helperText: t.string,
  showHelperText: t.boolean,
  payload: Payload,
  order: t.number,
  // mainQuestionId: t.maybe(t.string),
})

const Survey = t.model({
  surveyId: t.string,
  title: t.string,
  mainQuestions: t.array(MainQuestion),
  start: t.Date,
  end: t.Date,
  author: t.string,
  imageId: t.maybe(t.string),
  status: t.enumeration<SurveyStatus>('SurveyStatus', Object.values(SurveyStatus)),
  type: t.enumeration<SurveyType>('SurveyType', Object.values(SurveyType)),
  targetAudiencesMappingType: t.enumeration<TargetAudiencesMappingType>('TargetAudiencesMappingType', Object.values(TargetAudiencesMappingType)),
  withCards: t.boolean,
  /** Количество пользователей, попадающих в целевую аудиторию */
  numberOfDestination: t.number,
  /** Количество пользователей завершивших опрос */
  completedCount: t.number,
  /** Количество пользователей начавших опрос */
  startedCount: t.number,
  numberOfFeedback: t.number,
  pagesInfo: t.maybe(t.array(SurveyPageInfo)),
  description: t.string,
  targetServiceId: t.maybeNull(t.string),
  archivedDate: t.maybeNull(t.Date),
  version: t.number,
  createdAt: t.Date,
  isPinned: t.boolean,
  isAnonym: t.boolean,
  isVerified: t.boolean,
  permissions: t.array(Permission),
  lastReviewStepComment: t.maybeNull(t.string),
  hasActiveTestingSession: t.boolean,
}).views(self => ({
  get textStatus(): string {
    const hasEditors = self.permissions.some(x => x.type === PermissionType.editor)
    const isReview = self.status === SurveyStatus.Created && hasEditors
    const isEditing = self.status === SurveyStatus.Editing && hasEditors
    if (isReview) {
      if (self.isVerified) {
        return 'Готово к запуску'
      }

      return 'На согласовании'
    }

    if (isEditing) {
      if (self.lastReviewStepComment !== null) {
        return 'В доработке'
      }

      return 'Редактирование'
    }

    if (self.hasActiveTestingSession) {
      return 'Тестовый запуск'
    }

    return russianNameFromSurveyStatus[self.status as unknown as number]
  },
  get textType(): string {
    return russianNameFromSurveyType[self.type as unknown as number]
  },
  get textSurveyType(): string {
    return russianNameFromSurveyRepresentation.get(self.withCards) ?? ''
  },
  get imageUrl(): string {
    return getStaticsHybridUrl(`/api/file/image/${self.imageId ?? ''}`)
  },
  get textStatusSortNumber(): number {
    return getStatusPriority(russianNameFromSurveyStatus[self.status as unknown as number])
  },
  get isReviewStatus(): boolean {
    return self.status === SurveyStatus.Created && self.permissions.some(x => x.type === PermissionType.editor)
  },
})).actions(self => ({
  Start: flow(function * () {
    yield apiStartSurvey(self.surveyId)
  }),
  StartTestingSession: flow(function * () {
    yield apiStartTestingSession(self.surveyId)
  }),
  Stop: flow(function * () {
    yield apiStopSurvey(self.surveyId)
    self.status = SurveyStatus.Canceled
  }),
  StopTestingSession: flow(function * () {
    yield apiRemoveTestingSession(self.surveyId)
  }),
  ChangeImage: flow(function * (imageId: string) {
    yield apiChangeSurveyImage(self.surveyId, imageId)
    self.imageId = imageId
  }),
  changeService: flow(function * (serviceId: string | null) {
    yield apiUpdateSurveyService(self.surveyId, serviceId ?? null)
    self.targetServiceId = serviceId
  }),
  changeVerificationState: flow(function * (isVerified: boolean) {
    yield apiSetVerificationState(self.surveyId, isVerified)
    self.isVerified = isVerified

    const store = getRoot(self)
    yield (store as any).admin.trafficLightStateStore.createIfNotExistAndGet(self.surveyId).loadTrafficLight(false)
  }),
  getMainQuestionsAsIQuestionVersion: (): IQuestion[] => {
    return self.mainQuestions.map(x => ({
      isMain: true,
      text: x.text,
      type: x.type,
      commentType: x.commentType,
      helperText: x.helperText,
      options: x.options?.map(x => ({ ...x })) ?? [],
      hasCustomAnswer: x.hasCustomAnswer,
      showHelperText: x.showHelperText,
      isRequired: true,
      payload: {
        ...x.payload,
        adminFiles: x.payload.adminFiles.map(x => ({ ...x })),
        acceptFileTypes: x.payload.acceptFileTypes.map(x => x),
      },
      mainQuestionId: x.id ?? null,
      version: 0,
      order: x.order,
    }))
  },
  togglePin: flow(function * () {
    self.isPinned = !self.isPinned

    if (self.isPinned) {
      yield apiPinSurvey(self.surveyId)
    } else {
      yield apiUnpinSurvey(self.surveyId)
    }
  }),
  syncPermissions: flow(function * () {
    const permissions = yield apiGetSurveyPermissions(self.surveyId)
    applySnapshot(self.permissions, permissions)
  }),
}))

export enum SurveysStoreFilter {
  Archived = 'Archived',
  Default = 'Default',
  Review = 'Review',
}

export const SurveysStore = t.model({
  surveys: t.array(Survey),
  filter: t.enumeration<SurveysStoreFilter>('SurveysStoreFilter', Object.values(SurveysStoreFilter)),
}).views(self => ({
  get last(): ISurveyInstance | null {
    return self.surveys.length ? self.surveys[self.surveys.length - 1] : null
  },
  get filterNotPinned(): ISurveyInstance[] {
    if (self.filter === SurveysStoreFilter.Archived) {
      return self.surveys.filter(x => x.status === SurveyStatus.Archived && !x.isPinned)
    }

    if (self.filter === SurveysStoreFilter.Review) {
      return self.surveys.filter(x => x.isReviewStatus && !x.isPinned)
    }

    if (self.filter === SurveysStoreFilter.Default) {
      return self.surveys.filter(x => x.status !== SurveyStatus.Archived && !x.isReviewStatus && !x.isPinned)
    }

    return self.surveys.filter(x => x.status !== SurveyStatus.Archived && !x.isPinned)
  },
  get filterPinned(): ISurveyInstance[] {
    if (self.filter === SurveysStoreFilter.Archived) {
      return self.surveys.filter(x => x.status === SurveyStatus.Archived && x.isPinned)
    }

    if (self.filter === SurveysStoreFilter.Review) {
      return self.surveys.filter(x => x.isReviewStatus && x.isPinned)
    }

    if (self.filter === SurveysStoreFilter.Default) {
      return self.surveys.filter(x => x.status !== SurveyStatus.Archived && !x.isReviewStatus && x.isPinned)
    }

    return self.surveys.filter(x => x.status !== SurveyStatus.Archived && x.isPinned)
  },
  isSurveyWithCardsView(surveyId: string): boolean {
    return self.surveys.find(x => x.surveyId === surveyId)?.withCards ?? false
  },
}))
  .actions(self => ({
    loadSurveys: flow(function * () {
      const surveys = yield apiGetSurveyList()
      applySnapshot(self.surveys, surveys)
    }),
    duplicateSurvey: flow(function * (surveyId: string) {
      const newSurvey = yield apiDuplicateSurvey(surveyId)
      self.surveys.push(newSurvey)
    }),
    archiveSurvey: flow(function * (surveyId: string) {
      const archivedSurvey = yield apiArchiveSurvey(surveyId)
      const survey = self.surveys.find(x => x.surveyId === surveyId)

      if (!survey) return

      applySnapshot(survey, archivedSurvey)
    }),
    sortByCreateAt: () => {
      self.surveys.sort((a, b) => (a.createdAt === b.createdAt ? 0 : (a.createdAt > b.createdAt ? -1 : 1)))
    },
    setFilter: (filter: SurveysStoreFilter) => {
      self.filter = filter
    },
  }))
  .actions(self => ({
    addSurvey: flow(function * (model: CreteOrUpdateSurveyInterface) {
      const survey = yield apiCreateSurvey(model)
      self.surveys.push(survey)
      return survey.surveyId
    }),
    updateSurvey: flow(function * (model: CreteOrUpdateSurveyInterface) {
      const survey = self.surveys.find(x => x.surveyId === model.surveyId)

      if (survey) {
        survey.title = model.title
        survey.description = model.description
        survey.end = model.end
        survey.start = model.start
        survey.isAnonym = model.isAnonym
        survey.mainQuestions.replace(model.mainQuestions.map(x => MainQuestion.create({
          ...x,
          payload: Payload.create({
            ...x.payload,
            adminFiles: !x.payload.adminFiles ? [] : x.payload.adminFiles.map(f => File.create({ ...f })),
          }),
        })))
        yield apiUpdateSurvey(model)
      }
    }),
    changeSurveyMode: (surveyId: string, withCards: boolean) => {
      const survey = self.surveys.find(x => x.surveyId === surveyId)
      if (survey) {
        survey.withCards = withCards
      }
    },
    changeSurveyTargetAudiencesMappingType: (surveyId: string, targetAudiencesMappingType: TargetAudiencesMappingType) => {
      const survey = self.surveys.find(x => x.surveyId === surveyId)
      if (survey) {
        survey.targetAudiencesMappingType = targetAudiencesMappingType
      }
    },
    loadSurveyInfo: flow(function * (surveyId: string) {
      let surveySync = yield apiGetSurveyInfo(surveyId)

      if (!surveySync?.surveyId) return

      const permissions = yield apiGetSurveyPermissions(surveyId)

      surveySync = { ...surveySync, permissions }

      const survey = self.surveys.find(x => x.surveyId === surveyId)
      if (!survey) {
        self.surveys.push(surveySync)
      } else {
        applySnapshot(survey, surveySync)
      }
    }),
    getSurvey: (surveyId: string): ISurveyInstance | undefined => {
      return self.surveys.find(x => x.surveyId === surveyId)
    },
    getByArchivedState: (isArchiverState: boolean): ISurveyInstance[] => {
      return self.surveys.filter(x => (isArchiverState ? x.status === SurveyStatus.Archived : x.status !== SurveyStatus.Archived))
    },
  }))

export const SurveyEditingTimeSpan = t.model({
  id: t.string,
  surveyId: t.string,
})
