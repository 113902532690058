import styled from 'styled-components'

export const DialStyled = styled.div`
    position: absolute;
    right: 33%;
    top: 0;
    height: 100%;
    
    button{
      width: 40px;
      height: 40px;
    }
    
    .disabled {
    .MuiFab-label{
          opacity: 0.4;
          cursor: not-allowed;
      }
    }
    
    .MuiSpeedDial-actions{
      z-index: 100;
    }

    button.MuiSpeedDial-fab{
    width: 35px !important;
    height: 35px !important;
    box-shadow: none;
    background-color: transparent;
    color: black;
    }
`
