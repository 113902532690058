import { ChangeEvent } from 'react'
import { observer } from 'mobx-react'
import { Autocomplete, Grid, ListItem, TextField, Typography } from '@mui/material'

import { IPage } from '@/Models/page'
import { IServiceIn } from '@/Stores/AdminStores/adminServicesStore'
import bem from '@/Helpers/BemClass'
import { ToolTip } from '@/Components'
import { ColorTheme } from '@/Models/colorTheme'

import './style.scss'

const cnPageServiceRow = bem()('page-service-row')

interface IPageServiceRow {
  page: IPage
  services: IServiceIn[]
  onServiceChange: (serviceId?: string) => void
  disabled: boolean
}

const PageServiceRow = ({ page, services, onServiceChange, disabled }: IPageServiceRow) => {
  const handleServiceChange = (e: ChangeEvent<{}>, value: IServiceIn | null) => {
    onServiceChange(value?.id ?? undefined)
  }

  return <ListItem className={cnPageServiceRow({ 'with-data': !!page.serviceId })} key={page.id}>
    <Grid spacing={1} container justifyContent="space-between" alignItems="center">
      <Grid className={cnPageServiceRow('order')} item xs={2}>
        <Typography fontWeight='normal' className={cnPageServiceRow('order-text')}>
          {`Страница ${page.order}`}
        </Typography>
      </Grid>
      <Grid className={cnPageServiceRow('title')} item xs={6}>
        <Typography fontWeight='bold' className={cnPageServiceRow('title-text')}>
          <ToolTip title={page.title} arrow theme={ColorTheme.dark}>
            <div>{page.title}</div>
          </ToolTip>
        </Typography>
      </Grid>
      <Grid className={cnPageServiceRow('service')} item xs={4}>
        <Autocomplete
          selectOnFocus
          clearOnBlur
          value={services.find(x => x.id === page.serviceId)}
          options={services}
          getOptionLabel={(service) => service?.name}
          renderOption={(props, option, { selected }) => <li {...props} key={option.id}>
            {option.name}
          </li>}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='standard'
              placeholder='Выберите сервис'
            />
          )}
          onChange={handleServiceChange}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  </ListItem>
}

export default observer(PageServiceRow)
