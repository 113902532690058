import styled from 'styled-components'

export const AnswersSelectorContainer = styled.div`
  display: flex; 
  flex-direction: row;
`

export const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  .error {
    margin: 5px 0 10px 0;
  }
`

export const AnswerContainer = styled.div`
  display: flex; 
  flex-direction: row;
`

export const AnswerLabelStyled = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
  }

  .MuiCheckbox-root {
    padding: 4px;
  }

  & > svg {
    color: #FFD24D;
  }
`

