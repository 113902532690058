import { useParams } from 'react-router'

import { useMst } from '../../../Stores/rootStore'
import { useRouteManager } from '../../../Hooks/useRouteManager'
import { RouteEnum } from '../../../Enums/route'
import { SurveyType } from '../../../Stores/AdminStores/surveysStore'

import CardsSelectionPage from './CardsSelectionPage'

const StartPagePage = () => {
  const { surveyId } = useParams<{ surveyId: string }>()
  const routerManager = useRouteManager()
  const store = useMst()
  const survey = store.user.userSurveysStore.runningSurveys.find(x => x.surveyId === surveyId)

  if (!survey || (survey.type === SurveyType.Custom && !survey.withCards)) {
    routerManager.redirectTo(RouteEnum.clientSurveys)
    return <></>
  }

  return <CardsSelectionPage/>
}

export default StartPagePage
