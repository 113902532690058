import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { CSSTransition } from 'react-transition-group'
import { useSessionStorage } from 'usehooks-ts'

import { useRouteManager } from '@/Hooks'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import { htmlTagsIsEmpty } from '@/Helpers/htmlToTextCustom'
import { IPermissionsWithUsers } from '@/Models/permission'
import { apiGetSurveyPermissions, apiSetSurveyPermissions } from '@/Api/survey'
import { Button, PhotoInput } from '@/Components'
import { useMst } from '@/Stores/rootStore'
import {
  CreteOrUpdateSurveyInterface,
  ISurveyInstance,
  SurveyType,
  TargetAudiencesMappingType,
  SurveyStatus,
} from '@/Stores/AdminStores/surveysStore'
import { IQuestionInstance, Question } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'
import { PermissionType } from '@/Stores/sharedModels/models'
import Error from '@/Components/Error'
import { CommentType, QuestionType, IQuestion, QuestionStyle } from '@/Models/page'
import { applicationConfig, defaultImageHash, FrontendFeature } from '@/applicationConfig'
import TextLink from '@/Components/TextLink'
import TextEditor from '@/Components/TextEditor'
import RoleQualifier, { isRole } from '@/Components/RoleQualifier'
import ImageDefault from '@/Images/default.jpg'
import { IOrganizationStructureElementModel } from '@/Models/organizationStructure'
import { ResultAccess as UserShortResultAccess } from '@/Models/targetAudience'
import { RouteEnum } from '@/Enums/route'
import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'
import { MainQuestionBlock } from '@/Pages/Admin/SurveyListPage/SurveyModal/MainQuestionBlock'
import DateTimeRow from '@/Pages/Admin/SurveyListPage/SurveyModal/DateTimeRow'

import {
  MainContainer,
  MainContainerCalendar,
  MainQuestionsContainer,
  PageContainer,
  TextContainer,
  TitleContainer,
} from './styled'

import './style.scss'

interface IFields {
  title: string
  description: string
  type: SurveyType
  end: Date
  start: Date
  imageId?: string
  targetServiceId?: string
  targetAudiencesMappingType: TargetAudiencesMappingType
  version?: number
  isAnonym: boolean
  totalUsersCount: string
}

interface ISurveyModal {
  isOpen: boolean
  handleClose: ((surveyId?: string) => void) | ((surveyId?: string) => Promise<void>)
  survey?: ISurveyInstance
}

const defaultQuestion: IQuestion = {
  text: '<p><span style="font-size: 21px"><strong>​</strong></span></p>',
  type: QuestionType.slider,
  commentType: CommentType.VisibleToAndRequiredTo,
  options: [
    { value: '0', imageId: null, title: null, isCustom: false, order: 0 },
    { value: '10', imageId: null, title: null, isCustom: false, order: 1 },
  ],
  isMain: true,
  helperText: '',
  showHelperText: false,
  isRequired: true,
  hasCustomAnswer: false,
  payload: {
    helperStartText: 'Точно не порекомендую',
    helperEndText: 'Точно порекомендую',
    notReadyToRateText: '',
    showNotReadyToRate: false,
    requireCommentValue: '6',
    isEnabledLoadFileButton: false,
    maxFileCount: 0,
    limitFileType: false,
    acceptFileTypes: [],
    adminFiles: [],
    questionStyle: QuestionStyle.Default,
    size: SizeEnum.medium,
    align: AlignEnum.left,
  },
  version: 0,
  mainQuestionId: null,
  order: 0,
}

const defaultGvkQuestion: IQuestion = {
  text: '<p><span style="font-size: 21px"><strong>Оцените, насколько вы удовлетворены предоставляемым сервисом?</strong></span></p>',
  type: QuestionType.slider,
  commentType: CommentType.VisibleToAndRequiredTo,
  options: [
    { value: '0', imageId: null, title: null, isCustom: false, order: 0 },
    { value: '10', imageId: null, title: null, isCustom: false, order: 1 },
  ],
  isMain: true,
  helperText: 'Оцените по шкале от 0 до 10, где 0 – совершенно неудовлетворён, 10 – полностью удовлетворён',
  showHelperText: true,
  isRequired: true,
  hasCustomAnswer: false,
  payload: {
    helperStartText: 'Совершенно неудовлетворён',
    helperEndText: 'Полностью удовлетворён',
    notReadyToRateText: '',
    showNotReadyToRate: false,
    requireCommentValue: '6',
    isEnabledLoadFileButton: false,
    maxFileCount: 0,
    limitFileType: false,
    acceptFileTypes: [],
    adminFiles: [],
    questionStyle: QuestionStyle.Default,
    size: SizeEnum.medium,
    align: AlignEnum.left,
  },
  version: 0,
  mainQuestionId: null,
  order: 0,
}

const surveyModalOrganizationStructureStateSelector = 'survey-modal-organization-structure-state-selector'

const getDefaultTargetAudienceMappingType = (feature: FrontendFeature[]) => {
  const targetAudienceByService = feature.includes(FrontendFeature.service)
  const targetAudienceByOrganizationStructure = feature.includes(FrontendFeature.organizationStructure)

  if (targetAudienceByService && !targetAudienceByOrganizationStructure) {
    return TargetAudiencesMappingType.BySurveyService
  }

  return TargetAudiencesMappingType.BySurveyTargetAudience
}

const SurveyModal = ({ isOpen, handleClose, survey }: ISurveyModal) => {
  const store = useMst()
  const surveysStore = store.admin.surveysStore
  const targetAudienceStore = store.admin.organizationStructureStore
  const [serverFetching, setServerFetching] = useState(false) // TODO: заменить на useWaiter
  const [questions, setQuestions] = useState<IQuestionInstance[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errors, setErrors] = useState<any>({ err: false })
  const [users, setUsers] = useState<IOrganizationStructureElementModel[]>([])
  const [permissions, setPermissions] = useState<IPermissionsWithUsers[]>([])
  const routeManager = useRouteManager()
  const [editorIsOpen, setEditorIsOpen] = useState(false)
  const [tabState, setTabState] = useState(0)
  const isAdmin = isRole([{ type: PermissionType.admin }])
  const isEditor = isRole([{ type: PermissionType.editor, surveyId: survey?.surveyId }])

  const [userShortResultAccess, setUserShortResultAccess] = useState<UserShortResultAccess>(UserShortResultAccess.None)

  const [currentSurvey, setCurrentSurvey] = useState<IFields>({
    title: survey?.title ?? '',
    description: survey?.description ?? '',
    type: survey?.type ?? SurveyType.Custom,
    end: survey?.end ?? new Date(new Date().setHours(23, 59, 59)),
    start: survey?.start ?? new Date(),
    imageId: survey?.imageId ?? undefined,
    targetServiceId: survey?.targetServiceId ?? undefined,
    targetAudiencesMappingType: survey?.targetAudiencesMappingType ?? getDefaultTargetAudienceMappingType(applicationConfig?.frontendFeatures ?? []),
    version: survey?.version,
    isAnonym: survey?.isAnonym ?? false,
    totalUsersCount: survey?.numberOfDestination.toString() ?? '0',
  })

  const showServiceSelection = ([TargetAudiencesMappingType.BySurveyService, TargetAudiencesMappingType.ByPageService] as Array<TargetAudiencesMappingType | undefined>).includes(currentSurvey?.targetAudiencesMappingType)
  const [sessionStorageCurrentSessionSurveyIds, setSessionStorageCurrentSessionSurveyIds] = useSessionStorage('current_session_survey_ids', [''])

  useEffect(() => {
    (async () => {
      // await apiSyncUsers()

      if (survey) {
        const permissions = await apiGetSurveyPermissions(survey.surveyId)
        setPermissions(permissions)

        const elementsWithUserShortStatisticPermission = permissions.find(x => x.type === PermissionType.shortAnswersView)?.organizationStructureElementsId ?? []

        await targetAudienceStore.loadWithSelected(surveyModalOrganizationStructureStateSelector, elementsWithUserShortStatisticPermission)

        if (elementsWithUserShortStatisticPermission.includes(targetAudienceStore.rootElement ?? '')) {
          setUserShortResultAccess(UserShortResultAccess.All)
        } else {
          setUserShortResultAccess(UserShortResultAccess.None)
        }
      } else {
        await targetAudienceStore.loadWithSelected(surveyModalOrganizationStructureStateSelector, [])
      }

      setUsers(targetAudienceStore.getAllOrganizationStructureElements(surveyModalOrganizationStructureStateSelector).filter(x => x.isUser))

      const mainQuestions = survey?.getMainQuestionsAsIQuestionVersion().map(x => {
        const question = Question.create({
          ...x,
          options: x.options.map(x => ({
            ...x,
            dndId: uuidv4(),
          })),
          dndId: uuidv4(),
        })

        return question
      }) ?? []
      setQuestions(mainQuestions)

      setIsLoading(false)
    })()
  }, [survey])

  const handleAddQuestion = (presetType: 'custom' | 'gvk') => () => {
    const presset = presetType === 'gvk' ? defaultGvkQuestion : defaultQuestion

    setQuestions(prev => {
      const question = Question.create({
        ...presset,
        options: presset.options.map(x => ({
          ...x,
          dndId: uuidv4(),
        })),
        dndId: uuidv4(),
        order: prev.length,
      })

      return [...prev, question]
    })
  }

  const isStartTimeBiggerEndTime = currentSurvey.start > currentSurvey.end
  const isStartLimitValide = currentSurvey.start.toString() !== 'Invalid Date' && !isStartTimeBiggerEndTime
  const isEndLimitValide = currentSurvey.end.toString() !== 'Invalid Date' && currentSurvey.end > new Date() && !isStartTimeBiggerEndTime

  const validate = (): boolean => {
    let newErrors = {}
    if (!currentSurvey?.title) {
      newErrors = { ...newErrors, 'title': true }
    }
    if (htmlTagsIsEmpty(currentSurvey?.description)) {
      newErrors = { ...newErrors, 'description': true }
    }
    if (!isStartLimitValide) {
      newErrors = { ...newErrors, 'start': true }
    }
    if (!isEndLimitValide) {
      newErrors = { ...newErrors, 'end': true }
    }

    if (currentSurvey?.type === SurveyType.Repeatable && questions.length === 0) newErrors = { ...newErrors, zeroMainQuestions: true }

    setErrors({ ...newErrors })

    return Object.keys(newErrors).length !== 0
  }

  const getDefaultSurveyTargetAudiencesMappingTypeBySyrveyType = (type: SurveyType) => {
    switch (type) {
      case SurveyType.Gvk: return TargetAudiencesMappingType.ByPageService
      case SurveyType.Custom: return TargetAudiencesMappingType.BySurveyTargetAudience
      case SurveyType.Repeatable: return TargetAudiencesMappingType.ByPageTargetAudience
      default: return TargetAudiencesMappingType.BySurveyTargetAudience
    }
  }

  const handleSave = (openAfterSave: boolean) => async () => {
    questions.forEach(x => x.validate())

    const basicValidationError = validate()
    const questionsValidationError = questions.some(x => x.hasValidationErrors)

    if (questionsValidationError || basicValidationError) {
      if (questionsValidationError && !basicValidationError) {
        setTabState(1)
      }

      return
    }

    setServerFetching(true)

    const withEditor = permissions.some(x => x.type === PermissionType.editor)

    const mainQuestions = questions.map(x => getSnapshot(x))

    // Время в utc, на бэке работаем с временем РФ 
    currentSurvey.end.setHours(currentSurvey.end.getHours() + 3)
    currentSurvey.start.setHours(currentSurvey.start.getHours() + 3)

    const newSurveyModel: CreteOrUpdateSurveyInterface = {
      ...currentSurvey,
      surveyId: survey?.surveyId,
      imageId: currentSurvey?.imageId ?? defaultImageHash,
      withCards: [SurveyType.Repeatable, SurveyType.Gvk].includes(currentSurvey.type),
      withEditors: withEditor,
      targetAudiencesMappingType: currentSurvey.targetAudiencesMappingType ?? getDefaultSurveyTargetAudiencesMappingTypeBySyrveyType(currentSurvey.type),
      mainQuestions: [...mainQuestions.map(x => ({
        ...x,
        id: x.mainQuestionId ?? '',
        commentType: x.commentType ?? CommentType.AlwaysVisibleAndRequired,
        helperText: x.helperText ?? '',
        showHelperText: x.showHelperText ?? false,
        payload: {
          ...x.payload,
          acceptFileTypes: [...x.payload.acceptFileTypes],
          adminFiles: x.payload.adminFiles.map(f => ({ ...f })),
        },
      }))],
      totalUsersCount: parseInt(currentSurvey.totalUsersCount === '' ? '0' : currentSurvey.totalUsersCount),
    }
    let createdSurveyId: string | null = null
    if (survey) await surveysStore.updateSurvey(newSurveyModel)
    else createdSurveyId = await surveysStore.addSurvey(newSurveyModel)

    let allPermissions = permissions
    switch (userShortResultAccess) {
      case UserShortResultAccess.None: {
        allPermissions = [...permissions.filter(x => x.type !== PermissionType.shortAnswersView)]
        break
      }
      case UserShortResultAccess.All: {
        if (targetAudienceStore.rootElement) {
          allPermissions = [...permissions.filter(x => x.type !== PermissionType.shortAnswersView), { type: PermissionType.shortAnswersView, organizationStructureElementsId: [targetAudienceStore.rootElement] }]
        }
        break
      }
    }

    // store.last != null т.к. в store сохраняется survey ранее
    const surveyId = survey?.surveyId ?? surveysStore.last?.surveyId ?? ''
    await apiSetSurveyPermissions(surveyId, { permissions: allPermissions.map(x => ({ ...x, surveyId })) })

    await handleClose(surveyId)

    if (openAfterSave) {
      routeManager.redirectToUrl(RouteEnum.survey, surveyId)
    }

    if (!createdSurveyId) return
    const newCurrentSessionSurveyIds = sessionStorageCurrentSessionSurveyIds
    newCurrentSessionSurveyIds.push(createdSurveyId)
    setSessionStorageCurrentSessionSurveyIds(newCurrentSessionSurveyIds)
  }

  const handleChangeString = (text: string, field: keyof IFields, minLength?: number, maxLength?: number) => {
    if (((minLength ?? 0) && text.length > (minLength ?? 0)) || !minLength) setErrors((prev: any) => ({ ...prev, [field]: false }))
    if (!maxLength || text.length <= maxLength) {
      if (field === 'type') {
        setCurrentSurvey(prev => ({ ...prev, [field]: text as SurveyType, targetServiceId: undefined }))
      } else {
        setCurrentSurvey(prev => ({ ...prev, [field]: text }))
      }
    }
  }

  const handleChangeSurveyType = (e: SelectChangeEvent) => {
    handleChangeString(e.target.value, 'type')

    // Гвк не может быть с ца по орг структуре, если переключили на гвк, ставить ца с сервисом на весь опрос
    if ((e.target.value as SurveyType) === SurveyType.Gvk) {
      setCurrentSurvey(prev => ({ ...prev, targetAudiencesMappingType: TargetAudiencesMappingType.ByPageService }))
      setPermissions(prev => [...prev.filter(x => x.type === PermissionType.shortAnswersView)])
      handleAddQuestion('gvk')()
    } else if ((e.target.value as SurveyType) === SurveyType.Repeatable) {
      handleAddQuestion('custom')()
    } else {
      setQuestions([])
    }
  }

  const handleChangeEnd = (date: Date | null) => {
    const endDate = new Date(date ?? new Date())
    setCurrentSurvey(prev => ({
      ...prev,
      end: endDate,
    }))
    setErrors({ ...errors, end: false })
  }

  const handleChangeStart = (date: Date | null) => {
    const startDate = new Date(date ?? new Date())
    setCurrentSurvey(prev => ({
      ...prev,
      start: startDate,
    }))
    setErrors({ ...errors, start: false })
  }

  const onPhotoChange = (imageId: string) => {
    setCurrentSurvey(prev => ({ ...prev, imageId }))
  }

  const handleDeleteQuestion = (question: IQuestion) => () => {
    setQuestions(questions.filter(x => x !== question))
  }

  const handlePermissionsChange = (type: PermissionType) => (value: string[]) => {
    setPermissions(prev => {
      if (value.length === 0) {
        return [...prev.filter(x => x.type !== type)]
      } else if (prev.some(x => x.type === type)) {
        return [...prev.map(x => (x.type !== type ? x : { ...x, organizationStructureElementsId: [...value] }))]
      } else {
        return [...prev, { type: type, organizationStructureElementsId: [...value] }]
      }
    })
  }

  const handleTargetAudienceMappingTypeCheckbox = () => {
    if (currentSurvey.targetAudiencesMappingType !== TargetAudiencesMappingType.BySurveyService) {
      setCurrentSurvey(prev => ({ ...prev, targetAudiencesMappingType: TargetAudiencesMappingType.BySurveyService }))
    } else {
      setCurrentSurvey(prev => ({ ...prev, targetAudiencesMappingType: TargetAudiencesMappingType.BySurveyTargetAudience, targetServiceId: undefined, totalUsersCount: '0' }))
    }
  }

  const isAllEditorHasCopyingSurveysAccess = (permissions.find(x => x.type === PermissionType.editor)?.organizationStructureElementsId ?? []).length === (permissions.find(x => x.type === PermissionType.copyingSurveys)?.organizationStructureElementsId ?? []).length

  return (<Dialog onClose={async () => { await handleClose() }} open={isOpen} className='polls-survey-dialog'>
    <MainContainer>
      <div className='polls-dialog-data-layout'>
        <div>
          <PageContainer>
            <h2>{survey ? 'Редактирование опроса' : 'Создание опроса'}</h2>
          </PageContainer>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabState} onChange={(_, n) => setTabState(n)}>
              <Tab value={0} label="Основное" id="base-settings" />
              {questions.length > 0 && <Tab className={`polls-dialog-tab ${questions.some(x => x.hasValidationErrors) ? 'error' : ''}`} value={1} label="Вопросы" id="questions-settings" />}
              {currentSurvey.type !== SurveyType.Gvk && <Tab value={2} label="Роли" id="roles-settings" />}
            </Tabs>
          </Box>
          {tabState === 0 && <div className='polls-tab'>
            <MainContainerCalendar>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <TitleContainer image-offset>
                    <TextField
                      variant='standard'
                      label='Название'
                      placeholder='Введите название'
                      value={currentSurvey.title}
                      onChange={e => handleChangeString(e.target.value, 'title', 0, 101)}
                      error={errors.title}
                      helperText={`${currentSurvey.title.length}/100`}
                      disabled={!!survey?.archivedDate || !(isAdmin || isEditor)}
                    />
                  </TitleContainer>
                  <TitleContainer image-offset>
                    <TextEditor
                      title='Введите описание'
                      placeholder='Описание'
                      value={currentSurvey.description}
                      isOpen={editorIsOpen}
                      onOpen={() => setEditorIsOpen(true)}
                      onClose={() => setEditorIsOpen(false)}
                      onChange={(value) => handleChangeString(value, 'description', 0)}
                      error={errors.description}
                      disabled={!!survey?.archivedDate || !(isAdmin || isEditor)}
                      disableImage
                    />
                  </TitleContainer>
                  <TitleContainer image-offset>
                    <FormControl variant='standard' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <InputLabel shrink id="type-label">
                        Тип запуска
                      </InputLabel>
                      <Select
                        labelId="type-label"
                        value={currentSurvey.type}
                        onChange={handleChangeSurveyType}
                        disabled={!!survey}
                      >
                        <MenuItem value={SurveyType.Custom}>Кастомный</MenuItem>
                        {applicationConfig?.frontendFeatures.includes(FrontendFeature.gvk) && <MenuItem value={SurveyType.Gvk}>ГВК</MenuItem>}
                        <MenuItem value={SurveyType.Repeatable}>Постоянный</MenuItem>
                      </Select>
                    </FormControl>
                  </TitleContainer>
                  <TitleContainer image-offset>
                    <DateTimeRow
                      lable={survey === undefined || [SurveyStatus.Editing, SurveyStatus.Created].includes(survey.status) ? 'Плановая дата начала' : 'Дата начала'}
                      disabled={(!!survey && !!survey.archivedDate) || !(isAdmin || isEditor)}
                      value={currentSurvey.start}
                      onChange={handleChangeStart}
                      isValide={isStartLimitValide}
                    />
                  </TitleContainer>
                  <TitleContainer image-offset>
                    <DateTimeRow
                      lable='Дата завершения'
                      disabled={(!!survey && !!survey.archivedDate) || !(isAdmin || isEditor)}
                      value={currentSurvey.end}
                      onChange={handleChangeEnd}
                      isValide={isEndLimitValide}
                    />
                  </TitleContainer>
                  <TitleContainer image-offset className='target-audience-mapping-type soft-show-hide-animation'>
                    {(applicationConfig?.frontendFeatures.includes(FrontendFeature.organizationStructure) && applicationConfig?.frontendFeatures.includes(FrontendFeature.service)) && currentSurvey.type !== SurveyType.Gvk && <div className='polls-checkbox-container'>
                      {isLoading ? <Skeleton variant='circular' className='polls-checkbox-skeleton polls-checkbox' /> : <Checkbox
                        id='target-audience-mapping-type-checkbox'
                        className='polls-checkbox'
                        checked={showServiceSelection}
                        onChange={handleTargetAudienceMappingTypeCheckbox}
                        disabled={!!survey}
                      />}
                      <label className={`polls-checkbox-label ${survey ? 'disabled' : ''}`} htmlFor='target-audience-mapping-type-checkbox'><Typography>Ца по сервисам</Typography></label>
                    </div>}
                  </TitleContainer>
                  <div className='soft-show-hide-animation'>
                    <TitleContainer image-offset className='permission-target-audience-mapping'>
                      {isLoading ? <Skeleton variant='rectangular' height='48px' /> : <FormControl variant='standard' fullWidth>
                        <InputLabel id="result-access">Результаты доступны</InputLabel>
                        <Select
                          labelId="result-access"
                          value={userShortResultAccess}
                          label="Результаты доступны"
                          onChange={(e: SelectChangeEvent) => { setUserShortResultAccess(e.target.value as UserShortResultAccess) }}
                          disabled={!!survey?.archivedDate || !(isAdmin || isEditor)}
                        >
                          {Object.values(UserShortResultAccess).map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                        </Select>
                      </FormControl>}
                    </TitleContainer>
                  </div>
                  <TitleContainer className='soft-show-hide-animation'>
                    <div className='polls-checkbox-container'>
                      {isLoading ? <Skeleton variant='circular' className='polls-checkbox-skeleton polls-checkbox' /> : <Checkbox
                        id='is-anonym-checkbox'
                        className='polls-checkbox'
                        checked={currentSurvey.isAnonym}
                        onChange={(e) => setCurrentSurvey(prev => ({ ...prev, isAnonym: e.target.checked }))}
                        disabled={!!survey?.archivedDate || !(isAdmin || isEditor)}
                      />}
                      <label className={`polls-checkbox-label ${!!survey?.archivedDate || !(isAdmin || isEditor) ? 'disabled' : ''}`} htmlFor='is-anonym-checkbox'><Typography>Анонимность</Typography></label>
                    </div>
                  </TitleContainer>
                  {[TargetAudiencesMappingType.ByPageService, TargetAudiencesMappingType.BySurveyService].includes(currentSurvey.targetAudiencesMappingType) && <TitleContainer>
                    <TextField
                      className='polls-total-users-counter'
                      type='number'
                      variant='outlined'
                      label='Количество респондентов опроса'
                      placeholder='Введите количество респондентов опроса'
                      value={currentSurvey.totalUsersCount}
                      inputProps={{
                        maxLength: 10,
                        step: 1,
                        min: 0,
                      }}
                      onChange={e => {
                        let value = parseInt(e.target.value.slice(0, 10), 10)

                        if (value < 0) {
                          value = 0
                        }

                        setCurrentSurvey(prev => ({ ...prev, totalUsersCount: value.toString() }))
                      }}
                      disabled={!!survey?.archivedDate || !isAdmin}
                    />
                  </TitleContainer>}
                </div>
                <div>
                  <TitleContainer image-offset>
                    <PhotoInput
                      imageUrl={currentSurvey.imageId ? getStaticsHybridUrl(`/api/file/image/${currentSurvey.imageId}`) : undefined}
                      defaultImage={ImageDefault}
                      onChange={onPhotoChange}
                      cropWidth={256}
                      cropHeight={149}
                      width={256}
                      height={149}
                      disabled={!!survey?.archivedDate || !(isAdmin || isEditor)}
                    />
                  </TitleContainer>
                </div>
              </div>
            </MainContainerCalendar>
          </div>}
          {tabState === 1 && <div className='polls-tab'>
            <MainContainerCalendar>
              <MainQuestionsContainer>
                {isLoading && survey && questions.length ? <Skeleton variant='rectangular' width='100%' height={401} style={{ marginBottom: '10px' }}/> : questions.map((x, i) => <MainQuestionBlock
                  key={i}
                  onDelete={handleDeleteQuestion(x)}
                  question={x}
                  // В архиве - точно нельзя редактировать, если опрос имел запуски или запущен - нельзя редактировать
                  disabled={!!survey?.archivedDate || (!!survey && (currentSurvey.version !== 0 || survey.status === SurveyStatus.Surveyed))}
                  surveyType={currentSurvey.type}
                  showDeleteButton={currentSurvey.type === SurveyType.Repeatable && questions.length > 1}
                  questionLabel={`Вопрос ${i + 1}`}
                />)}
                {/* Характерно только для периодических опросов, не в архиве, и до первого запуска (т.к. не в состоянии "запущено" и не с версией больше 0),  */}
                {currentSurvey.type === SurveyType.Repeatable && !survey?.archivedDate && (!survey || (survey.status !== SurveyStatus.Surveyed && currentSurvey.version === 0)) && <TextLink
                  disabled={isLoading && survey && currentSurvey.type === SurveyType.Repeatable && !isAdmin}
                  text='Добавить основной вопрос'
                  color="#266DC2"
                  style={{ marginTop: '17px', lineHeight: '18px' }}
                  onClick={isLoading && survey && currentSurvey.type === SurveyType.Repeatable ? () => {} : handleAddQuestion('custom')}
                />}
              </MainQuestionsContainer>
            </MainContainerCalendar>
          </div>
          }
          {tabState === 2 && <div className='polls-tab'>
            <MainContainerCalendar>
              <TitleContainer className='expert-settings'>
                {isLoading ? <Skeleton variant='rounded' height={55} width='100%' className='polls-checkbox-skeleton polls-checkbox' /> : <Autocomplete
                  className='experts-autocomplete'
                  multiple
                  value={(permissions.find(x => x.type === PermissionType.expert)?.organizationStructureElementsId.map(organizationStructureElementId => users.find(user => user.id === organizationStructureElementId)).filter(x => !!x) ?? []) as IOrganizationStructureElementModel[]}
                  options={users}
                  getOptionLabel={(user) => (!user?.name || user.name.trim().length === 0 ? user?.foreignId : user.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label='Выбрать эксперта'
                      placeholder='Выбрать эксперта'
                    />
                  )}
                  onChange={(e, value) => handlePermissionsChange(PermissionType.expert)(value.map(x => x.id))}
                  disabled={!!survey?.archivedDate || !isAdmin}
                />}
              </TitleContainer>
              <TitleContainer className='editor-settings'>
                {isLoading ? <Skeleton variant='rounded' height={55} width='100%' className='polls-checkbox-skeleton polls-checkbox' /> : <Autocomplete
                  className='editor-autocomplete'
                  multiple
                  value={(permissions.find(x => x.type === PermissionType.editor)?.organizationStructureElementsId.map(organizationStructureElementId => users.find(user => user.id === organizationStructureElementId)).filter(x => !!x) ?? []) as IOrganizationStructureElementModel[]}
                  options={users}
                  getOptionLabel={(user) => (!user?.name || user.name.trim().length === 0 ? user?.foreignId : user.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label='Выбрать редактора'
                      placeholder='Выбрать редактора'
                    />
                  )}
                  onChange={(e, value) => {
                    const editorOrganizationStructureElementIds = value.map(x => x.id)

                    handlePermissionsChange(PermissionType.editor)(editorOrganizationStructureElementIds)

                    setPermissions(prev => [...prev.map(x => (x.type === PermissionType.copyingSurveys ? { ...x, organizationStructureElementsId: x.organizationStructureElementsId.filter(x => editorOrganizationStructureElementIds.includes(x)) } : x))])
                  }}
                  disabled={!!survey?.archivedDate || !isAdmin}
                />}
              </TitleContainer>
              <TitleContainer className='editor-settings'>
                <Typography fontWeight='bold' variant='caption'>Могут копировать опросы</Typography>
                <div className='polls-checkbox-container'>
                  {isLoading ? <Skeleton variant='circular' className='polls-checkbox-skeleton polls-checkbox' /> : <Checkbox
                    id='target-audience-mapping-type-checkbox'
                    className='polls-checkbox'
                    checked={isAllEditorHasCopyingSurveysAccess}
                    onChange={() => {
                      if (!isAllEditorHasCopyingSurveysAccess) {
                        const organizationStructureElementsId = permissions.find(x => x.type === PermissionType.editor)?.organizationStructureElementsId ?? []
                        handlePermissionsChange(PermissionType.copyingSurveys)(organizationStructureElementsId)
                      } else {
                        handlePermissionsChange(PermissionType.copyingSurveys)([])
                      }
                    }}
                    disabled={!!survey?.archivedDate || !isAdmin}
                  />}
                  <label className={`polls-checkbox-label ${!!survey?.archivedDate || !isAdmin ? 'disabled' : ''}`} htmlFor='target-audience-mapping-type-checkbox'><Typography>Все редакторы</Typography></label>
                </div>
                <div className='soft-show-hide-animation'>
                  <CSSTransition
                    in={!isAllEditorHasCopyingSurveysAccess}
                    timeout={300}
                    unmountOnExit
                  >
                    {isLoading ? <Skeleton variant='rounded' height={55} width='100%' className='polls-checkbox-skeleton polls-checkbox' /> : <Autocomplete
                      className='editor-autocomplete'
                      multiple
                      value={(permissions.find(x => x.type === PermissionType.copyingSurveys)?.organizationStructureElementsId.map(organizationStructureElementId => users.find(user => user.id === organizationStructureElementId)).filter(x => !!x) ?? []) as IOrganizationStructureElementModel[]}
                      options={(permissions.find(x => x.type === PermissionType.editor)?.organizationStructureElementsId.map(organizationStructureElementId => users.find(user => user.id === organizationStructureElementId)).filter(x => !!x) ?? []) as IOrganizationStructureElementModel[]}
                      getOptionLabel={(user) => (!user?.name || user.name.trim().length === 0 ? user?.foreignId : user.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label='Выбрать редактора с правом копирования'
                          placeholder='Выбрать редактора с правом копирования'
                        />
                      )}
                      onChange={(e, value) => handlePermissionsChange(PermissionType.copyingSurveys)(value.map(x => x.id))}
                      disabled={!!survey?.archivedDate || !isAdmin}
                    />}
                  </CSSTransition>
                </div>
              </TitleContainer>
            </MainContainerCalendar>
          </div>
          }
        </div>
        <div className='bottom'>
          <TextContainer>
            <Error isVisible={Object.values(errors).some(x => x) || questions.some(x => x.hasValidationErrors)}>Заполните все поля</Error>
          </TextContainer>
          <RoleQualifier roles={[{ type: PermissionType.admin }, { type: PermissionType.editor, surveyId: survey?.surveyId }]}>
            <div className='button-container'>
              <Button
                onClick={handleSave(!survey)}
                disabled={serverFetching || !!survey?.archivedDate}
                color='primary'
              >
                {survey ? 'Сохранить' : 'Далее'}
              </Button>
            </div>
          </RoleQualifier>
        </div>
      </div>
    </MainContainer>
  </Dialog>)
}

export default observer(SurveyModal)
