import { Typography } from '@mui/material'
import styled from 'styled-components'

export const LoaderContainer = styled.div`
  margin-top: 10px;
  height: 40px;
  border: solid #266DC2 1px;
  border-radius: 10px;
  align-items: center;
  width: calc(95% + 10px);

  & > div {
    height: 40px;
    border-radius: inherit;
  }
`

export const AddButtonContainer = styled.div`
  display: flex;
  width: 95%;

  & > label {
    border: solid #266DC2 1px;
    border-radius: 10px;

    & > span {
      font-weight: bold;
      font-size: 18px;
      text-transform: capitalize;
      color: #266DC2;

      & > input {
        display: none;
      }
    }

    & > svg {
      width: 30px;
      height: 30px;
      color: #266DC2;
    }
  }

  &.disabled {
    & > label {
      border: solid #8B98A7 1px;

      & > span {
        color: #8B98A7;
      }

      & > svg {
        color: #8B98A7;
      }
    }
  }
`

export const UserAnswersTitleTextStyled = styled(Typography)`
  font-weight: bold;
  margin: 20px 0 10px 0;
`
