import BackendAdapter from '@/Api/_adapter'
import { IPermissionOut, IPermissionIn } from '@/Stores/sharedModels/models'

export const apiCheckUserPermissions = async (permissions: IPermissionIn[]): Promise<boolean> => {
  try {
    return await BackendAdapter.post('/api/permissions/check', permissions)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetUserPermissions = async (): Promise<IPermissionOut[]> => {
  try {
    return await BackendAdapter.get('/api/permissions')
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
