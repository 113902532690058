import { isString } from 'lodash'

export const parseJson = (text: string) => {
  try {
    return isString(text) && text.includes('{') ? JSON.parse(text) : text
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
    return {}
  }
}

