import { observer } from 'mobx-react'
import { IconButton, Skeleton, Stack } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

import { useMst } from '@/Stores/rootStore'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnOrganizationStructureElementShortData = bem()('organization-structure-element-short-data')

interface IOrganizationStructureElementShortData {
  organizationStructureStateSelector: string
  elementId: string
  onDelete: (elementId: string) => void
  isLoading?: boolean
  disabled?: boolean
  freeElement?: boolean
}

const OrganizationStructureElementShortData = ({
  organizationStructureStateSelector,
  elementId,
  onDelete,
  isLoading = false,
  disabled = false,
  freeElement = false,
}: IOrganizationStructureElementShortData) => {
  const organizationStructureStore = useMst().admin.organizationStructureStore
  const organizationStructureState = organizationStructureStore.getOrganizationStructureState(organizationStructureStateSelector)

  const element = freeElement ? organizationStructureState?.getFreeElement(elementId) : organizationStructureState?.getElement(elementId)

  const handleDelete = () => {
    onDelete(elementId)
  }

  return <div className={cnOrganizationStructureElementShortData({ 'free-element': freeElement })}>
    {isLoading ? <Skeleton variant="rectangular" width='100%' height='30px' /> : <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={2}
      className={cnOrganizationStructureElementShortData('data')}
    >
      <div>{ element && element.name.trim().length > 0 ? element.name : element?.foreignId ?? '' }</div>
      {!disabled && !isLoading && <IconButton size='small' onClick={handleDelete} >
        <CancelIcon fontSize='small'/>
      </IconButton>}
    </Stack>}
  </div>
}

export default observer(OrganizationStructureElementShortData)
