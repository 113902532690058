import { useEffect, useState, ReactNode, MouseEvent } from 'react'
import { useMount } from 'react-use'
import { Helmet } from 'react-helmet-async'
import { useInterval } from 'usehooks-ts'
import { Grid, Button, IconButton, ListItemText, Popover } from '@mui/material'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import SyncIcon from '@mui/icons-material/Sync'
import SettingsIcon from '@mui/icons-material/Settings'

import { RouteEnum } from '@/Enums/route'
import { useNotifyUserForError } from '@/Hooks/useNotifyUserForError'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { useUserActivity } from '@/Hooks'
import { useMst } from '@/Stores/rootStore'
import { PermissionType } from '@/Stores/sharedModels/models'
import { ContentLoader, Dialog as CustomDialog, VersionLabel } from '@/Components'
import { DndContextLayout } from '@/Components/DragAndDrop/DndContext'
import { IDialog } from '@/Components/Dialog'
import { apiSyncGlobalServices } from '@/Api/service'
import { apiSyncOrganizationStructure } from '@/Api/organizationStructure'
import { apiTryRefreshEditingLock } from '@/Api/survey'
import { useWaiter } from '@/Helpers/react-wait'
import bem from '@/Helpers/BemClass'
import Feature from '@/Components/Feature'
import DynamicConfiguration from '@/Components/DynamicConfiguration'
import RoleQualifier, { isRole } from '@/Components/RoleQualifier'
import EditingTimeSpanActivityAlert from '@/Components/EditingTimeSpanActivityAlert'
import { FrontendFeature } from '@/applicationConfig'

import { Container, ContentContainer, GlobalSnackbarStyled, ListStyled, ListItemStyled } from './styled'

import './style.scss'

const cnAdminLayout = bem()('admin-layout')

/*
enum AdminLayoutTabs {
  surveys = 'surveys',
  archive = 'archive',
}
*/

export enum MenuAdminPage {
  pageServices = 0,
  surveyList = 1,
  feedback = 2,
  archive = 3,
  organizationStructure = 4,
  services = 5,
  review = 6,
}

interface IAdminLayout {
  children: ReactNode | ReactNode[]
  menuItem: number
  disableMainTabs?: boolean
  leftRightMargin?: number
}

const activityCheckTimeout = 2 * 60 * 1000 // 2 минута

const AdminLayout = ({ children, menuItem, disableMainTabs = false, leftRightMargin = 100 }: IAdminLayout) => {
  const [isLoading, setIsLoading] = useState(true)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  // const [tab, setTab] = useState<AdminLayoutTabs>(AdminLayoutTabs.surveys)
  const routeManager = useRouteManager()
  useNotifyUserForError()
  const { startWait, stopWait, isWaiting } = useWaiter()
  const store = useMst()
  const tabStore = store.admin.tabStore
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const [toUserSpaceButtonHovered, setToUserSpaceButtonHovered] = useState(false)
  const [userLastActivityTime, setUserLastActivityTime] = useState(new Date())
  const [showEditingTimeSpanActivityAlert, setShowEditingTimeSpanActivityAlert] = useState(false)

  useUserActivity(() => {
    setUserLastActivityTime(new Date())

    setShowEditingTimeSpanActivityAlert(false)
  })

  useEffect(() => {
    if (!showEditingTimeSpanActivityAlert && store.admin.surveyEditingTimeSpan) {
      apiTryRefreshEditingLock(store.admin.surveyEditingTimeSpan.surveyId, store.admin.surveyEditingTimeSpan.id)
        .catch(err => console.log('Не удалось обновить блокировку', err))
    }
  }, [showEditingTimeSpanActivityAlert, store.admin.surveyEditingTimeSpan])

  useInterval(() => {
    (async () => {
      if (store.admin.surveyEditingTimeSpan == null || showEditingTimeSpanActivityAlert) {
        return
      }

      const now = new Date()

      const hasActivity = now.valueOf() - userLastActivityTime.valueOf() < activityCheckTimeout

      if (hasActivity) {
        const result = await apiTryRefreshEditingLock(store.admin.surveyEditingTimeSpan.surveyId, store.admin.surveyEditingTimeSpan.id)

        if (!result) {
          await store.admin.updateSurveyInEditing(null)
        }
      } else {
        setShowEditingTimeSpanActivityAlert(true)
      }
    })()
  },
  activityCheckTimeout,
  )

  useMount(() => {
    tabStore.setWasChange(false)
    setIsLoading(false)
  })

  useEffect(() => {
    (async () => {
      isRole([{ type: PermissionType.admin }]) && await apiSyncOrganizationStructure()
    })()
  }, [])

  const handleSyncServices = async (e: MouseEvent) => {
    e.stopPropagation()

    startWait('global-services-sync')
    await apiSyncGlobalServices()
    stopWait('global-services-sync')

    document.dispatchEvent(new CustomEvent('service-sync-completed'))
  }

  const handleRedirect = (routeEnum: RouteEnum) => {
    setDialogState(undefined)
    routeManager.redirectTo(routeEnum)
    tabStore.setWasChange(false)
  }

  const redirect = (routeEnum: RouteEnum) => {
    if (tabStore.wasChanged) {
      setDialogState({
        open: true,
        handleClose: () => setDialogState(undefined),
        title: 'На странице были изменения',
        text: 'Выйти без сохранения?',
        actions: [
          <Button key={'changes-no-btn'} onClick={() => setDialogState(undefined)}>Нет</Button>,
          <Button key={'changes-yes-btn'} onClick={() => handleRedirect(routeEnum)}>Да</Button>,
        ],
      })
    } else {
      routeManager.redirectTo(routeEnum)
      tabStore.setWasChange(false)
    }
  }

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSettingsClose = () => {
    setAnchorEl(null)
  }

  const settingsIsOpen = Boolean(anchorEl)
  const settingsPopoverId = settingsIsOpen ? 'settings-popover' : undefined

  if (isLoading) return <ContentLoader/>
  return (
    <>
      <DndContextLayout>
        <GlobalSnackbarStyled/>
        <Helmet>
          <title>Сервис опросов: Администратор</title>
        </Helmet>
        <Grid container direction='row' alignItems='center' justifyContent='space-between' className={cnAdminLayout('header')}>
          <Grid item>
            <Button
              size='small'
              color='secondary'
              onClick={() => window.open(`${window.location.origin}/${RouteEnum.clientSurveys}`)}
              onMouseOver={() => setToUserSpaceButtonHovered(true)}
              onMouseOut={() => setToUserSpaceButtonHovered(false)}
              className={cnAdminLayout('user-space-button')}
            >
              <div className={cnAdminLayout('user-space-button-layout')}>
                <LaunchRoundedIcon fontSize='inherit'/>
                <div className={cnAdminLayout('user-space-button-text')} hidden={!toUserSpaceButtonHovered}>Перейти к опросам</div>
              </div>
            </Button>
          </Grid>
          <RoleQualifier roles={{ type: PermissionType.admin }}>
            <Feature features={FrontendFeature.dynamicConfiguration}>
              <Grid item>
                <IconButton aria-describedby={settingsPopoverId} onClick={handleSettingsClick} aria-label='settings'>
                  <SettingsIcon />
                </IconButton>
                <Popover
                  id={settingsPopoverId}
                  open={settingsIsOpen}
                  anchorEl={anchorEl}
                  onClose={handleSettingsClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <DynamicConfiguration/>
                </Popover>
              </Grid>
            </Feature>
          </RoleQualifier>
        </Grid>
        <Container>
          <ContentContainer leftRightMargin={leftRightMargin} >
            { !disableMainTabs &&
            (<ListStyled style={{ display: 'flex', flexDirection: 'row', padding: 0, paddingBottom: '1rem' }}>
              <ListItemStyled disableRipple selected={menuItem === MenuAdminPage.surveyList}
                onClick={() => redirect(RouteEnum.survey)}>
                <ListItemText primaryTypographyProps={{ variant: 'h2', style: { fontSize: '35px' } }} primary={'Опросы'}/>
              </ListItemStyled>
              <RoleQualifier roles={[{ type: PermissionType.admin }, { type: PermissionType.expert }, { type: PermissionType.editor }]}>
                <ListItemStyled disableRipple selected={menuItem === MenuAdminPage.archive}
                  onClick={() => redirect(RouteEnum.archive)}>
                  <ListItemText primaryTypographyProps={{ variant: 'h2', style: { fontSize: '35px' } }} primary={'Архив опросов'}/>
                </ListItemStyled>
              </RoleQualifier>
              <RoleQualifier roles={[{ type: PermissionType.admin }, { type: PermissionType.editor }]}>
                <ListItemStyled disableRipple selected={menuItem === MenuAdminPage.review}
                  onClick={() => redirect(RouteEnum.review)}>
                  <ListItemText primaryTypographyProps={{ variant: 'h2', style: { fontSize: '35px' } }} primary={'На согласовании'}/>
                </ListItemStyled>
              </RoleQualifier>
              <RoleQualifier roles={{ type: PermissionType.admin }}>
                <Feature features={FrontendFeature.organizationStructure}>
                  <ListItemStyled disableRipple selected={menuItem === MenuAdminPage.organizationStructure}
                    onClick={() => redirect(RouteEnum.organizationStructure)}>
                    <ListItemText primaryTypographyProps={{ variant: 'h2', style: { fontSize: '35px' } }} primary={'Оргструктура'}/>
                  </ListItemStyled>
                </Feature>
              </RoleQualifier>
              <RoleQualifier roles={{ type: PermissionType.admin }}>
                <Feature features={FrontendFeature.service}>
                  <ListItemStyled disableRipple selected={menuItem === MenuAdminPage.services}
                    onClick={() => redirect(RouteEnum.services)}>
                    <ListItemText primaryTypographyProps={{ variant: 'h2', style: { fontSize: '35px' } }} primary={'Сервисы'}/>
                    <IconButton
                      size='small'
                      edge='end'
                      disabled={isWaiting('global-services-sync')}
                      classes={{
                        root: cnAdminLayout('button', { spin: isWaiting('global-services-sync') }),
                      }}
                      onClick={handleSyncServices}
                    >
                      <SyncIcon fontSize='medium' className={cnAdminLayout('button-icon', { sync: true })}/>
                    </IconButton >
                  </ListItemStyled>
                </Feature>
              </RoleQualifier>
            </ListStyled>)}
            {children}
            {dialogState && <CustomDialog {...dialogState}/>}
            {showEditingTimeSpanActivityAlert && <EditingTimeSpanActivityAlert
              showTime={20}
              onExpired={() => {
                setShowEditingTimeSpanActivityAlert(false)
                store.admin.updateSurveyInEditing(null)
                  .catch(err => console.log('Не удалось освободить блокировку', err))
              }}/>
            }
          </ContentContainer>
        </Container>
        <VersionLabel/>
      </DndContextLayout>
    </>)
}

export default AdminLayout
