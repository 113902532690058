import styled, { StyledFunction } from 'styled-components'
import { List } from '@mui/material'

const nestedList: StyledFunction<any> = styled(List)

export const NestedList = nestedList`
    padding-left: 30px !important;
`

export const ServicesContainer = styled.div`
  .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ReactVirtualized__Grid::-webkit-scrollbar{
    //left: 10px;
  }
`

export const Container = styled.div`
  padding: 48px 25px 70px 25px;

  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.14);
  }

  .MuiIconButton-root.Mui-disabled {
    opacity: 0.4;
    color: inherit;
  }
`


export const FooterContainer = styled.div`
  min-width: 50px;
  min-height: 70px;
  background: rgba(230, 234, 240, 0.9);
  position: fixed;
  bottom: 0;
  margin-right: auto;
  width: 100%;
  margin-left: -50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button:first-child {
    background: #E6EAF0;
    margin-left: 80px;
    height: 40px;
    width: 130px;
  }

  button:last-child {
    background: #E6EAF0;
    margin-left: 30px;
    height: 40px;
    width: 130px;
  }
`
