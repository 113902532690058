import { htmlToTextWithNoImage } from '@/Helpers/htmlToTextCustom'
import { ColorTheme } from '@/Models/colorTheme'
import { ToolTip } from '@/Components'

import './style.scss'

interface ITruncatedTextProps {
  text: string
}

const TruncatedText = ({ text }: ITruncatedTextProps) => {
  return <ToolTip arrow title={htmlToTextWithNoImage(text)} theme={ColorTheme.dark}>
    <div className='truncated-text'>
      <div className='truncated-text__text'>
        {htmlToTextWithNoImage(text)}
      </div>
    </div>
  </ToolTip>
}

export default TruncatedText
