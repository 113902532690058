export enum SizeEnum {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export enum AlignEnum {
  left = 'left',
  center = 'center',
  right = 'right',
}

