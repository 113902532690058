import BackendAdapter from '@/Api/_adapter'
import { ITestingSession } from '@/Models/testingSession'


export const apiStartTestingSession = async (surveyId: string): Promise<ITestingSession> => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/testing/start`).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiRemoveTestingSession = async (surveyId: string): Promise<ITestingSession> => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/testing/remove`).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiStopTestingSession = async (surveyId: string): Promise<ITestingSession> => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/testing/stop`).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
