import { Instance, SnapshotIn, SnapshotOut, getParentOfType, getRoot, types as t } from 'mobx-state-tree'

import { FileType, QuestionStyle } from '@/Models/page'
import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'
import { IRootStore } from '@/Stores/rootStore'
import { QuestionValidationResult, Question } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'

export interface ILogicOption extends SnapshotIn<typeof LogicOption> {
}

export interface IPermissionOut extends SnapshotOut<typeof Permission> {
}

export interface IPermissionIn extends SnapshotIn<typeof Permission> {
}

export interface IFileInstance extends Instance<typeof File> {
}

export interface IFileSnapshotIn extends SnapshotIn<typeof File> {
}

export const Option = t.model({
  value: t.string,
  imageId: t.maybeNull(t.string),
  image: t.maybeNull(t.string),
  // отрефакторить сделать 1 общий опшенс (@Vidrochka)
  title: t.maybeNull(t.string),
  isCustom: t.boolean,
  order: t.number,
})

export const File = t.model({
  fileId: t.maybeNull(t.string),
  fileName: t.maybeNull(t.string),
  fileType: t.maybeNull(t.string),
})

export const Payload = t.model({
  helperStartText: t.string,
  helperEndText: t.string,
  showNotReadyToRate: t.boolean,
  notReadyToRateText: t.string,
  requireCommentValue: t.string,
  isEnabledLoadFileButton: t.boolean,
  maxFileCount: t.number,
  limitFileType: t.boolean,
  acceptFileTypes: t.array(t.enumeration<FileType>(Object.values(FileType))),
  adminFiles: t.array(File),
  questionStyle: t.enumeration<QuestionStyle>(Object.values(QuestionStyle)),
  size: t.enumeration<SizeEnum>(Object.values(SizeEnum)),
  align: t.enumeration<AlignEnum>(Object.values(AlignEnum)),
})

export const LogicOption = t.model({
  value: t.string,
  isCustom: t.boolean,
})

export enum PermissionType {
  expert = '0',
  admin = '1',
  shortAnswersView = '2',
  editor = '3',
  copyingSurveys = '4',
}

export const Permission = t.model({
  id: t.identifier,
  type: t.enumeration<PermissionType>(Object.values(PermissionType)),
  surveyId: t.maybeNull(t.string),
})
