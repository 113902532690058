import { observer } from 'mobx-react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { ColorTheme } from '@/Models/colorTheme'
import { ToolTip } from '@/Components'
import bem from '@/Helpers/BemClass'

import { TitleContainer } from '../Components/Accordion/styled'
import { useMst } from '../../../../../Stores/rootStore'
import { IPageInstance } from '../../../../../Stores/AdminStores/SurveyInfoStores/pagesStore'

import PageFiltersBlock from './PageFiltersBlock'
import { AccordionSummaryContent, ContentContainer } from './styled'
import Question from './Question'

import '../Components/Accordion/style.scss'

const cnBranchingTab = bem()('branching-tab')

interface IPageProps {
  page: IPageInstance
  index: number
  isFirstPage: boolean // Если первая страница, то для нее невозможно настроить фильтры
  disabled: boolean
}

const Page = ({ page, index, isFirstPage, disabled }: IPageProps) => {
  const store = useMst().admin.surveyInfoRootStore.branchingStore
  const pageLogic = store.pagesLogic.get(page.id ?? '')
  const surveyWithCards = store.surveyWithCards
  if (!pageLogic) return <></>

  return <Accordion className={cnBranchingTab('styled-accordion')} TransitionProps={{ unmountOnExit: true }} expanded={pageLogic.isExpanded} onChange={pageLogic.toggleExpanded}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
      className={cnBranchingTab('styled-accordion__summary', {
        'error': pageLogic.hasErrors,
        'warning': pageLogic.hasDeprecatedItems,
        'has-logic': pageLogic.hasLogic,
      })}
    >
      <AccordionSummaryContent>
        <Typography align='left'>
          Страница {index}
        </Typography>
        <ToolTip title={page.title} arrow theme={ColorTheme.dark}>
          <TitleContainer>
            <b>{page.title}</b>
          </TitleContainer>
        </ToolTip>
        <div></div>
      </AccordionSummaryContent>
    </AccordionSummary>
    <AccordionDetails>
      <ContentContainer>
        {!isFirstPage && !surveyWithCards && <PageFiltersBlock pageId={page.id ?? ''} disabled={disabled}/>}
        {page.questions.map(x => <Question key={x.id} pageId={page.id ?? ''} question={x} disabled={disabled}/>)}
      </ContentContainer>
    </AccordionDetails>
  </Accordion>
}

export default observer(Page)

