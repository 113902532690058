import { ReactNode } from 'react'
import { CSSTransition } from 'react-transition-group'
import { observer } from 'mobx-react'
import { Box, Grid, Stack, Typography } from '@mui/material'

import { IQuestionInstance } from '@/Stores/UserStores/pageStore'
import htmlSafeParse from '@/Helpers/htmlSafeParseHelper'
import { MultilineTextField } from '@/Components/index'
import bem from '@/Helpers/BemClass'

import '@/Components/Questions/style.scss'

const cnQuestion = bem()('question')

interface IQuestionLayout {
  /** Вопрос по которому строится компонент */
  question: IQuestionInstance
  /** Текст подсказки если админ не указал подсказку */
  alternativeHelperText?: string
  /** Признак добавления расстояния над основным компонентом вопроса для tooltip */
  tooltipSpace?: boolean
  /** Признак отображения поля ввода комментария */
  showComment?: boolean
  /** Признак обязательного комментария */
  requiredComment?: boolean
  /** Значение в комментарии */
  commentValue?: string
  /** Событие изменения комментария */
  onCommentChange?: (value: string) => void
  /** То что будет отображено в основной части вопросов */
  children?: ReactNode
}

const QuestionLayout = ({ question, alternativeHelperText = '', tooltipSpace = false, showComment = false, requiredComment = false, commentValue, onCommentChange, children }: IQuestionLayout) => {
  return <Grid container className={cnQuestion({ 'with-tooltip': tooltipSpace, 'main-question': question.isMain })}>
    <Stack width='100%' direction='column'>
      <div className={cnQuestion('text-title')}>
        {question.isRequired && <span className={cnQuestion('text-title_required-mark')}>*</span>}
        <div className={cnQuestion('text-title_text')}>{htmlSafeParse(question.text)}</div>
      </div>
      {question.showHelperText && <Typography variant='body1' component='p' className={cnQuestion('text-helper')}>{question.helperText ?? alternativeHelperText}</Typography>}
      <div className={cnQuestion('body')}>{children}</div>
      <CSSTransition
        in={showComment}
        timeout={700}
        unmountOnExit>
        <Box mt={'20px'} className={cnQuestion('comment')}>
          {requiredComment && <span className={cnQuestion('comment-required-indicator')}>*</span>}
          <MultilineTextField
            placeholder='Комментарий'
            label='Комментарий'
            value={commentValue ?? ''}
            onChange={e => onCommentChange?.(e.target.value)}
            rows={3}
          />
        </Box>
      </CSSTransition>
    </Stack>
  </Grid>
}

export default observer(QuestionLayout)
