import { CommentType } from '@/Models/page'
import { IAnswerInstance, IQuestionInstance } from '@/Stores/UserStores/pageStore'

export const commentIsValid = (question: IQuestionInstance, answer?: IAnswerInstance) => {
  const comment = answer?.comment ?? ''
  const hasAnswer = (answer?.value?.length ?? 0) > 0

  if (!question.isVisible) return true
  if ((question?.payload?.showNotReadyToRate ?? false) && answer?.isSkip) return true
  if (!question.isRequired && !question.isMain && !hasAnswer) return true

  switch (question.commentType) {
    case CommentType.CommentIsRequired:
    case CommentType.AlwaysVisibleAndRequired:
      return comment.length > 0
    case CommentType.VisibleToAndRequiredTo:
    case CommentType.AlwaysVisibleAndRequiredTo:
      return Number(answer?.singleValue ?? 0) > Number(question?.payload?.requireCommentValue) || comment.length > 0
    case CommentType.NoComment:
    case CommentType.Comment:
    case CommentType.AlwaysVisible:
    case CommentType.NotRequiredAndVisibleTo:
    default:
      return true
  }
}

export const commentIsRequired = (question: IQuestionInstance, answer?: IAnswerInstance) => {
  switch (question.commentType) {
    case CommentType.CommentIsRequired:
    case CommentType.AlwaysVisibleAndRequired:
      return true
    case CommentType.VisibleToAndRequiredTo:
    case CommentType.AlwaysVisibleAndRequiredTo:
      return Number(answer?.singleValue ?? 0) <= Number(question?.payload?.requireCommentValue)
    case CommentType.NoComment:
    case CommentType.Comment:
    case CommentType.AlwaysVisible:
    case CommentType.NotRequiredAndVisibleTo:
    default:
      return false
  }
}
