import { Instance, getParentOfType, getSnapshot, types as t } from 'mobx-state-tree'

import { SpreadsheetCellBorderStyleVariant, ISpreadsheetCellData, ISpreadsheetCellsData, ISpreadsheetCellBorderStyles, ISpreadsheetCellSummaryData } from '@/Models/spreadsheet'

export interface ISpreadsheetStoreInstance extends Instance<typeof SpreadsheetStore> { }
export interface ISpreadsheetCellStoreInstance extends Instance<typeof SpreadsheetCellStore> { }

export const SpreadsheetCellColor = t.model({
  r: t.number,
  g: t.number,
  b: t.number,
})

export enum InputType {
  None = '0',
  Text = '1',
  Number = '2',
}

export const SpreadsheetCellBorderStyles = t.model({
  variant: t.optional(t.enumeration<SpreadsheetCellBorderStyleVariant>(Object.values(SpreadsheetCellBorderStyleVariant)), SpreadsheetCellBorderStyleVariant.None),
  color: SpreadsheetCellColor,
})

export const SpreadsheetCellStylesStore = t.model({
  bgColor: t.maybeNull(SpreadsheetCellColor),
  fontColor: t.maybeNull(SpreadsheetCellColor),
  fontSize: t.number,
  isItalic: t.boolean,
  isBold: t.boolean,
  borderTop: t.maybeNull(SpreadsheetCellBorderStyles),
  borderRight: t.maybeNull(SpreadsheetCellBorderStyles),
  borderBottom: t.maybeNull(SpreadsheetCellBorderStyles),
  borderLeft: t.maybeNull(SpreadsheetCellBorderStyles),
})

export const SpreadsheetCellStore = t.model({
  fromRow: t.number,
  toRow: t.number,
  fromColumn: t.number,
  toColumn: t.number,
  value: t.string,
  styles: SpreadsheetCellStylesStore,
  isSelected: t.optional(t.boolean, false),
  inputValue: t.optional(t.string, ''),
  inputType: t.optional(t.enumeration<InputType>(Object.values(InputType)), InputType.None),
}).actions(self => ({
  togleSelect: (rewrite: boolean) => {
    if (rewrite) {
      const parentStore = getParentOfType(self, SpreadsheetStore)
      parentStore.cells.forEach(x => x.setSelected(false))
    }
    self.isSelected = !self.isSelected
  },
  setSelected: (isSelected: boolean) => {
    self.isSelected = isSelected
  },
  checkLineAndSetSelection: (isSelected: boolean, order: number, line: 'row' | 'col', clearIfNotMatch: boolean) => {
    if (self.inputType !== InputType.None) {
      self.isSelected = false
      return
    }

    if (line === 'row' && self.fromRow === order) {
      self.isSelected = isSelected
    } else if (line === 'col' && self.fromColumn === order) {
      self.isSelected = isSelected
    } else if (clearIfNotMatch) {
      self.isSelected = false
    }
  },
  selectInclusive: () => {
    const parentStore = getParentOfType(self, SpreadsheetStore)
    parentStore.cells.forEach(x => x.setSelected(false))

    self.isSelected = true
  },
  clearModifications: () => {
    self.isSelected = false
    self.inputType = InputType.None
    self.inputValue = self.value
  },
  clearInput: () => {
    self.inputValue = self.value
  },
  setInputValue: (inputValue: string) => {
    self.inputValue = inputValue
  },
  setValue: (value: string) => {
    self.value = value
  },
  setInputType: (inputType: InputType) => {
    if (inputType === InputType.Number && isNaN(Number.parseFloat(self.value)) && self.value !== '') {
      return
    }

    self.inputValue = self.value
    self.inputType = inputType
  },
}))


export const SpreadsheetStore = t.model({
  cells: t.array(SpreadsheetCellStore),
  columnsWidth: t.array(t.number),
  rowsHeight: t.array(t.number),
}).views(self => ({
  get hasNotEditableInSelected(): boolean {
    return self.cells.some(x => x.isSelected && x.inputType === InputType.None)
  },
  get isSingleSelectinon(): boolean {
    return self.cells.filter(x => x.isSelected).length === 1
  },
  get tryGetSelectedElementsEqualInputType(): InputType | undefined {
    const selectedCellsType = self.cells.filter(x => x.isSelected).map(x => x.inputType)

    if (selectedCellsType.length === 0) {
      return undefined
    }

    const firstInputType = selectedCellsType[0]

    return selectedCellsType.every(x => x === firstInputType) ? firstInputType : undefined
  },
})).actions(self => ({
  selectLine: (order: number, line: 'row' | 'col', rewrite: boolean) => {
    const hasDeselected = self.cells.some(x => (line === 'row' ? x.fromRow === order : x.fromColumn === order) && !x.isSelected && x.inputType === InputType.None)

    self.cells.forEach(x => {
      x.checkLineAndSetSelection(hasDeselected, order, line, rewrite)
    })
  },
  setSelectedAsEditable: (inputType: InputType) => {
    self.cells.forEach(x => {
      if (x.isSelected) {
        x.setInputType(inputType)
      }
    })
  },
  deselectAll: () => {
    self.cells.forEach(x => x.setSelected(false))
  },
  clearModifications: () => {
    self.cells.forEach(x => x.clearModifications())
  },
  clearInput: () => {
    self.cells.forEach(x => x.clearInput())
  },
  fillFromData: (data: ISpreadsheetCellsData, editableCells: Array<{ x: number, y: number, type: InputType }>) => {
    self.columnsWidth.replace(data.columnsWidth)
    self.rowsHeight.replace(data.rowsHeight)

    // Карта чтобы искать информацию о соседних ячейках, даже если они смержены
    const cellsMap = new Map<number, Map<number, ISpreadsheetCellData>>()

    Object.entries(data.cells).forEach(([_, cellsRow]) => {
      Object.entries(cellsRow).forEach(([_, cell]) => {

        for (let row = cell.fromRow; row < cell.toRow + 1; row++) {
          for (let col = cell.fromColumn; col < cell.toColumn + 1; col++) {
            let rowInfo = cellsMap.get(row)

            if (rowInfo === undefined) {
              cellsMap.set(row, new Map<number, ISpreadsheetCellData>())
              rowInfo = cellsMap.get(row)!
            }

            rowInfo.set(col, cell)
          }
        }
      })
    })

    // cellBoxes.filter(x => x.fromColumn - x.toColumn > 1).flatMap(x => {

    // });

    for (let row = 0; row < data.rowsHeight.length; row++) {
      for (let col = 0; col < data.columnsWidth.length; col++) {
        const cell = data.cells[row]?.[col] as (ISpreadsheetCellData | undefined)

        if (cell) {
          /*
            TODO: У exccel поведение если нет границы и сделали цветной фон - границы пропадают. Это можно вычислить проверив свои и соседа границу и цвет.
            Если нет границы и соседа, удаляем границу. Но это потенициально ломается, т.к. цвет смерженых ячеек внезапно белый
            Оставил чтобы можно было вернуться подумать
          */

          // topBorder и leftBorder убраны чтобы граница была тоньше

          // Если у ячейки есть стиль - берем, иначе берем с соседней ячейки
          // const topBorder: ISpreadsheetCellBorderStyles | null = (cell.styles.borderTop && cell.styles.borderTop.variant !== SpreadsheetCellBorderStyleVariant.None) ? (
          //   cell.styles.borderTop
          // ) : (
          //   cellsMap.get(row - 1)?.get(col)?.styles?.borderBottom ?? { variant: SpreadsheetCellBorderStyleVariant.None, color: { r: 0, g: 0, b: 0 } }
          // )

          // Если у этой и соседней ячейки дефолтные границы (или отсутствуют) и одна из ячеек имеет цвет - удяляем границы
          // const topCell = cellsMap.get(row - 1)?.get(col)
          // if (topBorder.variant === SpreadsheetCellBorderStyleVariant.None && ((topCell?.styles.borderBottom?.variant ?? SpreadsheetCellBorderStyleVariant.None) === SpreadsheetCellBorderStyleVariant.None) && (!!topCell?.styles?.color || !!cell.styles.color)) {
          //   topBorder = null
          // }

          const rightBorder: ISpreadsheetCellBorderStyles | null = (cell.styles.borderRight && cell.styles.borderRight.variant !== SpreadsheetCellBorderStyleVariant.None) ? (
            cell.styles.borderRight
          ) : (
            cellsMap.get(row)?.get(col + 1)?.styles?.borderLeft ?? { variant: SpreadsheetCellBorderStyleVariant.None, color: { r: 0, g: 0, b: 0 } }
          )

          // Если у этой и соседней ячейки дефолтные границы (или отсутствуют) и одна из ячеек имеет цвет - удяляем границы
          // const rightCell = cellsMap.get(row)?.get(col + 1)
          // if (rightBorder.variant === SpreadsheetCellBorderStyleVariant.None && ((rightCell?.styles.borderLeft?.variant ?? SpreadsheetCellBorderStyleVariant.None) === SpreadsheetCellBorderStyleVariant.None) && (!!rightCell?.styles?.color || !!cell.styles.color)) {
          //   rightBorder = null
          // }

          const bottomBorder: ISpreadsheetCellBorderStyles | null = (cell.styles.borderBottom && cell.styles.borderBottom.variant !== SpreadsheetCellBorderStyleVariant.None) ? (
            cell.styles.borderBottom
          ) : (
            cellsMap.get(row + 1)?.get(col)?.styles?.borderTop ?? { variant: SpreadsheetCellBorderStyleVariant.None, color: { r: 0, g: 0, b: 0 } }
          )

          // Если у этой и соседней ячейки дефолтные границы (или отсутствуют) и одна из ячеек имеет цвет - удяляем границы
          // const bottomCell = cellsMap.get(row + 1)?.get(col)
          // if (bottomBorder.variant === SpreadsheetCellBorderStyleVariant.None && ((bottomCell?.styles.borderTop?.variant ?? SpreadsheetCellBorderStyleVariant.None) === SpreadsheetCellBorderStyleVariant.None) && (!!bottomCell?.styles?.color || !!cell.styles.color)) {
          //   bottomBorder = null
          // }

          // const leftBorder: ISpreadsheetCellBorderStyles | null = (cell.styles.borderLeft && cell.styles.borderLeft.variant !== SpreadsheetCellBorderStyleVariant.None) ? (
          //   cell.styles.borderLeft
          // ) : (
          //   cellsMap.get(row)?.get(col - 1)?.styles?.borderRight ?? { variant: SpreadsheetCellBorderStyleVariant.None, color: { r: 0, g: 0, b: 0 } }
          // )

          /// Если у этой и соседней ячейки дефолтные границы (или отсутствуют) и одна из ячеек имеет цвет - удяляем границы
          // const leftCell = cellsMap.get(row)?.get(col - 1)
          // if (leftBorder.variant === SpreadsheetCellBorderStyleVariant.None && ((leftCell?.styles.borderRight?.variant ?? SpreadsheetCellBorderStyleVariant.None) === SpreadsheetCellBorderStyleVariant.None) && (!!leftCell?.styles?.color || !!cell.styles.color)) {
          //   leftBorder = null
          // }

          self.cells.push({
            fromRow: cell.fromRow,
            toRow: cell.toRow,
            fromColumn: cell.fromColumn,
            toColumn: cell.toColumn,
            value: cell.value,
            inputValue: cell.value,
            inputType: editableCells.find(x => x.x === col && x.y === row)?.type ?? InputType.None,
            styles: {
              bgColor: cell.styles.color ? SpreadsheetCellColor.create({
                r: cell.styles.color.r,
                g: cell.styles.color.g,
                b: cell.styles.color.b,
              }) : null,
              fontColor: cell.styles.fontColor ? SpreadsheetCellColor.create({
                r: cell.styles.fontColor.r,
                g: cell.styles.fontColor.g,
                b: cell.styles.fontColor.b,
              }) : null,
              fontSize: cell.styles.fontSize / 12,
              isItalic: cell.styles.isItalic,
              isBold: cell.styles.isBold,
              // borderTop: topBorder ? SpreadsheetCellBorderStyles.create({
              //   color: SpreadsheetCellColor.create({
              //     r: topBorder.color.r,
              //     g: topBorder.color.g,
              //     b: topBorder.color.b,
              //   }),
              //   variant: topBorder.variant,
              // }) : null,
              borderTop: null,
              borderRight: rightBorder ? SpreadsheetCellBorderStyles.create({
                color: SpreadsheetCellColor.create({
                  r: rightBorder.color.r,
                  g: rightBorder.color.g,
                  b: rightBorder.color.b,
                }),
                variant: rightBorder.variant,
              }) : null,
              borderBottom: bottomBorder ? SpreadsheetCellBorderStyles.create({
                color: SpreadsheetCellColor.create({
                  r: bottomBorder.color.r,
                  g: bottomBorder.color.g,
                  b: bottomBorder.color.b,
                }),
                variant: bottomBorder.variant,
              }) : null,
              // borderLeft: leftBorder ? SpreadsheetCellBorderStyles.create({
              //   color: SpreadsheetCellColor.create({
              //     r: leftBorder.color.r,
              //     g: leftBorder.color.g,
              //     b: leftBorder.color.b,
              //   }),
              //   variant: leftBorder.variant,
              // }) : null,
              borderLeft: null,
            },
          })
          // Если смерженая ячейка - скипаем, т.к. она отобразится в рамках первой ячейки смерженого диапазона
        } else if (!(data.mergedCells[row] ?? []).includes(col)) {
          // const topBorder: ISpreadsheetCellBorderStyles | null = cellsMap.get(row - 1)?.get(col)?.styles?.borderBottom ?? { variant: SpreadsheetCellBorderStyleVariant.None, color: { r: 0, g: 0, b: 0 } }

          // Если у соседней ячейки нет границы. но есть цвет, границы не должно быть
          // if (topBorder.variant === SpreadsheetCellBorderStyleVariant.None && cellsMap.get(row - 1)?.get(col)?.styles?.color !== null && cellsMap.get(row - 1)?.get(col)?.styles?.color !== undefined) {
          //   topBorder = null
          // }

          const rightBorder: ISpreadsheetCellBorderStyles | null = cellsMap.get(row)?.get(col + 1)?.styles?.borderLeft ?? { variant: SpreadsheetCellBorderStyleVariant.None, color: { r: 0, g: 0, b: 0 } }

          // Если у соседней ячейки нет границы. но есть цвет, границы не должно быть
          // if (rightBorder.variant === SpreadsheetCellBorderStyleVariant.None && cellsMap.get(row)?.get(col + 1)?.styles?.color !== null && cellsMap.get(row)?.get(col + 1)?.styles?.color !== undefined) {
          //   rightBorder = null
          // }

          const bottomBorder: ISpreadsheetCellBorderStyles | null = cellsMap.get(row + 1)?.get(col)?.styles?.borderTop ?? { variant: SpreadsheetCellBorderStyleVariant.None, color: { r: 0, g: 0, b: 0 } }

          // Если у соседней ячейки нет границы. но есть цвет, границы не должно быть
          // if (bottomBorder.variant === SpreadsheetCellBorderStyleVariant.None && cellsMap.get(row + 1)?.get(col)?.styles?.color !== null && cellsMap.get(row + 1)?.get(col)?.styles?.color !== undefined) {
          //   bottomBorder = null
          // }

          // const leftBorder: ISpreadsheetCellBorderStyles | null = cellsMap.get(row)?.get(col - 1)?.styles?.borderRight ?? { variant: SpreadsheetCellBorderStyleVariant.None, color: { r: 0, g: 0, b: 0 } }

          // Если у соседней ячейки нет границы. но есть цвет, границы не должно быть
          // if (leftBorder.variant === SpreadsheetCellBorderStyleVariant.None && cellsMap.get(row)?.get(col - 1)?.styles?.color !== null && cellsMap.get(row)?.get(col - 1)?.styles?.color !== undefined) {
          //   leftBorder = null
          // }

          // Если ячейки нет - она пустая
          self.cells.push({
            fromRow: row,
            toRow: row,
            fromColumn: col,
            toColumn: col,
            value: '',
            inputValue: '',
            inputType: editableCells.find(x => x.x === col && x.y === row)?.type ?? InputType.None,
            styles: {
              bgColor: null,
              fontColor: null,
              fontSize: 1,
              isItalic: false,
              isBold: false,
              // borderTop: topBorder ? SpreadsheetCellBorderStyles.create({
              //   color: SpreadsheetCellColor.create({
              //     r: topBorder.color.r,
              //     g: topBorder.color.g,
              //     b: topBorder.color.b,
              //   }),
              //   variant: topBorder.variant,
              // }) : null,
              borderTop: null,
              borderRight: rightBorder ? SpreadsheetCellBorderStyles.create({
                color: SpreadsheetCellColor.create({
                  r: rightBorder.color.r,
                  g: rightBorder.color.g,
                  b: rightBorder.color.b,
                }),
                variant: rightBorder.variant,
              }) : null,
              borderBottom: bottomBorder ? SpreadsheetCellBorderStyles.create({
                color: SpreadsheetCellColor.create({
                  r: bottomBorder.color.r,
                  g: bottomBorder.color.g,
                  b: bottomBorder.color.b,
                }),
                variant: bottomBorder.variant,
              }) : null,
              // borderLeft: leftBorder ? SpreadsheetCellBorderStyles.create({
              //   color: SpreadsheetCellColor.create({
              //     r: leftBorder.color.r,
              //     g: leftBorder.color.g,
              //     b: leftBorder.color.b,
              //   }),
              //   variant: leftBorder.variant,
              // }) : null,
              borderLeft: null,
            },
          })
        }
      }
    }

    // eslint-disable-next-line no-console
    console.log(getSnapshot(self))
  },
  updateValues: (answers: ISpreadsheetCellSummaryData[]) => {
    self.cells.forEach(cell => {
      const answer = answers.find(answer => answer.x === cell.fromColumn && answer.y === cell.fromRow)
      answer && cell.setValue(answer?.value)
    })
  },
  fillAnswers: (answers: string[]) => {
    let cellsCount = self.cells.length

    // Пока есть ячейки для перебора или ответы для записи
    while (cellsCount-- > 0 && answers.length > 0) {
      if (self.cells[cellsCount].inputType !== InputType.None) {
        self.cells[cellsCount].setInputValue(answers.shift() ?? '')
      }
    }
  },
}))
