import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
  }
}

const secondary = grey[600]

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1100,
      xxl: 1400,
    },
  },
  typography: {
    fontSize: 18,
    fontFamily: [
      '-apple-system',
      '"Proxima Nova"',
      'Roboto',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 40,
    },
    h2: {
      fontSize: 38,
    },
    h3: {
      fontSize: 34,
    },
    h4: {
      fontSize: 28,
    },
    h5: {
      fontSize: 24,
    },
    h6: {
      fontSize: 22,
    },
    body1: {
      fontSize: 19,
    },
    body2: {
      fontSize: 18,
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#266DC2',
    },
    secondary: {
      main: secondary,
    },
  },
})

theme = responsiveFontSizes(theme, { factor: 3.5 })

// Пример применения адаптивного размера для шрифта у h3 элементов MUI.
// Лучше делать именно так (через настройку глобальной темы), чем писать media-запросы в css и точечно применять ручные "фиксы" к размерам в отдельных компонентах и файлах.
/*
theme.typography.h3 = {
  [theme.breakpoints.down('xs')]: {
    fontSize: '2.4rem',
  },
}
*/

export { theme }
