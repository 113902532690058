import { ActionType, IAction } from '@/Models/answer'
import SpreadsheetSummary from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/Actions/SpreadsheetSummary'

interface IActionComponent {
  action: IAction
  questionId: string
}

const ActionComponent = ({ action, questionId }: IActionComponent) => {
  if (action.type === ActionType.Spreadsheet) {
    return <SpreadsheetSummary action={action} questionId={questionId}/>
  }
  return <></>
}

export default ActionComponent
