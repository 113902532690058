import styled from 'styled-components'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'

export const AnswerStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 0px 0;
  align-items: center;
`

export const ActionContainer = styled.div<{ isVisible: boolean }>`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  margin-left: 20px;

  & > *:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

export const ImageContainer = styled.div`
  padding-right: 10px;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 80px;

  & > *:nth-child(1) {
    width: 100%;
  }
`

export const PhotoImageContainer = styled.div`
  background-color: #E1E1E1;
  height: 60px;
  width: 60px;
  border-radius: 13px;
  margin-bottom: 4px;
`

export const AddPhotoAlternateIconStyled = styled(AddPhotoAlternateIcon)`
  height: 55px !important;
  width: 55px !important;
  padding-left: 5px;
  color: #686868;
`
