import { useState, ChangeEvent } from 'react'
import { observer } from 'mobx-react'
import { Dialog, Typography, Switch, Box, useTheme, useMediaQuery } from '@mui/material'

import { useMst } from '@/Stores/rootStore'
import { Button, Error as ErrorComponent, MultilineTextField } from '@/Components'
import { apiSetFeedbackService, apiSetFeedbackPage } from '@/Api/feedback'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnFeedbackModal = bem()('feedback-modal')

interface IFeedbackModal {
  isOpen: boolean
  handleClose: () => void
  pageId: string
}

const FeedbackModal = ({ isOpen, handleClose, pageId }: IFeedbackModal) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const [comment, setComment] = useState<string>('')
  const [error, setError] = useState<boolean>()
  const [switchIsChecked, setSwitchIsChecked] = useState<boolean>(true)
  const store = useMst()
  const survey = store.user.pageStore.currentSurvey

  const handleSendFeedback = async () => {
    if (!survey) throw new Error('Ошибка консистентности данных, нет currentSurvey')
    if (comment.length > 0) {
      if (switchIsChecked) await apiSetFeedbackPage(survey.surveyId, pageId, comment)
      else await apiSetFeedbackService(survey.surveyId, comment)
      handleClose()
      setComment('')
      setSwitchIsChecked(true)
    } else {
      setError(true)
    }
  }
  const handleTextChange = (e: ChangeEvent<{ value: string }>) => {
    setComment(e.target.value)
    setError(false)
  }
  const handleChangeChecked = () => {
    setSwitchIsChecked(!switchIsChecked)
  }
  return (<>
    <Dialog onClose={handleClose} open={isOpen}>
      <div className={cnFeedbackModal({ mobile: UIisMobile })}>
        <Typography variant='h5' component='h3'>
          Что хотите оценить?
        </Typography>
        <div className={cnFeedbackModal('switch')}>
          <span className={cnFeedbackModal('switch__label', { selected: !switchIsChecked })} onClick={handleChangeChecked}>Сервис опросов</span>
          <Switch
            disableRipple
            checked={switchIsChecked}
            onChange={handleChangeChecked}
          />
          <span className={cnFeedbackModal('switch__label', { selected: switchIsChecked })} onClick={handleChangeChecked}>Текущий опрос</span>
        </div>
        <Box className={cnFeedbackModal('comment')}>
          <MultilineTextField
            placeholder='Введите комментарий...'
            label='Комментарий'
            value={comment ?? ''}
            onChange={handleTextChange}
          />
        </Box>
        <ErrorComponent isVisible={error}>Введите комментарий</ErrorComponent>
        <Button width="158px" height="44px" onClick={handleSendFeedback}>
          Отправить
        </Button>
      </div>
    </Dialog>
  </>)
}

export default observer(FeedbackModal)
