export enum TrafficLightGroupType {
  Pages = 'Pages',
  Branching = 'Branching',
  TargetAudience = 'TargetAudience',
  Verification = 'Verification',
}

export enum TrafficLightPartType {
  ContainsDeletedTargetAudience = 'ContainsDeletedTargetAudience',
  BranchingError = 'BranchingError',
  ContainsDeprecatedBranchingElements = 'ContainsDeprecatedBranchingElements',
  EmptyTargetAudience = 'EmptyTargetAudience',
  NoPages = 'NoPages',
  Verified = 'Verified',
}

export enum TrafficLightColor {
  Green = '0',
  Yellow = '1',
  Red = '2',
}
