import styled from 'styled-components'

export const Container = styled.div`
  .requireCommentValue {
    margin-top: 25px;

    label {
      width: max-content;
    }

    .MuiInputBase-root {
      width: 270px;
    }
  }

`

export const ScaleSelectValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;

  div {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  & > div:nth-child(1) {
    margin-right: 60px;
  }
`
