import { useState } from 'react'
import { Dialog, IconButton, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

import { ReactComponent as Tick } from '@/Images/tick.svg'
import { ReactComponent as Required } from '@/Images/required.svg'
import { ReactComponent as PageComplete } from '@/Images/pageComplete.svg'
import {
  ISurveyInstance,
  IUserPageInstance,
} from '@/Stores/UserStores/userSurveysStore'
import { PermissionType } from '@/Stores/sharedModels/models'
import { AnswersView } from '@/Pages/User/StartSurvey/CardsSelectionPage/ServiceCard/AnswersView'
import '@/Pages/User/Styles/round-dialog.scss'
import { ToolTip } from '@/Components'
import RoleQualifier from '@/Components/RoleQualifier'
import htmlSafeParse from '@/Helpers/htmlSafeParseHelper'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import bem from '@/Helpers/BemClass'

import {
  ServiceCheckContainer,
  ServiceCheckBackgroundContainer,
  ServiceCheckBackground,
} from './styled'

import './style.scss'

const cnServiceCard = bem()('service-card')

interface IServiceCard {
  page: IUserPageInstance
  selected: boolean
  handleSelectService: (serviceId: string) => void
  isRequired: boolean
  survey: ISurveyInstance | undefined
}

const ServiceCard = ({
  page,
  selected,
  handleSelectService,
  isRequired,
  survey,
}: IServiceCard) => {
  const muiTheme = useTheme()
  const UIisTablet = useMediaQuery(muiTheme.breakpoints.down('md'))

  const [showStatisticDialog, setShowStatisticDialog] =
    useState<boolean>(false)

  const onSelectService = () => {
    if (page.isComplete || isRequired) return
    handleSelectService(page.id)
  }

  const getMark = () => {
    if (isRequired) {
      return (
        <ToolTip arrow title='Опрос по сервису обязателен к прохождению'>
          <ServiceCheckContainer>
            <Required />
          </ServiceCheckContainer>
        </ToolTip>
      )
    } else {
      return (
        <ServiceCheckContainer>
          <ServiceCheckBackgroundContainer />
          <ServiceCheckBackground />
          {selected && <Tick />}
        </ServiceCheckContainer>
      )
    }
  }

  // TODO: Не уверен что img нормально позиционируется в ie, стоит перепроверить
  return (
    <div
      onClick={onSelectService}
      className={cnServiceCard({ active: !page.isComplete })}
    >
      <div className={cnServiceCard('inner-container')}>
        <img
          className={cnServiceCard('image')}
          src={getStaticsHybridUrl(`/api/file/image/${page.imageId}`)}
        />
        {page.isComplete ? (
          <div className={cnServiceCard('service-complete-container')}>
            <PageComplete className={cnServiceCard('page-complete-img')} />
          </div>
        ) : (
          getMark()
        )}
        {page.isComplete &&
          <div className={cnServiceCard('answers-ref-container')}>
            <RoleQualifier roles={{ type: PermissionType.shortAnswersView, surveyId: survey?.surveyId ?? '' }}>
              <Typography
                onClick={() => setShowStatisticDialog(true)}
                className={cnServiceCard('answers-ref')}
              >
                Посмотреть результат
              </Typography>
            </RoleQualifier>
          </div>
        }
        <div className={cnServiceCard('description-container')}>
          <ToolTip arrow title={htmlSafeParse(page.title)}>
            <span className={cnServiceCard('description')}>
              {htmlSafeParse(page.title)}
            </span>
          </ToolTip>
        </div>
        {showStatisticDialog && (
          <Dialog
            fullScreen={UIisTablet}
            onClose={() => setShowStatisticDialog(false)}
            open={showStatisticDialog}
            PaperProps={{
              classes: {
                rounded: `rounded-paper ${UIisTablet ? 'rounded-paper--mobile' : ''}`,
              },
            }}
          >
            <div>
              <div className='actions'>
                <IconButton
                  edge='end'
                  color="default"
                  onClick={() => setShowStatisticDialog(false)}
                  aria-label='close'
                  className='btn--close'
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <AnswersView
                surveyId={survey?.surveyId ?? ''}
                pageId={page.id}
                onClose={() => setShowStatisticDialog(false)}
              />
            </div>
          </Dialog>
        )}
      </div>
    </div>
  )
}

export default ServiceCard
