import { useEffect, useState } from "react";

export const useMouseDown = (onDown: () => void) => {
  useEffect(() => {
    const onMoveEvent = onDown;
    window.addEventListener("mousedown", onMoveEvent);

    return () => {
      window.removeEventListener("mousedown", onMoveEvent);
    };
  }, []);
};