import styled from 'styled-components'

import { IColor, SpreadsheetCellBorderStyleVariant, ISpreadsheetCellBorderStyles } from '@/Models/spreadsheet'
import { borderVariantToStyle } from '@/Helpers/borderVariantToStyle'


const getBorder = (borderStyle: ISpreadsheetCellBorderStyles | null | undefined, direction: 'top' | 'right' | 'bottom' | 'left', forceAddBorder: boolean) => {
  if (borderStyle) {
    if (borderStyle.variant !== SpreadsheetCellBorderStyleVariant.None) {
      return `border-${direction}: 1px ${borderVariantToStyle.get(borderStyle.variant) ?? 'solid'} rgb(${borderStyle.color.r}, ${borderStyle.color.g}, ${borderStyle.color.b});`
    } else if (borderStyle) {
      return `border-${direction}: 1px solid lightgray;`
    }
  }

  return forceAddBorder ? `border-${direction}: 1px solid lightgray;` : ''
}

export const SpreadsheetCell = styled.div<{
  fromRow: number
  toRow: number
  fromColumn: number
  toColumn: number
  width?: number
  height?: number
  bgcolor?: (IColor | null)
  fontColor?: (IColor | null)
  fontSize?: number
  isItalic?: boolean
  isBold?: boolean
  borderTop?: (ISpreadsheetCellBorderStyles | null)
  borderRight?: (ISpreadsheetCellBorderStyles | null)
  borderBottom?: (ISpreadsheetCellBorderStyles | null)
  borderLeft?: (ISpreadsheetCellBorderStyles | null)
  forceAddBorder?: boolean
}>`
  grid-column-start: ${props => props.fromColumn};
  grid-column-end: ${props => props.toColumn};
  grid-row-start: ${props => props.fromRow};
  grid-row-end: ${props => props.toRow};
  ${props => (props.width ? `min-width: ${props.width}px;` : '')}
  ${props => (props.height ? `min-height: ${props.height}px;` : '')}

  ${props => (props.bgcolor ? `background-color: rgb(${props.bgcolor.r}, ${props.bgcolor.g}, ${props.bgcolor.b});` : '')}
  ${props => (props.fontColor ? `color: rgb(${props.fontColor.r}, ${props.fontColor.g}, ${props.fontColor.b});` : '')}
  ${props => (props.fontSize ? `font-size: ${props.fontSize}rem;` : '')}

  ${props => (props.isItalic ? 'font-style: italic;' : '')}
  ${props => (props.isBold ? 'font-weight: bold;' : '')}

  position: relative;

  ${props => getBorder(props.borderTop, 'top', false)}
  ${props => getBorder(props.borderRight, 'right', props.forceAddBorder ?? false)}
  ${props => getBorder(props.borderBottom, 'bottom', props.forceAddBorder ?? false)}
  ${props => getBorder(props.borderLeft, 'left', false)}

  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
`
