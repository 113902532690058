import { CSSProperties } from 'react'
import { ListItem } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'

import { LabelStyled } from '@/Pages/Admin/SurveyInfoPage/ServicesTab/ServiceListItem/styled'
import { SurveyType } from '@/Stores/AdminStores/surveysStore'

import { CategoryContainer, SpanCheckMarkStyled } from './styled'

export interface ICategoryListItem {
  surveyType: SurveyType | undefined
  checked: boolean
  determinate: boolean
  name: string
  id: string
  servicesCount: number
  servicesCountWithPage: number
  handleGroupCheck: (id: string) => void
  style: CSSProperties
  disabled: boolean
}

const CategoryListItem = ({ surveyType, checked, determinate, name, id, servicesCount, servicesCountWithPage, handleGroupCheck, style, disabled }: ICategoryListItem) => {
  return (<CategoryContainer style={style}>
    {surveyType === SurveyType.Gvk && <LabelStyled>
      {!disabled && <input
        type="checkbox"
        checked={checked}
        onChange={() => handleGroupCheck(id)}
      /> }
      {!disabled &&
        <SpanCheckMarkStyled>
          {determinate && <RemoveRoundedIcon/>}
          {checked && <CheckIcon/>}
        </SpanCheckMarkStyled>
      }
    </LabelStyled>}
    <ListItem style={{ fontWeight: 800 }}>
      {name.length > 0 ? name : 'Прочее'} ({servicesCount}/{servicesCountWithPage})
    </ListItem>
  </CategoryContainer>)
}

export default CategoryListItem
