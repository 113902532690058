import { useState } from 'react'
import { Button } from '@mui/material'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'

import Dialog, { IDialog } from '@/Components/Dialog'
import { useRouteManager } from '@/Hooks/useRouteManager'
import bem from '@/Helpers/BemClass'
import { useMst } from '@/Stores/rootStore'

import './style.scss'

const cnGvkPageHeader = bem()('gvk-page-header')

const GvkPageHeader = () => {
  const tabStore = useMst().admin.tabStore
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const routeManager = useRouteManager()

  const handleRedirect = () => {
    setDialogState(undefined)
    routeManager.goBack()
    tabStore.setWasChange(false)
  }

  const redirect = () => {
    if (tabStore.wasChanged) {
      setDialogState({
        open: true,
        handleClose: () => setDialogState(undefined),
        title: 'На странице были изменения',
        text: 'Выйти без сохранения?',
        actions: [
          <Button key='changes-no-btn' onClick={() => setDialogState(undefined)}>Нет</Button>,
          <Button key='changes-yes-btn' onClick={() => handleRedirect()}>Да</Button>,
        ],
      })
    } else {
      routeManager.goBack()
      tabStore.setWasChange(false)
    }
  }

  return (<div className={cnGvkPageHeader()}>
    <ArrowBackOutlinedIcon onClick={() => redirect()}/>
    <h2>Создание опроса</h2>
    {dialogState && <Dialog {...dialogState} />}
  </div>)
}

export default GvkPageHeader
