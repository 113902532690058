import { useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { useParams } from 'react-router'
import { Button } from '@mui/material'

import StyledButton from '@/Components/Button'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { ContentLoader } from '@/Components'
import Dialog, { IDialog } from '@/Components/Dialog'
import { apiSetGvkPage } from '@/Api/page'
import { useMst } from '@/Stores/rootStore'
import { RouteEnum } from '@/Enums/route'
import { defaultPayload } from '@/Models/page'
import { IServiceInstance } from '@/Stores/AdminStores/adminServicesStore'
import Page from '@/Pages/Admin/EditPage/Components/Page'
import { SurveyStatus } from '@/Stores/AdminStores/surveysStore'

import { SaveButtonContainer } from './styled'

interface IGvkEditPage {
  readonly: boolean
}

const GvkEditPage = ({ readonly }: IGvkEditPage) => {
  const { surveyId, serviceId } = useParams<{ surveyId: string, serviceId: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const [service, setService] = useState<IServiceInstance>()
  const routeManager = useRouteManager()
  const store = useMst()
  const pageStore = store.admin.surveyInfoRootStore.pagesStore
  const survey = store.admin.surveysStore.getSurvey(surveyId)
  const [dialogState, setDialogState] = useState<IDialog | undefined>()

  useMount(() => {
    (async () => {
      if (!survey) {
        return
      }

      await pageStore.loadOrCreate(surveyId, serviceId)

      let service = store.admin.adminServicesStore.getService(serviceId)
      if (!service) {
        await store.admin.adminServicesStore.loadServices(surveyId)
        service = store.admin.adminServicesStore.getService(serviceId)
        if (!service) return
      }

      const mainQuestions = survey.getMainQuestionsAsIQuestionVersion()

      const page = pageStore.getPageRequired(serviceId)
      page.fillFromService(service)
      page.addMainQuestionIfNotExist(mainQuestions)

      setService(service)

      setIsLoading(false)
    })()
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isLoading])

  if (!survey) {
    routeManager.redirectTo(RouteEnum.survey)
    return <></>
  }

  const handleSave = async () => {
    setIsLoading(true)

    const page = pageStore.getPageRequired(serviceId)

    page.validate()

    if (!page.hasSelfValidationErrors) {
      const resultQuestions = [...page.orderedQuestions]

      let hasBranchingChanges = false
      if (resultQuestions.length > 0) {
        hasBranchingChanges = await apiSetGvkPage(surveyId, serviceId, {
          id: page.id,
          imageId: page.imageId,
          title: page.title,
          description: page.description,
          category: page.category,
          questions: resultQuestions.map(x => (
            {
              ...x,
              payload: x?.payload ? x.payload : defaultPayload,
            }
          )),
          isRequired: page.isRequired,
          order: page.order,
        })
      }

      if (hasBranchingChanges) {
        setDialogState({
          open: true,
          handleClose: () => {
            setDialogState(undefined)
            routeManager.goBack()
          },
          title: 'Некоторые правила могли перестать работать!',
          text: 'Необходимо скорректировать настройки на вкладке Ветвление',
          actions: [<Button
            key='page-changes-ok-btn'
            onClick={() => {
              setDialogState(undefined)
              routeManager.goBack()
            }}
          >ОК</Button>],
        })
      } else {
        routeManager.goBack()
      }

      store.admin.tabStore.setWasChange(false)
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  if (isLoading) return <ContentLoader/>

  const saveButton = (
    <SaveButtonContainer>
      <StyledButton onClick={handleSave} height="40px">Сохранить</StyledButton>
    </SaveButtonContainer>
  )

  return (<>
    <Page page={pageStore.getPageRequired(serviceId)} hasActivePeriod={(survey?.version ?? 0) > 0 || survey?.status === SurveyStatus.Surveyed} service={service ?? null} isGvk isCardState={false} readonly={readonly} isRepeatableSurveyed={false} disableAccordion/>
    {survey && !readonly && saveButton}
    {!isLoading && dialogState && <Dialog {...dialogState} />}
  </>)
}


export default GvkEditPage
