import { useState, ChangeEvent, memo } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import Page from '../Page'
import { IPageModel, DropType } from '../../Models/models'
import DragLabel from '../../../../../../Components/DragAndDrop/DragLabel'
import CategoryCreator from '../CategoryCreator'

import { CategoryContainer, CategoryHeader, CategoryData, HeaderText, UseButtons } from './styled'

interface ICategory {
  existCategoryName: string[]
  pages: IPageModel[]
  name: string
  order: number
  isOther: boolean
  index: number
  onIsRequiredChange: (categoryName: string) => (pageId: string) => (e: ChangeEvent<HTMLInputElement>) => void
  onDeleteCategory: (name: string) => void
  onUpdateCategory: (oldName: string, newName: string) => void
  disabled: boolean
}

const Category = ({ existCategoryName, pages, name, order, isOther, index, onIsRequiredChange, onDeleteCategory, onUpdateCategory, disabled }: ICategory) => {
  const [showEditCategoryModal, setShowEditCategoryModal] = useState<boolean>(false)

  const handleDeleteCategory = () => {
    onDeleteCategory(name)
  }

  const handleUpdateCategory = (newName: string) => {
    onUpdateCategory(name, newName)
    setShowEditCategoryModal(false)
  }

  const pageListLayout = (<Droppable droppableId={name} type={DropType.page} >
    {(provided, snapshot) => (
      <div ref={provided.innerRef} {...provided.droppableProps} >
        {pages.map((page, idx) => <Page {...page} key={page.id}
          order={page.order}
          onIsRequiredChange={onIsRequiredChange(name)}
          index={idx}
          disabled={disabled}
        />
        )}
        { provided.placeholder }
      </div>
    )}
  </Droppable>)


  if (!isOther && !disabled) {
    return (<>
      <Draggable key={name} draggableId={name} index={index} >
        {(provided, snapshot) => (
          <CategoryContainer ref={provided.innerRef} {...provided.draggableProps}>
            <CategoryHeader>
              <div />
              <HeaderText variant="h4">{name}</HeaderText>
              <UseButtons>
                <DragLabel color='white' dragHandleProps={provided.dragHandleProps} />
                <EditIcon style={{ color: 'white' }} onClick={() => setShowEditCategoryModal(true)}/>
                <DeleteOutlineIcon style={{ color: 'white' }} onClick={handleDeleteCategory}/>
              </UseButtons>
            </CategoryHeader>
            <CategoryData>{pageListLayout}</CategoryData>
          </CategoryContainer>
        )}
      </Draggable>
      {showEditCategoryModal && <CategoryCreator
        defaultValue={name}
        existCategoryName={existCategoryName}
        isOpen={showEditCategoryModal}
        onClose={() => setShowEditCategoryModal(false)}
        onSave={handleUpdateCategory}
      />
      }</>)
  } else {
    return (<CategoryContainer>
      <CategoryHeader>
        <HeaderText variant="h4">{name}</HeaderText>
      </CategoryHeader>
      <CategoryData>{pageListLayout}</CategoryData>
    </CategoryContainer>)
  }
}

export default memo(Category)
